import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../shared/Loader";
import ProductionFunding from "./ProductionFunding"

import { employeelist, employerProdFunding, productionContribution, productionUnionList, unionContribution } from "../Services/https/Employer/Employer";
import { prodOverview } from "../Services/https/Admin/Admin";

import Pagination from "../shared/Pagination";
import NoRecords from "../shared/NoRecords";
import toast from "react-hot-toast";

const AdminProductionFocused = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false)
  const [employeesList, setEmployeeList] = useState([]);
  const [unionList, setUnionList] = useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const [productionfund, setProductionFund] = useState(null);
  const [unionfund, setUnionFund] = useState(null);
  const [unionid, setUnionId] = useState(null);
  const [funding,setFunding]=useState([])
  const pageCount = Math.ceil(totalcount / 10);
  const [options, setOptions] = useState({});
  const [overview,setOverview]=useState([]);
  const completed = Math.trunc((funding?.spent_amount / funding?.total_amount) * 100);


  useEffect(() => {
    employeelist(id, "", 12, initialpage).then((response) => {
      setEmployeeList(response?.data?.data?.paginate?.data);
      setTotalcount(response?.data?.data?.total);
    }).catch(err => {
      return err
    })
  }, [initialpage])

  useEffect(() => {
    productionUnionList(id).then((response) => {
      setUnionList(response?.data?.data?.paginate?.data);
      setUnionId(response?.data?.data?.paginate?.data?.[0]?.union_id)
    }).catch(err => {
      return err
    })

    productionContribution(id).then((response) => {
      setProductionFund(response?.data?.data);
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      return err
    })
    prodOverview(id).then((response)=>{
      setOverview(response?.data?.data)
    }).catch(err=>{
      setLoader(false)
      return err
    })
  }, [])


  useEffect(()=>{
    employerProdFunding(id).then((response) => {
      setFunding(response?.data?.data);
      setOptions({
       series: [completed],
       options: {
         chart: {
           height: 350,
           type: "radialBar",
         },
         plotOptions: {
           radialBar: {
             hollow: {
               size: "70%",
             },
             dataLabels: {
               name: {
                 offsetY: -10,
                 color: "#000000",
                 fontSize: "24px",
                 fontWeight: 800,
                 formatter: () => {
                   return [
                     `$${response?.data?.data?.spent_amount}`,
                     `$${response?.data?.data?.total_amount}`,
                   ];
                 },
               },
               value: {
                 color: "#000000",
                 fontSize: "24px",
                 fontWeight: 800,
 
                 show: false,
               },
             },
           },
         },
         fill: {
           colors: ["#44B5AE"],
         },
         labels: [`${response?.data?.data?.spent_amount}`, `${response?.data?.data?.total_amount}`],
       },
     });
     }).catch(err => {
      setLoader(false)
       return err
     })

  },[])

  useEffect(() => {
    setLoader(true)
    if (unionid) {
      unionContribution(id, unionid).then(response => {
        setLoader(false);
        setUnionFund(response?.data?.data)
      }).catch(err => {
        toast.error(err?.response?.data?.message);
        setUnionFund("00.00")
        setLoader(false)
        return err
      })
    }
  }, [unionid])

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };



  return (
    <>
      {loader && <Loader />}
      <h3
        className="title_h3"
        style={{ paddingLeft: "15px" }}
        onClick={() => navigate(-1)}
      >
      <span style={{cursor:"pointer",marginRight:"10px"}}>&lt;</span>   {overview?.production_name}
      </h3>
      <div className="row ms-0 me-0 mt-3">
        <div className="col-xl-3 col-sm-12">
          <div className="white_box">
            <div className="heading_box adminDashboard">
              <h5 className="title_h5_line">
                {t("Admin.ProductionFocused.Overview")}
              </h5>
              <h5>
                <strong>{t("Admin.ProductionFocused.Manager")}:</strong>{" "}
              </h5>
              <h6>{overview?.name}</h6>
              <h5>
                <strong>{t("Admin.ProductionFocused.AccountingContact")}:</strong>
              </h5>
              <h6>{overview?.accounting_contact}</h6>
              <h5>
                <strong>{t("Admin.ProductionFocused.OperationsContact")}:</strong>
              </h5>
              <h6>{overview?.operation_contacts}</h6>{" "}
              <h5>
                <strong>{t("Employer.Dashboard.Union")}:</strong>
              </h5>
              {overview?.union?.map((list,index)=>{
                return(
                  <React.Fragment key={index}>
                  <h6>{list?.union_name}</h6>
                  </React.Fragment>
                )
              }
                
              )}
              <h5>
                <strong>{t("Admin.ProductionFocused.Payroll")}:</strong>
              </h5>
              <h6>{overview?.affiliated_payroll_company}</h6>{" "}
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-sm-12">
          <div className="white_box employess_in_production">
            <h3 className="title_h5_line">
              {t("Admin.ProductionFocused.Employees")}
            </h3>

            {show ? (
              <>
                <div className="row employers_box employers_box_kids">
                  { 
                  employeesList?.length === 0 ? <NoRecords/> :
                    employeesList?.map((list, index) => {
                      return (
                        <React.Fragment key={index}>
                        <div className="col-xl-4">
                          <ul className="productions_list">
                            <li>
                              <img src={list?.profile_image_url} alt="" />
                              <div className="d-inline-flex w100p">
                                <h5>{list?.first_name} {list?.last_name}</h5>
                                <h6>{list?.production_employee_role==null || list?.production_employee_role==="null"? t('Employer.Add_employee.Role'): list?.production_employee_role}</h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
                <div className="custom_pagination a_productions">
                <div
                  className="show_text"
                  onClick={() => setShow((prev) => !prev)}
                >
                  {t("Admin.ProductionFocused.Hide")}
                </div>
                <span>
                  <ul className="page_navi ">
                    {employeesList?.length >= 1 ? (
                      <Pagination
                        initialpage={initialpage}
                        onpage={pageChange}
                        pageCount={pageCount}
                        custom="true"
                        
                      />
                    ) : null}
                  </ul>
                </span>
                </div>
                
                
              </>
            ) : (
              <>
                <div className="row employers_box p-4 employers_box_ed-kids">
                  <div className="row d-flex justify-content-center f-size-18 emp_text">
                    {t("Admin.ProductionFocused.EmployeeHeading")}
                    <br></br>{" "}
                    {t("Admin.ProductionFocused.EmployeeHeadingBreak")}
                  </div>
                </div>

                <div
                  className="show_text custom_pagination me-5"
                  onClick={() => setShow((prev) => !prev)}
                >
                  {t("Admin.ProductionFocused.Show")}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="row ms-0 me-0 mt-4">
        <div className="col-xl-7 col-sm-12">
          <div className="white_box ">
            <div>
              <ProductionFunding option={options} funding={funding}/>
            
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-sm-12">
          <div className="white_box">
            <div className="heading_box focused">
              <h5 className="title_h3_line">
                {t("Admin.ProductionFocused.Contributions")}
              </h5>
              <div>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setUnionId(e.target.value)}
                >

                  {
                    unionList?.length == 0 ? <option selected disabled>No union</option> :  unionList?.map((list, index) => {
                      return (
                        <React.Fragment key={index}>
                        <option value={list?.union_id}>{list?.union_name}</option>
                        </React.Fragment>
                      )
                    })
                  }
                </select>
              </div>

              <div className="mt-3">
                <div className="contributions_div">${unionList?.length===0 ?"0.00" :  unionfund}</div>
              </div>

              <div className="Employeer mt-4 ">
                {t("Admin.ProductionFocused.Employeer")}
              </div>
              <div className="contributions_div mt-4">${productionfund===0 ? "00.00":productionfund}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProductionFocused;
