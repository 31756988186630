import React, { useState } from "react";

import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { loginState } from "../../../Reducers/Login/loginSlice";

import { switchprod, UnionAddFunds, UnionConfirmProduction } from "../../../Services/https/Employer/Employer";

import Loader from "../../Loader";

const UnionFinalConfirmation = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState({
    amount: "",
    opt_out: 1
  });

  const id = new URLSearchParams(location.search).get("inviteid");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const union_name = new URLSearchParams(location.search).get("union_name");
  const link_guid = new URLSearchParams(location.search).get("link_guid");


  const unionAddFunds = () => {
    setLoader(true)
    const unionData = {
      production_puid: prod_guid,
      link_guid: link_guid,
      opt_out: data?.opt_out
    };

    //  Union Add Funds

    UnionAddFunds(unionData, data?.amount, union_name)
      .then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          window.open(res?.data?.data?.payment_url, "_self")
        } else if (res?.response?.status === 500) {
          setLoader(false);
          toast.error("Amount added cannot be empty");
        } else {
          setLoader(false);
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  }


  const Confirmed = () => {
    const uniondata = {
      production_puid: prod_guid,
      link_guid: id,
      opt_out: data?.opt_out
    };
    setLoader(true);
    UnionConfirmProduction(uniondata, union_name)
      .then((response) => {
        setLoader(false);
        if (response?.status === 200) {
          const data = {
            production_id: prod_guid,
          };
          switchprod(data)
            .then((response) => {
              dispatch(loginState(location.state));
              navigate("/union/dashboard");
              setLoader(false);
            })
            .catch((err) => {
              return err;
            });
        }
      })
      .catch((error) => {
        return error
      });
  }

  return (
    <>
      {loader && <Loader />}
      <div className="confirm-production confirm-production-kids">
        <div className="container">
          <div>
            <div>
              <h3 className="confirm-production-header">
                {" "}
                {t("Confirmation.Title")}
              </h3>

            </div>
            <h5 className="confirm-production-text mt-3">
              {t('Confirmation.Message')}
            </h5>

            <div>

              <p className="production-name mt-5">Contribution amount</p>
              <div className="contribution ">
                <div className="conti_input">
                  <input
                    className="contribution-amount"
                    name="contribution-amount"
                    placeholder=" 12,000.00"
                    onChange={(e) => setData({ amount: e.target.value, opt_out: 0 })}
                    disabled={checked}
                    value={data?.amount}
                    type="number"
                    step="any"
                  />
                </div>
                <button
                  className="confirm-amount ms-3  mt-2"
                  onClick={unionAddFunds}
                  disabled={!data?.amount}
                >
                  <u>Confirm</u>
                </button>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <input
                  className="addFunds_check form-check-input custom_input_check"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => { setData({ amount: "", opt_out: 1 }); setChecked(!checked) }}
                />
                <label
                  className=" ms-4  addFunds_check_label"
                  htmlFor="flexCheckDefault"
                >
                  {t(
                    "Employer.Funding.Add_funds.Optout"
                  )}
                </label>

              </div>
            </div>
          </div>

          <div className="mt-3 prod-buttons">
            <Button className="next ms-1" onClick={Confirmed}>
              {t("Confirmation.Next")}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnionFinalConfirmation