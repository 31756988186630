import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Dashboard from "../shared/Dashboard";
import FundsChart from "../Employer/FundsChart";
import EmployeCard from "../Employer/EmployeeCard";
import EmployeeHide from "../Employer/EmployeeHide";
import Contacts from "../Employee/dashboard/main/contacts";
import NoProduction from "../Employer/Noproduction";
import Loader from "../shared/Loader";
import CurrentProduction from "../Employer/DashboardCurrentProduction";

import { getcurrentprod } from "../Services/https/Employer/Employer";
import { UnionState } from "../Reducers/UnionReducer/unionSlice";
import Funding from "../Employer/DashboardFunding";

function UnionDashboard() {
  const [showEmployeeCard, setShowEmployeeCard] = useState(true);
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productions, setProductions] = useState({});
  const [change, setChange] = useState(false);
  const [loader, setLoader] = useState(true);
  const { name } = useSelector((state) => state.login);
  const handelHideAndShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    getcurrentprod()
      .then((response) => {
        setProductions(response?.data?.data);
        setLoader(false);
        dispatch(
          UnionState({
            production_id: response?.data?.data?.id,
          })
        );
      })
      .catch((err) => {
        return err;
      });
  }, [change]);

  const detectChange = (value) => {
    setChange(value)
  }
  return (

    <div className="dashboard_screen">
      <h3 className="title_h3">
        {t("Union.Dashboard.Welcome")}, <span>{name}</span>
      </h3>

      {
        loader ? (
          <Loader />
        ) : Object.keys(productions)?.length === 0 ? (
          <NoProduction />
        ) : (
          <div className="employer-dashboard">
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="row">
                  <div className="current-production">
                    <Dashboard
                      current={true}
                      text_decorator="true"
                      title={t("Union.Dashboard.Current_production")}
                      component={<CurrentProduction observechange={detectChange} change={change} />}
                    />
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="row">

                  <Dashboard
                    component={<Funding />}
                  />

                </div>
                <div className="row">
                  <Dashboard
                    text_decorator="false"
                    title={t("Union.Dashboard.Key_contacts")}
                    component={<Contacts data={productions} />}
                  />
                </div>
              </div>
            </div>

                 

                    <div className="row">
                    <div className="col-xl-12">
            <div className="dashboard_screen">
              <div className="white_box emp_pro_box">
                <div className="employee-union-cards">
                  <h4 className="title_h4_line">
                    <span>{t("Union.Dashboard.Emp_in_prod")}</span>{" "}
                  </h4>
                  {showEmployeeCard && (
                    <div className="employee-card">
                      {show ? (
                        <EmployeCard data={productions?.id} />
                      ) : (
                        <EmployeeHide />
                      )}
                    </div>
                  )}
                </div>
                <div className="col mt-2 d-flex">
                  {showEmployeeCard && (
                    <button
                      onClick={() => {
                        handelHideAndShow();
                      }}
                      className="show-manage ms-auto"
                    >
                      {show ? (
                        <> {t("Union.Dashboard.Hide")} </>
                      ) : (
                        <>{t("Union.Dashboard.Show")}</>
                      )}
                    </button>
                  )}
                </div>

              </div>
            </div>
            </div>
                  </div>
                    

          </div>
        )
      }

    </div>
  );
}

export default UnionDashboard;
