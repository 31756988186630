import React,{ useState,useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {Button,Form,Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import Modal from '../shared/Modal';
import Loader from '../shared/Loader';
import NoRecords from '../shared/NoRecords';
import Pagination from '../shared/Pagination';

import { restoreprod } from '../Services/https/Admin/Admin';
import { archivedUnionlist } from '../Services/https/Employer/Employer';
import { svg } from '../assets/svg/svg';

function UnionArchive() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { production_id } = useSelector(state => state.invite)
    const [loader, setLoader] = useState(true);
    const [restore, setRestore] = useState(false);
    const [id,setId]=useState(null);
    const [initialpage, setInitialPage] = useState(1);
    const [totalcount, setTotalcount] = useState(null);
    const [searchkeyword, setSearchKeyword] = useState("");
    const [handlesearch, sethandleSearch] = useState(false);
    const [archivelist, setArchivelist] = useState([]);
    const pageCount = Math.ceil(totalcount / 10);

    useEffect(() => {
        archivedUnionlist(production_id, searchkeyword, initialpage).then(response => {
            setArchivelist(response?.data?.data?.data)
            setTotalcount(response?.data?.data?.total)
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            return err
        })
    }, [handlesearch,restore])

    const pageChange = (pageNum) => {
        setInitialPage(pageNum);
    };
    return (
        <>
            {loader && <Loader />}
            <Modal onHide={() => setRestore(false)} show={restore} modalTitle={t("Employer.Employee_requests_approval.Restore_confirmation")} setShowResend={setRestore} footer={
                <div className="group_buttons mt-2">
                    <Button
                        variant="outline-info"
                        className="btn-lg"
                        onClick={() => setRestore((prev) => !prev)}
                    >
                        {t('Employer.Add_employee.Delete_modal.Cancel')}
                    </Button>
                    <Button
                        variant="info"
                        className="btn-lg"
                        onClick={() => {
                            setLoader(true)
                            restoreprod(id).then(response=>{
                                setLoader(false);
                            setRestore((prev) => !prev);
                            toast.success(response?.data?.message)
                            }).catch(err=>{
                                setLoader(false)
                                return err
                            })
                        }}
                    >
                        {t('Employer.Add_employee.Delete_modal.Confirm')}
                    </Button>
                </div>
            } />

            <div className='white_box manage_screen'>
                <div className='title_box d-inline-flex w100p'>
                    <h3 className='title_h3'>{t('Employer.Unions-archive.Manage_union')}</h3>
                    <div className='search_box d-flex ms-auto'>
                        <div className='search_box_in'>
                            <span onClick={() => {
                                    sethandleSearch((prev) => !prev);
                                    setLoader(true);
                                }}>{svg["Search"]}</span>
                            <Form.Control type="text" placeholder={t('Employer.Unions-archive.Search')} onChange={(e) => setSearchKeyword(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setSearchKeyword(e.target.value);
                                        sethandleSearch((prev) => !prev);
                                        setLoader(true);
                                    }
                                }} />
                        </div>

                    </div>
                </div>
                <Table className='custom_table' responsive>
                    {
                        !loader && 
                         archivelist?.length === 0 ? <NoRecords /> :
                            <>
                                <thead>
                                    <tr>
                                        <th>{t('Employer.Unions-archive.Union_name')}</th>
                                        <th>{t('Employer.Unions-archive.Production')}</th>
                                        <th>{t('Employer.Unions-archive.Studio')}</th>
                                        <th width="200px">
                                            {svg["Edit"]}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        archivelist?.map((list, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <tr >
                                                    <td><b>{list?.union_name}</b></td>
                                                    <td>{list?.production_name}</td>
                                                    <td>{list?.studio_name}</td>
                                                    <td onClick={() => {setRestore(true);setId(list?.user_id)}}><a href="#"
                                                        className='link' >{t('Employer.Unions-archive.Restore')}</a> </td>
                                                </tr>
                                                </React.Fragment>
                                            )
                                        })
                                    }

                                </tbody>
                            </>
                    }

                </Table>
                <div className="request-invite-archive">
                    <div className='d-flex'>
                        <Button className='btn-sm ms-2 ps-3 pe-3 ' variant="outline-secondary" onClick={() => navigate('/employer/unions')} >
                            {t('Employer.Unions-archive.Union_List')}
                        </Button>
                    </div>
                    <div>
                        {archivelist?.length >= 1 ? (
                            <Pagination
                                initialpage={initialpage}
                                onpage={pageChange}
                                pageCount={pageCount}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnionArchive