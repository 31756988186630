import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {Button,Row,Col,Form} from "react-bootstrap";
import InputMask from "react-input-mask";


import Logo from "../assets/Images/reelkids_logo_Dark-svg.svg";

import Loader from "../shared/Loader";
import { authForgetPassword } from "../Services/https/Auth/Auth";

function ForgotPassword() {
  // Loader State
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const navigate = (address) => {
    navigation(`${address}`);
  };

  const forgetPasswordSchema = Yup.object().shape({
    phone: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/,`${t("UpdateSchema.Phone")}` ).required(`${t("UpdateSchema.Required")}`)
  });


  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }

  return (
    <section className="auth_layout login_screen forgot_screen">
      <div className="left_box"></div>

      {/* Loader spinner condition */}
      {loader ? <Loader /> : null}

      <div className="right_box ">
      <div className="right_box_cont">
        <a onClick={() => navigate("/")} className="logo">
          <img src={Logo} className='forgot_password_logo_reelkids' />
        </a>
        <h3 className="title_h3">
          {t("Forgot_password.Forgot_password_title")}
        </h3>
        <div className="auth_form">
          <Formik
            initialValues={{ phone: "" }}
            validationSchema={forgetPasswordSchema}
            onSubmit={(values) => {
              const data = {
                phone: values.phone.replace(/\D+/g, ""),
              };
              
              setLoader(true);

              // Forget Password API consumption

              authForgetPassword(data)
                .then((response) => {
                  setLoader(false);

                  if (response?.status === 200) {
                    toast.success(response?.data?.message);
                  } else if (response?.response?.status === 400) {
                    toast.error(response?.response?.data?.message);
                  } else if (response?.response?.status === 422) {
                    toast.error(response?.response?.data?.message);
                  }
                })
                .catch((error) => {
                  setLoader(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Row className="">
                  <Form.Group
                    className="form-group-1"
                    as={Col}
                    md="12"
                    controlId="validationCustom03"
                  >
                    <Form.Label>{t("SignUp.Phone")}</Form.Label>
                    <PhoneInput
                      className="form-control"
                      name="phone"
                      maskChar={null}
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="778-7XX-XXXX"
                    />

                    {/* Validation Messages*/}
                    {errors.phone && (
                      <div className="error">
                        {touched.phone && (
                          <>{errors?.phone}</>
                        )}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Button
                  type="button"
                  variant="info"
                  className="btn-lg w100p justify-content-center d-inline-block "
                  onClick={handleSubmit}
                >
                  {t("Forgot_password.Submit")}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
