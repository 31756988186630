import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next'

import NoRecords from '../../shared/NoRecords';
import { dateFormat } from '../../shared/moment';
import BookingDetails from '../../shared/bookingDetails';
import { svg } from '../../assets/svg/svg';

const DeclinedBookings = ({ data, load }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  const getdata = (id) => {
    const getRecord = data?.find((records) => records?.id === id);
    setDetails(getRecord);
  }

  useEffect(() => {
    if (Object.keys(details)?.length >= 1) {
      setShowDetails(true)
    }
  }, [details])

  return (
    <>
      {(data?.length === 0 && !load) ? <div style={{ marginTop: "50px" }}> <NoRecords /></div> :
        <div style={{ paddingBottom: "0px", position: "relative" }}>
          <Table className="custom_table bookingRequests" responsive>
            <thead>
              <tr>
                <th>{t("Caregiver.BookingRequests.AcceptedBookings.Client")}</th>
                <th width="600px">{t("Caregiver.BookingRequests.AcceptedBookings.Dates")}</th>
                <th>{t("Caregiver.BookingRequests.AcceptedBookings.Detail")}</th>
              </tr>
            </thead>
            <tbody>
              {
                data?.map((list, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr >
                        <td>
                          <b className="mb-5">{list?.client_name}</b>
                        </td>
                        <td>
                          <p> {dateFormat(list?.dates, "YYYY-MM-DD HH:mm:ss", lang)}{" "} - {dateFormat(list?.end_date, "YYYY-MM-DD HH:mm:ss", lang)}({list?.time_zone_code})</p>
                          <h5 className="mt-3">
                            {list?.location}
                          </h5>
                        </td>
                        <td>
                          <div>{list?.price === "" ? "$0.00" : <label>${list?.price}</label>}{t('Caregiver.BookingRequests.Day')}</div>
                          <div style={{color:"#787878"}}>{t('Caregiver.BookingRequests.Or')}</div>
                        <div><span>${list?.hourly_rate}</span> {t('Caregiver.BookingRequests.Hour')}</div>
                          <div>{list?.fund}</div>
                          <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(list?.id)}>{svg["File"]}</span> </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
              }

            </tbody>
          </Table>
          {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails} />}
        </div>
      }

    </>

  )
}

export default DeclinedBookings