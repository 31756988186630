import React, { useEffect, useState } from "react";

import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import Select from 'react-select'
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import ModalDialog from "../../../../shared/Modal";
import Loader from "../../../../shared/Loader";
import ChangePassword from "../../../../shared/changePassword";

import { profiledetail, profileupdate } from "../../../../Services/https/Employee/Employee";
import { getTimezone, uploadProfilePicture } from "../../../../Services/https/comman/common";
import { loginState } from "../../../../Reducers/Login/loginSlice";


const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [detail, setDetail] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [disable, setDisabled] = useState(true);
  const [timezone, setTimeZone] = useState([]);
  const [timezonevalue, setTimezoneValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const logindata = useSelector((state) => state.login);


  useEffect(() => {
    getProfile();
  }, [disable]);

  const getProfile = () => {
    profiledetail().then(response => {
      setDetail(response?.data?.data)
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      return err
    })

    getTimezone().then(response => {
      setTimeZone(response?.data?.data)
    }).catch(err => {
      return err
    })
  }

  const updateAvatar = (file) => {
    setLoader(true)
    const formdata = new FormData();
    formdata.append("profile_image_url",file)
    uploadProfilePicture(formdata).then(response => {
      setLoader(false);
      toast.success(response?.data?.message)
      getProfile();
    }).catch(err => {
      if (err?.response?.status === 422) {
        setErrorMessage(err?.response?.data?.errors)
        setAvatar(null)
      }
      if (err?.response?.status === 400) {
        setAvatar(null)
        setErrorMessage(err?.response?.data?.message)
      }
      setLoader(false)
      return err
    })
  }

  useEffect(() => {
    Object.keys(errorMessage).map(key => {
      toast.error(errorMessage[key])
    })
  }, [errorMessage])

  const updateSchema = Yup.object().shape({
    first_name: Yup.string().required(`${t("UpdateSchema.Required")}`),
    last_name: Yup.string()
      .required(`${t("UpdateSchema.Required")}`).nullable(),
    email: Yup.string()
      .email(`${t("Admin.UpdateEmployeer.Details.Validations.Email")}`)
      .required(`${t("UpdateSchema.Required")}`),
    phone: Yup.string().required(`${t("UpdateSchema.Required")}`),
    postalcode: Yup.string().required(`${t("UpdateSchema.Required")}`).nullable(),
    province: Yup.string().required(`${t("UpdateSchema.Required")}`).nullable(),
    country: Yup.string().required(`${t("UpdateSchema.Required")}`).nullable(),
    biography: Yup.string().when("biography", (val, schema) => {
      if ((val !== null && val !== "null") && val?.length > 0) {  //if address exist then apply min max else not
        return Yup.string().min(100, `${t("UpdateSchema.MinBiography")}`);
      } else {
        return Yup.string().notRequired().nullable();
      }
    })
  },
    [
      ["biography", "biography"]
    ]
  );

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control field_view"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        defaultValue={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left"
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '8px',
      backgroundColor: '#f2f2f2',
      padding: '10px'

    }),
    indicatorSeparator: () => { },
  }
  const closePopup = (value) => {
    setModal(value)
  }
  return (
    <>

      {loader && <Loader />}


      {modal && <ModalDialog modalTitle={t('Employee.Profile.PasswordChange')} show={modal} onHide={() => setModal(false)} component={<ChangePassword close={closePopup} showPopup={modal} />} />}

      <Formik
        initialValues={{ first_name: detail?.first_name, last_name: detail?.last_name, phone: detail?.phone, email: detail?.email, postalcode: detail?.postal_code, province: detail?.province, country: detail?.country, address: detail?.address, biography: detail?.biography, time_zone: detail?.time_zone }}
        enableReinitialize={true}
        validationSchema={updateSchema}
        onSubmit={(values) => {
          setLoader(true)
          const formdata = new FormData();
          formdata.append('first_name', values.first_name);
          formdata.append('last_name', values?.last_name);
          formdata.append('email', values?.email);
          formdata.append('phone', values?.phone?.toString().replace(/\D+/g, ""));
          formdata.append('address', values?.address);
          formdata.append('postal_code', values?.postalcode);
          formdata.append('province', values?.province);
          formdata.append('country', values?.country);
          formdata.append("time_zone", values?.time_zone);
          formdata.append("biography", values?.biography);
          avatar && formdata.append('profile_image_url', avatar);
          profileupdate(formdata).then(response => {
            toast.success(response?.data?.message)
            setLoader(false);
            setDisabled(!disable)
            dispatch(loginState({ ...logindata, ...{ timezone: values?.time_zone, name: values?.first_name + " " + values?.last_name, timezonevalue: timezonevalue ? timezonevalue : logindata?.timezonevalue } }))
          }).catch(err => {
            setLoader(false)
            if (err?.response?.status === 422) {
              setErrorMessage(err?.response?.data?.errors)
            }
            if (err?.response?.status === 400) {
              setErrorMessage(err?.response?.data?.message)
            }
            return err
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="profile_main">
              <div className="row">
                <div className="col-lg-8 profile_form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Fname')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="John"
                          name="first_name"
                          id="first_name"
                          disabled={disable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.first_name}
                          value={values.first_name}
                        />
                        {errors.first_name && (
                          <div className="error">
                            {touched.first_name && <>{errors.first_name}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Lname')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="Doe"
                          name="last_name"
                          id="last_name"
                          disabled={disable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.last_name}
                          value={values.last_name}
                        />
                        {errors.last_name && (
                          <div className="error">
                            {touched.last_name && <>{errors.last_name}</>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Email')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="johdoe@mail.com"
                          name="email"
                          disabled={disable}
                          id="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.email}
                          value={values.email}
                        />
                        {errors.email && (
                          <div className="error">
                            {touched.email && <>{errors.email}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <label className="mb-3 mt-2 setting_label">
                          {t('Employee.Profile.Number')}<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <PhoneInput
                          name="phone"
                          id="phone"
                          disabled={disable}
                          maskChar={null}
                          defaultValue={detail?.phone}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="778-XXX-XXXX"

                        />
                        {errors.phone && (
                          <div className="error">
                            {touched.phone && <>{errors.phone}</>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Biography')}</label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="biography"
                          disabled={disable}
                          id="biography"
                          placeholder={t("Caregiver.Profile.Personal.BiographyText")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view "
                          value={(values.biography === null || values?.biography === "null") ? "" : values?.biography}
                        />
                        {errors.biography && (
                          <div className="error">
                            {touched.biography && <>{errors.biography}</>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Address')}</label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="123 Breddock Ave Unit 231"
                          name="address"
                          disabled={disable}
                          id="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.address}
                          value={values.address === "null" ? null : values.address}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Postal')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="V3K 2K7"
                          name="postalcode"
                          id="postalcode"
                          disabled={disable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.postal_code}
                          value={values.postalcode}

                        />
                        {errors.postalcode && (
                          <div className="error">
                            {touched.postalcode && <>{errors.postalcode}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Province')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="BC"
                          name="province"
                          id="province"
                          disabled={disable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.province}
                          value={values.province}
                        />
                        {errors.province && (
                          <div className="error">
                            {touched.province && <>{errors.province}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Country')}<span style={{ color: "red" }}>*</span></label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          placeholder="Canada"
                          name="country"
                          id="country"
                          disabled={disable}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field_view"
                          defaultValue={detail?.country}
                          value={values.country}
                        />
                        {errors.country && (
                          <div className="error">
                            {touched.country && <>{errors.country}</>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Timezone')}</label>
                      </div>
                      <Select
                        name="timezone"
                        styles={CustomStyle}
                        isDisabled={disable}
                        defaultValue={{ label: logindata?.timezone, name: logindata?.timezone }}
                        options={timezone?.map((list, index) => {
                          return {
                            label: list?.timezone_name,
                            name: list?.timezone_name,
                            value: list?.timezone_name_value,
                            code: list?.code_name
                          }
                        })}
                        onChange={(option) => { setFieldValue("time_zone", option?.name); setTimezoneValue(option?.code) }}
                        placeholder={<div style={{ textAlign: "left" }}>{t('SelectTimezone')}</div>} />

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Password')}</label>
                      </div>
                      <div className="form-group input_icon mb-3">
                        <Form.Control
                          placeholder="********"
                          // type={!showPassword ? "password" : "text"}
                          name="password"
                          className="update_employeer_input"
                          disabled
                          // value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 mb-3 mt-2  setting_label avatar_section ">

                  <div className="">{t('Caregiver.Profile.Personal.ProfilePicture')}</div>
                  <div className=" mt-3">
                    <img src={preview ? preview : detail?.profile_image_url} alt="" style={{ height: "208px", width: "208px" }} />
                  </div>
                  <div className=" profile-pic-text" >
                    <label for="files" style={{ cursor: "pointer", textDecoration: "underline" }}>{t('Employee.Profile.Avatarlabel')}</label>
                    <input id="files" style={{ visibility: "hidden" }} type="file" accept="image/*"  onChange={(e) => updateAvatar(e.target.files[0])} />
                  </div>
                  <div className="row change_passrow">
                    <div className="change_profile mt-5 " onClick={() => setModal(true)}>
                      {t('Employee.Profile.PasswordChange')}
                    </div>
                  </div>
                </div>




              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {
                  !disable &&
                  <>
                    <Button
                      variant="outline-info"
                      className="cancel_childreq"
                      onClick={() => setDisabled(true)}
                    >
                      {t("Employer.Add_employee.Delete_modal.Cancel")}
                    </Button>
                    <Button type="submit" variant="info" className="ms-3 update_child btn w_100" style={{ float: "right" }}
                      onClick={() => setDisabled(false)}
                    >
                      {t('Employee.Profile.Save')}
                    </Button>
                  </>
                }
                {
                  disable && <Button type="button" variant="info" className="ms-3 update_child btn w_100" style={{ float: "right" }}
                    onClick={() => setDisabled(false)}
                  >
                    {t('Employer.Calender_listing.Edit')}
                  </Button>
                }


              </div>

            </div>

          </form>
        )}
      </Formik>

    </>
  )
}

export default PersonalInfo