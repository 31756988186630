import * as Yup from 'yup';


export const resetSchema = Yup.object().shape({
    password: Yup.string()
    .required()
    .matches( /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Special Case Character"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm Password must match"
      ).required('required')
})
export const inviteSchema = Yup.object().shape({
    studio :Yup.string().required(),
    email: Yup.string().email('Invalid email').required('Required'),
  });
  
  export const inviteUnionSchema = Yup.object().shape({
    union_name :Yup.string().required(),
    email: Yup.string().email('Invalid email').required('Required'),
  
  });

















