import React, { useState, useEffect } from "react";

import moment from "moment";
import toast from "react-hot-toast";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "../../shared/Modal";
import NoRecords from "../../shared/NoRecords";
import { svg } from "../../assets/svg/svg";
import { dateFormat, payrolldateFormat } from "../../shared/moment";

import { updateRequests } from "../../Services/https/Caregiver/Caregivers";
import BookingDetails from "../../shared/bookingDetails";


const PendingRequests = ({ data, change, load }) => {
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [del, setDel] = useState(false);
  const [delAfter, setDelAfter] = useState(false);
  const [modalData, setModalData] = useState([])
  const [id, setId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("i18nextLng");
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (id) {
      const userDetail = data?.filter((item) => item?.id === id);
      setModalData(userDetail);
      if (userDetail?.length > 0 && (!show && !del)) {
        navigate('/caregiver/requests/view-profile', { state: { user: userDetail } });
        setId(null);
        setModalData([])
      }
    }
  }, [id, show, del]);


  const update = (status, requestid, accept) => {
    updateRequests(status, requestid).then(response => {
      toast.success(response?.data?.message)
      change(false);
      setDel(false);
      setShow(false);
      setId(null);
      setModalData([])
      accept ? setConfirm(true) : setDelAfter(true)
    }).catch(err => {
      return err
    })
  }

  const getdata = (id) => {
    const getRecord = data?.find((records) => records?.id === id);
    setDetails(getRecord);

  }

  useEffect(() => {
    if (Object.keys(details)?.length > 0) {
      setShowDetails(true)
    }
  }, [details])

  const AcceptDescription = () => {
    return (
      <div>
        <div className="mt-5">
          <div className="acceptRequest_modal">
            {t(`Caregiver.BookingRequests.PendingRequests.PopUpHeading`)} <b>{modalData[0]?.client_name}'</b> <br />
            {t(`Caregiver.BookingRequests.PendingRequests.Date`)}
            <b>{payrolldateFormat(modalData[0]?.dates, "YYYY-MM-DD HH:mm:ss", lang)}</b> {t("Caregiver.Availability_calender.to")} <b>{payrolldateFormat(modalData[0]?.end_date, "YYYY-MM-DD HH:mm:ss", lang)}</b>
          </div>
          <div>
          </div>
          <div className="acceptRequest_modal">
            {t("Caregiver.BookingRequests.PendingRequests.PopUpHeading2") + moment(modalData[0]?.time?.split("-")[0], "HH:mm:ss").format("hh:mm A") + "-" + moment(modalData[0]?.time?.split("-")[1], "HH:mm:ss").format("hh:mm A")}
          </div>
          <div className="acceptRequest_modal">
            {`${t("Caregiver.Profile.Personal.Address")}:`} {modalData[0]?.location}
          </div>

        </div>
      </div>
    );
  };

  const accept_description = {
    body: <AcceptDescription />,
    footer: (
      <>
        <div className="group_buttons mt-5">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => { setShow(false); setId(null) }}
          >
            {t("Caregiver.BookingRequests.PendingRequests.Cancel")}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              change(true);
              update(1, id, true);
            }}
          >
            {t("Caregiver.BookingRequests.PendingRequests.Confirm")}
          </Button>
        </div>
      </>
    ),
  };

  return (
    <>
      {confirm ? (
        <Modal
          modalTitle={t("Caregiver.BookingRequests.PendingRequests.PopUpAfter")}
          show={confirm}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setConfirm(false);
                  change(false);
                  setId(null);
                  setModalData([]);
                }}
              >
                {t("Caregiver.BookingRequests.PendingRequests.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {delAfter ? (
        <Modal
          modalTitle={t("Caregiver.BookingRequests.PendingRequests.PopUpDeclineAfterTitle")}
          show={delAfter}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDelAfter(false);
                  setId(null);
                  setModalData([]);
                }}
              >
                {t("Caregiver.BookingRequests.PendingRequests.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {del && (
        <Modal
          show={del}
          modalTitle={t("Caregiver.BookingRequests.PendingRequests.PopUpDeclineTitle")}
          description={
            <>
              <p style={{ margin: "70px 0px 50px 0px" }}>
                {t("Caregiver.BookingRequests.PendingRequests.PopUpDeclineHeading") + `${modalData[0]?.client_name} '` + t(`Caregiver.BookingRequests.PendingRequests.Request`) + dateFormat(modalData[0]?.dates, "YYYY-MM-DD HH:mm:ss", lang) + t("Caregiver.Availability_calender.to") + " " + dateFormat(modalData[0]?.end_date, "YYYY-MM-DD HH:mm:ss", lang) + t(`Caregiver.BookingRequests.PendingRequests.At`) + modalData[0]?.location}
              </p>
              <div></div>
            </>
          }
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => { setDel(false); setId(null) }}
              >
                {t("Caregiver.BookingRequests.PendingRequests.PopUpDeclineCancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  change(true);
                  update(2, id, false);
                }}
              >
                {t("Caregiver.BookingRequests.PendingRequests.PopUpDeclineConfirm")}
              </Button>
            </div>
          }
        />
      )}

      {(data?.length === 0 && !load) ? <div style={{ marginTop: "50px" }}> <NoRecords /></div> :


        <div style={{ paddingBottom: "0px", position: "relative" }}>
          <Table responsive className=" childcareBreakdown_main   custom_table">
            <thead>
              <tr>
                <th>{t("Caregiver.BookingRequests.PendingRequests.Client_name")}</th>
                <th width="550px">{t("Caregiver.BookingRequests.PendingRequests.Date_&_location")}</th>
                <th>{t("Caregiver.BookingRequests.PendingRequests.Details")}</th>
                <th>{t("Caregiver.BookingRequests.PendingRequests.Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <React.Fragment  key={index}>
                  <tr>
                    <td>
                      <b>{item?.client_name}</b>{" "}
                      <div className="v-profile"><span style={{ cursor: "pointer" }} onClick={() => {
                        setId(item?.id);
                      }}>{t("Caregiver.BookingRequests.PendingRequests.Profile")}</span></div>
                    </td>
                    <td>
                      <div className="mb-3">
                        <p>
                          {
                            dateFormat(item?.dates, "YYYY-MM-DD HH:mm:ss", lang)
                          }{" "}
                          - {
                            dateFormat(item?.end_date, "YYYY-MM-DD HH:mm:ss", lang)
                          }
                          ({item?.time_zone_code})

                        </p> {" "}
                      </div>
                      <div>
                        <div>{item?.location}</div>
                      </div>
                    </td>
                    <td>
                      <div >
                        <div>{item?.price === "" ? "$0.00" : <label>${item?.price}</label>}{t('Caregiver.BookingRequests.Day')}</div>
                        <div style={{color:"#787878"}}>{t('Caregiver.BookingRequests.Or')}</div>
                        <div><span>${item?.hourly_rate}</span> {t('Caregiver.BookingRequests.Hour')}</div>
                        <div>{item?.fund}</div>
                        <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(item?.id)}>{svg["File"]}</span> </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        {" "}
                        <span
                          className="me-3 accept_action "
                          onClick={() => { setShow(true); setId(item?.id) }}
                        >
                          {t('Caregiver.BookingRequests.PendingRequests.Accept')}
                        </span>{" "}
                        <span className="decline_action" onClick={() => { setDel(true); setId(item?.id) }}>{t("Caregiver.BookingRequests.PendingRequests.Decline")}</span>
                      </div>
                    </td>
                  </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <Modal
            show={show}
            setShow={setShow}
            accept_description={accept_description}
            modalTitle={t("Caregiver.BookingRequests.PendingRequests.PopUpTitle")}
          />

          {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails}/>}
        </div>
      }


    </>
  );
};

export default PendingRequests;
