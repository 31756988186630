import React, { useState, useEffect } from "react";

import { Form, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../shared/Loader";
import ModalDialog from "../../shared/Modal";
import ChangePassword from "../../shared/changePassword";
import { loginState } from "../../Reducers/Login/loginSlice";

import {
  CaregiverPersonalDetails,
  getServiceArea,
} from "../../Services/https/Caregiver/Caregivers";
import { updateEmployerPersonalInfo } from "../../Services/https/Employer/Employer";
import { getTimezone, uploadProfilePicture } from "../../Services/https/comman/common";


const Personal = (props) => {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [show, setShow] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [update, setUpdate] = useState(false);
  const [citylist, setCityList] = useState([]);
  const [timezone, setTimeZone] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logindata = useSelector((state) => state.login);
  const [userCity, setUserCity] = useState();
  const [showMenu, setShowMenu] = React.useState(false);
  const [empdetail, setEmpDetail] = useState([]);
  const [ids, setIds] = useState([]);
  const [timezonevalue, setTimezoneValue] = useState(null);

  //Get personal details of caregiver
  // Begin with setting value for react-select
  useEffect(() => {
   getProfile();
  }, [update]);

const getProfile = () => {
  setLoader(true);
  CaregiverPersonalDetails()
    .then((response) => {
      setLoader(false);
      setPersonalInfo(response?.data?.data);
      setUserCity(
        response?.data?.data?.user_service_area.map((item) => {
          return {
            value: item?.city_id,
            label: item?.city_name,
          };
        })
      );
    })
    .catch((error) => { });

  getServiceArea().then((response) => {
    setCityList(response?.data?.data);
  });

  getTimezone()
    .then((response) => {
      setTimeZone(response?.data?.data);
    })
    .catch((err) => {
      return err;
    });
}

const updateAvatar = (file) => {
  setLoader(true)
  const formdata = new FormData();
  formdata.append("profile_image_url",file)
  uploadProfilePicture(formdata).then(response => {
    setLoader(false);
    toast.success(response?.data?.message)
    getProfile();
  }).catch(err => {
    if (err?.response?.status === 422) {
      setErrorMessage(err?.response?.data?.errors)
      setAvatar(null)
    }
    if (err?.response?.status === 400) {
      setAvatar(null)
      setErrorMessage(err?.response?.data?.message)
    }
    setLoader(false)
    return err
  })
}

  const cityOptions = citylist.map((item, index) => {
    return {
      label: item?.city_name,
      value: item?.id,
    };
  });

  // Personal Details Schema
  const personalDetailsSchema = Yup.object().shape({
    first_name: Yup.string().required(
      `${t("Validation.InviteModal.Required")}`
    ),
    last_name: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    email: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .email(`${t("Validation.InviteModal.Invalid_email")}`)
      .nullable(),
    phone: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    // address: Yup.string()
    //   .required(`${t("Validation.InviteModal.Required")}`)
    //   .nullable(),
    postal_code: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    province: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    country: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    userservice_area: Yup.string().required(
      `${t("Validation.InviteModal.Required")}`
    ),
    biography: Yup.string().when("biography", (val, schema) => {
      if ((val !== null && val !== "null") && val?.length > 0) {  //if address exist then apply min max else not
        return Yup.string().min(100, `${t("UpdateSchema.MinBiography")}`).max(10000);
      } else {
        return Yup.string().notRequired().nullable();
      }
    })
  }, [
    ["biography", "biography"]
  ]

  );

  useEffect(() => {
    if (userCity?.length >= 0) {
      const userIds = userCity.map((item) => item?.value);
      setIds(userIds);
      const records = cityOptions?.filter((item) =>
        userIds.includes(item?.value)
      );
      setEmpDetail(records);
    }
  }, [userCity]);

  // Toaster Messages
  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  useEffect(() => {
    let id;
    const ids = cityOptions.filter((item) => item.id === id);
  }, [cityOptions]);

  
  const handleInputChange = (typedOption) => {
    setShowMenu(typedOption.length >= 3 ? true : false);
  };


  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="phone"
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    );
  }
  const closePopup = (value) => {
    setModal(value)
  }
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="caregiver_profile_font">
          <div className="profile_form">

            {modal && <ModalDialog modalTitle={t('Employee.Profile.PasswordChange')} show={modal} onHide={() => setModal(false)} component={<ChangePassword close={closePopup} showPopup={modal} />} />}

            <Formik
              initialValues={{
                first_name: personalInfo?.first_name,
                last_name: personalInfo?.last_name,
                email: personalInfo?.email,
                phone: personalInfo?.phone,
                address: personalInfo?.address,
                postal_code: personalInfo?.postal_code,
                province: personalInfo?.province,
                country: personalInfo?.country,
                userservice_area: userCity
                  ?.filter((e) => e.value != undefined)
                  .map((e) => e.value).join(","),
                time_zone: personalInfo?.time_zone,
                biography: personalInfo?.biography
              }}
              enableReinitialize={true}
              validationSchema={personalDetailsSchema}
              onSubmit={(values) => {
                setLoader(true);
                const cityId = userCity
                  .filter((e) => e.value != undefined)
                  .map((e) => e.value)
                  .join(",");

                const formdata = new FormData();
                formdata.append("first_name", values.first_name);
                formdata.append("last_name", values?.last_name);
                formdata.append("email", values?.email);
                formdata.append(
                  "phone",
                  values?.phone?.toString().replace(/\D+/g, "")
                );
                formdata.append("address", values?.address);
                formdata.append("postal_code", values?.postal_code);
                formdata.append("province", values?.province);
                formdata.append("country", values?.country);
                formdata.append("user_service_area", cityId);
                formdata.append("time_zone", values?.time_zone);
                formdata.append("biography", values?.biography);
                avatar && formdata.append("profile_image_url", avatar);
                // if (!formik.errors) {
                //   setShow(true);
                // }
                updateEmployerPersonalInfo(formdata)
                  .then((response) => {
                    if (response?.status === 200) {
                      setUpdate(!update);
                      setShow(true);
                      setLoader(false);
                      toast.success(response?.data?.message);
                      dispatch(
                        loginState({
                          ...logindata,
                          ...{
                            timezone: values?.time_zone,
                            name: values?.first_name + " " + values?.last_name,
                            timezonevalue: timezonevalue ? timezonevalue :logindata?.timezonevalue
                          },
                        })
                      );
                    }
                  })
                  .catch((err) => {
                    if (err?.response?.status === 422) {
                      setErrorMessage(err?.response?.data?.errors);

                      setLoader(false);
                    }
                    if (err?.response?.status === 400) {
                      setErrorMessage(err?.response?.data?.message);
                      setLoader(false);
                    }
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form className="mt-3" onSubmit={handleSubmit}>
                  <div className="profile_main">
                  <div className="row">
                    <div className="col-lg-8 profile_form">
                      <Row className="mb-3">
                        <Form.Group
                          className="required"
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.FirstName")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="First name"
                            name="first_name"
                            onChange={handleChange}
                            value={values.first_name}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.first_name && (
                            <div className="error">
                              {touched.first_name && <>{errors.first_name}</>}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="required"
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.LastName")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last name"
                            name="last_name"
                            onChange={handleChange}
                            value={values.last_name}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.last_name && (
                            <div className="error">
                              {touched.last_name && <>{errors.last_name}</>}
                            </div>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group
                          className="required"
                          as={Col}
                          md="6"
                          controlId="validationCustom03"
                        >
                          <Form.Label className="control-label control_label_text">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            placeholder="johdoe@mail.com"
                            value={values.email}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.email && (
                            <div className="error">
                              {touched.email && <>{errors.email}</>}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="required"
                          as={Col}
                          md="6"
                          controlId="validationCustom04"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.PhoneNumber")}
                          </Form.Label>

                          <PhoneInput
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="phone"
                            className="form-control update_employeer_input"
                            disabled={show}
                          />
                          {errors.phone && (
                            <div className="error">
                              {touched.phone && <>{errors.phone}</>}
                            </div>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          className="required"
                          as={Col}
                          md="12"
                          controlId="validationCustom05"
                        >
                          <Form.Label className="control_label_text">
                            {t("Caregiver.Profile.Personal.Biography")}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder={t("Caregiver.Profile.Personal.BiographyText")}
                            className="update_employeer_input2"
                            disabled={show}
                            value={(values.biography === null || values?.biography === "null") ? "" : values?.biography}
                            name="biography"
                            onChange={handleChange}
                          ></Form.Control>
                          {errors.biography && (
                            <div className="error">
                              {touched.biography && <>{errors.biography}</>}
                            </div>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          className="required"
                          as={Col}
                          md="5"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control_label_text">
                            {t("Caregiver.Profile.Personal.Address")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            onChange={handleChange}
                            placeholder="123 Breddock Ave Unit 231"
                            value={(values.address === "null" || values?.address === null) ? "" : values?.address}
                            className="update_employeer_input"
                            disabled={show}
                          />
                        </Form.Group>
                        <Form.Group
                          className="required"
                          as={Col}
                          md="3"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.Postal")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="postal_code"
                            onChange={handleChange}
                            placeholder="V3K 2K7"
                            value={values.postal_code}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.postal_code && (
                            <div className="error">
                              {touched.postal_code && <>{errors.postal_code}</>}
                            </div>
                          )}
                        </Form.Group>{" "}
                        <Form.Group
                          className="required"
                          as={Col}
                          md="2"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.Province")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="province"
                            onChange={handleChange}
                            placeholder="BC"
                            value={values.province}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.province && (
                            <div className="error">
                              {touched.province && <>{errors.province}</>}
                            </div>
                          )}
                        </Form.Group>{" "}
                        <Form.Group
                          className="required"
                          as={Col}
                          md="2"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.Country")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Canada"
                            name="country"
                            onChange={handleChange}
                            value={values.country}
                            className="update_employeer_input"
                            disabled={show}
                          />
                          {errors.country && (
                            <div className="error">
                              {touched.country && <>{errors.country}</>}
                            </div>
                          )}
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          className="required"
                          as={Col}
                          md="12"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.City")}
                          </Form.Label>
                          <Select
                            isMulti
                            isClearable={true}
                            options={cityOptions}
                            className="basic-multi-select "
                            value={userCity}
                            classNamePrefix="select"
                            placeholder={"Search here..."}
                            onInputChange={handleInputChange}
                            menuIsOpen={showMenu}
                            onChange={(option, e) => {
                              setUserCity(option);
                              setFieldValue(
                                "userservice_area",
                                option
                                  .filter((e) => e.value != undefined)
                                  .map((e) => e.value)
                                  .join(",")
                              );
                            }}
                            isDisabled={show}
                          />
                          {errors.userservice_area && (
                            <div className="error">
                              {touched.userservice_area && (
                                <>{errors.userservice_area}</>
                              )}
                            </div>
                          )}
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group
                          className=" mt-3"
                          as={Col}
                          md="12"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Timezone")}
                          </Form.Label>
                          <Select
                            name="timezone"
                            defaultValue={{
                              label: logindata?.timezone,
                              name: logindata?.timezone,
                            }}
                            options={timezone?.map((list, index) => {
                              return {
                                label: list?.timezone_name,
                                name: list?.timezone_name,
                                value: list?.timezone_name_value,
                                code: list?.code_name
                              };
                            })}
                            onChange={(option) => {
                              setFieldValue("time_zone", option?.name);
                              setTimezoneValue(option?.code)
                            }}
                            className="basic-multi-select "
                            classNamePrefix="select"
                            isDisabled={show}
                            placeholder={
                              <div style={{ textAlign: "left" }}>
                                {t("SelectTimezone")}
                              </div>
                            }
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          className="required mt-3"
                          as={Col}
                          md="12"
                          controlId="validationCustom02"
                        >
                          <Form.Label className="control-label control_label_text">
                            {t("Caregiver.Profile.Personal.Password")}
                          </Form.Label>
                          <div className="input_icon ">
                            <Form.Control
                              placeholder="********"
                              // type={!showPassword ? "password" : "text"}
                              name="password"
                              className="update_employeer_input"
                              disabled={show}
                              // value={values.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="col-lg-4 mb-3 mt-2  setting_label avatar_section">
                    
                      <div className="ms-4">
                        {t("Caregiver.Profile.Personal.ProfilePicture")}
                      </div>
                      <div className="ms-4 mt-3">
                        <img
                          src={
                            preview ? preview : personalInfo?.profile_image_url
                          }
                          alt="profile picture"
                          style={{ height: "208px", width: "208px" }}
                        />
                      </div>
                      <div className="change_profile mt-3 ms-4">
                        {/* <div className="change_profile mt-3 ms-4"> */}
                        <label
                          htmlFor="files"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {t("Employee.Profile.Avatarlabel")}
                        </label>
                        <input
                          id="files"
                          style={{ visibility: "hidden" }}
                          type="file"
                          accept="image/*"
                          onChange={(e) => updateAvatar(e.target.files[0])}
                        />
                        {/* </div> */}
                      </div>
                      <div className="row change_passrow">

                        <div
                          className="change_profile mt-5 ms-4"
                          onClick={() => setModal(true)}
                        >
                          {t(
                            "Employer.Employer_profile.Personal_info.Password_change"
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                  
                  
                  {show && (
                    <Button
                      type="button"
                      variant="info"
                      className="btn-lg caregiver_profile_button"
                      onClick={() => setShow(false)}
                    >
                      {t("Caregiver.Profile.Button")}
                    </Button>
                  )}
                  {!show && (
                    <Button
                      type="submit"
                      variant="info"
                      className="btn-lg caregiver_profile_button "
                    >
                      {t("Caregiver.Profile.ButtonConfirm")}
                    </Button>
                  )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default Personal;
