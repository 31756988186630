import React from 'react'
import { useTranslation } from 'react-i18next'

function NoRecords() {
    const {t}=useTranslation()
    return (
        <div className="no-records">
            <h3> {t('Norecord.Title')} </h3>
        </div>

    )
}

export default NoRecords