import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Detail from "./Detail";
import Additional from "./Additional";

import {svg} from '../../assets/svg/svg'

import { downloadReport } from "../../Services/https/Admin/Admin";


const UpdateEmployeer = () => {
  const [accepted, setAccepted] = useState(false);
  const [pending, setPending] = useState(true);
  const [initial, setInitial] = useState(true);
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const params=useParams();

  const GenerateReport  = () => {
    downloadReport(params?.id).then(response=>
      response.data
    ).then(blob=>{
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = "productionreport.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(err=>{
      return err
    })
  }

  const productionStatus=(value)=>{
    setStatus(value)
  }
  
  return (
    <div className="white_box manage_screen">
      <div className="title_box dashborad_header w100p">
        <div>
        <h3 className="title_h3">{t("Admin.UpdateEmployeer.Title")}</h3>
        </div>
        {
          status===0 && <div onClick={()=>GenerateReport()}>
          <span style={{cursor:"pointer"}}>{svg["Download"]}</span> 
         </div>
        }   
      </div>
      <p style={{ fontSize: "18px", fontWeight: "400" }}>
        {t("Admin.UpdateEmployeer.EditDetail")}
      </p>

      <div className="employee-booking-cards">
        <div className="row ">
          <div className="col-xl-3">
            <Button
              onClick={() => {
                setPending(true);
                setAccepted(false);
              }}
              className={pending ? "employee-btn-active" : null}
            >
              {t("Admin.UpdateEmployeer.Details.Title")}
            </Button>
          </div>
          <div className="col-xl-3">
            <Button
            className={!pending ? "employee-btn-active" : null}
              onClick={() => {
                setPending(false);
                setAccepted(true);
                setInitial(false);
              }}
            >
              {t("Admin.UpdateEmployeer.Additional.Title")}
            </Button>
          </div>
        </div>
      </div>
      {accepted && <Additional />}
      {pending && <Detail getStatus={productionStatus}/>}
    </div>
  );
};

export default UpdateEmployeer;
