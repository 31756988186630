import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "../shared/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { svg } from "../assets/svg/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Creatable from "react-select/creatable";
import {
  getcurrentprod,
  UnionList,
  UnionProductionList,
  getunion,
  deleteUnion,
} from "../Services/https/Employer/Employer";
import Loader from "../shared/Loader";

import {
  createUnionInvite,
  sendInviteUnion,
} from "../Services/https/Employer/Employer";
import { useSelector } from "react-redux";
import NoRecords from "../shared/NoRecords";
import Pagination from "../shared/Pagination";
import { pendingEmployeeRequest } from "../Services/https/comman/common";
import { unionList } from "../Services/https/Admin/Admin";

function ManageUnion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { role_data } = useSelector((state) => state.login);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentprod, setCurrentProd] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [unions, setUnions] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [link, setlink] = useState("");
  const [productionId, setProductionId] = useState();
  const [inviteUnionList, setInviteUnionList] = useState([]);
  const [deleterecord, setDeleteRecord] = useState([]);
  const { production_id } = useSelector((state) => state.invite);
  const [searchkeyword, setSearchKeyword] = useState("");
  const [handlesearch, sethandleSearch] = useState(false);
  const [initialpage, setInitialPage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const [unionsTable, setUnionsTable] = useState([]);
  const pageCount = Math.ceil(totalcount / 10);
  const [requestcount, setRequestCount] = useState(null);

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: "60px",
      width: "100%",

      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #ced4da",
    }),

    indicatorSeparator: () => {},
  };
  const handleInputChange = (typedOption) => {
    setShowMenu(typedOption.length >= 3 ? true : false);
  };

  // Current production

  const openModal = () => {
    setShow(true);
    setLoader(true);
    getcurrentprod()
      .then((response) => {
        setCurrentProd(response?.data?.data?.production_name);
        setProductionId(response?.data?.data?.id);
        setLoader(false);

        // Invited Unions List

        UnionProductionList(response?.data?.data?.id)
          .then((response) => {
            setInviteUnionList(response?.data?.data);
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((err) => {
        setLoader(false);
        return err;
      });
  };

  // Union Listing

  useEffect(() => {
    setLoader(true)
    if (role_data === "2") {
      UnionList()
        .then((response) => {
          if (response?.status === 200) {
            setUnions(response?.data?.data);
            setLoader(false)
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }, [role_data]);

  useEffect(() => {
    if (role_data === "2") {
      getunion(production_id, searchkeyword, initialpage)
        .then((response) => {
          setUnionsTable(response?.data?.data?.paginate?.data);
          setTotalcount(response?.data?.data?.total_count);
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [handlesearch, modalShow, initialpage, role_data]);

  useEffect(() => {
    if (role_data === "2") {
      pendingEmployeeRequest(production_id, 7, 4)
        .then((response) => {
          setRequestCount(response?.data?.data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [show, role_data]);

  useEffect(() => {
    if (role_data === "1") {
      setLoader(true)
      unionList(searchkeyword, initialpage)
        .then((response) => {
          setUnionsTable(
            response?.data?.data?.data.map((item) => {
              item.showunions = false;
              return item;
            })
          );
          setTotalcount(response?.data?.data?.total);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    }
  }, [role_data, handlesearch, initialpage]);

  const pageChange = (pageNum) => {
    setInitialPage(pageNum);
  };

  const onShowUnions = (index) => {
    const allUnions = [...unionsTable];
    allUnions[index].showunions = !allUnions[index].showunions;
    setUnionsTable(allUnions);
  };

  const recordstoDelete = (e, id) => {
    const records = [...deleterecord];
    if (e.target.checked) {
      records.push(id);
      setDeleteRecord(records);
    } else {
      const recs = records.filter((rec) => rec !== id);
      setDeleteRecord(recs);
    }
  };

  // Union Invite validation schema

  const inviteUnionSchema = Yup.object().shape({
    union_name: Yup.object()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    email: Yup.string()
      .email(`${t("Validation.InviteModal.Invalid_email")}`)
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
  });

  // Union invite generate form

  const formik = useFormik({
    initialValues: { email: "", union_name: "" },
    validationSchema: inviteUnionSchema,
    onSubmit: (values) => {
      const data = {
        email: formik?.values.email,
        union_name: formik?.values?.union_name.value,
      };

      // Send Invite to union through email
      setLoader(true);
      sendInviteUnion(data, productionId)
        .then((response) => {
          setShow(false);
          if (response?.status === 200) {
            setLoader(false);
            setGenerate(false);
            formik.resetForm(formik?.values);
            setlink(null);
            toast.success(response?.data?.message);
          } else {
            setLoader(false);
            toast.error(response?.response?.data?.message);
            formik.resetForm(formik?.values);
            setlink(null);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.message);
        });
    },
  });

  // Create invite link for union

  const inviteGenreate = () => {
    setLoader(true);
    let data = {
      email: formik?.values.email,
      union_name: formik?.values?.union_name.value,
    };
    createUnionInvite(data, productionId)
      .then((response) => {
        setLoader(false);
        if (response?.status === 200) {
          setGenerate(true);
          setlink(response?.data?.data);
        } else if (response?.response?.status === 422) {
          toast.error(response?.response?.data?.errors?.union_name);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        return error;
      });
  };

  // Check if email of the union already exists

  const checkEmailExist = (value) => {
    const emailData = unions.find((i) => i.name === value);
    if (emailData) {
      formik.setFieldValue("email", emailData?.email);
    } else {
      formik.setFieldValue("email", "");
    }
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <Modal
        show={modalShow}
        modalTitle={t("Employer.Modal.Title")}
        description={
          t("Employer.Modal.Heading.start") +
          deleterecord?.length +
          t("Employer.Modal.Heading.union")
        }
        onHide={() => setModalShow(false)}
        footer={
          <div className="group_buttons mt-5">
            <Button
              variant="outline-info"
              type="button"
              className="btn-lg"
              onClick={() => setModalShow((prev) => !prev)}
            >
              {t("Admin.ManageEmployeer.Modal.Cancel")}
            </Button>
            <Button
              variant="info"
              type="button"
              className="btn-lg"
              onClick={() => {
                setLoader(true);
                deleteUnion(deleterecord).then((response) => {
                  setModalShow((prev) => !prev);
                  setLoader(false);
                  setDeleteRecord([]);
                  toast.success(response?.data?.message);
                });
              }}
            >
              {t("Admin.ManageEmployeer.Modal.Confirm")}
            </Button>
          </div>
        }
      />

      {/* Generate Union Invite Modal  */}

      {show ? (
        <Modal
          show={show}
          setShow={setShow}
          modalTitle={t(
            "Employer.Manage_union.Generate_link_modal.Invite_union"
          )}
          field={
            <>
              <div className="text-center w100p font">
                <p style={{ textAlign: "center" }}>
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Heading")}
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 invitelink">
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input4heading"
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            name="production"
                            id="production"
                            value={currentprod}
                            disabled
                            class="form-control"
                            placeholder="Production #1142"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.UnionName"
                        )}
                        :
                      </div>
                      <div className="input-group mb-3">
                        <Creatable
                          options={unions?.map((list, index) => ({
                            value: list?.name,
                            label: list?.name,
                            id: list?.id,
                          }))}
                          styles={CustomStyle}
                          name="union"
                          id="union"
                          isClearable={true}
                          value={formik.values.union_name}
                          components={{ DropdownIndicator: () => null }}
                          loadingMessage={() => "Loading..."}
                          menuIsOpen={showMenu}
                          onInputChange={handleInputChange}
                          onChange={(option) => {
                            checkEmailExist(option?.value);
                            formik.setFieldValue("union_name", option);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("union_name", true);
                            setShowMenu(false);
                          }}
                          placeholder={
                            <div
                              style={{ textAlign: "left", color: "#9D9D9D" }}
                            >
                              VFSEU
                            </div>
                          }
                        />
                      </div>
                      {formik.errors.union_name && (
                        <div className="error">
                          {formik.touched.union_name && (
                            <>{formik.errors.union_name}</>
                          )}
                        </div>
                      )}
                      <div>
                        <div>
                          {t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input1Heading"
                          )}{" "}
                          <button
                            type="button"
                            className="generate"
                            onClick={() => {
                              setGenerate(true);
                              inviteGenreate();
                            }}
                          >
                            {t(
                              "Admin.ManageEmployeer.ModalGenerateInvite.Generate"
                            )}
                          </button>
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          placeholder={t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input1"
                          )}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={link ? link : null}
                        />
                      </div>
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input2heading"
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formik.values.email}
                          class="form-control"
                          placeholder={t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input2"
                          )}
                          aria-describedby="basic-addon1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                      </div>
                      {formik.errors.email && (
                        <div className="error">
                          {formik.touched.email && <>{formik.errors.email}</>}
                        </div>
                      )}
                    </div>
                    <div className="group_buttons mt-5">
                      <Button
                        type="button"
                        variant="outline-info"
                        className="btn-lg"
                        onClick={() => {
                          setShow((prev) => !prev);
                          formik.resetForm(formik?.values);
                          setlink("");
                          setGenerate(false);
                        }}
                      >
                        {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                      </Button>
                      <Button
                        variant="info"
                        className="btn-lg"
                        type="submit"
                        disabled={!link}
                        onClick={() => {
                          setGenerate(false);
                        }}
                      >
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.SendInvite"
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          }
        />
      ) : null}

      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">
            {t("Employer.Manage_unions.Manage_union")}
          </h3>
          <div className="search_box d-flex ms-auto">
            <div className="search_box_in">
              <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoader(true);
                }}>{svg["Search"]}</span>
              <Form.Control
                type="text"
                placeholder={t("Employer.Manage_unions.Search")}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchKeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoader(true);
                  }
                }}
              />
            </div>
            {role_data === "2" && (
              <Button
                type="button"
                variant="outline-secondary"
                onClick={() => {
                  deleterecord?.length !== 0 && setModalShow(true);
                }}
              >
                {deleterecord?.length} {t("Employer.Manage_unions.Selected")}
                {svg["Delete"]}
              </Button>
            )}
          </div>
        </div>
        <Table className="custom_table" responsive>
          {handlesearch && unionsTable?.length === 0 ? (
            <NoRecords />
          ) : (
            <>
              <thead>
                <tr>
                  {role_data === "2" && <th width="50px"></th>}
                  <th width={role_data === "1" ? "450px" : null}>
                    {t("Employer.Manage_unions.Union_name")}
                  </th>
                  <th width={role_data === "1" ? "450px" : null}>
                    {t("Employer.Manage_unions.Production")}
                  </th>
                  <th>{t("Employer.Manage_unions.Studio")}</th>
                  {role_data === "2" && (
                    <th width="200px">
                      <span>{svg["Edit"]}</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {unionsTable?.map((list, index) => {
                  return (
                    <tr key={index}>
                      {role_data === "2" && (
                        <td>
                          <Form.Check
                            className="custom_checkbox"
                            key={`${list?.user_id}`}
                            aria-label={`option-${list?.user_id}`}
                            id={list?.user_id}
                            onClick={(e) => {
                              recordstoDelete(e, list?.user_id);
                            }}
                          />
                        </td>
                      )}

                      <td>
                        {role_data === "1" &&
                        list?.union?.length > 0 &&
                        !list.showunions ? (
                          <>
                            {list?.union[0]?.name}

                            <span
                              onClick={() => onShowUnions(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {list?.union?.length > 1 && ` ...`}
                            </span>
                          </>
                        ) : (
                          <b>{list?.union_name}</b>
                        )}
                        {list.showunions &&
                          list?.union.map((item, index) => {
                            return (
                              <>
                                {item?.name}
                                {index < list.union.length - 1 && `, `}
                              </>
                            );
                          })}
                      </td>
                      <td>{list?.production_name}</td>
                      <td>{list?.studio_name}</td>
                      {role_data === "2" && (
                        <td>
                          <a
                            onClick={() =>
                              navigate("/employer/unions/updateUnion", {
                                state: { id: list?.union_id },
                              })
                            }
                            className="link"
                          >
                            {t("Employer.Manage_unions.Edit")}
                          </a>{" "}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </>
          )}
        </Table>
        <div className="request-invite-archive mt-2">
          <div className="d-flex">
            {role_data === "2" && (
              <>
                <Button
                  type="button"
                  className="btn-sm ms-0 ps-3 pe-3"
                  variant="outline-secondary"
                  onClick={() => {
                    navigate("/employer/unions/union-request-approval");
                  }}
                >
                  {requestcount} {t("Employer.Manage_unions.Requests_pending")}
                </Button>

                <Button
                  type="button"
                  className="btn-sm ms-2 ps-3 pe-3"
                  variant="outline-secondary"
                  onClick={openModal}
                >
                  {t("Employer.Manage_unions.Generate_invite")}
                </Button>
                <Button
                  type="button"
                  className="btn-sm ms-2 ps-3 pe-3 "
                  variant="outline-secondary"
                  onClick={() => navigate("/employer/unions/unions-archive")}
                >
                  {t("Employer.Manage_unions.Archive")}
                </Button>
              </>
            )}
          </div>
          <div>
            {unionsTable?.length >= 1 && (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageUnion;
