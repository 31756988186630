import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import {Button,Form,Table} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Modal from "../shared/Modal"
import NoRecords from '../shared/NoRecords';
import Loader from '../shared/Loader';
import Pagination from '../shared/Pagination';

import { cancelemployeerequest, unionPendingRequest } from '../Services/https/Employer/Employer';
import { resendrequest } from '../Services/https/Admin/Admin';

import { svg } from '../assets/svg/svg';

function UnionRequestApproval() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { production_id } = useSelector(state => state.invite)
  const [showResend, setShowResend] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [initialpage, setInitialPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [guid, setGuid] = useState(null)
  const [totalcount, setTotalcount] = useState(null);
  const [searchkeyword, setSearchKeyword] = useState("");
  const [sort,setSort]=useState(null)
  const [handlesearch, sethandleSearch] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const pageCount = Math.ceil(totalcount / 10);

  useEffect(() => {
    unionPendingRequest(production_id, searchkeyword, initialpage, sort).then(response => {
      setRequestlist(response?.data?.data?.data);
      setTotalcount(response?.data?.data?.total);
      setLoader(false)
    }).catch(err => {
      return err
    })

  }, [handlesearch,showCancel,sort]);

  const cancelRequest = (id) => {
    cancelemployeerequest(id).then(response => {
      toast.success(response?.data?.message)
      setLoader(false);
      setShowCancel((prev) => !prev);
    }).catch(err => {
      return err
    })
  }

  const resendRequest = (guid) => {
    if (guid) {
      resendrequest(guid).then(response => {
        toast.success(response?.data?.message);
        setShowResend(false)
      }).catch(err => {
        return err
      })
    }
  }

  const pageChange = (pageNum) => {
    setInitialPage(pageNum);
  };

  return (
    <>
      {loader && <Loader />}


      <Modal onHide={() => setShowCancel(false)} show={showCancel} modalTitle={t("Employer.Employee_requests_approval.Cancel_confirmation")} setShowCancel={setShowCancel} footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowCancel((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              cancelRequest(guid)
            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      } />
      <Modal onHide={() => setShowResend(false)} show={showResend} modalTitle={t("Employer.Employee_requests_approval.Resend_confirmation")} setShowResend={setShowCancel} footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowResend((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              resendRequest(guid)
            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      } />
      <div className='white_box manage_screen'>
        <div className='title_box d-inline-flex w100p'>
          <h3 className='title_h3'>{t('Employer.Union_requests_approval.Union_request')}</h3>
          <div className='search_box d-flex ms-auto'>
            <div className='search_box_in'><span onClick={() => { sethandleSearch((prev) => !prev); setLoader(true) }}>{svg["Search"]}</span>
              <Form.Control type="text" placeholder={t("Employer.Manage_unions.Search")} onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchKeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoader(true);
                  }
                }} />
                </div>
                <div className='common_select ms-1'>
                  <span className='arrange' > SORT BY :</span>
                  <div class="select-wrapper" data-prefix="$" >
                <select
                  className="admin-dashboard-dropdown"
                  name="Studio"
                  id="Studio"
                  selected="Studio"
                  onChange={(e) => { setSort(e.target.value) }}
                >
                  <option value="union_name">
                    {t("Admin.EmployerRequests.Dropdown.Union")}
                  </option>
                  <option value="email">
                    {t("Admin.EmployerRequests.Dropdown.Email")}
                  </option>
                </select>
              </div>
                </div>
               
            

          </div>
        </div>
        <Table className='custom_table' responsive>
          {
            !loader && requestlist?.length === 0 ? <NoRecords /> :
              <>
                <thead>
                  <tr>

                    <th>{t('Employer.Union_requests_approval.Union_name')}</th>
                    <th>{t('Employer.Union_requests_approval.Contact')}</th>
                    <th>{t('Employer.Union_requests_approval.Production')}</th>
                    <th>{t('Employer.Union_requests_approval.Studio')}</th>
                    <th width="200px"> {svg["Edit"]} </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    requestlist.map((list, index) => {
                      return (
                        <tr key={index}>
                          <td><b>{list?.union_name}</b></td>
                          <td><b>{list?.invite_email?.split("@")[0]}<br></br> @{list?.invite_email?.split("@")[1]} </b></td>
                          <td>{list?.production_name}</td>
                          <td>{list?.studio_name}</td>
                          <td> <div className="action_button">
                            <a onClick={() => { setShowCancel(prev => !prev); setGuid(list?.linkguid) }} className="link" style={{ color: "#DC6962" }}>
                              {t('Employer.Union_requests_approval.Cancel')}
                            </a>
                            <a onClick={() => { setShowResend(prev => !prev); setGuid(list?.linkguid) }} className="link" style={{ color: "#038900" }}>
                              {t('Employer.Union_requests_approval.Resend')}
                            </a>
                          </div></td>
                        </tr>
                      )
                    })
                  }






                </tbody>
              </>
          }

        </Table>
        <div className="request-invite-archive">
                    <div className='d-flex'>
                    <Button className='btn-sm ms-2 ps-3 pe-3 ' variant="outline-secondary" onClick={() => navigate('/employer/unions')} >
                            {t('Employer.Unions-archive.Union_List')}
                        </Button>                     
                    </div>
                    <div>
                    {requestlist?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
                    </div>
                </div>

      </div>
    </>


  )
}

export default UnionRequestApproval