import { axiosInstance } from "../interceptor";

export const productionlist = (sort, search, perpage, page) => {
  return axiosInstance.get(
    `search/production?sortby=${sort}&search=${search}&perpage=${perpage}&page=${page}`
  );
};

export const Dashboardproductionlist = (sort, perpage, page) => {
  return axiosInstance.get(
    `search/production?sortby=${sort}&perpage=${perpage}&page=${page}`
  );
};

export const DashboardContactList = () => {
  return axiosInstance.get(`production-contacts`);
};

export const delproductionlist = (records) => {
  const data = {
    id: records,
  };
  return axiosInstance.delete(`employer`, { data: data });
};

export const DeleteCaregivers = (id) => {
  return axiosInstance.delete(`caregiver?id=${id}`)
};

export const studiolist = () => {
  return axiosInstance.get("studio-list");
};

export const inviteproductions = (id) => {
  return axiosInstance.get(`production-list?studio_id=${id}`);
};

export const archivelist = (search, sort, perpage, page) => {
  return axiosInstance.get(
    `archived-employers?sortby=${sort}&search=${search}&perpage=${perpage}&page=${page}`
  );
};

export const createinvite = (data) => {
  return axiosInstance.post(`create-invite`, data);
};

export const sendInvite = (data) => {
  return axiosInstance.post(`invite-link`, data)
};

export const linkexpire = (id) => {
  return axiosInstance
    .get(`invite-id-data?id=${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const restoreprod = (id) => {
  return axiosInstance.put(`restore/${id}`)
};

export const employerrequests = (search, page, sort) => {
  return axiosInstance.get(
    `employer/request?search=${search}&perpage=10&page=${page}&sortby=${sort}`
  );
};

export const cancelrequest = (id) => {
  return axiosInstance.delete(`cancel-request-link/${id}`)  
};

export const resendrequest = (email) => {
  return axiosInstance.post(`resend?linkguid=${email}`)
};

export const unionList = (search, page) => {
  return axiosInstance.get(
    `union-list?search=${search}&perpage=10&page=${page}`
  );
};

export const updateCaregiverDetail = (id) => {
  return axiosInstance.put(`admin/caregiver/${id}`);
};
export const ManageCaregiverListing = (sort, search, perpage, page) => {
  return axiosInstance.get(
    `admin/caregiver?sortby=${sort}&search=${search}&perpage=${perpage}&page=${page}`
  );
};

export const ArchivedCaregiverListing = (search, sort) => {
  return axiosInstance.get(
    `archived-caregiver?search=${search}&sortby=${sort}`
  );
};

export const AdminCaregivers = (id) => {
  return axiosInstance
    .get(`admin/caregiver/${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const AdminCaregiverUpdate = (id, data) => {
  return axiosInstance
    .put(`admin/caregiver/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const AdminCaregiverQualificationUpdate = (id, data) => {
  return axiosInstance
    .put(`admin/caregiver-qualification?id=${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const prodOverview = (id) => {
  return axiosInstance.get(`admin/production-overview?production_id=${id}`);
};

export const cancelledBookings = (search, page) => {
  return axiosInstance.get(
    `/admin/cancel-booking?search=${search}&page=${page}`
  );
};

export const blockCaregiver = (id) => {
  return axiosInstance.put(`admin/caregiver-block?caregiver_id=${id}`);
};

export const unblockCaregiver = (id) => {
  return axiosInstance.put(`admin/caregiver-unblock?caregiver_id=${id}`);
};

export const downloadReport = (id) => {
  return axiosInstance.get(
    `admin/download-report-complete-production?production_id=${id}`,
    { responseType: "blob" }
  );
};

export const payrollList = (search, sort, perpage, page) => {
  return axiosInstance.get(
    `admin/payroll-approval?search=${search}&sort_by=${sort}&perpage=${perpage}&page=${page}`
  );
};

export const viewPayroll = (id) => {
  return axiosInstance.get(`admin/view-payroll-approval?booking_id=${id}`);
};

export const approvePayroll = (data) => {
  return axiosInstance.put(`admin/update-payroll-approval`, data);
};

export const exportPayroll = (search, sort) => {
  return axiosInstance.get(
    `admin/export-to-excel-payroll-approval?search=${search}&sort_by=${sort}`,
    { responseType: "blob" }
  );
};

export const payrollgenerateList = (excel) => {
  let url = !excel
    ? "admin/payroll-generate-new-report"
    : `admin/payroll-generate-new-report?export=1`;
  return axiosInstance.get(url, { responseType: excel && "blob" });
};

export const generatenewPayroll = () => {
  return axiosInstance.post(`admin/payroll-generate-new-report-generate`);
};

export const generatedpayrollList = (page, perPage) => {
  return axiosInstance.get(
    `admin/payroll-generate?page=${page}&perPage=${perPage}`
  );
};

export const viewgeneratedPayroll = (id, excel) => {
  let url = !excel
    ? `admin/payroll-generate/${id}`
    : `admin/payroll-generate/${id}?export=1`;
  return axiosInstance.get(url, { responseType: excel && "blob" });
};
