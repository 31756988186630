import React from "react";

import { useTranslation } from "react-i18next";
import { dateFormat } from "../shared/moment";

const PayrollDetail = ({ data }) => {
    const { t } = useTranslation();
    const language = localStorage.getItem('i18nextLng')
    // const details= ["Regular","Kids","Extrakid","Midnight","Statutaory","Aftereight","Afterten","Special"]
    const details = [{
        title: "Regular",
        value: data?.regular_allowance
    },
    {
        title: "Kids",
        value: data?.extra_kids_allowance
    },
    {
        title: "Extrakid",
        value: data?.extra_kids_from_other_family_allowance
    },
        ,
    {
        title: "Midnight",
        value: data?.mid_night_allowance
    },
        ,
    {
        title: "Statutaory",
        value: data?.statutaory_hour_allowance
    },
        ,
    {
        title: "Aftereight",
        value: data?.after_8_hour_allowance
    },
        ,
    {
        title: "Afterten",
        value: data?.after_10_hour_allowance
    },
        ,
    {
        title: "Special",
        value: data?.special_day_allowance
    },


    ]
    return (
        <>
            <div className="payroll">
                <h6 className="event_heading">{t("Admin.Payroll.Detail")}</h6>
            </div>
            <section>
                <div>
                    <div className="row p-1">
                        <div className="col-md-4">
                            <div className="row payroll_headings">
                                <div className="col-md-12">
                                    <h6>{t("Admin.Payroll.Employee")} :</h6>
                                </div>
                            </div>
                            <div className="row payroll_headings">
                                <div className="col-md-12">
                                    <h6>{t("Employer.Employer_profile.Company_info.Production")} :</h6>
                                </div>
                            </div>
                            <div className="row payroll_headings">
                                <div className="col-md-12">
                                    <h6>Booking Date :</h6>
                                </div>
                            </div>
                            <div className="row payroll_headings">
                                <div className="col-md-12">
                                    <h6>{t("Admin.Payroll.CheckIn")} :</h6>
                                </div>
                            </div>
                            <div className="row payroll_headings">
                                <div className="col-md-12">
                                    <h6>{t("Admin.Payroll.CheckOut")} :</h6>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5">
                            <div className="row payroll_detail">
                                <div className="col-md-12">
                                    <h6>{data?.employee_name}</h6>
                                </div>
                            </div>
                            <div className="row payroll_detail">
                                <div className="col-md-12">
                                    <h6>{data?.production_name}</h6>
                                </div>
                            </div>
                            <div className="row payroll_detail">
                                <div className="col-md-12">
                                    <h6>{dateFormat(data?.booking_date, "YYYY-MM-DD HH:mm:ss", language)}</h6>
                                </div>
                            </div>
                            <div className="row payroll_detail">
                                <div className="col-md-12">
                                    <h6>{dateFormat(data?.check_in_date, "YYYY-MM-DD HH:mm:ss", language)}</h6>
                                </div>
                            </div>
                            <div className="row payroll_detail">
                                <div className="col-md-12">
                                    <h6>{dateFormat(data?.check_out_date, "YYYY-MM-DD HH:mm:ss", language)}</h6>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section>

            <section>
                <div className="white_box">
                    <div className="payment_title">
                        <h5>Calculation :</h5>
                        {
                            details?.map((list, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    <div className="row payment_block">
                                        <div className="d-flex justify-content-between calculation">
                                            <div><h6>{t(`Admin.Payroll.Modal.${list?.title}`)}</h6></div>
                                            <div><span>{list?.value === "" ? "$0.00" : `$${list?.value}`}</span> </div>
                                        </div>

                                    </div>
                                    </React.Fragment>
                                )
                            })
                        }

                        <div>
                            <div className="total_block">
                                <span>TOTAL</span>
                                <span className="float-end total">{`$${data?.amount}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PayrollDetail