import React, { useEffect,useState } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { recentCaregiver } from "../../../Services/https/Employee/Employee";

const Contacts = ({ data }) => {
    const { t } = useTranslation();
    const [caregiver,setCaregiver]=useState({})
    const { role_data } = useSelector(state => state.login);
   
    useEffect(() => {
        if(role_data==="3"){
            recentCaregiver().then(response => {
            setCaregiver(response?.data?.data)
            }).catch(err => {
                return err
            })
        }
    }, [])
    
    return (
        <>
            <div className="row">
                <div className="col-xl-6">
                    <div className="custom_headings">{t('Employee.Dashboard.Contact')}</div>
                    <span className="custom_description">{data?.manager_name}</span>
                    <div className="short_desc">M: <span className="c_939393">{data?.manager_phone}</span> </div>
                </div>
                <div className="col-xl-6">
                    <div className="custom_headings">{t('Employee.Dashboard.Recent_caregiver')}</div>
                    <span className="custom_description">{caregiver?.name}</span>
                    <div className="short_desc ">M:<span className="c_939393">{caregiver?.phone}</span> </div>
                </div>

            </div>

        </>
    )
}

export default Contacts