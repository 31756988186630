import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-select";

import {
  getcurrentprod,
  getprodlist,
  EmployerAddFundsInside,
} from "../Services/https/Employer/Employer";
import {
  UnionAddFundsInside,
  UnionDetails,
} from "../Services/https/Union/Union";

import Loader from "../shared/Loader";

const AddFunds = () => {
  const { t } = useTranslation();
  const [currentprod, setCurrentProd] = useState();
  const [productionId, setProductionId] = useState();
  const [productions, setProductions] = useState([]);
  const [loader, setLoader] = useState(true);
  const [unionId, setUnionId] = useState(null);
  const [checkboxValid, setCheckboxValid] = useState(false);
  const { role_data } = useSelector(state => state.login);


  // Prevent scrolling on number input

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // Allow only numbers and decimal on all browsers

  const keyPressAlphaNumeric = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 190 || (event.shiftKey === true)) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  //Get Current Production

  useEffect(() => {
    setLoader(true);
    getcurrentprod()
      .then((response) => {
        setCurrentProd(response?.data?.data?.production_name);
        setProductionId(response?.data?.data?.id);
        setLoader(false);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  // All Productions of an Employer

  useEffect(() => {
    getprodlist()
      .then((response) => {
        setProductions(response?.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  // Get Union id

  useEffect(() => {
    if (role_data === "4") {
      UnionDetails()
        .then((response) => {
          setUnionId(response?.data?.data?.union_id);
        })
        .catch((err) => {
          return err;
        });
    }
  }, []);

  //React-select style

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: "50px",

      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
    }),
    indicatorSeparator: () => { },
  };

  //Amount Schema

  const Schema = Yup.object().shape({
    amount: Yup.string().matches(
      "^[+]?[0-9]{1,9}(?:.[0-9]{1,9})?$",
      `${t("Employer.Funding.Add_funds.Valid")}`
    ),
  });

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="dashboard_screen">
          <div className={role_data === "2" ? "white_box" : ""}>
            <div className="row addFunds_main">
              <div className="col addFunds_header">
                <h2 className="h2_addFunds ">
                  {t("Employer.Funding.Add_funds.Add_fund")}
                </h2>
                <div className="pb-5">
                  <div className="addFunds_body">
                    {t("Employer.Funding.Add_funds.Funds_production")}
                  </div>
                  <div className="addFunds_body">
                    {t("Employer.Funding.Add_funds.Funds_thankyou")}!
                  </div>
                </div>
                <Formik
                  initialValues={{ amount: "" }}
                  validationSchema={Schema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    setLoader(true);
                    const data = {
                      production_id: productionId,
                      amount: values.amount,
                    };

                    // Union Add Funds

                    role_data === "4" ?
                      UnionAddFundsInside(data, unionId)
                        .then((response) => {
                          console.log(response);
                          if (response?.status === 200) {
                            setLoader(false);
                            window.open(response?.data?.data?.payment_url, "_self")
                          }
                        })
                        .catch((err) => {
                          return err;
                        })
                      :
                      //Employer Add Funds
                      EmployerAddFundsInside(data)
                        .then((response) => {
                          if (response?.status === 200) {
                            window.open(response?.data?.data?.payment_url, "_self")
                          }
                        })
                        .catch((err) => {
                          return err;
                        });

                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <div className="row">
                      <div className="col-xl-3"></div>
                      <div className="col-xl-6">
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-4">
                            <div className="col-xl-12">
                              <div className="form-group">
                                <Select
                                  options={productions?.map((list, index) => ({
                                    value: list?.production_name,
                                    label: list?.production_name,
                                    id: list?.id,
                                  }))}
                                  styles={CustomStyle}
                                  defaultValue={{
                                    label: currentprod,
                                    value: "2",
                                  }}
                                  onChange={(option) => {
                                    setProductionId(option?.id);
                                  }}
                                  placeholder={
                                    <div style={{ textAlign: "left" }}>
                                      Production
                                    </div>
                                  }
                                  name="production"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-xl-12">
                              <div className="form-group">
                                <input
                                  type="number"
                                  name="amount"
                                  value={values.amount}
                                  onKeyDown={(e) => keyPressAlphaNumeric(e)}
                                  placeholder={t(
                                    "Employer.Funding.Add_funds.Value"
                                  )}
                                  onChange={handleChange}
                                  onWheel={numberInputOnWheelPreventChange}
                                  onBlur={handleBlur}
                                  step="any"
                                  className="field_view form-control"
                                />
                                {values.amount == "0.0" && (
                                  <div className="error">
                                    {" "}
                                    {t("Employer.Funding.Add_funds.Valid")}
                                  </div>
                                )}
                                {errors.amount && (
                                  <div className="error">
                                    {touched.amount && <>{errors.amount}</>}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-xl-12">
                              <div className="d-flex align-items-center">
                                <input
                                  className="addFunds_check form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  disabled={
                                    values.amount && currentprod ? false : true
                                  }
                                  onChange={(e) =>
                                    setCheckboxValid(e.target.checked)
                                  }
                                />
                                <label
                                  className=" ms-4 addFunds_check_label"
                                  htmlFor="flexCheckDefault"
                                >
                                  {t(
                                    "Employer.Funding.Add_funds.Confirm_contribution"
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="text-center ">
                            <Button
                              type="submit"
                              disabled={checkboxValid ? false : true}
                              className="next ms-1 mt-4 funding_add"
                            >
                              {" "}
                              {t("Employer.Funding.Add_funds.Confirm")}
                            </Button>
                          </div>
                        </form>
                      </div>
                      <div className="col-xl-3"></div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddFunds;
