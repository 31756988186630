import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {Button,Form,Table} from 'react-bootstrap'
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Modal from "../shared/Modal";
import Loader from "../shared/Loader";
import Pagination from "../shared/Pagination";
import NoRecords from "../shared/NoRecords";

import { svg } from "../assets/svg/svg";

import { archivelist, restoreprod } from "../Services/https/Admin/Admin";


const ArchivedEmployers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [restore, setRestore] = useState(false)
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState('')
    const [handlesearch, sethandleSearch] = useState(false)
    const [loader, setLoader] = useState(true)
    const [initialpage, setInitialpage] = useState(1);
    const [totalcount, setTotalcount] = useState(null);
    const [id, setId] = useState(null)
    const [showNoRecord, setShowNoRecord] = useState(false)
    const pageCount = Math.ceil(totalcount / 10);

    useEffect(() => {
        getarchiveList();
    }, [sort, handlesearch, initialpage])

    const getarchiveList = () => {
        archivelist(search, sort, 10, initialpage).then(response => {
            setList(response?.data?.data?.data);
            setLoader(false);
            setTotalcount(response?.data?.data?.total)
            setShowNoRecord(true)
        }).catch(error => {
            return error
        })
    }

    const pageChange = (pageNum) => {
        setInitialpage(pageNum)
    }
    return (
        <>
            <Modal onHide={() =>
                setRestore(false)}
                show={restore}
                modalTitle={t("Employer.Employee_requests_approval.Restore_confirmation")}
                footer={
                    <div className="group_buttons mt-2">
                        <Button
                            variant="outline-info"
                            className="btn-lg"
                            onClick={() => setRestore((prev) => !prev)}
                        >
                            {t('Employer.Add_employee.Delete_modal.Cancel')}
                        </Button>
                        <Button
                            variant="info"
                            className="btn-lg"
                            onClick={() => {
                                restoreprod(id).then(response => {
                                    toast.success(response?.data?.message)
                                    getarchiveList();
                                    setRestore((prev) => !prev);
                                }).catch(err => {
                                    return err
                                })

                            }}
                        >
                            {t('Employer.Add_employee.Delete_modal.Confirm')}
                        </Button>
                    </div>
                } />


            {loader && <Loader />}

            <div className="white_box manage_screen">
                <div className="title_box d-inline-flex w100p">
                    <h3 className="title_h3 me-3">{t('Admin.Archived.Employers')}</h3>
                    <div className="search_box d-flex ">
                        <div className="search_box_in me-3">
                            <span onClick={() => { sethandleSearch(prev => !prev); setLoader(true) }}>{svg["Search"]}</span>
                            <Form.Control type="text" placeholder={t(
                                "Employer.Employee_archive.Search"
                            )}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setSearch(e.target.value);
                                        setLoader(true)
                                        sethandleSearch(prev => !prev)
                                    }
                                }} />
                        </div>
                        <div className="manage_employeer_dropdown">
                                   <div className="common_select">
                <span className="arrange">{t("Admin.Dashboard.Dropdown.Sort")}</span>
                <select
                  className="admin-dashboard-dropdown"
                  name="language"
                  id="language"
                  selected="Studio"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="studio_name">
                    {t("Admin.Dashboard.Dropdown.Studio")}
                  </option>
                  <option value="first_name">
                    {t("Admin.Dashboard.Dropdown.First_name")}
                  </option>
                  <option value="last_name">
                    {t("Admin.Dashboard.Dropdown.Last_name")}
                  </option>
                  <option value="production_name">
                    {t("Admin.Dashboard.Dropdown.Production")}
                  </option>
                </select>
              </div>


                        </div>
                    </div>
                </div>
                <Table className="custom_table" responsive>
                    {list?.length === 0 && showNoRecord ? <NoRecords /> : <>
                        <thead>
                            <tr>
                                <th>{t("Employer.Employee_archive.Last_name")}</th>
                                <th>{t("Employer.Employee_archive.First_name")}</th>
                                <th>{t("Employer.Employee_archive.Production")}</th>
                                <th>{t("Employer.Employee_archive.Studio")}</th>
                                <th width="200px">{svg["Edit"]} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list?.map((employers, index) => {
                                    return (
                                        <>
                                            <tr key={index}>

                                                <td><b>{employers?.last_name}</b></td>
                                                <td><b>{employers?.first_name}</b></td>
                                                <td>{employers?.production_name}</td>
                                                <td>{employers?.studio_name}</td>
                                                <td ><a onClick={() => { setId(employers?.user_id); setRestore(true) }} href="#" className='link'>{t(
                                                    "Employer.Employee_archive.Restore"
                                                )}</a> </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </>}
                </Table>

                <div className="request-invite-archive mt-2">
                    <div className='d-flex'>

                        <Button className='btn-sm  ps-3 pe-3 ' variant="outline-secondary" onClick={() => navigate('/admin/productions')}>
                            {t('Admin.Archived.Employer_list')}
                        </Button>
                    </div>
                    <div>
                        {
                            list?.length >= 1 ? <Pagination initialpage={initialpage} onpage={pageChange} pageCount={pageCount} /> : null
                        }

                    </div>

                </div>


            </div>
        </>
    )
}

export default ArchivedEmployers