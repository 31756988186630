import React,{ useState } from "react";

import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import CaregiverProfileEmployee from "./caregiverProfile";
import Rates from "../../Caregiver/profile/Rates";

const CaregiverInfo = () => {
    const {t} = useTranslation();
    const navigation=useNavigate();
    const [details,setDetails]=useState(true);
    const [rates,setRates]=useState(false);
    return (
        <>
<div>
      <div className="white_box manage_screen">
        <div className="row">
          <h3 className="title_balances">{t('Employee.Bookings.CaregiverInfo')}</h3>
        </div>
        <div className="employee-booking-cards">
            <div className="row ">
              <div className="col-xl-3">
                <Button 
                className={details ? "employee-btn-active":""}
                onClick={() => {
                  setRates(false);
                    setDetails(true);
                }}>{t('Admin.UpdateEmployeer.Details.Title')}</Button>
              </div>
              <div className="col-xl-3">
                <Button
                className={rates ? "employee-btn-active":""}
                onClick={() => {
                  setDetails(false);
                    setRates(true);
                }}>{t('Caregiver.Profile.Rates.Title')}</Button>
              </div>
            </div>
          </div>
          {details && <CaregiverProfileEmployee />}
          {rates && <Rates />}  
      </div>
    </div>
        </>
    )
}

export default CaregiverInfo