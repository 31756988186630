import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  role:null,
  production_name:null,
  studio_name:null,
  id:null,
  prod_id:null,
  production_id:null,
  redirectURL:null
};

export const invite = createSlice({
  name: "invite",
  initialState,
  reducers: {
    inviteEmail: (state, action) => {
     return {
        email:action.payload.email,
        role:action.payload.role,
        production_name:action.payload.production_name,
        studio_name:action.payload.studio_name,
        id:action.payload.id,
        prod_id:action.payload.prod_id,
        production_id:action.payload.production_id,
        redirectURL:action.payload.redirectURL
      }
    },

  },
});

export const { inviteEmail } = invite.actions;

export default invite.reducer;
