import moment from "moment";
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { upcomingShifts } from "../Services/https/Caregiver/Caregivers";

const Shifts = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [shifts, setShifts] = useState([]);
  const { timezonevalue } = useSelector(state => state?.login)
  const navigate = (address) => {
    navigation(`${address}`)
  }

  useEffect(() => {
    upcomingShifts().then(response => {
      setShifts(response?.data?.data)
    }).catch(err => {
      return err
    })
  }, [])
  return (
    <div>
      <div className="row d-flex">
        {
          shifts?.slice(0, 3)?.map((list, index) => {
            return (
              <React.Fragment key={index}>
                <div className='col-md-4 d-flex justify-content-center ' >
                  <div className=" caregiver_imgpreview d-flex ">
                    <div className="me-2">
                      <img src={list?.employee_profile_image} height="97px" width="97px" alt="no preview"></img>
                    </div>
                    <div className="caregiver_overview ">
                      <h6 className="shifts_dates">{moment(list?.booking_start_date_time, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY hh:mm A")} -</h6>
                      <h6 className="shifts_dates">{moment(list?.booking_end_date_time, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY hh:mm A")} ({timezonevalue})</h6>
                      <section className="ave">{list?.employee_name}</section>
                      <div className="upcomingShifts_details" onClick={() => navigate('/caregiver/requests?status=accept')}>{t('Caregiver.BookingRequests.PendingRequests.Detail')}</div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  );
};

export default Shifts;
