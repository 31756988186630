import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { requestReport } from "../Services/https/comman/common";


const Report = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const type = new URLSearchParams(location?.search).get("type");
    const userid = new URLSearchParams(location?.search).get("user_id");
    const productionId = new URLSearchParams(location?.search).get("production_id");
    const reqDate = new URLSearchParams(location?.search).get("request_date");

    useEffect(() => {
        if (type) {
            Generate();
        }
    }, [])

    const Generate = () => {
        requestReport(type, productionId, userid, reqDate).then(response => {
            const blob = new Blob([response?.data], { type: "octect/stream" });
            // creates a url that points to the file in the browsers memory
            const url = URL.createObjectURL(blob);
            // using an 'a' tag to trigger a download
            // browsers don't have a nice api for this
            const anchor = document.createElement("a");
            anchor.setAttribute("href", url);
            anchor.setAttribute("download", 'productionreport.xlsx');
            anchor.click();
            navigate('/admin/dashboard');
            window.self.close();
            window.close();
        }
        )
            .catch(err => {
                return err
            })
    }

    return (
        <></>
    )
}

export default Report;