import React,{ useEffect,useState } from "react";

import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { svg } from "../assets/svg/svg";

import FamilyLogo from '../assets/Images/icon-park-solid_family.png'


function Sidebar({ setActive }) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [employer, setEmployer] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [union, setUnion] = useState(false);
  const [caregiver, setCaregiver] = useState(false);
 

  const checkLoginStates = useSelector(role => role.login);
  const role = checkLoginStates.role_data
  {/* "Productions",*/}
  const CaregiverSidebar = ['Dashboard', 'Calendar', 'Requests', 'Profile']
  const AdminSidebar = ['Dashboard', "Productions", "Caregivers","Unions","Payroll Approval","Payroll Generate","Journals"]
  const UnionSidebar = ['Dashboard', "Productions", "Balances", "Profile"]
  const EmployerSidebar = ['Dashboard', "Calendar", "Add Funds",
    "Employees", "Unions", "Profile"]
  const EmployeeSidebar = ['Dashboard', "Productions","Calendar", "Requests", "Booking", "Profile", "Family Members"]

  useEffect(() => {
    const route = location?.pathname;
    const user = route.split("/")[1];
    if (user === "admin") {
      setAdmin(true);
      setUnion(false);
      setCaregiver(false);
      setEmployee(false);
      setEmployer(false);
    }
    if (user === "union") {
      setUnion(true);
      setAdmin(false);
      setCaregiver(false);
      setEmployee(false);
      setEmployer(false);
    }
    if (user === "caregiver") {
      setUnion(false);
      setCaregiver(true);
      setAdmin(false);

      setEmployee(false);
      setEmployer(false);
    }
    if (user === "employee") {
      setUnion(false);
      setCaregiver(false);
      setAdmin(false);

      setEmployee(true);
      setEmployer(false);
    }
    if (user === "employer") {
      setUnion(false);
      setCaregiver(false);
      setAdmin(false);

      setEmployee(false);
      setEmployer(true);
    }
  }, [location]);
  const navigation = (address) => {
    if (employee) {
      navigate(`/employee/${address}`);
    } else if (caregiver) {
      navigate(`/caregiver/${address}`);
    } else if (employer) {
      navigate(`/employer/${address}`);
    } else if (admin) {
      navigate(`/admin/${address}`);
    } else {
      navigate(`/union/${address}`);
    }
  };
 
  const links = document.querySelectorAll(".sidemenu .nav-link");
  links.forEach((link) => {
    link.addEventListener("click", function () {
      links.forEach((e) => {
        e.classList.remove("active");
      });
      this.classList.add("active");
    });
  });
  
  const removeSpace = (val) => {
   return val?.replace(/\s/g, '').toLowerCase()
  }

  return (
    <div className="sidebar">
      <Nav className="sidemenu" activeKey={location.pathname}>

        <ul className="sidebar_ul">
          {role === '1' && AdminSidebar.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <Nav.Link
                    className={removeSpace(value) === location?.pathname.split('/')[2]  ? "nav-link active" : 'nav-link'}
                    key={`${index}_${new Date().toDateString()}`}
                    onClick={() => {
                      navigation(removeSpace(value.toLowerCase()));
                      setActive((prev) => !prev);
                    }}
                  >
                    {value==="Payroll Approval" || value==="Payroll Generate" ? svg['Unions'] :svg[value]}
                    {t(`${value}`)}
                  </Nav.Link>
                </li>
              </React.Fragment>
            );
          })}
          {role === '2' && EmployerSidebar.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li >
                  <Nav.Link
                    className={removeSpace(value) === location?.pathname.split('/')[2] ? "nav-link active" : 'nav-link'}
                    key={`${index}_${new Date().toDateString()}`}
                    onClick={() => {
                       navigation(removeSpace(value.toLowerCase()));
                      setActive((prev) => !prev);
                    }}
                  >
                    {value==="Add Funds"? svg["Balances"]:  svg[value]}
                    {t(`${value}`)}
                  </Nav.Link>
                </li>
              </React.Fragment>
            );
          })}
          {role === '3' && EmployeeSidebar.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <Nav.Link
                    className={removeSpace(value)  === location?.pathname.split('/')[2]  ? "nav-link active" : 'nav-link'}
                    key={`${index}_${new Date().toDateString()}`}
                    onClick={() => {
                      value ==="Family Members" ? navigation('familymembers') :  navigation(value.toLowerCase());
                      setActive((prev) => !prev);
                    }}
                  >
                    {value==="Family Members" ? svg['Families'] :svg[value]}
                    {t(`${value}`)}
                  </Nav.Link>
                </li>
              </React.Fragment>
            );
          })}
          {role === '4' && UnionSidebar.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li >
                  <Nav.Link
                    className={removeSpace(value)  === location?.pathname.split('/')[2] ? "nav-link active" : 'nav-link'}
                    key={`${index}_${new Date().toDateString()}`}
                    onClick={() => {
                      navigation(removeSpace(value.toLowerCase()));
                      setActive((prev) => !prev);
                    }}
                  >
                    {svg[value]}
                    {t(`${value}`)}
                  </Nav.Link>
                </li>
              </React.Fragment>
            );
          })}
          {role === '5' && CaregiverSidebar.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <Nav.Link
                    className={removeSpace(value)  === location?.pathname.split('/')[2] ? "nav-link active" : 'nav-link'}
                    key={`${index}_${new Date().toDateString()}`}
                    onClick={() => {
                      navigation(removeSpace(value.toLowerCase()));
                      setActive((prev) => !prev);
                    }}
                  >
                    {svg[value]}
                    {t(`${value}`)}
                  </Nav.Link>
                </li>
              </React.Fragment>
            );
          })}
          <li><a>{(role==="3" && checkLoginStates?.hasfamily) && <img src ={FamilyLogo}/>}</a> </li>

        </ul>
      </Nav>
    </div>
  );
}

export default Sidebar;
