import React,{ useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {useLocation} from 'react-router-dom'
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";

import { employeeBookings } from "../../../Services/https/Employee/Employee";

import Accepted from "./Accepted";
import Pending from "./Pending";
import Cancelled from "./Cancelled";
import Loader from "../../../shared/Loader";
import UserTimezone from "../../../shared/UserTimezone";


const Bookings = () => {
  const { t } = useTranslation();
  const location =useLocation();
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [accepted, setAccepted] = useState(true);
  const [pending, setPending] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchKeyword,setSearchkeyword]=useState('')
  const [handlesearch, sethandleSearch] = useState(false);
  const {timezone} = useSelector(state=>state?.login)
 
  useEffect(() => {
    setLoader(true);
    if(searchKeyword === undefined){
      setSearchkeyword("")
    }
    employeeBookings(count,searchKeyword ).then((response) => { 
      setSearchkeyword("")
      setData(response?.data?.data);
      setLoader(false);
    }).catch(err=>{
      setLoader(false)
      toast.error(err?.response?.data?.message)
      return err
    });
  }, [count,handlesearch,timezone]);

useEffect(()=>{
  if(location?.search){
    if(location?.search==="?status=cancel"){
      setCancelled(true);
      setPending(false);
      setAccepted(false);
      setCount(2);
    }
    else if(location?.search==="?status=accept"){
      setAccepted(true);
      setCancelled(false);
      setPending(false);
      setCount(1);
    }
  }
},[location])

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="dashboard_screen">
          <div className="white_box">
            <p className="title_h3 ">{t("Booking")}</p>
            <div className="employee-booking-cards">
              <div className="row emplyee_booktab">
                <div className="col-xl-3">
                  <Button
                  className={accepted ? "employee-btn-active":""}
                    onClick={() => {
                      setAccepted(true);
                      setCount(1);
                      setPending(false);
                      setCancelled(false);
                    }}
                  >
                    {t("Employee.Bookings.Upcoming")}{" "}
                  </Button>
                </div>
                <div className="col-xl-3">
                  <Button
                   className={pending ? "employee-btn-active":""}
                    onClick={() => {
                      setAccepted(false);
                      setCount(0);
                      setPending(true);
                      setCancelled(false);
                      setSearchkeyword('')
                    }}
                  >
                    {t("Employee.Bookings.Pending")}
                  </Button>
                </div>
                <div className="col-xl-3">
                  <Button
                   className={cancelled ? "employee-btn-active":""}
                    onClick={() => {
                      setAccepted(false);
                      setCount(2);
                      setPending(false);
                      setCancelled(true);
                    }}
                  >
                    {t("Employee.Bookings.Cancelled")}
                  </Button>
                </div>

                { pending ? null : ( <div className="col-xl-3">
                  <div className="search_box_in">
                    <svg
                      className="icon"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={()=>   sethandleSearch((prev) => !prev)}
                    >
                      <path
                        d="M14.7507 16.3704C12.9139 17.7979 10.6024 18.4714 8.28671 18.2537C5.97106 18.0359 3.82543 16.9433 2.28669 15.1983C0.747957 13.4533 -0.068179 11.1871 0.00446449 8.86121C0.077108 6.5353 1.03307 4.32454 2.6777 2.67907C4.32232 1.0336 6.53196 0.0771475 8.85668 0.00446678C11.1814 -0.0682139 13.4464 0.748339 15.1905 2.28786C16.9346 3.82739 18.0267 5.97412 18.2443 8.29096C18.462 10.6078 17.7888 12.9205 16.362 14.7582L21.663 20.0421C22.1117 20.4894 22.1124 21.216 21.6645 21.6641C21.2166 22.1123 20.49 22.1119 20.0425 21.6633L14.7621 16.3704H14.7507ZM9.15107 16.0045C10.0515 16.0045 10.9431 15.827 11.775 15.4823C12.6069 15.1375 13.3628 14.6322 13.9995 13.9952C14.6362 13.3582 15.1412 12.6019 15.4858 11.7696C15.8304 10.9373 16.0077 10.0452 16.0077 9.14433C16.0077 8.24343 15.8304 7.35137 15.4858 6.51905C15.1412 5.68674 14.6362 4.93048 13.9995 4.29345C13.3628 3.65643 12.6069 3.15111 11.775 2.80636C10.9431 2.4616 10.0515 2.28416 9.15107 2.28416C7.33258 2.28416 5.58856 3.00692 4.30268 4.29345C3.01681 5.57998 2.29441 7.32489 2.29441 9.14433C2.29441 10.9638 3.01681 12.7087 4.30268 13.9952C5.58856 15.2817 7.33258 16.0045 9.15107 16.0045Z"
                        fill="#1D1D56"
                      />
                    </svg>
                    <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);

                   
                  }
                }}
                />
                  </div>
                </div> )}
              </div>
              <div>
                  <UserTimezone/>
                  </div>
                {accepted && <Accepted data={data} />}
                {pending && <Pending data={data} />}
                {cancelled && <Cancelled data={data} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Bookings;
