import React,{ useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Button,  Table } from "react-bootstrap";
import { toast } from "react-hot-toast";

import Modal from "../../../shared/Modal";
import Loader from "../../../shared/Loader";
import NoRecords from "../../../shared/NoRecords";
import { dateFormat } from "../../../shared/moment";
import { svg } from "../../../assets/svg/svg";
import BookingDetails from "../../../shared/bookingDetails";

import {
  employeeBookings,
  cancelBooking,
} from "../../../Services/https/Employee/Employee";


const Pending = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([...props?.data]);
  const [id, setId] = useState("");
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (modal) {
      setLoader(true);
      cancelBooking(id).then((response) => {
        toast.success("Request Deleted Succesfully");
        setId("");

        employeeBookings(0, searchKeyword).then((response) => {
          setData(response?.data?.data);
          setModal(false);
          setLoader(false);
        });
      });
    }
  }, [modal]);

  const getdata = (id) => {
    const getRecord = data?.find((records) => records?.id === id);
    setDetails(getRecord);
  }

  useEffect(() => {
    if (Object.keys(details)?.length >= 1) {
      setShowDetails(true)
    }
  }, [details])

  return (
    <>
      {open && (
        <Modal
          modalTitle={t(`Employee.Bookings.Delete`)}
          show={open}
          footer={
            <>
              <div className="group_buttons mt-5">
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  {t(
                    "Caregiver.BookingRequests.PendingRequests.PopUpDeclineCancel"
                  )}
                </Button>{" "}
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setModal(true);
                  }}
                >
                  {t(
                    "Caregiver.BookingRequests.PendingRequests.PopUpDeclineConfirm"
                  )}
                </Button>{" "}
              </div>
            </>
          }
        />
      )}
      {loader ? (
        <Loader />
      ) : data.length === 0 ? (
        <div style={{ marginTop: "50px" }}>
          {" "}
          <NoRecords />
        </div>
      ) : (
        <>
        <div style={{ paddingBottom: "0px", position: "relative" }}>
              <Table className="custom_table" responsive > 
          <thead>
            <tr>
              <th>{t("Employee.Bookings.Status")}</th>
              <th width="550px">{t("Employee.Bookings.Date")}</th>
              <th>{t("Employee.Bookings.Caretaker")}</th>
              <th>{t(`Employee.Bookings.Detail`)}</th>
              <th>{t("Employee.Bookings.Action")}</th>
            </tr>
          </thead>
          <tbody>
          {data?.map((item, index) => {
            return (
              <>
               
                  <tr>
                    <td>
                      <b className="pending manage_button">
                        {t("Employee.Bookings.Pending_req")}
                      </b>
                    </td>

                    <td>
                      <p>
                      { dateFormat(item?.dates,"YYYY-MM-DD HH:mm:ss",lang)
                      }{" "}
                          - { dateFormat(item?.end_date,"YYYY-MM-DD HH:mm:ss",lang)
                          }
                         ({item?.time_zone_code})
                      </p>
                      <div className="d-flex flex-column mt-2">
                        <span>{item?.location}</span>
                      </div>
                    </td>

                    <td>
                      <p style={{ color: "#DCA562" }}>{item?.status}</p>
                    </td>

                    <td>
                      <div className="d-flex flex-column mt-2">
                        <span>${item?.price ? item?.price : "0.00"}</span>
                        <div>{item?.fund}</div>
                        <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(item?.id)}>{svg["File"]}</span> </div>
                      </div>
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      <div className="dropdown">
                        <div
                          className="link"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <u
                            onClick={() => {
                              setId(item?.id);
                              setOpen((prev) => !prev);
                            }}
                          >
                            {t(
                              "Caregiver.BookingRequests.PendingRequests.PopUpDeclineCancel"
                            )}
                          </u>
                        </div>
                      </div>
                    </td>
                  </tr>
               
              </>
            );
          })}
           </tbody>
          </Table>
          {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails} />}

          </div>
        </>
      )}
    </>
  );
};

export default Pending;
