import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import { linkexpire } from "../../Services/https/Admin/Admin";

import { Button } from "react-bootstrap";
import { productiondata, removeunionlink } from "../../Services/https/Employer/Employer";



const SecondConfirmation = () => {

    const [productionData, setProductionData] = useState("")
    const location = useLocation()
    const navigation = useNavigate()

    const navigate = (route) => {
        navigation(`${route}`)
    }

    const id = new URLSearchParams(location.search).get("inviteid");
    const prod_guid = new URLSearchParams(location.search).get("prodid");
    const linkid = new URLSearchParams(location.search).get("linkguid");

    useEffect(() => {
        if (id !== "null") {
            linkexpire(id).then((response) => {

                setProductionData(response?.data?.data)
            })
                .catch(error => {
                    return error
                })
        }
        else {
            productiondata(prod_guid).then((response) => {
                setProductionData(response?.data?.data)
            })
                .catch(error => {
                    return error
                })
        }

    }, []
    )

    const removeLink = () => {
        const data = {
            status: 9,
            linkguid: linkid
        }
        removeunionlink(data).then(response => {
            navigate('/')
        }).catch(err => {
            return err
        })
    }
    return (
        <>

            <div className="confirm-production confirm-production-kids">
                <div className="container ">
                    <h3 className="confirm-production-header"> We are sorry to hear that.</h3>

                    <h5 className="confirm-production-text mt-3">Please contact {productionData?.manager}  to inform them that this is the incorrect production listed.</h5>

                    <p className="production-name mt-5">{productionData?.studio_name}</p>
                    <p className="production-name">{productionData?.production_name}</p>

                    <div className="mt-3  prod-buttons">
                        <Button className="next ms-1" onClick={() => removeLink()



                        }>Confirm</Button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SecondConfirmation