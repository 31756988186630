import React, { useState, useEffect } from "react";

import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { journallist } from "../../Services/https/comman/common";

import Loader from "../../shared/Loader";
import Pagination from "../../shared/Pagination";


const Journalslist = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [sort, setSort] = useState("");
    const [loader, setLoader] = useState(false);
    const [initialpage, setInitialpage] = useState(1);
    const [totalcount, setTotalcount] = useState(null);
    const pageCount = Math.ceil(totalcount / 10);


    useEffect(() => {
        setLoader(true)
        Listing();
    }, [initialpage, sort])

    const Listing = () => {
        journallist(10, initialpage, sort).then(response => {
            setLoader(false)
            setList(response?.data?.data?.data);
            setTotalcount(response?.data?.data?.meta?.pagination.total)
        }).catch(err => {
            setLoader(false)
            return err
        })
    }

    const pageChange = (pageNum) => {
        setInitialpage(pageNum);
    };


    return (
        <>
            {loader && <Loader />}
            <div className="white_box manage_screen">
                <div className="title_box d-inline-flex w100p">
                    <h3 className="title_h3 ">{t('Admin.Journals.Title')}</h3>
                    <div className="ms-auto d-flex">
                        <div className="manage_employeer_dropdown">
                            <div className="common_select">
                                <span className="arrange">{t("Admin.Dashboard.Dropdown.Sort")}</span>
                                <select
                                    className="admin-dashboard-dropdown"
                                    name="language"
                                    id="language"
                                    selected="Studio"
                                    onChange={(e) => setSort(e.target.value)}
                                >
                                    <option value="platform">
                                        {t("Admin.Journals.DropdownFee")}
                                    </option>
                                    <option value="union">
                                        {t("Admin.EmployerRequests.Dropdown.Union")}
                                    </option>
                                    <option value="production">
                                        {t("Admin.Dashboard.Dropdown.Production")}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="ms-3">
                            <Button
                                variant="info btn-info btn-lg"
                                className=""
                                onClick={() => navigate('/admin/journals/new')}
                            >
                                {t('Admin.Journals.Add')}
                            </Button>
                        </div>



                    </div>
                </div>
                <Table className="custom_table" responsive>
                    <>
                        <thead>
                            <tr>
                            <th>{t("Admin.Journals.Id")}</th>
                            <th>{t("Admin.Journals.TransactionType")}</th>
                            <th >{t("Employer.Employer_profile.Company_info.Production")}</th>
                            <th>{t("Admin.Journals.Refund")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list?.map((list, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                        <tr>
                                            <td>#{list?.id}</td>
                                            <td>{list?.transaction_type?.charAt(0).toUpperCase() + list?.transaction_type?.slice(1)}</td>
                                            <td>{list?.production}
                                                {list?.union && <div>{list?.union}</div>}
                                            </td>
                                            <td>${list?.refund}</td>
                                        </tr>
                                        </React.Fragment>
                                    )
                                })
                            }

                        </tbody>
                    </>
                </Table>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    {list?.length >= 1 ? (
                        <Pagination
                            initialpage={initialpage}
                            onpage={pageChange}
                            pageCount={pageCount}
                        />
                    ) : null}
                </div>

            </div>
        </>
    )
}

export default Journalslist