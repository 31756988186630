import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Dashboard from '../shared/Dashboard'
import Loader from '../shared/Loader'
import Overview from './Overview'
import StyleOfCare from './StyleOfCare'
import UnionCards from './UnionCards'
import UnionHide from './UnionHide'
import UnionProduction from './UnionProduction'

import { productionOverview } from '../Services/https/Employee/Employee'
import { employeelist } from '../Services/https/Employer/Employer'
import Pagination from '../shared/Pagination'


function ProductionDetails() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { id } = useParams();
  const [showEmployeeCard, setShowEmployeeCard] = useState(true)
  const [show, setShow] = useState(true);
  const [overview, setOverView] = useState([]);
  const [employees, setEmployees] = useState([])
  const [totalcount, setTotalcount] = useState(null);
  const [initialpage, setInitialpage] = useState(1);
  const [loader, setLoader] = useState(true);
  const pageCount = Math.ceil(totalcount / 10);

  useEffect(() => {
    productionOverview(id).then(response => {
      setOverView(response?.data?.data);
      setLoader(false)
    }).catch(err => {
      return err
    })
  }, [])

  useEffect(() => {
    employeelist(id, "", 15, initialpage).then(response => {
      setEmployees(response?.data?.data?.paginate?.data)
      setTotalcount(response?.data?.data?.total);
      setLoader(false)
    }).catch(err => {
      return err
    })
  }, [initialpage, show])

  const pageChange = (pageNum) => {
    setLoader(true)
    setInitialpage(pageNum);
  };

  const handelHideAndShow = () => {
    !show && setLoader(true)
    setShow(!show)
  }
  return (
    <>
      {loader && <Loader />}
      <div className="d-flex align-items-baseline">
        <svg onClick={() => { navigate(-1) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-left ms-2" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg >
        <p className="title_h3 ms-4">{overview[0]?.production_name}
        </p>
      </div>


      <div className="row mb-2">
        <div className="col-xl-12 col-md-12 ">
          <Dashboard title={t('Employee.Focused-productions.Studio')} text_decorator='true' component={<UnionProduction data={overview} />} />
         
        </div>
      </div>



      <div className="row">
        <div className='col-xl-8 col-sm-12'>
          <div className="dashboard_screen">
            <div className='white_box union_emp_box'>
              <div className="employee-union-cards">
                <h4 className="title_h4_line"><span>{t('Union.Dashboard.Emp_in_prod')}</span> </h4>
                {showEmployeeCard && <div className="employee-card">
                  {show ? <UnionCards list={employees} /> :
                    <UnionHide />}

                </div>}

              </div>
              <div className='col  float-end' style={{ display: "flex", alignItems: "baseline" }}>
                {showEmployeeCard &&

                  <button onClick={() => { handelHideAndShow() }} className="show-manage ms-auto">
                    {show ? <>{t('Union.Production_detail.Hide')}


                    </> : <>{t('Union.Production_detail.Show')}</>}
                  </button>}
                {employees?.length >= 1 && show ? (
                  <Pagination
                    initialpage={initialpage}
                    onpage={pageChange}
                    pageCount={pageCount}

                  />
                ) : null}

              </div>
            </div>
          </div>

        </div>
        <div className='col-xl-4 col-sm-12'>
          <div className="row">
            <div className="overview">
              <Dashboard title={t('Union.Production_detail.Overview')} text_decorator='true' component={<Overview data={overview} prodid={id} />} /> </div>
            <div className="style-of-care">
              <Dashboard title={t('Union.Production_detail.Style_of_Care')} text_decorator='true' component={<StyleOfCare />} />
            </div>
          </div>
        </div>

      </div>
    </>)
}

export default ProductionDetails