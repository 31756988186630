import React from "react";

import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";

import EmployerFundingOverview from './EmployerFundingOverview';
import ChildcareBreakdown from "../Union/balances/childBreakdown/ChildcareBreakdown";
import AddFunds from "./AddFunds";
import UserTimezone from "../shared/UserTimezone";

const EmployerFundingTabs = () => {
  const {t} = useTranslation();
  const navigation=useNavigate();
  const [overview,setOverview]=useState(true);
  const [childcare,setChildcare]=useState(true);
  const [funds,setFunds]=useState(false);

  return (
    <div>
      <div className="white_box manage_screen">
        <div className="row">
          <h3 className="title_balances">{t('Employer.Funding.Balances')}</h3>
        </div>
        <div className="employee-booking-cards">
            <div className="row ">
              {/* <div className="col-xl-3">
                <Button
                className={overview ? "employee-btn-active":""}
                  onClick={() => {
                    setOverview(true);
                    setFunds(false);
                    setChildcare(false);
                  }}
                >
                
                 {t('Employer.Funding.Overview_title')}
                </Button>
              </div> */}
              <div className="col-xl-3">
                <Button 
                className={childcare ? "employee-btn-active":""}
                onClick={() => {
                  setOverview(false);
                    setFunds(false);
                    setChildcare(true);
                }}>{t('Employer.Funding.Childcare_breakdown_title')}</Button>
              </div>
              <div className="col-xl-3">
                <Button
                className={funds ? "employee-btn-active":""}
                onClick={() => {
                  setOverview(false);
                    setFunds(true);
                    setChildcare(false);
                }}>{t('Employer.Funding.Add_funds_title')}</Button>
              </div>
            </div>
            <div>
                  {
                    !funds && <UserTimezone/>
                  }
                  </div>
          </div>
          {/* {overview && <EmployerFundingOverview /> }  */}
          {funds && <AddFunds />}
          {childcare && <ChildcareBreakdown />}  
      </div>
    </div>
  );
};

export default EmployerFundingTabs;
