import React,{useEffect,useState} from "react";

import {useTranslation} from 'react-i18next';
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import PendingRequests from "./PendingRequests";
import AcceptedBookings from "./AcceptedBookings";
import DeclinedBookings from "./DeclinedBookings"

import Loader from "../../shared/Loader";

import { bookingRequests } from "../../Services/https/Caregiver/Caregivers";
import UserTimezone from "../../shared/UserTimezone";

const BookingRequests = () => {
  const [changes,setChanges]=useState(null);
  const [loader,setLoader]=useState(false)
  const [records,setRecords]=useState([]);
  const {t}=useTranslation();
  const navigation=useNavigate();
  const location = useLocation();
  const [reqstatus,setRequestStatus]=useState("");
  const {timezone} = useSelector(state=>state?.login)

  useEffect(()=>{
    if(location?.search){
      if(location?.search==="?status=pending"){
        setRequestStatus('Pending')
      }
      if(location?.search==="?status=cancel"){
        setRequestStatus('Declined')
      }
      if(location?.search==="?status=accept"){
        setRequestStatus('Accepted')
      }
      
    }
    else{
      setRequestStatus("Pending")
    }
  },[location?.search])


  useEffect(()=>{
    if(reqstatus) {
      const status = reqstatus==="Pending" ? 0 : reqstatus==="Accepted" ? 1: 2;
      Requests(status)
    }

  },[reqstatus,changes,timezone]);

  const Requests = (status) => {
    setLoader(true)
    bookingRequests(status).then((response)=>{
      setRecords(response?.data?.data);
      setLoader(false)
    }).catch(err=>{
      setLoader(false)
      return err
    })
  }

  const detectChange = (value) => {
    setChanges(value)
  }

  return (
    <>
    {loader && <Loader/>}
      <div>
        <div className="white_box manage_screen">
          <div className="row">
            <h3 className="title_h3"> {t("Caregiver.BookingRequests.Booking_requests")}</h3>
          </div>
          <div className="employee-booking-cards">
            <div className="row ">
              <div className="col-xl-3">
                <Button
                className={reqstatus==="Pending" ? "employee-btn-active":""}
                  onClick={() => {
                    navigation(`/caregiver/requests?status=pending`)
                  }}
                >
                
                 {t('Caregiver.BookingRequests.PendingRequests.Title')}
                </Button>
              </div>
              <div className="col-xl-3">
                <Button 
                className={reqstatus==="Accepted" ? "employee-btn-active":""}
                onClick={() => {
                  navigation(`/caregiver/requests?status=accept`)
                }}>{t('Caregiver.BookingRequests.AcceptedBookings.Title')}</Button>
              </div>
              <div className="col-xl-3">
                <Button
                className={reqstatus==="Declined" ? "employee-btn-active":""}
                onClick={() => {
                  navigation(`/caregiver/requests?status=cancel`)
                }}>{t('Caregiver.BookingRequests.DeclinedBookings')}</Button>
              </div>
            </div>
            <div><UserTimezone/></div>

          </div>
          {reqstatus==="Accepted" && <AcceptedBookings data={records} change= {detectChange} load={loader}/>}
          {reqstatus==="Pending" && <PendingRequests data={records} change= {detectChange} load={loader}/>}
          {reqstatus==="Declined"  && <DeclinedBookings data={records} change= {detectChange} load={loader}/>}
        </div>
      </div>
    </>
  );
};

export default BookingRequests;
