import React,{ useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import { Tooltip } from "bootstrap";
import toast from "react-hot-toast";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import DatePicker, { registerLocale } from "react-datepicker";

import {Button} from "react-bootstrap";
import fr from "date-fns/locale/fr-CA";
import en from "date-fns/locale/en-US";

import Loader from "../shared/Loader";
import Modal from "../shared/Modal";
import UserTimezone from "../shared/UserTimezone";
import CalenderModal from "./CalenderModal";

import {
  CreateAvailability,
  GetMonthData,
  CancelAvailability,
} from "../Services/https/Caregiver/Caregivers";
registerLocale("fr", fr);
registerLocale("en", en);

let tooltipInstance = null;

const AvailabilityCalender = () => {
  const tdArray = document.getElementsByClassName("fc-day-future");
  for (var i = 0; i <= tdArray.length; i++) {
    if (
      document.getElementsByClassName("fc-day-future")[i]?.childNodes[0]
        ?.childNodes[2]?.childNodes[0]?.childNodes[0]
    ) {
      document
        .getElementsByClassName("fc-day-future")
        [i]?.classList.add("td-class");
    }
  }


  const formRef = useRef();
  const { t } = useTranslation();
  const localization = localStorage.getItem("i18nextLng");
  const { name } = useSelector((state) => state.login);

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState({});
  const [timeerrormessage, setTimeErrorMessage] = useState(false);
  const [dateerrormessage, setDateErrorMessage] = useState(false);
  const [availabilityCollapsed, setAvailabilityCollapsed] = useState(false);
  const [collapsedDates, setCollapsedDates] = useState([]);
  const [eventsOfMonth, setEventsOfMonth] = useState([]);
  const [newData, setNewData] = useState({});
  const [eventsShow, setEventsShow] = useState(false);
  const [eventTime, setEventTime] = useState({
    start_time: null,
    end_time: null,
  });
  const [eventId, setEventId] = useState(null);
  const [flag, setFlag] = useState(false);
  const [days, setDays] = useState([
    {
      id: 1,
      value: "Sunday",
      checked: false,
    },
    {
      id: 2,
      value: "Monday",
      checked: false,
    },
    {
      id: 3,
      value: "Tuesday",
      checked: false,
    },
    {
      id: 4,
      value: "Wednesday",
      checked: false,
    },
    {
      id: 5,
      value: "Thursday",
      checked: false,
    },
    {
      id: 6,
      value: "Friday",
      checked: false,
    },
    {
      id: 7,
      value: "Saturday",
      checked: false,
    },
  ]);

  const [selectedDate, setSelectedDate] = useState("");

  const numberOfMonths = 2;

  useEffect(()=>{
    if(selectedDate){
      setLoader(true)
      GetMonthData(selectedDate, numberOfMonths)
      .then((response) => {
         setEventsOfMonth(response?.data?.data);
          setLoader(false);
        })
      .catch((error) => {
         return error;
       });
    }
     
  },[selectedDate,show,eventsShow,flag])

  // Time Check
  useEffect(() => {
    const start = moment(startTime, "HH:mm:ss");
    const end = moment(endTime, "HH:mm:ss");
    if (start.isAfter(end)) {
      setTimeErrorMessage(true);
    } else {
      setTimeErrorMessage(false);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    const editstart = moment(eventTime.start_time, "HH:mm:ss");
    const editend = moment(eventTime.end_time, "HH:mm:ss");
    if (editstart.isAfter(editend)) {
      setTimeErrorMessage(true);
    } else {
      setTimeErrorMessage(false);
    }
  }, [eventTime.start_time, eventTime.end_time]);

  //Date Check
  useEffect(() => {
    const start = moment(startDate, "HH:mm:ss");
    const end = moment(endDate, "HH:mm:ss");
    if (start.isAfter(end)) {
      setDateErrorMessage(true);
    } else {
      setDateErrorMessage(false);
    }
  }, [startDate, endDate]);

  //Toaster Messages
  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  //Checkbox Onchange

  const handleRepeatChange = (e, id) => {
    const { checked } = e.target;
    setDays(
      days.map((i) => {
        if (id === i.id) {
          return { ...i, checked: checked };
        } else {
          return i;
        }
      })
    );
  };

  // Edit Availability Modal Description
  const ChangeAvailability = () => {
    return (
      <div>
        <Formik
          innerRef={formRef}
          initialValues={{
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            start_time: moment(startTime).format("HH:mm:ss"),
            end_time: moment(endTime).format("HH:mm:ss"),
            repeat_request: "",
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            setShow(false);
            setNewData(formRef?.current?.values);

            setLoader(true);
            //Get selected days
            const selectedDays = days
              .filter((i) => {
                return i.checked;
              })
              .map((i) => {
                return i.value;
              })
              .toString();
            const data = {
              start_date: values.start_date,
              end_date: values.end_date,
              start_time: values.start_time,
              end_time: values.end_time,
              repeat_request: selectedDays,
              is_collapse: false,
            };
            // Create Availability

            if (!timeerrormessage && !dateerrormessage) {
              CreateAvailability(data)
                .then((response) => {
                  if (response?.status === 200) {
                    toast.success(response?.data?.message);
                  }
                  if (response?.response?.status === 400) {
                    setCollapsedDates(response?.response?.data?.data);
                    setAvailabilityCollapsed(true);
                  }
                  setFlag((prev) => !prev);
                  setLoader(false);
                })
                .catch((err) => {
                  setErrorMessage(err?.response?.data?.errors);
                  return err;
                });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <UserTimezone/>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <label className="mb-2 mt-2 calendar_modal_label">
                      {t("Caregiver.Availability_calender.StartDate")}
                    </label>
                  </div>
                  <DatePicker
                    className="crew_schedule_fields form-control"
                    locale={localization == "en" ? "en" : "fr"}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={new Date()}
                  />
                </div>
                <div className="col-lg-6">
                  <div>
                    <label className="mb-2 mt-2 calendar_modal_label">
                      {t("Caregiver.Availability_calender.EndDate")}
                    </label>
                  </div>

                  <DatePicker
                    className="crew_schedule_fields form-control"
                    locale={localization == "en" ? "en" : "fr"}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={new Date()}
                  />
                  {dateerrormessage && (
                    <div className="error">
                      {t("Employer.Crew_schedule.Date")}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div>
                      <label className="mb-2 mt-4 calendar_modal_label">
                        {t("Caregiver.Calendar.CalendarPopUp.StartTime")}
                      </label>
                    </div>

                    <DatePicker
                      className="crew_schedule_fields form-control time_width"
                      name="start_time"
                      id="start_time"
                      selected={startTime}
                      placeholderText="Start Time"
                      onChange={(date) => setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss aa"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <div>
                      <label className="mb-2 mt-4 calendar_modal_label">
                        {t("Caregiver.Calendar.CalendarPopUp.EndTime")}
                      </label>
                    </div>
                    <DatePicker
                      className="crew_schedule_fields form-control time_width"
                      name="end_time"
                      id="end_time"
                      selected={endTime}
                      placeholderText="End Time"
                      onChange={(date) => setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss aa"
                    />

                    {timeerrormessage && (
                      <div className="error">
                        {t("Employer.Crew_schedule.Time")}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label className="mb-2 mt-4 calendar_modal_repeat_label ">
                  {t("Caregiver.Calendar.CalendarPopUp.Repeat")}
                </label>
              </div>
              <div className="row popup_week">
                {days?.map((item, index) => {
                  return (
                    <div className="col-lg-3 weekdays_label">
                      <input
                        className="change_availability_checkbox form-check-input"
                        type="checkbox"
                        value={`${item?.value}`}
                        id="flexCheckDefault"
                        name="repeat_request"
                        checked={item.checked}
                        onChange={(e) => handleRepeatChange(e, item?.id)}
                      />
                      <label className="form-check-label  ms-2">
                        {t(`Caregiver.Availability_calender.${item?.value}`)}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="group_buttons mt-5">
                <Button variant="info" type="submit" className="btn-lg" disabled={timeerrormessage}>
                  {t("Caregiver.Calendar.CalendarPopUp.Confirm")}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  // Cancel availability
  const handleCancellation = () => {
    setLoader(true);
    CancelAvailability(eventId)
      .then((response) => {
        setEventsShow(false);
        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }

        setLoader(false);

        setFlag((prev) => !prev);
      })
      .catch((error) => {
        return error;
      });
  };

  //  Single day Availability Modal body
  const EditAvailability = () => {
    return (
      <div>
        <Formik
          innerRef={formRef}
          initialValues={{
            start_date: moment(startDate).format("YYYY-MM-DD"),
            start_time: moment(eventTime.start_time).format("HH:mm:ss"),
            end_time: moment(eventTime.end_time).format("HH:mm:ss"),
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            setLoader(true);
            setEventsShow(false);

            const data = {
              start_date: values.start_date,
              end_date: values.start_date,
              start_time: values.start_time,
              end_time: values.end_time,
              is_collapse: true,
            };
            // Create Availability

            CreateAvailability(data)
              .then((response) => {
                setLoader(false);
                if (response?.status === 200) {
                  toast.success(response?.data?.message);
                }
                if (response?.response?.status === 400) {
                  setCollapsedDates(response?.response?.data?.data);
                  setAvailabilityCollapsed(true);
                  setLoader(false);
                }

                setFlag((prev) => !prev);
              })
              .catch((err) => {
                setErrorMessage(err?.response?.data?.errors);
                return err;
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <label className="mb-2 mt-2 calendar_modal_label">
                      {t("Caregiver.Calendar.CalendarPopUp.Date")}
                    </label>
                  </div>

                  <DatePicker
                    className="crew_schedule_fields form-control"
                    locale={localization == "en" ? "en" : "fr"}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    name="start_date"
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div>
                      <label className="mb-2 mt-4 calendar_modal_label">
                        {t("Caregiver.Calendar.CalendarPopUp.StartTime")}
                      </label>
                    </div>

                    <DatePicker
                      className="crew_schedule_fields form-control time_width"
                      name="start_time"
                      id="start_time"
                      selected={eventTime?.start_time}
                      placeholderText="Start Time"
                      onChange={(date) =>
                        setEventTime({ ...eventTime, start_time: date })
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss aa"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <div>
                      <label className="mb-2 mt-4 calendar_modal_label">
                        {t("Caregiver.Calendar.CalendarPopUp.EndTime")}
                      </label>
                    </div>
                    <DatePicker
                      className="crew_schedule_fields form-control time_width"
                      name="end_time"
                      id="end_time"
                      selected={eventTime?.end_time}
                      placeholderText="End Time"
                      onChange={(date) =>
                        setEventTime({ ...eventTime, end_time: date })
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss aa"
                    />

                    {timeerrormessage && (
                      <div className="error">
                        {t("Employer.Crew_schedule.Time")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 group_buttons mt-5">
                  <Button
                    variant="info"
                    onClick={handleCancellation}
                    className="btn-lg"
                  >
                    {t("Caregiver.Availability_calender.CancelAvailability")}
                  </Button>
                </div>
                <div className="col-lg-6  group_buttons mt-5">
                  <Button variant="info" type="submit" className="btn-lg">
                    {t("Caregiver.Calendar.CalendarPopUp.Confirm")}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  // Modal's descriptions
  const ChangeAvailabilityDescription = {
    title: `${t("Caregiver.Calendar.CalendarPopUp.Title")}`,
    body: <ChangeAvailability />,
  };
  const EditAvailabilityDescription = {
    title: `${t("Caregiver.Calendar.CalendarPopUp.Title")}`,
    body: <EditAvailability />,
  };

  // get selected days while overwriting availability
  const selectedDays = days
    .filter((i) => {
      return i.checked;
    })
    .map((i) => {
      return i.value;
    })
    .toString();

  // Overwrite Availability
  const handleOverwrite = () => {
    const data = {
      start_date: newData.start_date,
      end_date: newData.end_date,
      start_time: newData.start_time,
      end_time: newData.end_time,
      repeat_request: selectedDays,
      is_collapse: true,
    };
    setLoader(true);
    if (!timeerrormessage && !dateerrormessage) {
      CreateAvailability(data)
        .then((response) => {
          if (response?.status === 200) {
            toast.success(response?.data?.message);
            setAvailabilityCollapsed((prev) => !prev);
          }
          if (response?.response?.status === 400) {
            setCollapsedDates(response?.response?.data?.data);
            setAvailabilityCollapsed(true);
          }

          setFlag((prev) => !prev);
          setLoader(false);
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.errors);
          return err;
        });
    }
  };

  // Calendar hover
  const handleMouseEnter = (arg) => {
    if (arg.event.extendedProps.start_time) {
      tooltipInstance = new Tooltip(arg.el, {
        title:
          moment(arg.event.extendedProps.start_time,"HH:mm:ss").format("hh:mm A")  +
          ` ${t("Caregiver.Availability_calender.to")} ` +
         moment(arg.event.extendedProps.end_time,"HH:mm:ss").format("hh:mm A") ,
        html: true,
        trigger: "hover",
        placement: "top",
        container: "body",
      });

      tooltipInstance.show();
    }
  };

  // Dispose tooltip
  const handleMouseLeave = (arg) => {
    setLoader(true);
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
      setLoader(false);
    }
  };
  return (
    <>
    {/* Conflicts in availability modall */}

      <Modal
        show={availabilityCollapsed}
        modalTitle={t("Caregiver.Availability_calender.Conflicts")}
        description={collapsedDates.map((item, index) => {
          return (
            <>
              <b> {item?.start_date_time + ","}</b>
            </>
          );
        })}
        additionalDescription={t(
          "Caregiver.Availability_calender.AdditionalDescription"
        )}
        footer={
          <div className="group_buttons mt-2">
            <Button
              variant="info"
              className="btn-lg"
              onClick={() => setAvailabilityCollapsed((prev) => !prev)}
            >
              {t("Caregiver.Availability_calender.Cancel")}
            </Button>
            <Button variant="info" className="btn-lg" onClick={handleOverwrite}>
              {t("Caregiver.Availability_calender.Overwrite")}
            </Button>
          </div>
        }
      />
      <div>
        {loader && <Loader />}
        <div className="dashboard_screen row">
          <h3 className=" title_h3">
            {t("Caregiver.Availability_calender.Welcome")}, <span>{name}</span>
          </h3>

          <div className="availability_info">
            <div className="availaibility_subheading ">
              {t("Caregiver.Availability_calender.Availability")}
            </div>
            <div className="availability_btn ">
              <button
                className="btn  funding_add edit_availability_button "
                type="button"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("Caregiver.Availability_calender.Edit_availability")}
              </button>
            </div>
          </div>
        </div>
        
          <div className="row mt-3 d-flex justify-content-center calender_wrapper1">
            <div className="col-lg-6 ">
              
                <FullCalendar
                  plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  locale={localization == "fr" && frLocale}
                  headerToolbar={{
                    left: "prev",
                    center: "title",
                    right: "next",
                  }}
                  // initialDate={calendarInitialMonth}
                  lazyFetching={false}
                  eventMouseEnter={handleMouseEnter}
                  eventMouseLeave={handleMouseLeave}
                  themeSystem="Simplex"
                  datesSet={(e) => {

                    var midDateMonth = new Date((e.start.getTime() + e.end.getTime()) / 2).getMonth() + 1;
                var year = new Date((e.start.getTime() + e.end.getTime()) / 2).getFullYear();
                let startDateFormat;
                if (midDateMonth < 10) {
                  startDateFormat = `0${midDateMonth}`;
                }
                else {
                  startDateFormat = `${midDateMonth}`;
                }
                var start = year + "-" + moment(startDateFormat, "MM").startOf("month").format("MM-DD");
setSelectedDate(start)
                  }}
                  eventBackgroundColor={"#1e1e56"}
                  eventColor={"1e1e56"}
                  eventClick={(arg) => {
                    setEventId(arg.event._def.publicId);
                    const date = moment(arg.event._instance.range.start).format(
                      "LL"
                    );
                    const startEventTime = new Date(
                      moment(
                        `${date} ` + arg.event.extendedProps.start_time
                      ).format()
                    );
                    const endEventTime = new Date(
                      moment(
                        `${date} ` + arg.event.extendedProps.end_time
                      ).format()
                    );
                    setEventTime({
                      start_time: startEventTime,
                      end_time: endEventTime,
                    });
                    setStartDate(arg.event?.start);
                    setEventsShow((prev) => !prev);
                  }}
                  events={eventsOfMonth}
                />
             
            </div>
          </div>
        

        <CalenderModal
          onHide={() => setShow(false)}
          show={show}
          setShow={setShow}
          ChangeAvailabilityDescription={ChangeAvailabilityDescription}
        />
        <CalenderModal
          onHide={() => setEventsShow(false)}
          show={eventsShow}
          setShow={setEventsShow}
          ChangeAvailabilityDescription={EditAvailabilityDescription}
        />
      </div>
    </>
  );
};

export default AvailabilityCalender;
