import React,{ useEffect,useState } from 'react';

import { Form, Table,Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Loader from '../shared/Loader';
import NoRecords from '../shared/NoRecords';
import Pagination from '../shared/Pagination';
import ModalDialog from '../shared/Modal';

import { svg } from '../assets/svg/svg';

import { blockCaregiver, cancelledBookings } from '../Services/https/Admin/Admin';

const BlockedCaregivers = () => {
    const {t}= useTranslation();
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const [show,setShow]=useState(false)
    const [records,setRecords]=useState([])
    const [searchkeyword, setSearchkeyword] = useState("");
    const [handlesearch, sethandleSearch] = useState(false);
    const [totalcount, setTotalcount] = useState(null);
    const [initialpage, setInitialpage] = useState(1);
    const pageCount = Math.ceil(totalcount / 10);

    useEffect(()=>{
      cancelBookings();
    },[handlesearch,initialpage])

    

    const cancelBookings=()=>{
      setLoading(true)
      cancelledBookings(searchkeyword,initialpage).then(response=>{
        setLoading(false);
        setRecords(response?.data?.data?.data?.map(list=>{
          list['showfullString'] = false
          return list
        }))
        setTotalcount(response?.data?.data?.meta?.pagination?.total)
      }).catch(err=>{
        return err
      })
    }

    const showString = (index) => {
      const allrecords = [...records];
      allrecords[index].showfullString = !allrecords[index].showfullString;
        setRecords(allrecords);
    }

    const BlockCaregiver = (id) => {
        setLoading(true)
        blockCaregiver(id).then(response=>{
          setLoading(false);
          setShow(true);
        }).catch(err=>{
          return err
        })
    }

    const pageChange = (pageNum) => {
      setInitialpage(pageNum);
    };

    return (
      <>
      {show && <ModalDialog show={show} modalTitle={t('Employee.Bookings.Cancelled')}
      description={t('Admin.ManageCaregiver.Modal.Description')}
      footer={
        <div className="group_buttons mt-5">
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {setShow(false);
          cancelBookings();
            }} 
          >
            {t('Employer.Manage_union.Generate_link_modal.Close')}
          </Button>
        </div>
      }
      />}
        <div className="white_box manage_screen">
        <div className="title_box cancel-bookings ">
          <h3 className="title_h3">
            {t("Employee.Bookings.Cancelled")}
          </h3>
          <div className="search_box  d-flex ">
            <div className="search_box_in">
              <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }} >{svg["Search"]}</span>
              <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>{" "}
            
          </div>
        </div>
        {
          loading ? <Loader/> : records?.length === 0 ?<NoRecords/> : <Table className="custom_table" responsive>
          <thead>
                <tr>
                  {/* {arechieve ? null : <th width="50px"></th>} */}
  
                  <th>{t("Admin.ManageCaregiver.Table.Caregiver")}</th>
                  <th>{t("Admin.ManageCaregiver.Table.Client")}</th>
                  <th width="500px">{t("Admin.ManageCaregiver.Table.Reasoning")}</th>
                  <th>{svg["Edit"]}</th>
                </tr>
              </thead>
              <tbody>
                {
                  records?.map((list,index)=>{
                    return(
                      <React.Fragment key={index}>
                      <tr>
                        <td>
                          <b>{list?.caregiver}</b>
                        </td>
                        <td>
                          {list?.client}
                        </td>
                        <td>
                          {list?.reason?.slice(0,30)}
                          {
                            list?.showfullString && <div className='reason_block'>
                            <span>{list?.reason}</span>  
                            </div>
                          }
                          {
                            list?.reason?.length>60 && <span onClick={()=>showString(index)}  style={{cursor:"pointer"}}>...</span>
                          }
                        </td>
                        <td>
                          <span className='decline_action' onClick={()=>BlockCaregiver(list?.caregiver_id)}>{t("Admin.ManageCaregiver.Table.Invalid")}</span>
                        </td>
                      </tr>
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
          </Table>
        }

        <div className='d-flex justify-content-between'>
          <div>
          <Button
            onClick={() => navigation('/admin/caregivers')}
            className="btn-sm ms-0 ps-3 pe-3"
            variant="outline-secondary"
          >
            <strong>{t("Admin.ManageCaregiver.cargiver_list")}</strong>
          </Button>
          </div>
          <div>
        {records?.length >= 1 && (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            )  
            }
        </div>
        </div>
        
        
        </div>
        </>

    )
}

export default BlockedCaregivers