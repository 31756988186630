import { axiosInstance } from "../interceptor";

export const profiledetail = () => {
  return axiosInstance.get(`production/get-profile-personal-info`);
};

export const profileupdate = (data) => {
  return axiosInstance.post(`production/update-profile-personal-info`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const changepassword = (data) => {
  return axiosInstance.post(`production/change-password`, data);
};

export const addchild = (data) => {
  return axiosInstance.post(`kids`, data);
};

export const prodList = (search, page, sort) => {
  return axiosInstance.get(
    `get-Production?search=${search}&perpage=10&sortby=${sort}&page=${page}`
  );
};

export const getchildrens = () => {
  return axiosInstance.get(`kids`);
};

export const deletechildren = (id) => {
  return axiosInstance.delete(`kids`, { data: id });
};

export const employeeEvents = (id, date) => {
  return axiosInstance.get(
    `getEmployeeEvents?production_id=${id}&date=${date}`
  );
};

export const employeeSearchEvents = (id, search) => {
  return axiosInstance.get(
    `getEmployeeSearchEvents?production_id=${id}&search=${search}`
  );
};
export const productionOverview = (id) => {
  return axiosInstance.get(`production-overview?production_id=${id}`);
};
export const lookupdata = (option1, option2) => {
  return axiosInstance.get(
    `lookup?type%5B%5D=${option1}&type%5B%5D=${option2}`
  );
};

export const employeeBookings = (status,keyword) => {
  return axiosInstance.get(`employee-booking?status=${status}&search=${keyword}`);
};
export const requestChildcare = (id, data, city) => {
  return axiosInstance.post(
    `request-childcare?production_id=${id}&city_name=${city}`,
    data
  );
};

export const cancelBooking=(id)=>{
  return axiosInstance.delete(`cancel-booking?booking_request_id=${id}`)
}

export const modeofContact=(data)=>{
  return axiosInstance.post(`follow-up`,data);
}

export const recentCaregiver=()=>{
  return axiosInstance.get(`recent-caregiver`);
}

export const nextBookings=()=>{
  return axiosInstance.get(`employee/next-booking`);
}

export const familyCount=(id)=>{
  return axiosInstance.get(`employee/family-account-count?employee_id=${id}`);
}

export const createFamilyAccount=(data)=>{
  return axiosInstance.post(`employee/create-family-account`,data);
}

export const updateFamilyMember=(id,data)=>{
  return axiosInstance.post(`employee/update-family-account?member_id=${id}`,data);
}

export const getFamilyMemberInfo=(id)=>{
  return axiosInstance.get(`employee/family-member?member_id=${id}`);
}

export const deleteFamilyMember = (id) => {
  return axiosInstance.delete(`employee/family-member-destory?member_id=${id}`);
}

export const caregiverRatedetails = (id) => {
  return axiosInstance.get(`employee/caregiver/get-rate-details/${id}`);
}


