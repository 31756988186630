import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import frLocale from "@fullcalendar/core/locales/fr";
import { Form, Button } from "react-bootstrap";
import interactionPlugin from "@fullcalendar/interaction";

import Modal from "../shared/Modal";

import addIcon from "../assets/Images/addIcon.png";

import {
  deleteEvent,
  geteventsbyDate,
  geteventsbyId,
  listingofEvents,
} from "../Services/https/Employer/Employer";
import {
  employeeEvents,
  employeeSearchEvents,
} from "../Services/https/Employee/Employee";
import Loader from "../shared/Loader";
import UserTimezone from "../shared/UserTimezone";
import { useRef } from "react";

const CalenderListing = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const calendarRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventsofday, setEventsOfDay] = useState([]);
  const [eventsOfmonth, setEventsOfMonth] = useState([]);
  const [eventlist, setEventList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [handlesearch, sethandleSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [id, setId] = useState(null);
  const [eventid, setEventId] = useState(null);
  const [bg, setBg] = useState(0);
  const currentprodId = useSelector((state) => state?.invite?.production_id);
  const employyeprodId = useSelector((state) => state.employee.productionId);
  const { role_data, timezonevalue } = useSelector((state) => state.login);
  const localization = localStorage.getItem("i18nextLng");

  const handleclick = () => {

    showDeleteModal ? setLoader(false) : setLoader(true);
    const date = moment(selectedDate).format("YYYY-MM-DD");
    if (role_data === "2") {
      geteventsbyDate(date, currentprodId).then((response) => {
        setEventsOfDay(response?.data?.data?.eventsOfDay);
        setEventsOfMonth(response?.data?.data?.eventsOfmonth);
        setEventId(response?.data?.data?.eventsOfDay[0]?.id);
        setLoader(false);
      })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    } else {
      employeeEvents(employyeprodId, date)
        .then((response) => {
          setEventsOfDay(response?.data?.data?.eventDetails);
          setEventsOfMonth(response?.data?.data?.eventList);
          setEventId(response?.data?.data?.eventDetails[0]?.id);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    }
  };

  useEffect(() => {
    if (search && role_data === "2") {
      listingofEvents(currentprodId, search)
        .then((response) => {
          setEventList(response?.data?.data);
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    } else if (search && role_data === "3") {
      employeeSearchEvents(employyeprodId, search)
        .then((response) => {
          setEventList(response?.data?.data);
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    } else {
      handleclick();
      getEvent();
    }
  }, [handlesearch, showDeleteModal, timezonevalue,selectedDate]);

  useEffect(() => {
    if (eventid) {
      getEvent();
    } else {
      setEventList([]);
    }
  }, [eventid]);

  const getEvent = () => {
    if (eventid) {
      geteventsbyId(eventid)
        .then((response) => {
          setLoader(false);
          setEventList([response?.data?.data]);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    } else {
      setEventList([]);
    }
  };

  // useEffect(() => {
  //   handleclick();
  // }, [selectedDate]);

  const customTitleFormat = () => {
    const customDateFomat = localization === "fr"
      ? moment().format("DD-MM-YYYY") ===
        moment(selectedDate).format("DD-MM-YYYY")
        ? moment().format(`[Aujourd'hui]`)
        : moment(selectedDate).locale("fr").format("dddd")
      : moment().format("DD-MM-YYYY") ===
        moment(selectedDate).format("DD-MM-YYYY")
        ? moment().format("[Today], MMMM Do")
        : moment(selectedDate).format("dddd,MMMM Do ")
    // Custom title format logic
    return `${customDateFomat}`;
  };

  return (
    <div>
      {loader && <Loader />}
      <Modal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        modalTitle={t("Employer.Add_employee.Delete_modal.Confirmation")}
        footer={
          <div className="group_buttons mt-5">
            <Button
              variant="outline-info"
              className="btn-lg"
              onClick={() => setShowDeleteModal(false)}
            >
              {t("Employer.Add_employee.Delete_modal.Cancel")}
            </Button>
            <Button
              variant="info"
              className="btn-lg"
              onClick={() => {
                const data = {
                  id: id,
                };
                setLoader(true);
                deleteEvent(id)
                  .then((response) => {
                    toast.success(response?.data?.message);
                    setShowDeleteModal(false);
                    setLoader(false);
                    getEvent();
                    setBg(0);
                  })
                  .catch((err) => {
                    setLoader(false);
                    return err;
                  });
              }}
            >
              {t("Employer.Add_employee.Delete_modal.Confirm")}
            </Button>
          </div>
        }
      />
      <div className="white_box manage_screen">

        <div>
          <UserTimezone />
        </div>

        <div className="row">
          <div className="col-lg-4  calender_background">
            <div className="ms-3"><h3 className="schedule_title">{moment(selectedDate).locale(localization).format("MMMM , YYYY")}</h3> </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
              locale={localization == "fr" && frLocale}
              headerToolbar={{
                left: "prev",
                center: "title",
                right: "next",
              }}
              height={414}
              dateClick={(event) => setSelectedDate(moment(event.date))}
              events={eventsOfmonth}
              initialView="dayGridMonth"
              views={{
                day: moment().format("MM/DD"),
                dayGrid: { titleFormat: customTitleFormat }
              }}
              editable={false}
              selectable={true}
              datesSet={(e) => {
                var midDateMonth = new Date((e.start.getTime() + e.end.getTime()) / 2).getMonth() + 1;
                var year = new Date((e.start.getTime() + e.end.getTime()) / 2).getFullYear();
                let startDateFormat;
                if (midDateMonth < 10) {
                  startDateFormat = `0${midDateMonth}`;
                }
                else {
                  startDateFormat = `${midDateMonth}`;
                }
                var start = year + "-" + moment(startDateFormat, "MM").startOf("month").format("MM-DD");
                moment(startDateFormat).format("M") == parseInt(moment().format("M")) ? setSelectedDate(new Date()) : setSelectedDate(start);
              }}
            />
            <div className="ms-4 mt-5">
              {/* <h5 className="listing_day">
                {localization === "fr"
                  ? moment().format("DD-MM-YYYY") ===
                    moment(selectedDate).format("DD-MM-YYYY")
                    ? moment().format(`[Aujourd'hui]`)
                    : moment(selectedDate).locale("fr").format("dddd")
                  : moment().format("DD-MM-YYYY") ===
                    moment(selectedDate).format("DD-MM-YYYY")
                    ? moment().format("[Today]")
                    : moment(selectedDate).format("dddd")}
                ,{" "}
                {localization === "fr"
                  ? moment(selectedDate, "YYYY-MM-DD")
                    .locale("fr")
                    .format("MMMM")
                  : moment(selectedDate).format("MMMM")}{" "}
                {moment(selectedDate).format("Do")}
              </h5> */}
            </div>
            {(role_data === "2" || role_data === "3") && (
              <div className="">
                <div style={{ padding: "0px 6px" }}>
                  {eventsofday?.map((event, index) => {
                    return (
                      <div style={{ padding: "0px 12px", cursor: "pointer" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "12px",
                            alignItems: "center",
                          }}
                          className={bg === index ? "change_background" : ""}
                          onClick={(e) => {
                            setBg(index);
                            setEventId(event?.id);
                            if (eventid !== event?.id) {
                              setLoader(true);
                            }
                          }}
                          key={index}
                        >
                          <div className="list_calendar1 ">
                            {event?.event_title}
                          </div>
                          <div className="list_calendar_timings  ">
                            {moment(event?.event_start_date_time, ["YYYY-MM-DD HH:mm:ss"]).format(
                              "hh:mm A"
                            )}
                            -
                            {moment(event?.event_end_date_time, ["YYYY-MM-DD HH:mm:ss"]).format(
                              "hh:mm A"
                            )} ({timezonevalue})
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-8 schedule">
            <div className="schedule_info">
              <div>
                {role_data === "3" ? (
                  <h3 className=" schedule_title">
                    {t("Admin.UpdateEmployeer.Details.Title")}
                  </h3>
                ) : (
                  <h3 className=" schedule_title">
                    {t("Employer.Calender_listing.Crew_schedule")}
                  </h3>
                )}
              </div>
              <div>
                <div className="search_box d-flex ms-auto">
                  <div className="search_box_in">
                    <svg
                      className="icon"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        sethandleSearch((prev) => !prev);
                        setLoader(true);
                      }}
                    >
                      <path
                        d="M14.7507 16.3704C12.9139 17.7979 10.6024 18.4714 8.28671 18.2537C5.97106 18.0359 3.82543 16.9433 2.28669 15.1983C0.747957 13.4533 -0.068179 11.1871 0.00446449 8.86121C0.077108 6.5353 1.03307 4.32454 2.6777 2.67907C4.32232 1.0336 6.53196 0.0771475 8.85668 0.00446678C11.1814 -0.0682139 13.4464 0.748339 15.1905 2.28786C16.9346 3.82739 18.0267 5.97412 18.2443 8.29096C18.462 10.6078 17.7888 12.9205 16.362 14.7582L21.663 20.0421C22.1117 20.4894 22.1124 21.216 21.6645 21.6641C21.2166 22.1123 20.49 22.1119 20.0425 21.6633L14.7621 16.3704H14.7507ZM9.15107 16.0045C10.0515 16.0045 10.9431 15.827 11.775 15.4823C12.6069 15.1375 13.3628 14.6322 13.9995 13.9952C14.6362 13.3582 15.1412 12.6019 15.4858 11.7696C15.8304 10.9373 16.0077 10.0452 16.0077 9.14433C16.0077 8.24343 15.8304 7.35137 15.4858 6.51905C15.1412 5.68674 14.6362 4.93048 13.9995 4.29345C13.3628 3.65643 12.6069 3.15111 11.775 2.80636C10.9431 2.4616 10.0515 2.28416 9.15107 2.28416C7.33258 2.28416 5.58856 3.00692 4.30268 4.29345C3.01681 5.57998 2.29441 7.32489 2.29441 9.14433C2.29441 10.9638 3.01681 12.7087 4.30268 13.9952C5.58856 15.2817 7.33258 16.0045 9.15107 16.0045Z"
                        fill="#1D1D56"
                      />
                    </svg>
                    <Form.Control
                      type="text"
                      placeholder={t("Employer.Calender_listing.Search")}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSearch(e.target.value);
                          sethandleSearch((prev) => !prev);
                          setLoader(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {eventlist?.map((list, index) => {
              return (
                <div className="listing_right mt-4  " key={index}>
                  <div className="scheduling_details">
                    <h5 className="date_title mt-1">
                      {localization == "fr"
                        ? moment(list?.event_date, "YYYY-MM-DD")
                          .locale("fr")
                          .format("dddd MMMM")
                        : moment(list?.event_date)
                          .locale("en")
                          .format("dddd MMMM")}{" "}
                      {moment(list?.event_date).format("Do")},{" "}
                      {moment(list?.event_date).format("YYYY")}
                    </h5>
                    <div className="event_Details">
                      {moment(list?.event_start_date_time, ["YYYY-MM-DD HH:mm:ss"]).format("hh:mm A")} -{" "}
                      {moment(list?.event_end_date_time, ["YYYY-MM-DD HH:mm:ss"]).format("hh:mm A")} ({timezonevalue})
                    </div>
                    <div className="event_Details">{list?.event_title}</div>
                    <div className="event_Details">
                      {list?.event_participants?.length}{" "}
                      {t("Employer.Calender_listing.Guests")}
                    </div>
                  </div>

                  {role_data === "2" && (
                    <div className="listing_btn">
                      <button
                        type="button"
                        className="btn btn-info listing_edit_button  me-3"
                        onClick={() =>
                          navigation("/employer/calendar/crew_schedule/edit", {
                            state: { id: list?.id },
                          })
                        }
                      >
                        {t("Employer.Calender_listing.Edit")}
                      </button>
                      <button
                        type="button"
                        className="btn  btn-outline-info listing_delete_button me-2"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setId(list?.id);
                        }}
                      >
                        {t("Employer.Calender_listing.Delete")}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {role_data === "2" && (
          <div className="listing_icon ">
            <img
              src={addIcon}
              alt="add icon"
              onClick={() => navigation("/employer/calendar/crew_schedule")}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalenderListing;
