import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import Select from "react-select";
import Loader from "../../shared/Loader";

import { addjournal, getBalance, refundProductionlist } from "../../Services/https/comman/common";
import { productionUnionList } from "../../Services/https/Employer/Employer";


const Refunds = () => {
  const { t } = useTranslation();
  const [showUnion, setShowUnion] = useState(false);
  const [type, setType] = useState("");
  const [productionId, setProductionId] = useState();
  const [unionId, setUnionId] = useState("");
  const [productions, setProductions] = useState([]);
  const [unions, setUnions] = useState([])
  const [loader, setLoader] = useState(true);
  const navigation = useNavigate();
  const [balance, setBalance] = useState("")
  const [checkboxValid, setCheckboxValid] = useState(false);



  // Prevent scrolling on number input

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();

    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // Allow only numbers and decimal on all browsers

  const keyPressAlphaNumeric = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 190 || (event.shiftKey === true)) {
      event.preventDefault();
      return false;
    }

    return true;
  };

  //Get Current Production



  // All Productions of an Employer

  useEffect(() => {
    setLoader(true)
    refundProductionlist()
      .then((response) => {
        setProductions(response?.data?.data?.map((item) => {
          return {
            label: item?.production_name,
            value: item?.production_id
          }
        }));
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        return error;
      });
  }, []);

  useEffect(() => {
    if (productionId && showUnion) {
      productionUnionList(productionId).then((response) => {
        setUnions(response?.data?.data?.paginate?.data.map((item) => {
          return {
            label: item?.first_name + " " + item?.last_name,
            value: item?.union_id
          }
        }));
        setLoader(false)
      })
        .catch((error) => {
          setLoader(false)
          return error;
        });
      if (unionId) {
        getBalances(type, productionId, unionId)

      }
    }
    else {
      getBalances(type, productionId, unionId)
    }

  }, [productionId, unionId])

  const getBalances = (refundType, production_id, id) => {
    if (productionId && type) {
      getBalance(refundType, production_id, id).then(response => {
        setBalance(response?.data?.data?.balance_amount)
      }).catch(err => {
        return err
      })
    }
  }




  //React-select style

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: "50px",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
    }),
    indicatorSeparator: () => { },
  };

  //Amount Schema

  const Schema = Yup.object().shape({
    type: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    amount: Yup.string().matches(
      "^[+]?[0-9]{1,9}(?:.[0-9]{1,9})?$",
      `${t("Employer.Funding.Add_funds.Valid")}`
    ).required(`${t("Validation.InviteModal.Required")}`),
    production: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    union: Yup.string().when("union", (val, schema) => {
      if (val=="undefined") { 
     //if address exist then apply min max else not
        return Yup.string().required(`${t("Validation.InviteModal.Required")}`);
      } else {
        if(!showUnion){
          return Yup.string().notRequired();
        }
        else{
          return  Yup.string().required(`${t("Validation.InviteModal.Required")}`);
        }
       
       
      }
    })
  },
   [
    ["union", "union"]
  ]
  );

  const transaction = [
    {
      label: t('Employee.Productions.Select_production'),
      value: ""
    },
    {
    label: t('Admin.Journals.RefundProduction'),
    value: "production"
  },
  {
    label: t('Admin.Journals.RefundUnion'),
    value: "union"
  },
  {
    label: t('Admin.Journals.Fees'),
    value: "platform"
  }]

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="row addFunds_main">
          <div className="col addFunds_header">
            <div>
              <h2 className="h2_addFunds ">
                {t('Admin.Journals.Title')}
              </h2>
            </div>

            <Formik
              initialValues={{ amount: "", type: "", production:"",union:"" }}
              validationSchema={Schema}
              onSubmit={(values) => {
                const data = {
                  transaction_type: type,
                  production_id: values?.production,
                  union_id: values?.union,
                  balance: balance,
                  refund: values?.amount
                }
                setLoader(true)
                addjournal(data).then(response=>{
                  setLoader(false);
                  setBalance("")
                  toast.success(response?.data?.message)
                }).catch(err=>{
                  setLoader(false);
                  return err
                })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                resetForm
                /* and other goodies */
              }) => (
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <div className="col-xl-12">
                          <div className="form-group">
                            <Select
                              name="type"
                              styles={CustomStyle}
                              options={transaction}
                              placeholder={
                                <div style={{ textAlign: "left" }}>
                                  {t('Admin.Journals.Type')}
                                </div>
                              }
                              onChange={(item) => {
                                setType(item?.value); setFieldValue("type",item.value) ;if (item?.value === "union") {
                                  setShowUnion(true)
                                }
                                else {
                                  setShowUnion(false);
                                  setUnions([]);
                                }
                              }}
                            />

                          </div>
                        </div>
                        {errors.type && (
                          <div className="error">
                            {touched.type && <>{errors.type}</>}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group">
                            <Select
                              styles={CustomStyle}
                              options={productions}
                              placeholder={
                                <div style={{ textAlign: "left" }}>
                                  {t('Employee.Productions.Select_production')}
                                </div>
                              }
                              onChange={(item) => { setProductionId(item?.value);setFieldValue("production",item.value); values.union="" }}
                              name="production"
                            />
                          </div>
                          {errors.production && (
                          <div className="error">
                            {touched.production && <>{errors.production}</>}
                          </div>
                        )}
                        </div>
                        <div>
                          <h6 style={{ padding: "10px 0px", float: "right" }}>{t('Caregiver.Dashboard.Balance')}{`: $${balance ? balance : "0.00"}`}</h6>
                        </div>
                      </div>
                      {
                        showUnion && <div className="row mb-4">
                          <div className="col-xl-12">
                            <div className="form-group">
                              <Select
                                styles={CustomStyle}
                                options={unions}
                                placeholder={
                                  <div style={{ textAlign: "left" }}>
                                    {t('Admin.Journals.Union')}
                                  </div>
                                }
                                onChange={(item) => {  setUnionId(item?.value);setFieldValue("union",item.value) }}
                                name="union"
                              />
                            </div>
                          </div>
                          {errors.union && (
                          <div className="error">
                            {touched.union && <>{errors.union}</>}
                          </div>
                        )}
                        </div>
                      }
                      <div className="row mb-4">
                        <div className="col-xl-12">
                          <div className="form-group">
                            <input
                              type="number"
                              name="amount"
                              value={values.amount}
                              onKeyDown={(e) => keyPressAlphaNumeric(e)}
                              placeholder={t(
                                "Employer.Funding.Add_funds.Value"
                              )}
                              onChange={handleChange}
                              onWheel={numberInputOnWheelPreventChange}
                              onBlur={handleBlur}
                              step="any"
                              className="field_view form-control"
                            />
                            {values.amount == "0.0" && (
                              <div className="error">
                                {" "}
                                {t("Employer.Funding.Add_funds.Valid")}
                              </div>
                            )}
                            {errors.amount && (
                              <div className="error">
                                {touched.amount && <>{errors.amount}</>}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-xl-12">
                          <div className="d-flex align-items-end">
                            <input
                              className="addFunds_check form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              // disabled={
                              //  (!showUnion &&  values.amount && productionId && type ) || (showUnion &&  values.amount && productionId && type && unionId) ?  false : true
                              // }
                              onChange={(e) =>
                                setCheckboxValid(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label ms-4 mt-1 addFunds_check_label"
                              for="flexCheckDefault"
                            >
                              {t(
                                "Employer.Funding.Add_funds.Confirm_contribution"
                              )}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="text-center button-group">
                        <div>
                          <Button
                            type="submit"
                            disabled={checkboxValid ? false : true}
                            className="next ms-1 mt-4 funding_add"
                          >
                            {" "}
                            {t('Employee.Profile.Save')}
                          </Button>
                        </div>
                        <div>
                          <Button
                            type="button"
                            className=" ms-1 mt-4"
                            onClick={()=>{resetForm();setBalance("")}}
                          >
                            {" "}
                            {t('Employee.Bookings.Cancel')}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default Refunds;
