import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  AdminCaregivers,
  AdminCaregiverUpdate,
} from "../../Services/https/Admin/Admin";
import { getServiceArea } from "../../Services/https/Caregiver/Caregivers";
import Loader from "../../shared/Loader";
import Select from "react-select";

const Detail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [personalInfo, setPersonalInfo] = useState(null);
  const [userPersonalInfo, setUserPersonalInfo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [update, setUpdate] = useState(false);
  const [citylist, setCityList] = useState([]);
  const [show, setShow] = useState(true);
  const [showMenu, setShowMenu] = React.useState(false);
  const [userCity, setUserCity] = useState([]);
  const [empdetail, setEmpDetail] = useState([]);
  const [ids, setIds] = useState([]);
  const [nullValue, setNullValue] = useState([]);

  // Toaster Messages
  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  //Get personal details of caregiver

  useEffect(() => {
    setLoader(true);
    AdminCaregivers(id)
      .then((response) => {
        setPersonalInfo(response?.data?.data[0]?.user_details);
        setUserPersonalInfo(response?.data?.data[0]);
        setUserCity(
          response?.data?.data[0]?.userservice_area.map((item) => {
            return {
              value: item?.city_id,
              label: item?.city_name,
            };
          })
        );

        setLoader(false);
      })
      .catch((error) => {
        return error
      });
    // setLoader(true);
    // CaregiverPersonalDetails()
    //   .then((response) => {
    //     setLoader(false);
    //     setPersonalInfo(response?.data?.data);
    //     setUserCity(
    //       response?.data?.data?.user_service_area.map((item) => {
    //         return {
    //           value: item?.city_id,
    //           label: item?.city_name,
    //         };
    //       })
    //     );
    //   })
    //   .catch((error) => {});

    getServiceArea().then((response) => {
      setCityList(response?.data?.data);
    });
  }, [update]);
  useEffect(() => {
    if (userCity?.length >= 0) {
      const userIds = userCity.map((item) => item?.value);
      setIds(userIds);
      const records = cityOptions?.filter((item) =>
        userIds.includes(item?.value)
      );
      setEmpDetail(records);
    }
  }, [userCity]);

  const cityOptions = citylist.map((item, index) => {
    return {
      label: item?.city_name,
      value: item?.id,
    };
  });

  // Personal Details Schema

  const personalDetailsSchema = Yup.object().shape({
    first_name: Yup.string().required(
      `${t("Validation.InviteModal.Required")}`
    ),
    last_name: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    email: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .email(`${t("Validation.InviteModal.Invalid_email")}`)
      .nullable(),
    phone: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    postal_code: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    province: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    address: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    userservice_area: Yup.string().required(
      `${t("Validation.InviteModal.Required")}`
    ),
  });

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }
  const handleInputChange = (typedOption) => {
    setShowMenu(typedOption.length >= 3 ? true : false);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{
              first_name: personalInfo?.first_name,
              last_name: personalInfo?.last_name,
              email: userPersonalInfo?.email,
              phone: userPersonalInfo?.phone,
              address: personalInfo?.address,
              postal_code: personalInfo?.postal_code,
              province: personalInfo?.province,
              userservice_area:userCity
              .filter((e) => e.value != undefined)
              .map((e) => e.value)
              .join(",") ,
            }}
            enableReinitialize={true}
            validationSchema={personalDetailsSchema}
            onSubmit={(values) => {
              const cityId = userCity
                .filter((e) => e.value != undefined)
                .map((e) => e.value)
                .join(",");
              setLoader(true);

              const data = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone: values.phone.toString().replace(/\D+/g, ""),
                address: values.address,
                postal_code: values.postal_code,
                province: values.province,
                userservice_area: cityId,
              };

              // Update Caregiver

              AdminCaregiverUpdate(id, data)
                .then((response) => {
                  setUpdate(true);
                  if (response?.status === 200) {
                    setLoader(false);
                    toast.success(response?.data?.message);
                  }
                  if (response?.response?.status === 422) {
                    setErrorMessage(response?.response?.data?.errors);
                    setLoader(false);
                  }
                  if (response?.response?.status === 400) {
                    setErrorMessage(response?.response?.data?.message);
                    setLoader(false);
                  }
                })
                .catch((err) => {
                  setLoader(false);
                  if (err?.response?.status === 422) {
                    setErrorMessage(err?.response?.data?.errors);
                    setLoader(false);
                  }
                  if (err?.response?.status === 400) {
                    setErrorMessage(err?.response?.data?.message);
                    setLoader(false);
                  }
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form className="mt-3" onSubmit={handleSubmit}>
                <Row className="mb-3 pt-3">
                  <Form.Group
                    className="required me-2"
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.FirstName")}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={personalInfo?.first_name}
                      className="update_employeer_input"
                    />
                    {errors.first_name && (
                      <div className="error">
                        {touched.first_name && <>{errors.first_name}</>}
                      </div>
                    )}
                    {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom02"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.LastName")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="last_name"
                      defaultValue={personalInfo?.last_name}
                      className="update_employeer_input"
                    />
                    {errors.last_name && (
                      <div className="error">
                        {touched.last_name && <>{errors.last_name}</>}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3 pt-3">
                  <Form.Group
                    className="required me-2"
                    as={Col}
                    md="5"
                    controlId="validationCustom03"
                  >
                    <Form.Label className="control-label control_label_text">
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="mail@website.com"
                      className="update_employeer_input"
                      defaultValue={userPersonalInfo?.email}
                    />
                    {errors.email && (
                      <div className="error">
                        {touched.email && <>{errors.email}</>}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom04"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.PhoneNumber")}
                    </Form.Label>
                    <PhoneInput
                      defaultValue={userPersonalInfo?.phone}
                      onChange={handleChange}
                      placeholder="773-XXX-XXXX"
                      onBlur={handleBlur}
                      name="phone"
                      className="form-control update_employeer_input"
                    />
                    {errors.phone && (
                      <div className="error">
                        {touched.phone && <>{errors.phone}</>}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3 pt-3">
                  <Form.Group
                    className="required me-2"
                    as={Col}
                    md="4"
                    controlId="validationCustom05"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.Address")}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Address"
                      defaultValue={personalInfo?.address}
                      className="update_employeer_input"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.address && (
                      <div className="error">
                        {touched.address && <>{errors.address}</>}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="required me-2"
                    as={Col}
                    md="4"
                    controlId="validationCustom05"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.City")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City Name"
                      name="province"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={personalInfo?.province}
                      className="update_employeer_input"
                    />
                    {errors.province && (
                      <div className="error">
                        {touched.province && <>{errors.province}</>}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="required code_cont"
                    as={Col}
                    md="2"
                    controlId="validationCustom05"
                  >
                    <Form.Label className="control-label control_label_text ">
                      {t("Admin.UpdateCaregiver.Detail.Postal")}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="142008"
                      defaultValue={personalInfo?.postal_code}
                      className="update_employeer_input"
                      name="postal_code"
                      onChange={handleChange}
                    />
                    {errors.postal_code && (
                      <div className="error">
                        {touched.postal_code && <>{errors.postal_code}</>}
                      </div>
                    )}
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    className="required"
                    as={Col}
                    md="9"
                    controlId="validationCustom02"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Personal.City")}
                    </Form.Label>
                    <Select
                      isMulti
                      name="userservice_area"
                      isClearable={true}
                      options={cityOptions}
                      className="basic-multi-select "
                      value={userCity}
                      classNamePrefix="select"
                      placeholder={"Search here..."}
                      onInputChange={handleInputChange}
                      menuIsOpen={showMenu}
                      onChange={(option, e) => {
                        setUserCity(option);
                        setNullValue(option);
                        setFieldValue(
                          "userservice_area",
                          option
                            .filter((e) => e.value != undefined)
                            .map((e) => e.value)
                            .join(",")
                        );
                      }}
                    />
                    {errors.userservice_area && (
                      <div className="error">
                        {touched.userservice_area && (
                          <>{errors.userservice_area}</>
                        )}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3 pt-3">
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom05"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Admin.UpdateCaregiver.Detail.Primary")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Language"
                      defaultValue="English"
                      className="update_employeer_input"
                    />
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </Form.Group>
                </Row>
                <Button
                  type="submit"
                  variant="info"
                  className="btn-lg text-center w_100"
                  style={{ float: "right" }}
                >
                  {t("Admin.UpdateCaregiver.Button")}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default Detail;
