import React,{ useEffect ,useState} from "react";

import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { changepassword } from "../Services/https/Employee/Employee";

import Loader from "./Loader";


const ChangePassword = ({ showPopup, close }) => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [showPassword, setShowPassword] = useState({
        previous: false,
        new: false,
        confirmpassword: false
    })

    const passwordSchema = Yup.object().shape({
        current_password: Yup.string().required(t('ResetSchema.Required')),
        new_password: Yup.string().required(t('ResetSchema.Required'))
            .matches(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/, (t('ResetSchema.Weak'))
            ),
        confirm_password: Yup.string().oneOf(
            [Yup.ref("new_password"), null],
            (t('ResetSchema.Confirm'))
        ).required(t('ResetSchema.Required')),
    });

    const formik = useFormik({
        initialValues: { current_password: "", new_password: "", confirm_password: "" },
        validationSchema: passwordSchema,
        onSubmit: (values) => {
            const data = {
                current_password: values.current_password,
                new_password: values.new_password,
                confirm_password: values.confirm_password
            }
            setLoader(true)
            changepassword(data).then(response => {
                toast.success(response?.data?.message);
                setLoader(false)
                close(!showPopup)
            }).catch(err => {
                setLoader(false)
                setErrorMessage(err?.response?.data?.errors);
                return err
            })
        },
    })

    useEffect(() => {
        Object.keys(errorMessage).map(key => {
            toast.error(errorMessage[key])
        })
    }, [errorMessage])

    return (
        <>
            {loader && <Loader />}
            <form onSubmit={formik.handleSubmit} style={{ paddingTop: "12px" }}>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 invitelink">
                        <div>
                            {t('Employee.Profile.Password')}
                        </div>
                        <div className="input_icon generate_invite_input mb-3">
                            <input
                                type={!showPassword.previous ? "password" : "text"}
                                value={formik.values.current_password}
                                class="form-control"
                                placeholder="*********"
                                name="current_password"
                                id="current_password"
                                aria-describedby="basic-addon1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                            />
                            <i
                                onClick={() => setShowPassword({ ...showPassword, previous: !showPassword.previous })}
                                className={
                                    !showPassword.previous ?
                                        "view_password fa-solid fa-eye-slash " : " view_password fa-solid fa-eye"

                                }
                            ></i>
                        </div>
                        {formik.errors.current_password && (
                            <div className="error">
                                {formik.touched.current_password && <>{formik.errors.current_password}</>}
                            </div>
                        )}
                        <div>
                            <div>
                                <div>{t('Employee.Profile.NewPassword')}</div>
                                <div className="input_icon generate_invite_input mb-2">
                                    <input
                                        type={!showPassword.new ? "password" : "text"}
                                        value={formik.values.new_password}
                                        class="form-control"
                                        placeholder="*********"
                                        name="new_password"
                                        id="new_password"
                                        aria-describedby="basic-addon1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                    />
                                    <i
                                        onClick={() => setShowPassword({ ...showPassword, new: !showPassword.new })}
                                        className={
                                            !showPassword.new ?
                                                "view_password fa-solid fa-eye-slash " : " view_password fa-solid fa-eye"

                                        }
                                    ></i>
                                </div>
                                <span style={{ fontSize: "13px" }}>
                                    {t('SignUp.Validations.Password')}
                                </span>
                                <div>
                                    {formik.errors.new_password && (
                                        <div className="error">
                                            {formik.touched.new_password && <>{formik.errors.new_password}</>}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>

                        <div>
                            <div>{t('Employee.Profile.ConfirmPassword')}</div>

                            <div className="input_icon generate_invite_input mb-3">

                                <input
                                    type={!showPassword.confirmpassword ? "password" : "text"}
                                    value={formik.values.confirm_password}
                                    class="form-control"
                                    placeholder="*********"
                                    name="confirm_password"
                                    id="confirm_password"
                                    aria-describedby="basic-addon1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="off"
                                />
                                <i
                                    onClick={() => setShowPassword({ ...showPassword, confirmpassword: !showPassword.confirmpassword })}
                                    className={
                                        !showPassword.confirmpassword ?
                                            "view_password fa-solid fa-eye-slash " : " view_password fa-solid fa-eye"

                                    }
                                ></i>
                            </div>
                            {formik.errors.confirm_password && (
                                <div className="error">
                                    {formik.touched.confirm_password && <>{formik.errors.confirm_password}</>}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="group_buttons mt-5">
                    <Button
                        type="button"
                        variant="outline-info"
                        className="btn-lg"
                        onClick={() => {
                            close(!showPopup)
                            formik.resetForm(formik?.values);
                        }}
                    >
                        {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                    </Button>
                    <Button
                        variant="info"
                        className="btn-lg"
                        type="submit"
                    >
                        {t('Employee.Profile.Submit')}
                    </Button>
                </div>
            </form>

        </>
    )
}
export default ChangePassword