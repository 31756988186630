import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import {
  AdminCaregivers,
  AdminCaregiverUpdate,
} from "../../Services/https/Admin/Admin";
import Loader from "../../shared/Loader";


const CaregiverProfileEmployee = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [personalInfo, setPersonalInfo] = useState(null);
  const [userPersonalInfo, setUserPersonalInfo] = useState(null);
  const [loader, setLoader] = useState(false);

  //Get personal details of caregiver

  useEffect(() => {
    setLoader(true);
    AdminCaregivers(id)
      .then((response) => {
        setPersonalInfo(response?.data?.data[0]?.user_details);
        setUserPersonalInfo(response?.data?.data[0]);

        setLoader(false);
      })
      .catch((error) => {
        return error
      });
  }, []);

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <Form className="mt-3">
              <Row className="mb-3 pt-3">
                <Form.Group
                  className="required me-2"
                  as={Col}
                  md="4"
                  controlId="validationCustom01"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateCaregiver.Detail.FirstName")}
                  </Form.Label>
                  <Form.Control
                    value={personalInfo?.first_name}
                    placeholder="First name"
                    name="first_name"
                    disabled
                    className="update_employeer_input"
                  />
                </Form.Group>
                <Form.Group
                  className="required"
                  as={Col}
                  md="4"
                  controlId="validationCustom02"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateCaregiver.Detail.LastName")}
                  </Form.Label>
                  <Form.Control
                    value={personalInfo?.last_name}
                    placeholder="Last name"
                    name="last_name"
                    disabled
                    className="update_employeer_input"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 pt-3">
                <Form.Group
                  className="required me-2"
                  as={Col}
                  md="5"
                  controlId="validationCustom03"
                >
                  <Form.Label className="control-label control_label_text">
                    Email
                  </Form.Label>
                  <Form.Control
                    value={userPersonalInfo?.email}
                    name="email"
                    placeholder="mail@website.com"
                    className="update_employeer_input"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="required"
                  as={Col}
                  md="4"
                  controlId="validationCustom04"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateCaregiver.Detail.PhoneNumber")}
                  </Form.Label>
                  <PhoneInput
                    placeholder="773-XXX-XXXX"
                    name="phone"
                    value={userPersonalInfo?.phone}
                    className="form-control update_employeer_input"
                    disabled
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 pt-3">
                <Form.Group
                  className="required me-2"
                  as={Col}
                  md="4"
                  controlId="validationCustom05"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateCaregiver.Detail.Address")}
                  </Form.Label>
                  <Form.Control
                    placeholder="Address"
                    value={personalInfo?.address === "null" ?  "" : personalInfo?.address  }
                    className="update_employeer_input"
                    name="address"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="required me-2"
                  as={Col}
                  md="4"
                  controlId="validationCustom05"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Employee.Profile.Province")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City Name"
                    name="province"
                    value={personalInfo?.province}
                    className="update_employeer_input"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="required code_cont"
                  as={Col}
                  md="2"
                  controlId="validationCustom05"
                >
                  <Form.Label className="control-label control_label_text ">
                    {t("Admin.UpdateCaregiver.Detail.Postal")}
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="142008"
                    value={personalInfo?.postal_code}
                    className="update_employeer_input"
                    name="postal_code"
                    disabled
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 pt-3">
                <Form.Group
                  className="required"
                  as={Col}
                  md="4"
                  controlId="validationCustom05"
                >
                  <Form.Label className="control-label control_label_text">
                    Service Area
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={userPersonalInfo?.userservice_area.map((item)=>{
                      return item?.city_name
                    })}
                    className="update_employeer_input"
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  className="required"
                  as={Col}
                  md="4"
                  controlId="validationCustom05"
                >
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateCaregiver.Detail.Primary")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Language"
                    defaultValue="English"
                    className="update_employeer_input"
                    disabled
                  />
                </Form.Group>
              </Row>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default CaregiverProfileEmployee;
