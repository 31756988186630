import React, { useEffect, useState } from 'react'
import Loader from '../shared/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from '../Services/https/Auth/Auth';
import { loginState } from "../Reducers/Login/loginSlice";
import { UnionConfirmProduction, switchprod } from '../Services/https/Employer/Employer';
import { useLocation } from 'react-router-dom';


const Redirect = () => {
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const state = useSelector((state) => state.invite);


    const prod_guid = new URLSearchParams(location.search).get("production_puid");
    const union_name = new URLSearchParams(location.search).get("union_name");
    const link_guid = new URLSearchParams(location.search).get("link_guid");
    const opt_out = new URLSearchParams(location.search).get("opt_out");

    useEffect(() => {
        getUserRole().then((userRoleResponse) => {
            if (userRoleResponse.status === 200) {
                setLoader(false)
                const { role_text, name, role_data, time_zone, time_zone_code, user_id } = userRoleResponse?.data?.data;
                if (role_data === "2") {
                    const data = {
                        production_id: state?.prod_id,
                    };
                    switchprod(data)
                        .then((response) => {
                            // dispatch(loginState(location.state));
                            setLoader(false);
                        })
                        .catch((err) => {
                            return err;
                        });
                    dispatch(
                        loginState({
                            loggedIn: true,
                            role: role_text,
                            name,
                            role_data,
                            timezone: time_zone,
                            timezonevalue: time_zone_code,
                            userid: user_id
                        })
                    )
                }
                else {
                    setLoader(true);
                    const uniondata = {
                        production_puid: prod_guid,
                        link_guid: link_guid,
                        opt_out: opt_out
                    };
                    setLoader(true);
                    UnionConfirmProduction(uniondata, union_name)
                        .then((response) => {
                            setLoader(false);
                            if (response?.status === 200) {
                                const data = {
                                    production_id: prod_guid,
                                };
                                switchprod(data)
                                    .then((response) => {
                                        console.log(response)
                                        // dispatch(loginState(location.state));
                                        dispatch(loginState({
                                            loggedIn: true,
                                            role: role_text,
                                            name,
                                            role_data,
                                            timezone: time_zone,
                                            timezonevalue: time_zone_code,
                                            userid: user_id
                                        }))
                                    })
                                    .catch((err) => {
                                        return err;

                                    });
                            }
                        })
                        .catch((error) => {
                            return error
                        });

                }
            }


        })
    }, [])

    return (
        <>
            {loader && <Loader />}
        </>
    )
}

export default Redirect