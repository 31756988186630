import React,{useEffect} from "react";
import {Formik} from "formik"
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { getEmployerCompanyInfo,updateEmployerCompanyInfo } from "../Services/https/Employer/Employer";
import { useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Loader from "../shared/Loader";
import InputMask from "react-input-mask";

const CompanyInfo = () => {
  const { t } = useTranslation();
  const state = useSelector((state) => state?.invite?.production_id);
  const [info,setInfo]=useState({})
  const [errorMessage, setErrorMessage] = useState({});
  const [loader,setLoader]=useState(false);
  

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="office_phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }
  function PhoneInput2(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="coordinator_phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }

  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);
  
  
useEffect(()=>{
  getEmployerCompanyInfo(state).then((response)=>{setInfo(response?.data?.data);}
  )
},[loader])

const numberInputOnWheelPreventChange = (e) => {
  e.target.blur();

  e.stopPropagation();

  setTimeout(() => {
    e.target.focus();
  }, 0);
};

  return (

    <>
    {loader ? <Loader/>:
    
      <Formik
        initialValues={{
          coordinator: info?.coordinator,
          coordinator_phone: info?.coordinator_phone,
          production_name: info?.production_name,
          office_email: info?.office_email,
          office_phone: info?.office_phone,
          office_address: info?.office_address,
          facebook_url: info?.facebook_url,
          biography: info?.biography,
          low_amount:info?.low_amount,
        }}
        enableReinitialize={true}
        // validationSchema={companyInfoSchema}
        onSubmit={(values) => {
             const data ={
              coordinator: values?.coordinator,
              coordinator_phone: values?.coordinator_phone?.toString()?.replace(/\D+/g, ""),
              production_name: values?.production_name,
              office_email: values?.office_email,
              office_phone: values?.office_phone?.toString()?.replace(/\D+/g, ""),
              office_address: values?.office_address,
              facebook_url: values?.facebook_url,
              biography: values?.biography,
              low_amount:values?.low_amount,
             }
          setLoader(true)  
           updateEmployerCompanyInfo(state,data).then((response)=>{
                 if(response?.status=== 200){
                  toast.success(response?.data?.message)
                  setLoader(false)
                 }
           }).catch((error)=>{
            if(error?.response?.status===400){
              toast.error(error?.response?.data?.message);
              setLoader(false)
            }
            else{
              setLoader(false);setErrorMessage(error?.response?.data?.errors)
            }
           })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
          <div>
      <div className="profile_main" style={{flexWrap:"wrap"}}>
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <label className="mb-3 mt-2 setting_label">
                    {t(
                      "Employer.Employer_profile.Company_info.Childcare_coordinator"
                    )}
                  </label>
                </div>
                <input
                  type="text"
                  name="coordinator"
                  placeholder="Marie Johnson"
                  className="crew_schedule_fields form-control mb-3 placeholder_style"
                  aria-describedby="emailHelp"
                  onChange={handleChange}
                  value={values?.coordinator}
                />
                 {/* {errors.coordinator && (
                          <div className="error">
                            {touched.coordinator && <>{errors.coordinator}</>}
                          </div>
                        )} */}
              </div>
              <div className="col-lg-6">
                <div>
                  <label className="mb-3 mt-2 setting_label">
                    {t(
                      "Employer.Employer_profile.Company_info.Coordinator_phone"
                    )}
                  </label>
                </div>
                {/* <input
                  type="text"
                  name="coordinator_phone"
                  
                  
                  aria-describedby="emailHelp"
                  onChange={handleChange}
                 
                /> */}
                         <PhoneInput2
                     value={values?.coordinator_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="coordinator_phone"
                    placeholder="+1 778 293 2813"
                    className="crew_schedule_fields form-control mb-3 placeholder_style"
                  />
                   {/* {errors.coordinator_phone && (
                          <div className="error">
                            {touched.coordinator_phone && <>{errors.coordinator_phone}</>}
                          </div>
                        )} */}
              </div>
            </div>
            
          </div>
          <div className="col-lg-4">
          <div>
                <label className="mb-3 mt-2 setting_label">
                {t('Employer.Employer_profile.Company_info.Minimum')}
                </label>
              </div>
              <input
                type="number"
                step="any"
                name="low_amount"
                placeholder={t('Employer.Employer_profile.Company_info.Minimum')}
                className="crew_schedule_fields form-control mb-3 placeholder_style"
                onChange={handleChange}
                aria-describedby="emailHelp"
                value={values?.low_amount}
                onWheel={numberInputOnWheelPreventChange}
              />
            
          </div>

          

         
        </div>


        <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                  <div>
                  <label className="mb-3 mt-2 setting_label">
                    {t("Employer.Employer_profile.Company_info.Production")}
                  </label>
                </div>
                <input
                  type="text"
                  name="production_name"
                  placeholder="Production #321"
                  className="crew_schedule_fields form-control mb-3 placeholder_style"
                  aria-describedby="emailHelp"
                  onChange={handleChange}
                  value={values?.production_name}
                />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
            <div className="col-lg-4">
              <div>
                <label className="mb-3 mt-2 setting_label">
                  {t("Employer.Employer_profile.Company_info.Email")}
                </label>
              </div>
              <input
                type="email"
                name="office_email"
                placeholder="johdoe@mail.com"
                className="crew_schedule_fields form-control mb-3 placeholder_style"
                onChange={handleChange}
                aria-describedby="emailHelp"
                value={values?.office_email}
              />
            </div>
            <div className="col-lg-4">
              <div>
                <label className="mb-3 mt-2 setting_label">
                  {t("Employer.Employer_profile.Company_info.Office_phone")}
                </label>
              </div>
                       <PhoneInput
                    value={values.office_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="office_phone"
                    placeholder="+1 604 123 4567"
                    className="crew_schedule_fields form-control mb-3 placeholder_style"
                  />
            </div>
            <div className="col-lg-4">
              <div>
                <label className="mb-3 mt-2 setting_label">
                  {t("Employer.Employer_profile.Company_info.Office_address")}
                </label>
              </div>
              <input
                type="text"
                name="office_address"
                placeholder="12768 21A Avenue"
                className="crew_schedule_fields form-control mb-3 placeholder_style"
                onChange={handleChange}
                aria-describedby="emailHelp"
                value={values?.office_address}
              />
            </div>
          </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-12">
              <label className="mb-3 mt-2 setting_label">Biography</label>
            <textarea
              className="crew_schedule_fields form-control"
              name="biography"
              placeholder="Lorem Ipsum......"
              onChange={handleChange}
              rows="5"
              value={values?.biography}
            ></textarea>
              </div>
            </div>
            
          </div>
        </div>

        

        <div className="row mt-3">
          <div className="col-lg-8">
            <div>
              <label className="mb-3 mt-2 setting_label">
                {t("Employer.Employer_profile.Company_info.Social_media")}
              </label>
            </div>
            <div className="input-group-prepend mb-3">
           
            
              <input 
                 type="text"
                 name="facebook_url"
                 onChange={handleChange}
                placeholder="Vancouver Film School"
                className="crew_schedule_fields social_media_field form-control mb-3 placeholder_style"
                aria-label="Amount (to the nearest dollar)"
                value={values?.facebook_url}
              /> 
              <div>
                        </div>
                     
                    
            </div>
          </div>
        </div>

        
      </div>
      <Button
                variant="info"
                style={{ float: "right" }}
                className="btn btn_primary"
                type="submit"
                size="lg"
              >
                {t('Employer.Employer_profile.Personal_info.Update')}
              </Button>
    </div>
    
    </form>
        )}
    </Formik>
}
    </>
  );
};

export default CompanyInfo;
