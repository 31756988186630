import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: false,
  role: null,
  name: null,
  role_data:null,
  id:null,
  userid:null,
  timezone:null,
  timezonevalue:null,
  hasfamily:false
}

export const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginState: (state, action) => {
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        role: action.payload.role,
        name: action.payload.name,
        role_data: action.payload.role_data,
        id: action.payload.id ,
        userid:action.payload.userid,
        timezone:action.payload.timezone,
        timezonevalue:action.payload.timezonevalue,
        hasfamily:action.payload.hasfamily
      }
      
    },
  },
})

export const { loginState } = login.actions

export default login.reducer