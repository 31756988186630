import React, { useState, useEffect, useRef } from "react";

import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, FieldArray } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";

import Loader from "../../../../shared/Loader";

import { addchild, deletechildren, getchildrens } from "../../../../Services/https/Employee/Employee";

const ChildInfo = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [loader, setLoader] = useState(true);
  const [childrens, setChildrens] = useState([{ first_name: "", last_name: "", age: "" }]);
  const [aboutchildrens,setAboutChildrens]=useState("");
  const [toggle, setToggle] = useState(false);
  const ages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]


  const getChildrens = () => {
    getchildrens().then(response => {
      if (response?.data?.data?.data && response?.data?.data?.data?.length !== 0) {
        setChildrens(response?.data?.data?.data);
        setAboutChildrens(response?.data?.data?.about_children)
      }
      else {
        setChildrens([{ first_name: "", last_name: "", age: "" }]);
      }
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      return err
    })
  }


  useEffect(() => {
    getChildrens();
  }, [])

  const updateSchema = Yup.object({
    kids: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required(`${t("UpdateSchema.Required")}`),
        last_name: Yup.string()
          .required(`${t("UpdateSchema.Required")}`).nullable(),
        age: Yup.string().required(`${t("UpdateSchema.Required")}`),
      })
    )
  }
  )


  const deleteRecord = (index, values) => {
    const records = [...values.kids]
    if (records[index]?.id) {
      const data = {
        id: records[index]?.id
      }
      setLoader(true)
      deletechildren(data).then(response => {
        toast.success(response?.data?.message);
        setLoader(false)
        getChildrens();
        setToggle(false)
      }).catch(err => {
        setLoader(false)
        return err
      })
    }
    else {
      records.splice(index, 1);
      setChildrens(records);
    }

  }

  const addRow = (values) => {
    if (toggle) {
      const records = [...values.kids]
      records.push({ first_name: "", last_name: "", age: "" });
      setChildrens(records);
    }

  }

  return (
    <>
      {loader && <Loader />}
      <Formik
        innerRef={formRef}
        initialValues={{ kids: childrens ,about_children:aboutchildrens}}
        validationSchema={updateSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          setLoader(true)
          addchild(values).then(response => {
            toast.success(response?.data?.message);
            getChildrens()
            setToggle(false)
          }).catch(err => {
            setLoader(false)
            return err
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm
          /* and other goodies */
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="profile_main" style={{ flexWrap: "wrap" }}>
                <FieldArray name="kids"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {
                          values?.kids && values?.kids?.length > 0 ? values?.kids?.map((children, index) => (
                            <>
                              <div className="row">
                                <div className="col-xl-3">
                                  <div>
                                    <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Fname')}<span style={{ color: "red" }}>*</span></label>
                                  </div>
                                  <div className="form-group">
                                    <Form.Control
                                      type="text"
                                      placeholder="John"
                                      name={`kids.${index}.first_name`}
                                      id={`kids.${index}.first_name`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="field_view"
                                      disabled={!toggle}
                                      defaultValue={values?.kids?.[index]?.first_name}
                                      value={values?.kids?.[index]?.first_name}
                                    />
                                  </div>
                                  {(errors?.kids && touched?.kids?.[index]?.first_name) && (
                                    <div className="error">
                                      {errors?.kids?.[index]?.first_name}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-3">
                                  <div>
                                    <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Lname')}<span style={{ color: "red" }}>*</span></label>
                                  </div>
                                  <div className="form-group">
                                    <Form.Control
                                      type="text"
                                      placeholder="Doe"
                                      name={`kids.${index}.last_name`}
                                      id={`kids.${index}.last_name`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="field_view"
                                      disabled={!toggle}
                                      defaultValue={values?.kids?.[index]?.last_name}
                                      value={values?.kids?.[index]?.last_name}
                                    />
                                  </div>
                                  {(errors?.kids && touched?.kids?.[index]?.last_name) && (
                                    <div className="error">
                                      {errors?.kids?.[index]?.last_name}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-2">
                                  <div>
                                    <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.Age')}<span style={{ color: "red" }}>*</span></label>
                                  </div>
                                  <div className="form-group">
                                    <select name={`kids.${index}.age`}
                                      id={`kids.${index}.age`}
                                      className="field_view form-control"
                                      disabled={!toggle}
                                      defaultValue={values?.kids?.[index]?.age}
                                      value={values?.kids?.[index]?.age}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="" label={!toggle ? values?.kids?.[index]?.age : ""}>
                                        Age{" "}
                                      </option>
                                      {
                                        ages?.map((age, index) => {
                                          return (
                                            <option value={age} label={age}>
                                              {" "}
                                              {age}
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                  {(errors?.kids && touched?.kids?.[index]?.age) && (
                                    <div className="error">
                                      {errors?.kids?.[index]?.age}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-2 delete_button" >
                                  <span onClick={() => {
                                    if (toggle) {
                                      handleSubmit();
                                    }
                                  }} style={{ cursor: "pointer" }}>
                                    {t('Employee.Profile.Save')}
                                  </span>

                                  <span onClick={() => {
                                    if (toggle) {
                                      deleteRecord(index, values);
                                    }
                                  }} style={{ cursor: "pointer" }}>
                                    {t('Employer.Add_employee.Delete')}
                                  </span>
                                </div>
                              </div>
                            </>
                          )) : null
                        }
                        <div className="row mt-2" >
                          <span className="add_child" onClick={() => addRow(values)}>+  {t('Employee.Profile.AddChild')}</span>
                        </div>
                      </>
                    )
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-xl-8">
                  <div>
                    <label className="mb-3 mt-2 setting_label">{t('Employee.Profile.ChildDescription')}</label>
                  </div>
                  <div className="form-group">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="about_children"
                      disabled={!toggle}
                      id="about_children"
                      placeholder={"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="field_view form-control"
                    value={values?.about_children}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {
                  toggle &&
                  <>
                    <Button
                      variant="outline-info"
                      className="cancel_childreq"
                      onClick={() => setToggle(false)}
                    >
                      {t("Employer.Add_employee.Delete_modal.Cancel")}
                    </Button>
                    <Button type="submit" variant="info" className="ms-3 update_child btn" onClick={() => setToggle(true)}>
                      {t('Employee.Profile.Save')}
                    </Button>
                  </>
                }
              </div>
            </form>
            {
              !toggle && <Button type="button" variant="info" className="ms-3 update_child btn" onClick={() => setToggle(true)}>
                {t('Employer.Calender_listing.Edit')}
              </Button>
            }

          </>
        )}
      </Formik>
    </>
  )
}

export default ChildInfo