import React, { useState, useEffect } from "react";

import * as Yup from 'yup';
import { Formik } from "formik";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Form } from "react-bootstrap";


import Logo from "../assets/Images/reelkids_logo_Dark-svg.svg";

import { getUserRole, login } from "../Services/https/Auth/Auth";
import { setLocalStorage } from "../Services/storage";
import { Encrypt } from "../Services/EncryptDecrypt";
import { SetCookie } from "../Services/Cookiees";
import { linkexpire } from "../Services/https/Admin/Admin";

import { loginState } from "../Reducers/Login/loginSlice";

import Loader from "../shared/Loader";

function Login() {
  const admin = useSelector((state) => state);
  const state = useSelector((state) => state.invite);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [productionData, setProductionData] = useState("");
  const inviteId = new URLSearchParams(location.search).get("inviteid");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const link_guid = new URLSearchParams(location.search).get("link_guid")
  const unionname = new URLSearchParams(location.search).get("union_name");

  const [showLoader, setShowLoader] = useState(false);
  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control"
        mask="999-999-9999"
        name="phoneNumber"
        value={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }
  useEffect(() => {
    if (inviteId) {
      linkexpire(inviteId)
        .then((response) => {
          const production_name = response?.data?.data?.production_name;
          const studio_name = response?.data?.data?.studio_name;
          const prod_id = response?.data?.data?.production_id;
          setProductionData(response?.data?.data);
        })
        .catch((error) => {
          return error
        });
    }

  }, []);

  const loginSchema = Yup.object({
    password: Yup.string().required(`${t("UpdateSchema.Required")}`),
    phoneNumber: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, `${t("UpdateSchema.Phone")}`).required(`${t("UpdateSchema.Required")}`)
  }
  )
  return (
    <section className="auth_layout login_screen">
      {showLoader && <Loader />}
      <div className="left_box"></div>
      <div className="right_box">
        <div className="right_box_cont">
          <div className="logo">
            <img
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              alt="Reelkids_Logi"
              src={Logo}
            />

          </div>
          <h3 className="title_h3">{t("Login.Title")}</h3>
          <div className="auth_form">
            <Formik
              initialValues={{ phoneNumber: "", password: "", remember: false }}
              validationSchema={loginSchema}
              onSubmit={(values) => {
                const data = {
                  username: values.phoneNumber.replace(/\D+/g, ""),
                  password: values.password,
                  remember: values.remember,
                };
                setShowLoader(true);
                login("/sign-in", data).then(async (loginResponse) => {
                  if (loginResponse.status === 200) {
                    const access_token = loginResponse.data.data.access_token;
                    const refresh_token = loginResponse.data.data.refresh_token;
                    const setAccessToken = setLocalStorage(
                      "access_token",
                      access_token
                    );
                    setLocalStorage("refresh_token", refresh_token);
                    if (setAccessToken) {
                      getUserRole().then((userRoleResponse) => {
                        if (userRoleResponse.status === 200) {
                          const { role_text, name, role_data, id, time_zone, time_zone_code, is_family, user_id } =
                            userRoleResponse?.data?.data;
                          const cookieData = [
                            {
                              username: values.phoneNumber,
                              password: values.password,
                            },
                          ];
                          const encrypteddata = Encrypt(cookieData);
                          if (values.remember) {
                            SetCookie("re_used", encrypteddata, "/");
                          }
                          if (location.search !== "" && role_data === "1") {
                            dispatch(
                              loginState({
                                loggedIn: true,
                                role: role_text,
                                name,
                                role_data,
                                timezone: time_zone,
                                timezonevalue: time_zone_code,
                                userid: user_id
                              })
                            );
                            setShowLoader(false);
                          }
                          if (
                            location.search !== "" &&
                            role_data === "2"
                          ) {
                            navigate(
                              `/employer/confirm-production?inviteid=${inviteId}`,
                              {
                                state: {
                                  loggedIn: true,
                                  role: role_text,
                                  name,
                                  role_data,
                                  id,
                                  timezone: time_zone,
                                  timezonevalue: time_zone_code,
                                  userid: user_id
                                },
                              }
                            );
                          }
                          if (
                            location.search !== "" &&
                            role_data === "3"
                          ) {
                            navigate(
                              `/employee/confirm-production?inviteid=${inviteId}&proid=${prod_guid}&link_guid=${link_guid}`,
                              {
                                state: {
                                  loggedIn: true,
                                  role: role_text,
                                  name,
                                  role_data,
                                  id,
                                  timezone: time_zone,
                                  timezonevalue: time_zone_code,
                                  userid: user_id
                                },
                              }
                            );
                          }
                          if (location.search !== "" && role_data === "4") {
                            navigate(`/union/confirm-production/step-1?inviteid=${inviteId}&proid=${prod_guid}&link_guid=${link_guid}&union_name=${unionname}`,
                              {
                                state: {
                                  loggedIn: true,
                                  role: role_text,
                                  name,
                                  role_data,
                                  id,
                                  timezone: time_zone,
                                  timezonevalue: time_zone_code,
                                  userid: user_id
                                },
                              }
                            )
                            setShowLoader(false);
                          }
                          if (location.search !== "" && role_data === "5") {
                            dispatch(
                              loginState({
                                loggedIn: true,
                                role: role_text,
                                name,
                                role_data,
                                timezone: time_zone,
                                timezonevalue: time_zone_code,
                                userid: user_id
                              })
                            )
                            navigate(`/caregiver/dashboard`
                            )
                            setShowLoader(false);
                          }

                          else if (location.search === "") {
                            dispatch(
                              loginState({
                                loggedIn: true,
                                role: role_text,
                                name,
                                role_data,
                                timezone: time_zone,
                                timezonevalue: time_zone_code,
                                hasfamily: is_family && true,
                                userid: user_id
                              })
                            );
                            setShowLoader(false);
                          }
                        }
                      });
                    }
                  } else {
                    toast.error(`${t("Validation.Login_validation.fail")}`);
                    setShowLoader(false);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group
                      className="form-group-1"
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                    >
                      <Form.Label>{t("Login.PhoneNumber")}</Form.Label>
                      <div className="input_icon">
                        <PhoneInput
                          className="form-control"
                          name="phoneNumber"
                          maskChar={null}
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="778-7XX-XXXX"
                        />
                      </div>
                      {errors.phoneNumber && (
                        <div className="error">
                          {touched.phoneNumber && (
                            <>{errors?.phoneNumber}</>
                          )}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="form-group-1"
                      as={Col}
                      md="12"
                      controlId="validationCustom02"
                    >
                      <Form.Label>{t("Login.Password")}</Form.Label>
                      <div className="input_icon">
                        <Form.Control
                          placeholder="********"
                          type={!showPassword ? "password" : "text"}
                          name="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <i
                          onClick={() => setShowPassword(!showPassword)}
                          className={
                            !showPassword
                              ? "view_password fa-solid fa-eye-slash "
                              : " view_password fa-solid fa-eye"
                          }
                        ></i>
                      </div>
                      {errors.password && (
                        <div className="error">
                          {touched.password && (
                            <>{errors?.password}</>
                          )}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <div className="forgot_section">
                    <Form.Group
                      className="custom_checkbox"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleChange}
                        name="remember"
                        value={values.remember}
                        className="resetpassword"
                        label={t("Login.Remember")}
                      />
                    </Form.Group>
                    <a
                      className="resetpassword"
                      onClick={() => navigate("/forgot_password")}
                    >
                      {t("Login.Forgot")}
                    </a>
                  </div>
                  <Button
                    type="submit"
                    variant="info"
                    className="btn-lg w100p justify-content-center d-inline-block"
                  >
                    {t("Login.Sign")}
                  </Button>
                </form>
              )}
            </Formik>

            <div className="sign-up-here">
              <a>{t("Login.Account")}</a>
              <a onClick={() => navigate("/signup")} className="signUpLogin">
                <u>{t("Login.SignUp")}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
