import React, { useState } from "react";

import ModalDialog from "../../Modal";
import { svg } from "../../../assets/svg/svg";
import { Button } from "react-bootstrap";

const PaymentModal = ({ setOpen }) => {

    return (
        <>
            <div style={{ padding: "30px 0px" }}>
                {svg["Alert"]}
            </div>
            <h1>ERROR!</h1>
            <div>
                <h3>Thank you for your request</h3>
                <h3>We are unable to continue process</h3>
            </div>

            <div>
                <h5>Please try again to complete the process</h5>
            </div>

            <div style={{ marginTop: "30px" }}>
                <Button
                    style={{ width: "50%" }}
                    type="button"
                    variant="danger"
                    onClick={() => setOpen(false)}
                >
                    Back
                </Button>
            </div>

        </>
    )
}

const PaymentError = () => {
    const [open, setOpen] = useState(true);
    return (
        <ModalDialog show={open} component={<PaymentModal setOpen={setOpen} />} />
    )
}

export default PaymentError