import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 productionId:null
}

export const Employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    EmployeeState: (state, action) => {
      return {
        ...state,
        productionId: action.payload.productionId,
      }
    },
  },
})

export const { EmployeeState } = Employee.actions

export default Employee.reducer