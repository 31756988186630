import React,{useEffect,useState} from "react";

import { useTranslation } from "react-i18next";

import Dashboard from "../../../shared/Dashboard";

import Contacts from "./contacts";
import EmpCalender from "./calender";
import { useDispatch, useSelector } from "react-redux";
import { getcurrentprod } from "../../../Services/https/Employer/Employer";
import CurrentProduction from "../../../Employer/DashboardCurrentProduction";
import Loader from "../../../shared/Loader";
import NoProduction from "../../../Employer/Noproduction";
import { EmployeeState } from "../../../Reducers/EmployeeReducer/EmployeeSlice";

import FamilyLogo from '../../../assets/Images/icon-park-solid_family.png' 
import FundsChart from "../../../Employer/FundsChart";


const EmployeeDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name,hasfamily } = useSelector(state => state.login);
const [productions,setProductions]=useState({});
const [change, setChange] = useState(false);
const [loader,setLoader]=useState(true)

useEffect(()=>{
  getcurrentprod()
  .then((response) => {
    setProductions(response?.data?.data);
    setLoader(false);
    dispatch(
      EmployeeState({
        productionId: response?.data?.data?.id ,
      })
    );
  })
  .catch((err) => {
    return err;
  });
},[change])

const detectChange = (value) => {
  setChange(value)
}


  return (
    <>
    {loader ? (
        <Loader />
      ) : Object.keys(productions)?.length === 0 ? (
        <NoProduction />
      ) : (
        <>
<div className="dashboard_screen">
        <h3 className=" title_h3">
          {t('Employee.Dashboard.Welcome')}, <span>{name} {hasfamily && <img src={FamilyLogo}/>}</span>
        </h3>
      </div>

      <div className="row">
        <div className="col-xl-6 col-sm-12">
          <div className="row">
            <Dashboard
              text_decorator='true'
              title={t('Employee.Dashboard.Current_productions')}
              component={<CurrentProduction observechange={detectChange} change={change}/>}
            />
          </div>
          <div className="row">
            <Dashboard text_decorator='true' title={t('Employee.Dashboard.Month_at_a_glance')}  component={<EmpCalender nextBooking={true} />} />
          </div>
        </div>
        <div className="col-xl-6 col-sm-12">
          <div className="row">
            <Dashboard text_decorator='true' title={t('Employee.Dashboard.Current_funding')} component={<FundsChart />} />
          </div>
          <div className="row">
            <Dashboard title={t('Employee.Dashboard.Key_contacts')} text_decorator='true' keycontacts='true' component={<Contacts data={productions}/>} />
          </div>
        </div>
      </div>
      </>
      )}

      
    </>
  );
};

export default EmployeeDashboard;
