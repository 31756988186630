import React,{ useState } from "react";

import AddFamilyAccount from "./AddFamilyAccount";

const FamilyAccount = () => {
  const [length,setLength]=useState([])

  const getCount = (count) =>{
    setLength(count)
  }

  return (
    <div className="white_box manage_screen">
      <div className="">
        <div className="heading_box">
        </div>
              <AddFamilyAccount countmembers={getCount}/>
      </div>
    </div>
  );
};

export default FamilyAccount;
