import moment from 'moment';
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { caregiverEarnings } from '../Services/https/Caregiver/Caregivers';

const Earnings = () => {
    const { t } = useTranslation();
    const [earnings, setEarnings] = useState([]);

    useEffect(() => {
        caregiverEarnings().then(response => {
            setEarnings(response?.data?.data)
        }).catch(err => {
            return err
        })
    }, [])


    return (
        <div className='earning_card'>
            <div className='text-center'>
                <h6>{t('Caregiver.Dashboard.Balance')}</h6>
                <h1> $1,200 </h1>
                <div>
                    <button className="btn btn-info funding_add" type="button">
                        {t('Caregiver.Dashboard.Income')}: $500
                    </button>
                </div>
            </div>
            <div className='mt-4'>
                <h5>{t('Caregiver.Dashboard.Recent_activity')}</h5>
                {
                    earnings?.slice(0, 2)?.map((list, index) => {
                        return (
                            <div className='caregiver_earnings_div ' key={index}>
                                <div className='recent_activity_dates'>   
                               <div>{moment(list?.date, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY")}</div>     
                                    <div className='caregiver_earnings'>${list?.amount}</div></div></div>

                        )
                    })
                }

            </div>
            <div>
                <h5>{t('Caregiver.Dashboard.Anticipated')}</h5>
                <p>{moment().day() === 6 ? moment().add(6, 'days').format('MMM DD, YYYY') : moment().day('Friday').format('MMM DD, YYYY')}</p>
            </div>
        </div>
    )
}

export default Earnings