import React,{useState} from 'react'
import { useEffect } from 'react';
import RadialBar from "react-apexcharts"
import { useTranslation} from 'react-i18next'


const ProductionFunding = ({funding,option}) => {
    const [show,setShow]=useState(false)
    const {t}=useTranslation();

    useEffect(()=>{
     if(Object.keys(option)?.length>0){
      setShow(true)
     } 
    },[option])

    return (
      <>
        <h3 className="title_h4_line">{t('Employee.Dashboard.Current_funding')}</h3>
      
        <div className="row">
          <div className="col-xl-6 d-flex justify-content-center ">
  <div className='funding_chart'>

   {
show && 
<RadialBar options={option.options} series={option.series} type="radialBar" width={500} height={320} labels={option.options.labels} />
   }
  </div>
          </div>
          <div className="col-xl-6">
            <div className="d-flex flex-column justify-content-center mt-5">
              <button type="button" className="btn btn-info h52 w100">{t('Union.Dashboard.Spent')}: {`$${funding?.spent_amount}`}</button>
              <button type="button" className="btn btn-outline-info h52 w100 mt-3">{t('Union.Dashboard.Remaining')}: {`$${funding?.remaining_amount}`}</button>
            </div>
          </div>
  
        </div>
      </>
    )
}

export default ProductionFunding