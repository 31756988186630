import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {  useNavigate, useParams } from "react-router-dom";

import { modeofContact } from "../../../Services/https/Employee/Employee";

import Loader from "../../../shared/Loader";
import { svg } from "../../../assets/svg/svg";


const Success = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const param = useParams();
    const [loader,setLoader]=useState(false)
    const [mode, setMode] = useState(null);

    const contactMode = () => {
        setLoader(true)
        const data={
            booking_request_id:param?.id,
            follow_up:mode
        }
        modeofContact(data).then(response=>{
            setLoader(false)
            navigate(-1);
        }).catch(err=>{
            return err
        })
    }

    return (
        <>
        {loader && <Loader/>}
            <div className="dashboard_screen">
                <div className="white_box">
                    <h1 className="text-center request_title">{t('Employee.Productions.Success')}</h1>

                    <p className="text-center heading_box">
                        {t('Employee.Productions.Success_desc')}
                    </p>
                    <div>
                        <div className="row">
                            <div className="col-xl-2"></div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="dashboard_screen">
                                            <div className={mode==="phone"? "white_box cardbg zoom":"white_box cardbg"}  onClick={() => setMode("phone")}>
                                                <div className="card_icons">
                                                    {svg["Phone"]}
                                                    <span>{t('Employee.Productions.Message')}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-6">
                                        <div className="dashboard_screen">
                                            <div className={mode==="email"? "white_box cardbg zoom":"white_box cardbg"}  onClick={() => setMode("email")}>
                                                <div className="card_icons">
                                                    {svg["Email"]}
                                                    <span>{t('Employee.Productions.Email')}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2"></div>
                        </div>
                        <div className="text-center btn-section mt-5">
                            <Button className="next"
                                disabled={!mode}
                                onClick={() => {contactMode()}}>{t('Employee.Productions.Done')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Success;
