import React from "react"; 

import { useSelector } from "react-redux";

function Dashboard({title,component,show,subtitle,height, text_decorator,keycontacts,current}) {
  const { role_data } = useSelector((state) => state.login);
  return(
    <>
    <div className={keycontacts ==='true' ? "dashboard_screen dashboard2 contact_card" : "dashboard_screen dashboard2 "}>
      <div className={height ?  "white_box height":  current ? "white_box employer_card_height":"white_box"}>
        <div className="d-flex justify-content-between">
        {title && <h4 className={`${text_decorator==='true' ? "title_h4_line" : 'mb-4'}`}><span>{title}</span> </h4>}
        </div>
        <div className="">
        {subtitle ? subtitle : null}
        </div>
        {component}
      </div>
    </div>
    </>
  )
  }

export default React.memo(Dashboard) ;
