import React, { useState, useEffect } from "react";

import { Button,Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Loader from "../../shared/Loader";

import { detailUpdate, getEmployerDetail, getStudioList, getUpdateProductionList } from "../../Services/https/Auth/Auth";

const Detail = ({getStatus}) => {
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [studio, setStudio] = useState([]);
  const [loader, setLoader] = useState(true);
  const [studioId, setStudioId] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const { id } = useParams();
  const { t } = useTranslation();

  const updateSchema = Yup.object().shape({
    name: Yup.string().required(`${t("UpdateSchema.Required")}`),
    last_name: Yup.string()
      .required(`${t("UpdateSchema.Required")}`)
      .nullable(),

    email: Yup.string()
      .email(`${t("Admin.UpdateEmployeer.Details.Validations.Email")}`)
      .required(`${t("UpdateSchema.Required")}`),
    phone: Yup.string().required(`${t("UpdateSchema.Required")}`),
  });

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }
  const onChangeStudio = (e) => {
    setStudioId(e.target.value);
  };
  useEffect(() => {
    getEmployerDetail(id).then((response) => {
      setData(response?.data?.data);
      setStudioId(response?.data?.data?.studio_id);
      getStatus(response?.data?.data?.status);
    });
    getStudioList().then((res) => {
      setStudio(res?.data?.data);
      setLoader(false);
    });
  }, []);
  useEffect(() => {
    if (studioId) {
      // setLoader(true);
      getUpdateProductionList(studioId).then((response) => {
        setList(response?.data?.data);
        // setLoader(false);
      });
    }
  }, [studioId]);

  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            name: data?.production_manager_name,
            last_name: data?.last_name,
            email: data?.email,
            phone: data?.phone,
          }}
          enableReinitialize={true}
          validationSchema={updateSchema}
          onSubmit={(values) => {
            const data = {
              name: values.name,
              last_name: values.last_name,
              email: values.email,
              phone: values.phone.toString().replace(/\D+/g, ""),
            };

            detailUpdate(id, data)
              .then((response) => {
                setLoader(true);
                if (response?.status === 200) {
                  getEmployerDetail(id).then((response) => {
                    setData(response?.data?.data);
                    toast.success(`${t("Toast.Update")}`);
                    setLoader(false);
                  });
                  
                }
              })
              .catch((err) => setErrorMessage(err?.response?.data?.errors));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row mb-3 mt-3">
                <div className="col-xl-3 required">
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateEmployeer.Details.Production")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="First name"
                    className="update_employeer_input"
                    defaultValue={data?.production_manager_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && (
                    <div className="error">
                      {touched.name && <>{errors.name}</>}
                    </div>
                  )}
                </div>
                <div className="col-xl-3 required">
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateEmployeer.Details.LastName")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    className="update_employeer_input"
                    defaultValue={data?.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.last_name && (
                    <div className="error">
                      {touched.last_name && <>{errors.last_name}</>}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-3 required">
                <div className="col-xl-4">
                  <Form.Label className="control-label control_label_text">
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="mail@website.com"
                    className="update_employeer_input"
                    defaultValue={data?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && (
                    <div className="error">
                      {touched.email && <>{errors.email}</>}
                    </div>
                  )}
                </div>
                <div className="col-xl-3">
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateEmployeer.Details.PhoneNumber")}
                  </Form.Label>
                  <PhoneInput
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="phone"
                    className="form-control update_employeer_input"
                  />
                  {errors.phone && (
                    <div className="error">
                      {touched.phone && <>{errors.phone}</>}
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-3 required">
                <div className="col-xl-7">
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateEmployeer.Details.CurrentStudio")}
                  </Form.Label>
                  <Form.Select
                    type="select"
                    className=" select"
                    onChange={onChangeStudio}
                  >
                    {studio?.map((items, index) => {
                      return (
                        <optgroup className="drop_option">
                          <option
                            value={items?.id}
                            key={index}
                            selected={data?.studio_name === items?.studio_name}
                            // onSelect={setStudioId(items?.id)}
                            // onChange={setStudioId(items?.id)}
                          >
                            {items?.studio_name}
                          </option>
                        </optgroup>
                      );
                    })}
                  </Form.Select>
                </div>
              </div>
              <div className="row mb-3 required">
                <div className="col-xl-7">
                  <Form.Label className="control-label control_label_text">
                    {t("Admin.UpdateEmployeer.Details.CurrentProduction")}
                  </Form.Label>
                  <Form.Select type="select" className=" select">
                    {list?.map((items, index) => {
                      return (
                        <optgroup className="drop_option">
                          <option
                            value={items}
                            key={index}
                            selected={
                              data?.current_production ===
                              items?.production_name
                            }
                          >
                            {items?.production_name}
                          </option>
                        </optgroup>
                      );
                    })}
                  </Form.Select>
                </div>
              </div>
              <Button
                type="submit"
                variant="info"
                className="btn-lg text-center"
                style={{ float: "right" }}
              >
                {t("Admin.UpdateEmployeer.Details.Button")}
              </Button>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Detail;
