import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../shared/Loader";

import {
  unionProfile,
  updateUnionProfile,
} from "../../Services/https/Union/Union";
import { getunionProfile } from "../../Services/https/Employer/Employer";
import { getTimezone } from "../../Services/https/comman/common";

import { loginState } from "../../Reducers/Login/loginSlice";

const Details = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const { role_data } = useSelector((state) => state.login);
  const { state } = useLocation();
  const logindata = useSelector((state) => state.login);
  const [timezone, setTimeZone] = useState([]);
  const [timezonevalue, setTimezoneValue] = useState(null);

  useEffect(() => {
    if (role_data === "4") {
      GetUnionProfile();
      getTimezone()
        .then((response) => {
          setTimeZone(response?.data?.data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, []);

  const GetUnionProfile = () => {
    unionProfile()
      .then((response) => {
        setLoader(false);
        setProfile(response?.data?.data);
      })
      .catch((err) => {
        setLoader(false);
        return err;
      });
  };

  useEffect(() => {
    if (role_data === "4") {
      Object.keys(errorMessage).map((key) => {
        toast.error(errorMessage[key]);
      });
    }
  }, [errorMessage, role_data]);

  useEffect(() => {
    if (role_data === "2") {
      getunionProfile(state?.id)
        .then((response) => {
          setProfile(response?.data?.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    }
  }, []);

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control field_appearance"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        defaultValue={props.value}
        onChange={props.onChange}
        disabled={role_data === "2" && true}
      ></InputMask>
    );
  }

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: "8px",
      backgroundColor: "#f2f2f2",
      padding: "10px",
      borderColor:state?.isDisabled ? "hsl(0, 0%, 80%)":"hsl(0, 0%, 80%)"
    }),
    indicatorSeparator: () => {},
  };

  const updateSchema = Yup.object().shape({
    firstname: Yup.string().required(`${t("UpdateSchema.Required")}`),
    lastname: Yup.string()
      .required(`${t("UpdateSchema.Required")}`)
      .nullable(),
    email: Yup.string()
      .email(`${t("Admin.UpdateEmployeer.Details.Validations.Email")}`)
      .required(`${t("UpdateSchema.Required")}`),
    phone: Yup.string().required(`${t("UpdateSchema.Required")}`),
    union: Yup.string().required(`${t("UpdateSchema.Required")}`),
    union_local: Yup.string()
      .required(`${t("UpdateSchema.Required")}`)
      .nullable(),
  });

  const keyPressAlphaNumeric = (event) => {
    if (event.keyCode === 190) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <>
      {loader && <Loader />}
      {role_data === "4" && (
        <p style={{ fontSize: "18px", fontWeight: "400" }}>
          {t("Union.Details.Heading")}
        </p>
      )}

      <div className={role_data === "2" && "dashboard_screen"}>
        <div className={role_data === "2" && "white_box"}>
          {role_data === "2" && (
            <>
              <p className="title_h3 ">{t("Union.Details.Title")}</p>
              <p style={{ fontSize: "18px", fontWeight: "400" }}>
                {t("Union.Details.Heading")}
              </p>
              <div className="employee-booking-cards cards_mb">
                <div className="row ">
                  <div className="col-xl-3">
                    <Button className="employee-btn-active">
                      {t("Admin.UpdateEmployeer.Details.Title")}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          <Formik
            initialValues={{
              email: profile?.email,
              phone: profile?.phone,
              firstname: profile?.first_name,
              lastname: profile?.last_name,
              production: profile?.production_name,
              union: profile?.union_name,
              studio: profile?.studio_name,
              time_zone: profile?.time_zone,
              union_local: profile?.union_local,
              address: profile?.address ? profile?.address + ","+ profile?.city + ","+ profile?.state+ ","+profile?.country:"",
              city: profile?.city,
              state: profile?.state,
              country: profile?.country,
            }}
            validationSchema={updateSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              setLoader(true);
              const data = {
                first_name: values?.firstname,
                last_name: values?.lastname,
                email: values?.email,
                phone: values?.phone?.toString().replace(/\D+/g, ""),
                union_name: values.union,
                time_zone: values.time_zone,
                union_local: values?.union_local,
                address: values?.address,
                city: values?.city,
                country: values?.country,
                state: values?.state,
              };
              updateUnionProfile(data)
                .then((response) => {
                  setLoader(false);
                  GetUnionProfile();
                  toast.success(response?.data?.message);
                  dispatch(
                    loginState({
                      ...logindata,
                      ...{
                        timezone: values?.time_zone,
                        name: values?.firstname + " " + values?.lastname,
                        timezonevalue: timezonevalue ? timezonevalue : logindata?.timezonevalue,
                      },
                    })
                  );
                })
                .catch((err) => {
                  setLoader(false);
                  setErrorMessage(err?.response?.data?.errors);
                  return err;
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-3">
                    <div>
                      <label className="mb-3  setting_label">
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.UnionName"
                        )}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={values.union}
                        className="form-control field_appearance"
                        defaultValue={profile?.union_name}
                        placeholder="The Arcade"
                        name="union"
                        id="union"
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        disabled={role_data === "2" && true}
                      />
                    </div>
                    {errors.union && (
                      <div className="error">
                        {touched.union && <>{errors.union}</>}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-2">
                    <div>
                      <label className="mb-3  setting_label">
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.UnionLocal"
                        )}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        step="any"
                        onKeyDown={(e) => keyPressAlphaNumeric(e)}
                        value={values?.union_local}
                        className="form-control field_appearance"
                        defaultValue={profile?.union_name}
                        // placeholder="The Arcade"
                        name="union_local"
                        id="union_local"
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        disabled={role_data === "2" && true}
                      />
                    </div>
                    {errors.union_local && (
                      <div className="error">
                        {touched.union_local && <>{errors.union_local}</>}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                <div className="col-xl-3">

              <p className="main-contact">{t("Union.Details.Contact")}</p>  
                </div>
                </div>

                <div className="row">
                  <div className="col-xl-4 ">
                    <div>
                      <label className="mb-3  setting_label">
                        {t("Union.Details.FirstName")}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={values.firstname}
                        className="form-control field_appearance"
                        defaultValue={profile?.first_name}
                        placeholder="John"
                        name="firstname"
                        id="firstname"
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        disabled={role_data === "2" && true}
                      />
                    </div>
                    {errors.firstname && (
                      <div className="error">
                        {touched.firstname && <>{errors.firstname}</>}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-4 ">
                    <div>
                      <label className="mb-3  setting_label">
                        {t("Employee.Profile.Lname")}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={values.lastname}
                        className="form-control field_appearance"
                        defaultValue={profile?.last_name}
                        placeholder="Doe"
                        name="lastname"
                        id="lastname"
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        disabled={role_data === "2" && true}
                      />
                    </div>
                    {errors.lastname && (
                      <div className="error">
                        {touched.lastname && <>{errors.lastname}</>}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5 mb-3">
                    <div>
                      <label className="mb-3 setting_label">
                        {t("Employee.Profile.Email")}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group ">
                      <input
                        type="text"
                        value={values.email}
                        className="form-control field_appearance"
                        defaultValue={profile?.email}
                        placeholder="johndoe@example.com"
                        name="email"
                        id="email"
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        disabled={role_data === "2" && true}
                      />
                    </div>
                    {errors.email && (
                      <div className="error">
                        {touched.email && <>{errors.email}</>}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-4 mb-3">
                    <div>
                      <label className="mb-3  setting_label">
                        {t("Employee.Profile.Number")}
                        {role_data === "4" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </label>
                    </div>
                    <div className="input-group ">
                      <PhoneInput
                        name="phone"
                        id="phone"
                        maskChar={null}
                        defaultValue={profile?.phone}
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="778-XXX-XXXX"
                      />
                    </div>
                    {errors.phone && (
                      <div className="error">
                        {touched.phone && <>{errors.phone}</>}
                      </div>
                    )}
                  </div>

                  <div className="">
                    <div className="col-xl-9 mb-3">
                      <div>
                        <label className=" mb-3 setting_label">
                          {t("Timezone")}
                        </label>
                      </div>
                      <Select
                        defaultValue={{
                          label: logindata?.timezone,
                          name: logindata?.timezone,
                        }}
                        isDisabled={role_data==="2" && true}
                        options={timezone?.map((list, index) => {
                          return {
                            label: list?.timezone_name,
                            name: list?.timezone_name,
                            value: list?.timezone_name_value,
                            code: list?.code_name,
                          };
                        })}
                        onChange={(option) => {
                          setFieldValue("time_zone", option?.name);
                          setTimezoneValue(option?.code);
                        }}
                        styles={CustomStyle}
                        placeholder={
                          <div style={{ textAlign: "left" }}>
                            {t("SelectTimezone")}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-9 ">
                    <div>
                      <label className=" mb-3 setting_label">
                        {t("Admin.UpdateCaregiver.Detail.Address")}
                      </label>
                    </div>
                    <Autocomplete
                      defaultValue={
                       values?.address
                      }
                      apiKey={process.env.REACT_APP_API_KEY}
                      className="field_view form-control"
                      id="address"
                      options={{
                        types: ["premise"],
                        //   fields: ["address_components", "formatted_address"],
                        //   componentRestrictions: { country: "ca" },
                      }}
                      placeholder={t("Admin.UpdateCaregiver.Detail.Address")}
                      onPlaceSelected={(place) => {
                        const addressName = place?.address_components?.find(
                          (list) => list.types.includes("premise")
                        );
                        const cityName = place?.address_components?.find(
                          (list) => list.types.includes("locality")
                        );
                        const stateName = place?.address_components?.find(
                          (list) =>
                            list.types.includes("administrative_area_level_1")
                        );
                        const countryName = place?.address_components?.find(
                          (list) => list.types.includes("country")
                        );
                        setFieldValue("address", addressName?.long_name);
                        setFieldValue("city", cityName?.long_name);
                        setFieldValue("state", stateName?.long_name);
                        setFieldValue("country", countryName?.long_name);
                      }}
                    />
                  </div>
                </div>

                {role_data === "4" && (
                  <Button
                    type="submit"
                    variant="info"
                    className="btn-add mt-0 w_100"
                    style={{ float: "right" }}
                  >
                    {t("Admin.ManageEmployeer.Modal.Confirm")}
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Details;
