import React, { useState } from "react";

import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from "react-bootstrap";

import PersonalInfo from "./PersonalInfo";
import CompanyInfo from "./CompanyInfo";
import ModalDialog from "../shared/Modal";
import DeleteAccount from "../shared/deleteAccount";

const EmployerProfile = () => {
  const { t } = useTranslation();
  const [deletePopup, setDeletePopup] = useState(false);

  const closePopup = () => {
    setDeletePopup(false)
  }

  return (
    <div>
      <div className="white_box manage_screen">
        <div className="d-flex align-items-center justify-content-between ">
          <h3 className="title_balances">{t('Employer.Employer_profile.Profile')}</h3>
          <Button
            type="button"
            variant="danger"
            onClick={() => setDeletePopup(true)}
          >
            {t('DeleteAccount')}
          </Button>
        </div>

        <Tabs
          defaultActiveKey="personal"
          id="fill-tab-example"
          className="mb-3 profile_tabs"

        >
          <Tab eventKey="personal" title={t('Employer.Employer_profile.Personal_info_title')}>
            <PersonalInfo />
          </Tab>
          <Tab eventKey="company" title={t('Employer.Employer_profile.Company_info_title')}>
            <CompanyInfo />
          </Tab>
        </Tabs>
        <ModalDialog
          align='left'
          show={deletePopup}
          onHide={() => setDeletePopup(false)}
          component={<DeleteAccount close={closePopup} />}
        />
      </div>
    </div>
  );
};

export default EmployerProfile;
