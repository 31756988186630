import React,{useState,useEffect} from "react";

import { useTranslation } from "react-i18next";
import {Button,Form,Table} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import Modal from "../shared/Modal";
import Invite from "../Employer/Invite";
import { svg } from "../assets/svg/svg";
import Pagination from "../shared/Pagination";
import Loader from "../shared/Loader";
import NoRecords from "../shared/NoRecords";

import FamilyLogo from '../assets/Images/icon-park-solid_family.png'

import {
  deleteEmployeeFromProduction,
  employeelist,
  generateInvite,
} from "../Services/https/Employer/Employer";
import { sendInvite } from "../Services/https/Employer/Employer";
import { pendingEmployeeRequest } from "../Services/https/comman/common";

function ManageEmployee() {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [del, setDel] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [handlesearch, sethandleSearch] = useState(false);
  const [searchkeyword, setSearchkeyword] = useState("");
  const [totalcount, setTotalcount] = useState(null);
  const [deleterecord, setDeleteRecord] = useState([]);
  const [employeesList, setEmployeeList] = useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [showNoRecord, setShowNoRecord] = useState(false)
  const [link,setLink]=useState(null);
  const pageCount = Math.ceil(totalcount / 10); 
  const currentprodId = useSelector(state=>state?.invite?.production_id)
  const role_id=3
  useEffect(() => {
    setLoading(true)
    employeelist(currentprodId, searchkeyword, 10, initialpage)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setEmployeeList(response?.data?.data?.paginate?.data);
          setTotalcount(response?.data?.data?.total);
          setLoading(false);
          setDeleteRecord([]);
          setShowNoRecord(true);
        }
        
      })
      .catch((error) => {
        return error;
      });
  }, [handlesearch, initialpage, del]);
  useEffect(()=>{
    if(show){
      generateInvite(currentprodId).then(response=>{
        setLink(response?.data?.data)
      }).catch(err=>{return err})
    }

  },[show])
  const [email,setEmail]=useState({
    email:''
  })
  const changeInput=(e)=>{
    setEmail({email:e.target.value})
  }
  const InviteDescription = {
    heading: `${t(
      "Employer.Manage_union.Generate_link_modal.Invite_employee"
    )}`,
    headin_two: `${t("Employer.Manage_union.Generate_link_modal.Otp")}`,
    body: <>
      <div>
      <div className="row  generate_invite_input">
        
        <div className="col-lg-6">
          <input
            type="text"
            className=" generate_invite_field_input form-control "
            placeholder=""
            disabled
            value={link?link:null}
          ></input>
        </div>

        <div></div>
      </div>
      <div className="row generate_invite_input">
        <div className="col-lg-6">
          <label className="mb-2 mt-4 generate_invite_modal_label">
            {t("Employer.Manage_union.Generate_link_modal.Send_invite")}:
          </label>
          <input
            type="text"
            value={email.email}
            name='email'
            className="generate_invite_field_input form-control"
            placeholder=""
            onChange={changeInput}
          ></input>
        </div>

      </div>
    </div>
      </>,
  };
  const recordstoDelete = (e, id) => {
    const records = [...deleterecord];
    if (e.target.checked) {
      records.push(id);
      setDeleteRecord(records);
    } else {
      const recs = records.filter((rec) => rec !== id);
      setDeleteRecord(recs);
    }
  };
  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };
  

  const inviteHandler = () => {
    setLoading(true);
    sendInvite(email, currentprodId, role_id)
      .then((response) => {
        setLoading(false);
        setShow(false)
        if (response?.status === 200) {
          setLoading(false);
          toast.success(response?.data?.message);
          setEmail({email:""})
        }
        else{
          toast.error('The given data is invalid')
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      });
  };

  useEffect(() => {
    pendingEmployeeRequest(currentprodId, 7,3).then((response) => {
      if (response.status === 200) {
        setEmployeeCount(response.data.data);
      }
    });
  }, [show]);
  return (
    <>
      <Modal
        show={modalShow}
        modalTitle={t("Employer.Modal.Title")}
        description={
          t("Employer.Modal.Heading.start") +
          deleterecord.length +
          t("Employer.Modal.Heading.end")
        }
        onHide={() => setModalShow(false)}
        footer={
          <div className="group_buttons mt-5">
            <Button
              variant="outline-info"
              className="btn-lg"
              onClick={() => setModalShow((prev) => !prev)}
            >
              {t("Employer.Modal.Cancel")}
            </Button>
            <Button
              variant="info"
              className="btn-lg"
              onClick={() => {
                deleteEmployeeFromProduction(deleterecord).then((response) => {
                  if (response.status === 200) {
                    setModalShow((prev) => !prev);
                    setDel(true);
                  }
                });
              }}
            >
              {t("Employer.Modal.Confirm")}
            </Button>
          </div>
        }
      />
      <Invite
        show={show}
        InviteDescription={InviteDescription}
        onHide={() => {setShow((prev) => !prev); setEmail({email:""})}}
        setShow={setShow}
        footer={
          <div className="group_buttons mt-5">
            <Button variant="info" className="btn-lg" onClick={inviteHandler}>
              {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
            </Button>{" "}
          </div>
        }
      />
      {del && (
        <Modal
          modalTitle={t("Admin.ManageEmployeer.ModalDelAfter.Title")}
          show={del}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      )}
      {loading && <Loader />}
      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">
            {t("Employer.Manage_employees.Manage_employee")}
          </h3>
          <div className="search_box d-flex ms-auto">
            <div className="search_box_in">
              <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }}>{svg["Search"]}</span>
              <Form.Control
                type="text"
                placeholder={t("Employer.Manage_employees.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>
           
            
              
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    deleterecord?.length !== 0 && setModalShow(true);
                  }}
                >
                  {deleterecord?.length}{" "}
                  {t("Employer.Manage_employees.Selected")}
                  {svg["Delete"]}
                </Button>
              </div>
            
          </div>
        </div>
   
        <Table className="custom_table" responsive>
          {employeesList?.length === 0 && showNoRecord ? (
            <NoRecords />
          ) : (
            <>
              <thead>
                <tr>
                  <th width="50px"></th>
                  <th>{t("Employer.Manage_employees.Last_name")}</th>
                  <th>{t("Employer.Manage_employees.First_name")}</th>
                  <th>{t("Employer.Manage_employees.Production")}</th>
                  <th>{t("Employer.Manage_employees.Studio")}</th>
                  <th >{svg["Edit"]}</th>
                </tr>
              </thead>
              <tbody>
                {employeesList?.map((list, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>
                          <Form.Check
                            key={`${list?.user_id}`}
                            className="custom_checkbox"
                            aria-label={`option-${list?.user_id}`}
                            name={`${list.id}`}
                            onClick={(e) => {
                              recordstoDelete(e, list?.user_id);
                            }}
                          />
                        </td>
                        <td>
                          <b>{list?.last_name}  </b><span>{list?.is_family_member && <img src={FamilyLogo} alt="no-preview"/>}</span>
                        </td>
                        <td>
                          <b>{list?.first_name}</b>
                        </td>
                        <td>{list?.production_name}</td>
                        <td>{list?.studio_name}</td>
                        <td>
                          <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <u> {t("Employee.Bookings.Manage")}</u>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            navigate("/employer/employees/addemploye", {
                              state: { id: list?.user_id,name: list?.production_name},
                            })
                          }
                        >
                          {t("Employer.Manage_employees.Editprofile")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            navigate("/employer/employees/familymembers", {
                              state: { id: list?.user_id , name : list?.first_name + " " + list?.last_name},
                            })
                          }}
                        >
                          {t("Employer.Manage_employees.Member")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </>
          )}
        </Table>

        <div className="request-invite-archive mt-2">
          <div className="d-flex">
            <Button
              className="btn-sm ms-0 ps-3 pe-3 "
              onClick={() => {
                employeeCount !== 0 &&
                  navigate("/employer/employees/employee-request-approval");
              }}
              variant="outline-secondary"
            >
              {employeeCount} {t("Employer.Manage_employees.Requests_pending")}
            </Button>
            <Button
              className="btn-sm  ps-3 pe-3 "
              variant="outline-secondary"
              onClick={() => setShow((prev) => !prev)}
            >
              {t("Employer.Manage_employees.Generate_invite")}
            </Button>
            <Button
              className="btn-sm ps-3 pe-3 "
              variant="outline-secondary"
              onClick={() => navigate("/employer/employees/employee-archive")}
            >
              {t("Employer.Manage_employees.Archive")}
            </Button>
          </div>
          <div>
            {employeesList?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageEmployee;
