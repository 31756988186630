import React from "react";

import ModalDialog from "../../Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const prod_guid = new URLSearchParams(location.search).get("production_puid");
    const union_name = new URLSearchParams(location.search).get("union_name");
    const link_guid = new URLSearchParams(location.search).get("link_guid");
    const opt_out = new URLSearchParams(location.search).get("opt_out");

    return (
        <>
            <ModalDialog
                show={true}
                description={t("Confirmation.Funds_added")}
                footer={
                    <div className="group_buttons mt-2">
                        <Button
                            variant="info"
                            className="btn-lg"
                            onClick={() => navigate(`/redirect?production_puid=${prod_guid}&union_name=${union_name}&link_guid=${link_guid}&opt_out=${opt_out}`)}
                        >
                            {t("Confirmation.OK")}
                        </Button>
                    </div>
                }
            />

        </>
    )
}


export default PaymentSuccess