
const PublicRoutes = [
    "/login",
    "/",
    "/signup",
    "/employer/confirm-production",
    "/wrong-production",
    "/employee/confirm-production",
    "/union/confirm-production",
    "/reset_password",
    "/reset_link_expired",
    "/invite_link_expired",

];

const AdminRoutes = [
    "/admin/caregivers",
    "/admin/dashboard",
    // "/admin/employers",
    "/employers/add",
    "/admin/productions",
    "/admin/productions/productionFocused/:id",
    "/admin/productions/employerRequests",
    "/admin/productions/updateEmployer/:id",
    "/admin/caregivers/updateCaregiver/:id",
    "/employers/productionslist",
    "/employers/addproduction",
    "/admin/productions/archive-employers",
    "/admin/unions",
    "/admin/dashboard/notifications",
    "/admin/productions/finished-productions",
    "/admin/caregivers/cancelled-bookings",
    '/admin/generatereport',
    "/admin/payrollgenerate",
    "/admin/payrollapproval",
    "/admin/payrollgenerate/new",
    "/admin/payrollgenerate/view",
    "/admin/journals",
    "/admin/journals/new"
];

const EmployeeRoutes = [
    "/employee/dashboard",
    "/employee/productions",
    "/employee/productions/finished",
    "/employee/productions/focused-productions/:id",
    "/employee/requests",
    "/employee/requests/success/:id",
    "/employee/booking",
    "/employee/settings",
    "/employee/profile",
    "/employee/calendar",
    "/employee/dashboard/notifications",
    "/employee/booking/caregiverProfile/:id",
    "/employee/familymembers",
]
const EmployerRoutes = [
    "/employee/union-manage",
    "/employer/balances",
    "/employer/union-request-approval",
    "/employer/employee-request-approval",
    "/employer/update-union",
    "/employer/dashboard",
    "/employer/updateUnion",
    "/employer/unions",
    "/employer/employees",
    "/employer/employees-manage",
    "/employer/success_confirmation",
    "/employer/calendar",
    "/employer/calendar/crew_schedule",
    "/employer/calendar/crew_schedule/edit",
    "/employer/profile",
    "/employer/settings",
    "/employer/unions-archive",
    "/employer/employee-archive",
    "/employer/dashboard/notifications",
    "/employer/employees/familymembers"
]
const UnionRoutes = [
    "/union/dashboard",
    "/union/productions",
    "/union/productions/finished",
    "/union/productions/detail/:id",
    "/union/balances",
    "/confirmation",
    "/union/settings",
    "/union/success_confirmation",
    "/union/dashboard/notifications"
]
const CaregiverRoutes = [
    '/caregiver/profile',
    '/caregiver/dashboard',
    '/caregiver/requests',
    '/caregiver/calendar',
    "/caregiver/dashboard/notifications",
    "/caregiver/requests/view-profile"
]

const CaregiverSidebar = ['Dashboard', 'Calendar', 'Requests', 'Profile']
const AdminSidebar = ['Dashboard', "Productions", "Caregivers"]
const UnionSidebar = ['Dashboard', "Productions", "Balances", "Settings"]
const EmployerSidebar = ['Dashboard', "Calendar", "Balances",
    "Employees", "Unions", "Profile", "Settings"]
const EmployeeSidebar = ['Dashboard', "Productions", "Calendar","Requests", "Booking", "Profile", "Settings"]


export {
    PublicRoutes, AdminRoutes, EmployeeRoutes, CaregiverRoutes,
    EmployerRoutes, UnionRoutes, CaregiverSidebar,
    AdminSidebar, UnionSidebar,
    EmployeeSidebar, EmployerSidebar,
}
