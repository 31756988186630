import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Studio from "./studio";
import { productionUnionList } from "../../../../Services/https/Employer/Employer"; 
const Overview = ({data}) => {
  const { t } = useTranslation();
  const prodId = useParams();

  const [union,setUnion] =useState([])

  useEffect(() => {
      productionUnionList(prodId.id).then((response)=>{
        setUnion(response?.data?.data?.paginate?.data)
      })
  },[]);
  return (
    <>
      <div className="row setHeight">
        <div className="col-md-6  ">
          <h5 className="prod_description">
            {t("Employee.Focused-productions.Contact")}:
          </h5>

          <div className="row mb-2">
            {data &&
              data?.map((item, index) => {
                const phone =
                  item?.phone.toString().slice(0, 3) +
                  "-" +
                  item?.phone.toString().slice(3, 6) +
                  "-" +
                  item?.phone.toString().slice(6, 12);
                return (
                    <>
                  <div className="d-flex flex-column">
                    <span>{item?.name}</span>
                    <span>M: {phone}</span>
                  </div>
                  </>
                );
              })}
          </div>
        </div>

        <div className="col-md-6">
          <h5 className="prod_description">
            {t("Employee.Focused-productions.Unions")}:
          </h5>
          <div className="row">
            <div className="col-sm-12">
              {union && union?.map((item)=>
                   <li className="mb-1">{item?.union_name}</li> 
              )}
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
