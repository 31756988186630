import { axiosInstance } from "../interceptor"

export const pendingEmployeeRequest = async (prod_id, status,id) => {
    return await axiosInstance
        .get(`/request-count-production?production_id=${prod_id}&status=${status}&role_id=${id}`).then((response) => {
            return response
        })
        .catch((err) => {
            return err
        })
}

export const getNotificationsList=(langId)=>{
    return axiosInstance.get(`/notification?language_id=${langId}`)
}
export const countNotifications=()=>{
    return axiosInstance.get(`/count-unread-notification`)
}

export const unreadNotifications=(langId)=>{
    return axiosInstance.get(`/unread-notification?language_id=${langId}`)
}

export const readNotifications=(id,status)=>{
    return axiosInstance.put(`/notification/${id}`,{
        "is_read": status
      })
}

export const delNotification=(id)=>{
    return axiosInstance.delete(`/notification/${id}`)
}

export const clearNotifications=()=>{
    return axiosInstance.get(`/clear-all-notifications`)
}

export const getTimezone=()=>{
    return axiosInstance.get(`/timezone`)
}

export const getStyleOfCare=(id)=>{
    return axiosInstance.get(`production-style-care?production_id=${id}`)
}

export const getchildBreakdown=(id)=>{
    return axiosInstance.get(`childcare-breakdown?production_id=${id}`)
}

export const requestReport=(type,id,userid,date)=>{
    return axiosInstance.get(`download-report-request-production-union?production_id=${id}&type=${type}&user_id=${userid}&request_date=${date}`,{responseType:"blob"})
}

export const updateTimeZone=(data)=>{
    return axiosInstance.post(`update-profile-time-zone`,data)
}

export const uploadProfilePicture=(data)=>{
    return axiosInstance.post(`production/update-profile-image-info`,data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
}

export const refundProductionlist=()=>{
    return axiosInstance.get(`production-all`)
}
export const getBalance=(type,productionId,unionId)=>{
    return axiosInstance.get(`admin/refund?type=${type}&production_id=${productionId}&union_id=${unionId}`)
}

export const addjournal=(data)=>{
    return axiosInstance.post(`admin/refund`,data)
}

export const journallist=(perPage,page,sort)=>{
    return axiosInstance.get(`admin/refund/list?perpage=${perPage}&page=${page}&sort_by=${sort}`)
}



