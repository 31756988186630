import React from "react";
import { useNavigate } from "react-router-dom";

const NoProduction = () => {
    const navigate = useNavigate()
    return (
        <>

        

            <div className='dashboard_screen'>
                <div className="cheight_550 mt-4"> 
                    <div className="white_box ">
                        <div className="mt-5">
                        <p className="title_h3 text-center ">
                        Sorry, there is no any Production assigned to you.
                        </p>
                       
                        </div>
                        
          
                    </div>
                </div>

            </div>

        </>
    )
}

export default NoProduction