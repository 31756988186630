import React from 'react'

import { useTranslation } from 'react-i18next'

function EmployeeHide({ data }) {
    const { t } = useTranslation();
    
    return (
        <div className="Hide-employee">
            <div className='row mt-3'>
            </div>
            <div className="hide-description">
                <h6>
                    {t('Employer.Dashboard.Employees_hidden')}
                </h6>
            </div>
        </div>
    )
}

export default EmployeeHide