import React, { useState, useEffect } from "react";

import { Table, Button,Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";

import Modal from "../../shared/Modal";
import Loader from "../../shared/Loader";
import NoRecords from "../../shared/NoRecords";
import { dateFormat } from "../../shared/moment";
import { svg } from "../../assets/svg/svg";
import BookingDetails from "../../shared/bookingDetails";

import {
  updateRequests,
  CheckInCaregiver,
  CheckOutCaregiver,
  bookingRequests,
  cancelBefore,
} from "../../Services/https/Caregiver/Caregivers";


const AcceptedBookings = ({ data, load, change }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [Otp, setOtp] = useState({
    otp: "",
  });
  const [clear, setClear] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [reason,setReason]=useState("");
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const userDetail = data?.filter((item) => item?.id === id);
    setModalData(userDetail);
  }, [id]);


  const acceptedRequest = () => {
    bookingRequests(1)
    .then((response) => {
      setList(response?.data?.data);
      setLoader(false);
    })
    .catch((err) => {
      setLoader(false);
      return err;
    });
  }

const CheckIn = () => {
  setLoader(true);
      CheckInCaregiver(id, Otp)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setOtp("");
            setOpenOtpModal(false);
            acceptedRequest(); 
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
            setOtp("");
            setLoader(false);
          }
        });
}

const CheckOut = () => {
  setLoader(true)
      CheckOutCaregiver(id, Otp)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setOtp("");
            setOpenOtpModal(false);
            acceptedRequest()
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
            setOtp("");
            setLoader(false);
          }
        });
}

const getdata = (id) => {
  const getRecord = data?.find((records) => records?.id === id);
  setDetails(getRecord);
}

useEffect(() => {
  if (Object.keys(details)?.length >= 1) {
    setShowDetails(true)
  }
}, [details])



  useEffect(() => {
    setList(data);
  }, [data]);

  const handleChange = (otp) => {
    setOtp({
      otp: otp,
    });
  };

  const update = (requestid) => {
    change(true);
    const data = {
      current_date_time:moment().format("YYYY-MM-DD HH:mm:ss")
    }
    updateRequests(2, requestid,data)
      .then((response) => {
        toast.success(response?.data?.message);
        setShow(false);
        setConfirm(true);
        change(false);
      })
      .catch((err) => {
        setShow(false);
        setAlertModal(true);
        return err;
      });
  };

const cancelRequest= (id) => {
  setLoader(true)
  const data = {
    reason:reason
  }
  cancelBefore(2,id,data).then(response=>{
    setLoader(false);
    setAlertModal(false);
    acceptedRequest();
  }).catch(err=>{
    return err
  })
}

  return (
    <>
      {show && (
        <Modal
          show={show}
          modalTitle={t(
            "Caregiver.BookingRequests.AcceptedBookings.PopUpTitle"
          )}
          description={
            <>
              <p>
                {t("Caregiver.BookingRequests.AcceptedBookings.PopUpHeading") +
                  `${modalData[0]?.client_name}'` +
                  t(`Caregiver.BookingRequests.PendingRequests.Request`) +
                  dateFormat(modalData[0]?.dates,"YYYY-MM-DD HH:mm:ss",lang)
                  + 
                  t("Caregiver.Availability_calender.to") + " " + dateFormat(modalData[0]?.end_date,"YYYY-MM-DD HH:mm:ss",lang) +
                  t(`Caregiver.BookingRequests.PendingRequests.At`) +
                  modalData[0]?.location}
              </p>
            </>
          }
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => {
                  setShow(false);
                }}
              >
                {t("Caregiver.BookingRequests.AcceptedBookings.PopUpCancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  update(id);
                }}
              >
                {t("Caregiver.BookingRequests.AcceptedBookings.PopUpConfirm")}
              </Button>
            </div>
          }
        />
      )}
      {confirm ? (
        <Modal
          modalTitle={t(
            "Caregiver.BookingRequests.AcceptedBookings.PopUpAfterTitle"
          )}
          show={confirm}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setConfirm(false);
                }}
              >
                {t(
                  "Caregiver.BookingRequests.AcceptedBookings.PopUpAfterButton"
                )}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {openOtpModal && (
        <Modal
          show={openOtpModal}
          description={
            <>
              <p>
                {clear
                  ? `${t(
                      "Caregiver.BookingRequests.AcceptedBookings.CheckOutTitle"
                    )}` + `${modalData[0]?.client_name}`
                  : `${t(
                      "Caregiver.BookingRequests.AcceptedBookings.CheckInTitle"
                    )}` + `${modalData[0]?.client_name}`}
              </p>
            </>
          }
          field={
            <>
              <div className="d-flex justify-content-center  ">
                <OtpInput
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1rem",
                    fontSize: "2rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                  name="otp"
                  value={Otp.otp}
                  onChange={(e) => handleChange(e)}
                  numInputs={6}
                  separator={<span className="p-1"> - </span>}
                  isInputNum={true}
                />
              </div>
            </>
          }
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => {
                  setOpenOtpModal(false);
                  setOtp("");
                }}
              >
                {t("Caregiver.BookingRequests.AcceptedBookings.PopUpCancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  {
                    clear
                      ? CheckOut()
                      : CheckIn();
                  }
                }}
                disabled={
                  Otp?.otp?.length < 6 || Otp?.otp === undefined ? true : false
                }
              >
                {clear
                  ? t("Caregiver.BookingRequests.AcceptedBookings.CheckOut")
                  : t("Caregiver.BookingRequests.AcceptedBookings.CheckIn")}
              </Button>
            </div>
          }
        />
      )}
      {alertModal && (
        <Modal
          show={alertModal}
          modalTitle={t("Caregiver.BookingRequests.AcceptedBookings.PopUpTitle")}
          description={
           t("Caregiver.BookingRequests.AcceptedBookings.AlertMessage")
          }
          field={
            <>
               <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" rows={3} onChange={(e)=>setReason(e.target.value)}/>
      </Form.Group>
    </Form>
            </>
          }
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => {
                  setAlertModal(false);
                }}
              >
                {t("Caregiver.BookingRequests.AcceptedBookings.PopUpCancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                disabled={!reason}
                onClick={() => {cancelRequest(id)}} 
              >
                {t('Forgot_password.Submit')}
              </Button>
            </div>
          }
        />
      )}

      { list?.length===0 && !load ? (
        <div style={{ marginTop: "50px" }}>
          {" "}
          <NoRecords />
        </div>
      ) : loader ? (
        <Loader />
      ) : (
        <div style={{ paddingBottom: "0px", position: "relative" }}>
        <Table className="custom_table bookingRequests" responsive>
          <thead>
            <tr>
              <th>{t("Caregiver.BookingRequests.AcceptedBookings.Client")}</th>
              <th width="550px">
                {t("Caregiver.BookingRequests.AcceptedBookings.Dates")}
              </th>
              <th>{t("Caregiver.BookingRequests.AcceptedBookings.Detail")}</th>
              <th>{t("Caregiver.BookingRequests.AcceptedBookings.Action")}</th>
            </tr>
          </thead>

          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <b className="mb-5">{item?.client_name}</b>
                    <br />
                    <h5>
                      {item?.phone
                        ? item?.phone?.toString()?.slice(0, 3) +
                          "-" +
                          item?.phone?.toString()?.slice(3, 6) +
                          "-" +
                          item?.phone?.toString()?.slice(6)
                        : item?.email}
                    </h5>
                  </td>
                  <td>
                    <p>
                    {dateFormat(item?.dates,"YYYY-MM-DD HH:mm:ss",lang)}{" "}
                          - {dateFormat(item?.end_date,"YYYY-MM-DD HH:mm:ss",lang)}
                         ({item?.time_zone_code})
                    </p>
                    <h5 className="mt-3">{item?.location}</h5>
                  </td>
                  <td>
                  <div>{item?.price === "" ? "$0.00" : <label>${item?.price}</label>}{t('Caregiver.BookingRequests.Day')}</div>
                  <div style={{color:"#787878"}}>{t('Caregiver.BookingRequests.Or')}</div>
                        <div><span>${item?.hourly_rate}</span> {t('Caregiver.BookingRequests.Hour')}</div>
                    <div>{item?.fund}</div>
                    <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(item?.id)}>{svg["File"]}</span> </div>
                  </td>

                  <td>

                    <div className="buttonAlign">
                      {" "}
                      <span>
                        <Button
                          className="me-3 accept_action"
                          style={{ background: "#fff", color: "black" }}
                          onClick={() => {
                            setClear(item?.check_in_date_time ? true : false);
                            setOpenOtpModal(true);
                            setId(item?.id);
                          }}
                          disabled={
                            item?.check_in_date_time &&
                            item?.check_out_date_time
                              ? true
                              : false
                          }
                        >
                          {item?.check_in_date_time && item?.check_out_date_time
                            ? t(
                              "Caregiver.BookingRequests.AcceptedBookings.CheckedOut"
                            )
                            : item?.check_in_date_time
                            ? t(
                                "Caregiver.BookingRequests.AcceptedBookings.CheckOut"
                              )
                            : t(
                                "Caregiver.BookingRequests.AcceptedBookings.CheckIn"
                              )}
                        </Button>
                      </span>{" "}
                      {item?.check_in_date_time ? (
                        ""
                      ) : (
                        <span
                          className="decline_action"
                          onClick={() => {
                            setShow(true);
                            setId(item?.id);
                          }}
                        >
                          {t(
                            "Caregiver.BookingRequests.AcceptedBookings.PopUpCancel"
                          )}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
       {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails}/>}
        </div>
      )}
    </>
  );
};

export default AcceptedBookings;
