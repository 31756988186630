import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip } from "bootstrap";
import moment from "moment";

import Loader from "../shared/Loader";
import Dashboard from "../shared/Dashboard";
import Earnings from "./Earnings";
import Shifts from "./Shifts";

import { GetMonthData } from "../Services/https/Caregiver/Caregivers";

let tooltipInstance = null;

const DashBoard = () => {
  const tdArray = document.getElementsByClassName("fc-day-future");
  for (var i = 0; i <= tdArray.length; i++) {
    if (
      document.getElementsByClassName("fc-day-future")[i]?.childNodes[0]
        ?.childNodes[2]?.childNodes[0]?.childNodes[0]
    ) {
      document
        .getElementsByClassName("fc-day-future")
        [i]?.classList.add("td-class");
    }
  }

  const { t } = useTranslation();
  const localization = localStorage.getItem("i18nextLng");
  const { name } = useSelector((state) => state.login);
  const [loader, setLoader] = useState(false);
  const [eventsOfMonth, setEventsOfMonth] = useState([]);

  // Get monthly data
  const currentMonth = moment(new Date()).format("YYYY-MM-DD");
  useEffect(() => {
    fetchMonthlyData();
  }, []);

  const fetchMonthlyData = async () => {
    setLoader(true);
    await GetMonthData(currentMonth)
      .then((response) => {
        setEventsOfMonth(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        return error;
      });
  };

  // Calendar hover
  const handleMouseEnter = (arg) => {
    if (arg.event.extendedProps.start_time) {
      tooltipInstance = new Tooltip(arg.el, {
        title:
         moment(arg.event.extendedProps.start_time,"HH:mm:ss").format("hh:mm A")  +
          ` ${t("Caregiver.Availability_calender.to")} ` +
         moment(arg.event.extendedProps.end_time,"HH:mm:ss").format("hh:mm A") ,
        html: true,
        trigger: "hover",
        placement: "top",
        container: "body",
      });

      tooltipInstance.show();
    }
  };

  // Dispose tooltip
  const handleMouseLeave = (arg) => {
    setLoader(true);
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
      setLoader(false);
    }
  };
  return (
    <div>
      <div className="dashboard_screen">
        <h3 className=" title_h3">
          {t("Caregiver.Dashboard.Welcome")}, <span>{name}</span>
        </h3>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Dashboard
            title={t("Caregiver.Dashboard.Earning")}
            text_decorator="true"
            component={<Earnings />}
          />
        </div>

        <div className="col-lg-8 calender_wrapper2 mb-3 p-0 pb-1">
          <Dashboard
            text_decorator="true"
            title={t("Caregiver.Dashboard.Availability")}
            subtitle={t("Caregiver.Dashboard.Subtitle")}
            component={
              loader ? (
                <Loader />
              ) : (
                <FullCalendar
                  plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  locale={localization == "fr" && frLocale}
                  headerToolbar={{
                    left: "prev",
                    center: "title",
                    right: "next",
                  }}
                  lazyFetching={false}
                  datesSet={(e)=>{
                    setEventsOfMonth(eventsOfMonth)
                  }}
                  eventBackgroundColor={"#1e1e56"}
                  eventColor={"1e1e56"}
                  eventMouseEnter={handleMouseEnter}
                  eventMouseLeave={handleMouseLeave}
                  themeSystem="Simplex"
                  events={eventsOfMonth}
                />
              )
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Dashboard
            text_decorator="true"
            title={t("Caregiver.Dashboard.Upcoming")}
            component={<Shifts />}
          />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
