import React from 'react'
import { useTranslation } from 'react-i18next'

function UnionHide
() {
    const {t} = useTranslation()
    return (
        <div className="Hide-employee">
            <div className="hide-description">
                <h6>
                {t('Employer.Dashboard.Employees_hidden')}
                </h6>
            </div>
        </div>
    )
}

export default UnionHide
