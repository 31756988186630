import React, { useEffect, useState } from "react";

import {Button,Form,Table} from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "../shared/Modal";
import NoRecords from "../shared/NoRecords";
import Loader from "../shared/Loader";

import { svg } from "../assets/svg/svg";

import {
  ArchivedCaregiverListing,
  restoreprod,
} from "../Services/https/Admin/Admin";


const ArchiveCaregivers = () => {
  const [show, setShow] = React.useState(false);
  const [popUp, setPopUp] = React.useState(false);
  const [del, setDel] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [id, setId] = useState(null);
  const [caregivers, setCaregivers] = useState([]);
  const [sort, setSort] = useState("");
  const [showNoRecord, setShowNoRecord] = useState(false);
  const [searchkeyword, setSearchkeyword] = useState("");
  const [handlesearch, sethandleSearch] = useState(false);
  const [totalcount, setTotalcount] = useState(null);
  const [initialpage, setInitialpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [restore, setRestore] = useState(false);
  const pageCount = Math.ceil(totalcount / 10);
  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Archived Caregiver Listing

  useEffect(() => {
    setLoading(true);
    getArchivedCaregivers();
  }, [sort, handlesearch]);

  const getArchivedCaregivers = () => {
    ArchivedCaregiverListing(searchkeyword, sort)
    .then((response) => {
      if (response?.status === 200) {
        setLoading(false);
        setCaregivers(response?.data?.data?.data);
        setShowNoRecord(true);
      }
    })
    .catch((error) => {
      return error;
    });
  }

  return (
    <>
      {show && (
        <Modal
          show={show}
          modalTitle={t("Admin.ManageEmployeer.Modal.Title")}
          description={
            t("Admin.ManageEmployeer.Modal.Heading.start") +
            "_" +
            t("Admin.ManageEmployeer.Modal.Heading.end")
          }
          setShow={setShow}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("Admin.ManageEmployeer.Modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setShow((prev) => !prev);
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.Modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}
      {del ? (
        <Modal
          title={t("Admin.ManageEmployeer.ModalDelAfter.Title")}
          show={del}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {popUp ? (
        <Modal
          show={popUp}
          title={t("Admin.ManageEmployeer.ModalGenerateInvite.Title")}
          field={
            <>
              <div className="text-center w100p font">
                <p style={{ textAlign: "center" }}>
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Heading")}
                </p>
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-8 invitelink">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input1"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input2heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input2"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input3heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input3"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input4heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input4"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          footer={
            <>
              {" "}
              <div className="group_buttons mt-5">
                <Button
                  variant="outline-info"
                  className="btn-lg"
                  onClick={() => setPopUp((prev) => !prev)}
                >
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                </Button>
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setPopUp((prev) => !prev);
                    setInvite((prev) => !prev);
                  }}
                >
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.SendInvite")}
                </Button>
              </div>
            </>
          }
        />
      ) : null}
      {invite ? (
        <Modal
          title={t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Title")}
          show={invite}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setInvite((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}

      <Modal
        onHide={() => setRestore(false)}
        show={restore}
        modalTitle={t(
          "Employer.Employee_requests_approval.Restore_confirmation"
        )}
        footer={
          <div className="group_buttons mt-2">
            <Button
              variant="outline-info"
              className="btn-lg"
              onClick={() => setRestore((prev) => !prev)}
            >
              {t("Employer.Add_employee.Delete_modal.Cancel")}
            </Button>
            <Button
              variant="info"
              className="btn-lg"
              onClick={() => {
                restoreprod(id)
                  .then((response) => {
                    toast.success(response?.data?.message);
                    setRestore((prev) => !prev);
                    getArchivedCaregivers();
                  })
                  .catch((err) => {
                    return err;
                  });
              }}
            >
              {t("Employer.Add_employee.Delete_modal.Confirm")}
            </Button>
          </div>
        }
      />

      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">
            {t("Admin.ManageCaregiver.Archived_caregivers")}
          </h3>
          <div className="search_box  d-flex ms-3">
            <div className="search_box_in">
              <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }}>{svg["Search"]}</span>
              <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>{" "}
            &nbsp; &nbsp;
            <div className="manage_employeer_dropdown">
              <div className="common_select">
                <span className="arrange">
                  {t("Admin.Dashboard.Dropdown.Sort")}
                </span>
                <select
                  className="admin-dashboard-dropdown"
                  name="language"
                  id="language"
                  selected="Studio"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="first_name">
                    {t("Admin.Dashboard.Dropdown.First_name")}
                  </option>
                  <option value="last_name">
                    {t("Admin.Dashboard.Dropdown.Last_name")}
                  </option>
                  <option value="experience_level_value">{t("Admin.Dashboard.Dropdown.Experience")}</option>
                  <option value="city">
                    {t("Admin.Dashboard.Dropdown.City")}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Table className="custom_table" responsive>
             {caregivers?.length === 0 && showNoRecord ? (
              <NoRecords />
            ) : (
              <>
            <thead>
              <tr>
                {/* {arechieve ? null : <th width="50px"></th>} */}

                <th>{t("Admin.ManageCaregiver.Table.LastName")}</th>
                <th>{t("Admin.ManageCaregiver.Table.FirstName")}</th>
                <th>{t("Admin.ManageCaregiver.Table.Experience")}</th>
                <th>{t("Admin.ManageCaregiver.Table.Rate")}</th>
                <th>{t("Admin.ManageCaregiver.Table.Service")}</th>
                <th width="200px">{svg["Edit"]}</th>
              </tr>
            </thead>
            <tbody>
              {caregivers?.map((list, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>
                        <b>{list?.last_name}</b>
                      </td>
                      <td>
                        <b>{list?.first_name}</b>
                      </td>
                      <td>{list?.experience_level_value}</td>
                      <td>{list?.hourly_rate}</td>
                      <td></td>

                      <td>
                        <a
                          className="link"
                          onClick={() => {
                            setId(list?.user_id);
                            setRestore(true);
                          }}
                        >
                          {t('Employer.Employee_archive.Restore')}
                        </a>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            </>
            )}
          </Table>
        )}

        <Button
          onClick={() => navigate("/admin/caregivers")}
          className="btn-sm ms-0 ps-3 pe-3"
          style={{ float: "right" }}
          variant="outline-secondary"
        >
          <strong>{t("Admin.ManageCaregiver.cargiver_list")}</strong>
        </Button>
      </div>
    </>
  );
};

export default ArchiveCaregivers;
