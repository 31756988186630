import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { updateCaregiverDetail } from "../../Services/https/Admin/Admin";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Detail from "./Detail";
import Qualifications from "./Qualifications";
import Rates from "../../Caregiver/profile/Rates";

const UpdateCaregiver = () => {
  const [fname, setFname] = useState(null);
  const [fname2, setFname2] = useState(null);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(true);
  const [validate, setValidate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accepted, setAccepted] = useState(false);
  const [pending, setPending] = useState(true);
  const [initial, setInitial] = useState(true);

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // let params = serializeFormQuery(searchParams);
      // setSearchParams(params);
    }

    setValidated(true);
  };

  return (
    <div className="white_box manage_screen">
      <div className="title_box d-inline-flex w100p">
        <h3 className="title_h3">{t("Admin.UpdateEmployeer.Title2")}</h3>
      </div>
      <p style={{ fontSize: "18px", fontWeight: "400" }}>
        {t("Admin.UpdateEmployeer.EditDetail2")}
      </p>

      <div className="employee-booking-cards">
        <div className="row ">
          <div className="col-xl-3">
            <Button
              onClick={() => {
                setPending(true);
                setAccepted(false);
              }}
              className={pending ? "employee-btn-active" : null}
            >
              {t("Admin.UpdateEmployeer.Details.Title")}
            </Button>
          </div>
          <div className="col-xl-3">
            <Button
              className={!pending ? "employee-btn-active" : null}
              onClick={() => {
                setPending(false);
                setAccepted(true);
                setInitial(false);
              }}
            >
              {t("Admin.UpdateEmployeer.Additional.Title")}
            </Button>
          </div>
        </div>
      </div>
      {accepted && <Rates />}
      {pending && <Detail />}
    </div>
  );
};

export default UpdateCaregiver;
