import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'

import { productionUnionList } from '../Services/https/Employer/Employer';

function Overview({ data, prodid }) {
    const { t } = useTranslation();
    const [unions, setUnions] = useState([])

    useEffect(() => {
        productionUnionList(prodid).then(response => {
            setUnions(response?.data?.data?.paginate?.data)
        }).catch(err => {
            return err
        })
    }, [])

    return (
        <>
            <li>  <h4>{t('Union.Production_detail.Production')} <br />{t('Union.Production_detail.Contact')}:</h4></li>
            <li>   <h6>{data[0]?.name}</h6></li>
            <li>   <h4>{t('Union.Production_detail.Unions')}:</h4></li>

            {
                unions && unions?.map((list, index) => {
                    return (
                        <li>{list?.union_name}</li>
                    )
                })
            }

        </>
    )
}

export default Overview