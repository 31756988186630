import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  production_id:null
}

export const Union = createSlice({
  name: 'union',
  initialState,
  reducers: {
    UnionState: (state, action) => {
      return {
        ...state,
        production_id: action.payload.production_id,
      }
    },
  },
})

export const { UnionState } = Union.actions

export default Union.reducer