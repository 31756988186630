import React from "react";

import { useTranslation } from "react-i18next";

import Logo from "../assets/Images/reelkids_logo_Dark-svg.svg"

const InviteLinkExpired = () => {
  const { t } = useTranslation();
  return (
    <section className="auth_layout login_screen">
      <div className="left_box"></div>
      <div className="right_box">
      <div className="right_box_cont">
        <a href="/" className="logo">
          <img src={Logo} />
        </a>
        <h3 className="title_h3">{t("InviteLinkExpired.Title")}</h3>
        <div className="auth_form">
          <h3 style={{ fontSize: "20px" }}>{t("InviteLinkExpired.Para")}</h3>
        </div>
      </div>
      </div>
    </section>
  );
};

export default InviteLinkExpired;
