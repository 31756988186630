import React,{ useState,useEffect }  from 'react'

import { useParams } from 'react-router-dom';

import { getStyleOfCare } from '../Services/https/comman/common';

function StyleOfCare() {
const [style,setStyle]=useState(null);
const params =useParams()

useEffect(()=>{
  getStyleOfCare(params?.id).then(response=>{
    setStyle(response?.data?.data?.care_style?.split(','))
  }).catch(err=>{
    return err
  })
},[])

  return (
    <div>
      {
        style?.map((list,index)=>{
          return(
              <li key={index}>{list}</li>
          )
        })
      }
      </div>
  )
}

export default StyleOfCare