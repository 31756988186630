import axios from 'axios' 
import { inviteEmail } from '../../Reducers/EmailInvite/inviteSlice';
import { loginState } from '../../Reducers/Login/loginSlice';
import { EmployeeState } from '../../Reducers/EmployeeReducer/EmployeeSlice';
import { store } from '../../store';
import { GetCookie } from '../Cookiees';
import { Decrypt } from '../EncryptDecrypt';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../storage';
import { UnionState } from '../../Reducers/UnionReducer/unionSlice';
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async config => {
  const token = getLocalStorage('access_token') && getLocalStorage('access_token')
  const lang = localStorage.getItem('i18nextLng')
  config.baseURL = process.env.REACT_APP_API_URL
  config.headers = {
    'X-Localization': `${lang === 'fr' ? 'fr-FR' : 'en'}`,
    "Cache-Control":"no-cache"
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
}, error => {
  Promise.reject(error)
})
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(async (response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          originalRequest.headers["Cache-Control"]="no-cache"
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
    originalRequest._retry = true;
    isRefreshing = true;
    const refresh_token = getLocalStorage('refresh_token')
    const loginData =  JSON.parse(JSON.parse(getLocalStorage("persist:root"))?.login);
    const data={
      refresh_token:refresh_token,
      user_id:loginData?.userid 
    }
    return new Promise(function (resolve, reject) {
      axiosInstance.post('refresh-token', data).then((response) => {
        const access_token = response.data.data.access_token
        const refresh_token = response.data.data.refresh_token
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
        originalRequest.headers['Authorization'] = 'Bearer ' + access_token
        originalRequest.headers["Cache-Control"]="no-cache"
        setLocalStorage('access_token', access_token)
        setLocalStorage('refresh_token', refresh_token)
        processQueue(null, access_token);
        resolve(axios(originalRequest));
      })
        .catch(err => {
          const cookieData = GetCookie('re_used')
          if (cookieData) {
            const userDetails = Decrypt(cookieData)
            const data = userDetails[0]
            return new Promise(function (resolve, reject) {
              axiosInstance.post('sign-in', data).then((response) => {
                const access_token = response.data.data.access_token
                const refresh_token = response.data.data.refresh_token
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
                originalRequest.headers['Authorization'] = 'Bearer ' + access_token
                originalRequest.headers["Cache-Control"]="no-cache"
                setLocalStorage('access_token', access_token)
                setLocalStorage('refresh_token', refresh_token)
                processQueue(null, access_token);
                resolve(axios(originalRequest));
              })
            })
          }
          else {
            processQueue(err, null);
            const loginData = JSON.parse(getLocalStorage("persist:root"))?.login;
            const loginDataParsed = JSON.parse(loginData);
            store.dispatch(loginState({...loginDataParsed, ...{ loggedIn: false, role: null, name: null,userid:null,timezone:null,timezonevalue:null,role_data:null }}));
            store.dispatch(inviteEmail({email: null,
              role:null,
              production_name:null,
              studio_name:null,
              id:null,
              prod_id:null,
              production_id:null,
              redirectURL:null}));
              store.dispatch(EmployeeState({productionId:null}));
              store.dispatch(UnionState({production_id:null}))
            clearLocalStorage()
            // DeleteCookie('re_used')
            reject(err);
          }
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
});   