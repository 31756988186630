import React from "react";

import { svg } from "../../assets/svg/svg";

const BookingDetails = ({ details, show, setShow, setDetails }) => {
    return (
        <>
            <div className="req-details-popup">

                <div onClick={() => { setShow(!show); setDetails({}) }} className="d-flex justify-content-end">
                    {svg["Close"]}
                </div>
                <div className="req-details-headings">
                    Care Needs
                </div>

                <p>{details?.care_needs}</p>

                <div>
                    <section className="req-details-headings">No. of Children</section>

                    {details?.number_of_kids_ids && details?.number_of_kids_ids?.map((record, index) => {
                        return (
                            <React.Fragment key={index}>
                            <section>{record?.first_name} {record?.last_name} - Age {record?.age}</section>

                            </React.Fragment>
                        )
                    })}

                </div>
            </div>

        </>
    )
}

export default BookingDetails