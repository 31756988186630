import React, { useState ,useEffect} from "react";

import { Button,Form,Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { cancelrequest, employerrequests, resendrequest } from "../Services/https/Admin/Admin";

import Modal from '../shared/Modal'
import Pagination from "../shared/Pagination";
import Loader from "../shared/Loader";
import NoRecords from "../shared/NoRecords";
import { svg } from "../assets/svg/svg";

const EmployeerRequests = () => {
  const { t } = useTranslation();
  const navigate =useNavigate();
  const [showCancel, setShowCancel] = useState(false)
  const [id, setId] = useState(null)
  const [showResend, setShowResend] = useState(false)
  const [search, setSearch] = useState('');
  const [handlesearch, sethandleSearch] = useState(false)
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const [requests, setRequests] = useState([])
  const pageCount = Math.ceil(totalcount / 10);
  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setLoader(true);
    getRequestList();
  }, [handlesearch, initialpage])

  const getRequestList = () => {
    employerrequests(search, initialpage, value).then(response => { setRequests(response?.data?.data?.data); setTotalcount(response?.data?.data?.total); setLoader(false) }).catch(err => {
      setLoader(false)
      return err
    })
  }
  
  const cancelRequest = (id) => {
    cancelrequest(id).then(response => {
      toast.success(response?.data?.message);
      getRequestList();
      setLoader(false)
    }).catch(err => {
      return err
    })
  }
  const resendRequest = (email) => {
    setShowResend(false)
    if (email) {
      resendrequest(email).then(response => {
        toast.success(response?.data?.message)
      }).catch(err => {
        return err
      })
    }
  }
  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };
  return (
    <>
      <Modal onHide={() => setShowCancel(false)} show={showCancel} modalTitle={t("Employer.Employee_requests_approval.Cancel_confirmation")} footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowCancel((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              setShowCancel((prev) => !prev);
              cancelRequest(id);
              setLoader(true)

            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      } />
      <Modal show={showResend} onHide={() => setShowResend(false)} modalTitle={t("Employer.Employee_requests_approval.Resend_confirmation")} footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowResend((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              // setShowResend((prev) => !prev);
              resendRequest(email)


            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      } />
      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">{t('Admin.EmployerRequests.Title')}</h3>
          <div className="search_box d-flex ms-auto">
            <div className="search_box_in">
            <span onClick={() => { sethandleSearch((prev) => !prev); setLoader(true) }}>{svg["Search"]}</span>  
              <Form.Control type="text" placeholder={t('Admin.EmployerRequests.Search')} onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoader(true);
                  }
                }} />
            </div>{" "}
            &nbsp; &nbsp;
            <div className="manage_employeer_dropdown">

               <div className="common_select">
                <span className='arrange'>{t("Admin.Dashboard.Dropdown.Sort")}</span>
                <select
                  className="admin-dashboard-dropdown"
                  name="language"
                  id="language"
                  selected="Studio"
                  onChange={(e) => { setValue(e.target.value); sethandleSearch((prev) => !prev) }}
                >
                  <option value="studio_name" >
                    {t("Admin.Dashboard.Dropdown.Studio")}
                  </option>
                  <option value="production_name">
                    {t("Admin.Dashboard.Dropdown.Production")}
                  </option>
                  
                <option value="email">
                  EMAIL
                </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        {
          loader? <Loader/>
          :
          requests?.length==0 ? <NoRecords/> : 

          <div>
          <Table className="custom_table" responsive>
            <thead>
              <tr>
                <th>{t('Employee.Productions.Email')}</th>
                <th>{t('Admin.EmployerRequests.Table.Production')}</th>
                <th>{t('Admin.EmployerRequests.Table.Studio')}</th>
                <th width="200px">
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8719 3.89995L23.1 8.12807C23.2781 8.3062 23.2781 8.59682 23.1 8.77495L12.8625 19.0124L8.5125 19.4953C7.93125 19.5609 7.43906 19.0687 7.50469 18.4874L7.9875 14.1374L18.225 3.89995C18.4031 3.72183 18.6938 3.72183 18.8719 3.89995ZM26.4656 2.82651L24.1781 0.539014C23.4656 -0.173486 22.3078 -0.173486 21.5906 0.539014L19.9313 2.19839C19.7531 2.37651 19.7531 2.66714 19.9313 2.84526L24.1594 7.07339C24.3375 7.25151 24.6281 7.25151 24.8063 7.07339L26.4656 5.41401C27.1781 4.69683 27.1781 3.53901 26.4656 2.82651ZM18 16.2281V20.9999H3V5.99995H13.7719C13.9219 5.99995 14.0625 5.93901 14.1703 5.83589L16.0453 3.96089C16.4016 3.60464 16.1484 2.99995 15.6469 2.99995H2.25C1.00781 2.99995 0 4.00776 0 5.24995V21.7499C0 22.9921 1.00781 23.9999 2.25 23.9999H18.75C19.9922 23.9999 21 22.9921 21 21.7499V14.3531C21 13.8515 20.3953 13.6031 20.0391 13.9546L18.1641 15.8296C18.0609 15.9374 18 16.0781 18 16.2281Z"
                      fill="#787878"
                    />
                  </svg>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                requests?.map((list, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <b>{list?.email}</b>
                        </td>
                        <td>{list?.production_name}</td>
                        <td>
                          {list?.studio_name}
                        </td>
                        <td>
                          <div className="action_button">
                            <a onClick={() => { setShowCancel(true); setId(list?.linkguid) }} className="link " style={{ color: "#DC6962" }}>
                              {t('Admin.EmployerRequests.Cancel')}
                            </a>
                            <a onClick={() => { setShowResend(true); setEmail(list?.linkguid) }} className="link" style={{ color: "#038900" }}>
                              {t('Admin.EmployerRequests.Resend')}
                            </a>
                          </div>

                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
              }


            </tbody>
          </Table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
            <Button
              type="button"
              className="btn-sm ms-2 ps-3 pe-3 "
              variant="outline-secondary"
              onClick={() => navigate("/admin/productions")}
            >
              {t("Admin.Archived.Employer_list")}
            </Button>
            </div>
            <div>
            {requests?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
            </div>
            
          </div>
        </div>
        }
        
      </div>
    </>
  );
};

export default EmployeerRequests;
