import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ModalDialog from "../shared/Modal";
import { useEffect } from "react";
import { employeedetail, updateemployee } from "../Services/https/Employer/Employer";
import Loader from "../shared/Loader";
import toast from "react-hot-toast";

function AddEmployer() {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [employee, setEmployee] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [preview,setPreview]=useState(null)
  const navigation = useNavigate()
  const { state } = useLocation();
  const [errorMessage, setErrorMessage] = useState({})
  const [loader, setloader] = useState(true)

  const navigate = (address) => {
    navigation(`${address}`)
  }
  useEffect(() => {
    employeedetail(state?.id).then(response => {
      setEmployee(response?.data?.data)
      setloader(false)
    }).catch(err => {
      return err
    })
  }, [loader])

  useEffect(() => {
    Object.keys(errorMessage).map(key => {
      toast.error(errorMessage[key])
    })
  }, [errorMessage])

  const updateSchema = Yup.object().shape({
    first_name: Yup.string().required(`${t("UpdateSchema.Required")}`),
    last_name: Yup.string()
      .required(`${t("UpdateSchema.Required")}`).nullable(),
    email: Yup.string()
      .email(`${t("Admin.UpdateEmployeer.Details.Validations.Email")}`)
      .required(`${t("UpdateSchema.Required")}`),
    phone: Yup.string().required(`${t("UpdateSchema.Required")}`),
    studio_name: Yup.string().required(`${t("UpdateSchema.Required")}`),
  });


  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }

  const changeAvatar = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl)
    setAvatar(e.target.files[0])
  }
  return (



    <div className="white_box manage_screen">
      {loader && <Loader />}
      <div className="heading_box mt-3">
        <h3 className="title_h3 mt-2">{state?.name}</h3>
      </div>
      <div className="d-flex align-items-center">
        <div className="me-3" >
          <img src={preview ? preview: employee?.profile_image_url} alt="no-preview" style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
        </div>
        <div className=" profile-pic-text" >
          <label for="files" style={{ cursor: "pointer" }}>{t('Employer.Add_employee.profile_photo')}</label>
          <input id="files" style={{ visibility: "hidden" }} type="file" onChange={(e) => changeAvatar(e) } />
        </div>
      </div>
      <Formik
        initialValues={{
          studio_name: employee?.studio_name,
          first_name: employee?.first_name,
          last_name: employee?.last_name,
          role: employee?.production_employee_role =="null" ? "" : employee?.production_employee_role,
          phone: employee?.phone,
          email: employee?.email,
          file: avatar

        }}
        enableReinitialize={true}
        validationSchema={updateSchema}
        onSubmit={(values) => {
          setloader(true)
          const formdata = new FormData();
          formdata.append('first_name', values.first_name);
          formdata.append('last_name', values?.last_name);
          formdata.append('email', values?.email);
          formdata.append('phone', values?.phone?.toString().replace(/\D+/g, ""));
          formdata.append('studio_name', values?.studio_name);
          formdata.append('production_employee_role', values?.role);
          formdata.append('_method', 'PUT');
          avatar && formdata.append('profile_image_url', avatar);
          updateemployee(state?.id, formdata).then(response => {
            toast.success(response?.data?.message)
            setloader(false)
          }).catch(err => {
            setErrorMessage(err.response.data.message)
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          
          /* and other goodies */
        }) => (
          <form className="form_control" onSubmit={handleSubmit}>
            <div className="row mt-5">
              <div className="col-sm-4">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.First_name')}</Form.Label>
                  <Form.Control type="text" placeholder="" name="first_name" id="first_name" defaultValue={employee?.first_name}  onChange={handleChange}
                    onBlur={handleBlur} value={values.first_name}/>
                </Form.Group>
                {errors.first_name && (
                  <div className="error">
                    {touched.first_name && <>{errors.first_name}</>}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Last_name')}</Form.Label>
                  <Form.Control type="text" placeholder="" name="last_name" id="last_name" autoComplete="off" defaultValue={employee?.last_name}  onChange={handleChange} value={values.last_name}
                    onBlur={handleBlur} />
                </Form.Group>
                {errors.last_name && (
                  <div className="error">
                    {touched.last_name && <>{errors.last_name}</>}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Email')}</Form.Label>
                  <Form.Control type="text" placeholder="" name="email" id="email" defaultValue={employee?.email} onChange={handleChange} value={values.email}
                    onBlur={handleBlur} />
                </Form.Group>
                {errors.email && (
                  <div className="error">
                    {touched.email && <>{errors.email}</>}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Phone')}</Form.Label>
                  {/* <Form.Control type="text" placeholder="" name="phone" id="phone" defaultValue={employee?.phone} onChange={handleChange} value={values.phone}
                    onBlur={handleBlur} />

                </Form.Group> */}
                 <PhoneInput
                      name="phone"
                      id="phone"
                      maskChar={null}
                      defaultValue={employee?.phone}
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="778-XXX-XXXX"
                     
                    />
                     </Form.Group>
                {errors.phone && (
                  <div className="error">
                    {touched.phone && <>{errors.phone}</>}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-9">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Current_production')}</Form.Label>
                  {/* <Form.Select
                className="form-control"
                aria-label="Diary of a Wimpy Kid"
              >
                <option disabled selected>
                  Diary of a Wimpy Kid
                </option>
              </Form.Select> */}
                  <Form.Control type="text" placeholder="" disabled name="current_production" id="current_production" defaultValue={employee?.current_production_name} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Studio')}</Form.Label>
                  <Form.Control type="text" placeholder="" name="studio_name" id="studio_name" disabled defaultValue={employee?.studio_name} onChange={handleChange} value={values.studio_name}
                    onBlur={handleBlur} />
                </Form.Group>
                {errors.studio_name && (
                  <div className="error">
                    {touched.studio_name && <>{errors.studio_name}</>}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <Form.Group className="form-group" >
                  <Form.Label>{t('Employer.Add_employee.Role')}</Form.Label>
                  <Form.Control type="text" placeholder="" name="role" id="role"  onChange={handleChange}
                    defaultValue={employee?.production_employee_role}
                    value={values.role}
                    onBlur={handleBlur} />
                </Form.Group>

              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-9">
                <div className="d-flex justify-content-end">
                <div className="button-group ">
                  <Button className="btn-secondary me-2 mt-0" onClick={() => { setShow(true) }}>
                    {t('Employer.Add_employee.Delete')}
                  </Button>
                  <Button type="submit" variant="info" className="btn-add mt-0">
                    {t('Employer.Add_employee.Add')}
                  </Button>
                </div>
                </div>
                
              </div>
            </div>
          </form>
        )}

      </Formik>


      {show && <ModalDialog show={show} modalTitle={t('Employer.Add_employee.Delete_modal.Confirmation')} description={`${t('Employer.Add_employee.Delete_modal.Delete_user')} 'John Doe' ${t('Employer.Add_employee.Delete_modal.Delete_list')}`} footer={
        <div className="group_buttons mt-5">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShow((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              setShow((prev) => !prev);
            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>} />}
    </div>
  );
}

export default AddEmployer;
