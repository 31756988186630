import React, { useEffect, useState } from "react";

import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { loginState } from "../Reducers/Login/loginSlice";
import { getTimezone, updateTimeZone } from "../Services/https/comman/common";

const UserTimezone = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [timezone, setTimeZone] = useState({
        name: "",
        code: ""
    })
    const [timezones, setTimeZones] = useState([]);
    const logindata = useSelector(state => state.login);

    useEffect(() => {
        getTimezone()
            .then((response) => {
                setTimeZones(response?.data?.data);
            })
            .catch((err) => {
                return err;
            });
    }, [])

    const CustomStyle = {
        option: (base, state) => ({
            ...base,
            textAlign: "left",
            color: "black",
            fontSize: "15px"
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            "&:hover": {
                backgroundColor: "transparent ",
                border: "none "
            },
            height: "50px",
            border: "none !important",
            borderRadius: "8px",
            boxShadow: 'none !important',
            backgroundColor: "transparent !important",
            width: "160px",
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: location?.pathname !== "/employee/requests" && "#dc6962"
        }),
        indicatorSeparator: () => { },
    };

    useEffect(() => {
        const data = {
            time_zone: timezone?.name
        }
        if (timezone?.name) {
            updateTimeZone(data).then(response => {
                dispatch(loginState({ ...logindata, ...{ timezone: timezone?.name, timezonevalue: timezone?.code } }))
            }).catch(err => {
                return err
            })
        }
    }, [timezone?.name])

    return (
        <>
            <div>
                <p className={location?.pathname === "/employee/requests" ? " timezone_block" : location?.pathname === "/caregiver/calendar" ? "modal_timezone_block " : "timezone_block timezone_text"}> <span className=""> {t('CurrentTimezone') + logindata?.timezone}  </span> .  <span ><ReactSelect
                    name="timezone"
                    value={{
                        label: t('Click'),
                        name: t('Click')
                    }}
                    options={timezones?.map((list, index) => {
                        return {
                            label: list?.timezone_name,
                            name: list?.timezone_name,
                            value: list?.timezone_name_value,
                            code: list?.code_name
                        };
                    })}
                    styles={CustomStyle}
                    onChange={(option) => {
                        setTimeZone({ name: option?.name, code: option?.code });
                    }}
                    className="basic-multi-select "
                    classNamePrefix="select"
                    placeholder={
                        <div style={{ textAlign: "left", color: "#dc6962" }} >
                            Click here
                        </div>
                    }
                /></span><span>{t('Change')}.</span> </p></div>

        </>
    )
}

export default UserTimezone;