import React, { useState ,useEffect} from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

import { employeeEvents, nextBookings } from "../../../Services/https/Employee/Employee";

const EmpCalender = ({ nextBooking }) => {
  const { t } = useTranslation();
  const [eventlist, setEventList] = useState([]);
  const localization = localStorage.getItem("i18nextLng");
  const [bookings,setBookings]=useState([])
  const employyeprodId = useSelector((state) => state.employee.productionId);
  const {timezonevalue}=useSelector(state=>state?.login)

  useEffect(()=>{
    const date=moment().format("YYYY-MM-DD")
    employeeEvents(employyeprodId, date)
        .then((response) => {
          setEventList(response?.data?.data?.eventList);
        })
        .catch((err) => {
          return err;
        });

  },[employyeprodId])

  useEffect(()=>{
    nextBookings().then(response=>{
      setBookings(response?.data?.data)
    }).catch(err=>{
      return err
    })

  },[])

  return (
    <>
      <FullCalendar
                  plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  locale={localization == "fr" && frLocale}
                  headerToolbar={{
                    start: '',
                    left: "title",
                    end: ''
                  }}
                  lazyFetching={false}
                  themeSystem="Simplex"
                  events={eventlist}
                />
      

      <div className="mt-5">
      {nextBooking && <>
        <p className="mt-3 fsize_18 booking title_h4_line">{t('Employee.Dashboard.Next_booking')} </p>
        {/* <div className="mb-4">March 5, 2022</div> */}
        <div className="row">
        {
          bookings?.slice(0,2)?.map((list,index)=>{
            return(
        <React.Fragment key={index}>
          <div className="col-xl-6">
            <p className="fsize_16">
            <span className="bname">{list?.employee_name}</span>  : <span className="b_timing">{moment(list?.booking_start_date_time,"YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY hh:mm A")} - {moment(list?.booking_end_date_time,"YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY hh:mm A")} ({timezonevalue})</span>{" "}
            </p>
          </div>
          </React.Fragment>
       
            )
          })
        }
 </div>
        
      </>}
      </div>
      

    </>
  );
};

export default EmpCalender;
