import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { linkexpire } from "../Services/https/Admin/Admin";
import { employeelinkExpire } from "../Services/https/Employer/Employer";
import { checkLinkExpiration } from "../Services/https/Auth/Auth";

import { inviteEmail } from "../Reducers/EmailInvite/inviteSlice";

const Temp = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = (address) => {
    navigation(`${address}`);
  };

  const token = new URLSearchParams(location.search).get("token");
  const queryParamLanguage = new URLSearchParams(location.search).get(
    "language"
  );
  const id = new URLSearchParams(location.search).get("id");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const redirectURL = new URLSearchParams(location.search).get("redirect");
  const link_guid = new URLSearchParams(location.search).get("id");
  const proid = new URLSearchParams(location.search).get("proid");
  const union_name = new URLSearchParams(location.search).get("unionname");
  const invite_type_id = new URLSearchParams(location.search).get(
    "invite_type_id"
  );
  localStorage.setItem("token", token);

  useEffect(() => {
    if (queryParamLanguage === "fr-FR") {
      i18n.changeLanguage(`fr`);
    } else if (queryParamLanguage === "en") {
      i18n.changeLanguage(`en`);
    }

    //Employer invite data

    if (location?.pathname === "/processinvite") {
      linkexpire(id)
        .then((response) => {
          if (response?.status === 200) {
            const email = response?.data?.data?.email;
            const role = response?.data?.data?.role_id;
            const production_name = response?.data?.data?.production_name;
            const studio_name = response?.data?.data?.studio_name;
            const prod_id = response?.data?.data?.production_id;

            const object = {
              email,
              role,
              production_name,
              studio_name,
              id,
              prod_id,
              redirectURL,
            };

            dispatch(inviteEmail(object));

            if (redirectURL === "signup") {
              navigate(`/signup?inviteid=${id}`);
            } else if (redirectURL === "login") {
              navigate(`/login?inviteid=${id}`);
            }
          } else {
            navigate("/invite_link_expired");
          }
        })
        .catch((err) => {
          return err;
        });
    }

    // Employee Invite data
    else if (location?.pathname === "/process-employee-invite") {
      if (!id) {
        employeelinkExpire(prod_guid)
          .then((response) => {
            if (response?.status === 200) {
              const email = response?.data?.data?.email;
              const role = response?.data?.data?.role_id;
              const production_name = response?.data?.data?.production_name;
              const studio_name = response?.data?.data?.studio_name;
              const prod_id = response?.data?.data?.production_id;

              const object = {
                email,
                role,
                production_name,
                studio_name,
                id,
                prod_id,
                redirectURL,
              };

              dispatch(inviteEmail(object));

              if (redirectURL === "signup") {
                navigate(
                  `/signup?proid=${prod_guid}&link_guid=${link_guid}&invite_type_id=${invite_type_id}`
                );
              } else if (redirectURL === "login") {
                navigate(
                  `/login?proid=${prod_guid}&link_guid=${link_guid}&invite_type_id=${invite_type_id}`
                );
              }
            } else {
              navigate("/invite_link_expired");
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        linkexpire(id)
          .then((response) => {
            if (response?.status === 200) {
              const email = response?.data?.data?.email;
              const role = response?.data?.data?.role_id;
              const production_name = response?.data?.data?.production_name;
              const studio_name = response?.data?.data?.studio_name;
              const prod_id = response?.data?.data?.production_id;

              const object = {
                email,
                role,
                production_name,
                studio_name,
                id,
                prod_id,
                redirectURL,
              };

              dispatch(inviteEmail(object));

              if (redirectURL === "signup") {
                navigate(
                  `/signup?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}`
                );
              } else if (redirectURL === "login") {
                navigate(
                  `/login?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}`
                );
              }
            } else {
              navigate("/invite_link_expired");
            }
          })
          .catch((err) => {
            return err;
          });
      }
    }

    // Union invite through link generation
    else if (location?.pathname === "/process-union-invite") {
      employeelinkExpire(proid)
        .then((response) => {
          if (response?.status === 200) {
            navigate(
              `/signup?proid=${proid}&union_name=${union_name}&invite_type_id=${invite_type_id}`
            );
          } else {
            navigate("/invite_link_expired");
          }
        })
        .catch((err) => {
          return err;
        });
    }

    // Union Invite through email
    else if (location?.pathname === "/process-union-email-invite") {
      linkexpire(id)
        .then((response) => {
          if (response?.status === 200) {
            const email = response?.data?.data?.email;
            const role = response?.data?.data?.role_id;
            const production_name = response?.data?.data?.production_name;
            const studio_name = response?.data?.data?.studio_name;
            const prod_id = response?.data?.data?.production_id;

            const object = {
              email,
              role,
              production_name,
              studio_name,
              id,
              prod_id,
              redirectURL,
            };

            dispatch(inviteEmail(object));

            if (redirectURL === "signup") {
              navigate(
                `/signup?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}&union_name=${union_name}`
              );
            } else if (redirectURL === "login") {
              navigate(
                `/login?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}&union_name=${union_name}`
              );
            }
          } else {
            navigate("/invite_link_expired");
          }
        })
        .catch((error) => {
          return error
        });
    }

    // Forget Password Link expiration check
    else {
      checkLinkExpiration(token, queryParamLanguage)
        .then((response) => {
          if (response?.status === 200) {
            navigate("/reset_password");
          }
        })
        .catch((error) => {
          navigate("/reset_link_expired");
          return error;
        });
    }
  }, []);

  return (
    <>
      <section className="auth_layout login_screen">
        <div className="left_box"></div>
        <div className="right_box">
          <h3 className="title_h3"></h3>
          <div className="auth_form">
            <h3 style={{ fontSize: "20px" }}></h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default Temp;
