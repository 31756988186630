import React,{ useState,useEffect } from "react";

import { useTranslation } from "react-i18next";
import {Button,Table,Form} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import Modal from "../shared/Modal"; 
import Pagination from "../shared/Pagination";
import Loader from "../shared/Loader";
import NoRecords from "../shared/NoRecords";
import { svg } from "../assets/svg/svg";

import { archiveEmployeeForProduction, restoreEmployeeForProduction } from "../Services/https/Employer/Employer";

function EmployeeArchive() {
  const { t } = useTranslation(); 
  const [restore, setRestore] = useState(false)
  const [handlesearch, sethandleSearch] = useState(false)
  const [loader, setLoader] = useState(false)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const [list, setList] = useState([])
  const [employeeId, setEmployeeId] = useState(null)
  const navigate = useNavigate()
  const [showNoRecord, setShowNoRecord] = useState(false)
  const currentprodId = useSelector(state=>state?.invite.production_id)
  const pageChange = (pageNum) => {
    setInitialpage(pageNum)
  }
  const pageCount = Math.ceil(totalcount / 10);
  useEffect(() => {
    archiveEmployeeForProduction(search, sort, 10, initialpage, currentprodId).then(response => {
      if (response.status === 200) {
        setList(response?.data?.data?.data);
        setLoader(false);
        setTotalcount(response?.data?.data?.total)
        setShowNoRecord(true)
      }
    })
  }, [sort, handlesearch, initialpage, restore])

  return (
    <>
      {loader && <Loader />}
      <Modal onHide={() => setRestore(false)}
        show={restore}
        modalTitle={t("Employer.Employee_requests_approval.Restore_confirmation")}
        setShowResend={setRestore}
        footer={
          <div className="group_buttons mt-2">
            <Button
              variant="outline-info"
              className="btn-lg"
              onClick={() => {
                setRestore((prev) => !prev)
              }
              }
            >
              {t('Employer.Add_employee.Delete_modal.Cancel')}
            </Button>
            <Button
              variant="info"
              className="btn-lg"
              onClick={() => {
                setLoader(true)
                if (employeeId) {
                  restoreEmployeeForProduction(employeeId).then((response) => {
                    if (response.status === 200) {
                      setRestore((prev) => !prev);
                      setLoader(false)
                    }
                  })
                }
              }}
            >
              {t('Employer.Add_employee.Delete_modal.Confirm')}
            </Button>
          </div>
        } />

      {loader && <Loader />}
      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">{t(
            "Employer.Employee_archive.Manage_employee"
          )}</h3>
          &nbsp; &nbsp;
          <div className="search_box d-flex ">
            <div className="search_box_in me-3">
              <span onClick={() => {
                  sethandleSearch(prev => !prev);
                  setLoader(true)
                }}>{svg["Search"]}</span>
              <Form.Control type="text" placeholder={t(
                "Employer.Employee_archive.Search"
              )}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                    setLoader(true)
                    sethandleSearch(prev => !prev)
                  }
                }} />
            </div>
            <div className="manage_employeer_dropdown">
              <div class="select-wrapper" data-prefix="$" >
                <select
                  className="admin-dashboard-dropdown"
                  name="Studio"
                  id="Studio"
                  selected="Studio"
                  onChange={(e) => { setSort(e.target.value) }}
                >
                  <option value="studio_name">
                    {t("Admin.EmployerRequests.Dropdown.Title")}
                  </option>
                  <option value="production_name">
                    {t("Admin.Dashboard.Dropdown.Production")}
                  </option>

                  <option value="first_name">
                    {t("Admin.Dashboard.Dropdown.First_name")}
                  </option>
                  <option value="last_name">
                    {t("Admin.Dashboard.Dropdown.Last_name")}
                  </option>
                </select>
              </div>


            </div>
          </div>
        </div>
        <Table className="custom_table" responsive>

          {list?.length === 0 && showNoRecord ? <NoRecords /> : <>
            <thead>
              <tr>
                <th>{t("Employer.Employee_archive.Last_name")}</th>
                <th>{t("Employer.Employee_archive.First_name")}</th>
                <th>{t("Employer.Employee_archive.Production")}</th>
                <th>{t("Employer.Employee_archive.Studio")}</th>
                <th width="200px">
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8719 3.89995L23.1 8.12807C23.2781 8.3062 23.2781 8.59682 23.1 8.77495L12.8625 19.0124L8.5125 19.4953C7.93125 19.5609 7.43906 19.0687 7.50469 18.4874L7.9875 14.1374L18.225 3.89995C18.4031 3.72183 18.6938 3.72183 18.8719 3.89995ZM26.4656 2.82651L24.1781 0.539014C23.4656 -0.173486 22.3078 -0.173486 21.5906 0.539014L19.9313 2.19839C19.7531 2.37651 19.7531 2.66714 19.9313 2.84526L24.1594 7.07339C24.3375 7.25151 24.6281 7.25151 24.8063 7.07339L26.4656 5.41401C27.1781 4.69683 27.1781 3.53901 26.4656 2.82651ZM18 16.2281V20.9999H3V5.99995H13.7719C13.9219 5.99995 14.0625 5.93901 14.1703 5.83589L16.0453 3.96089C16.4016 3.60464 16.1484 2.99995 15.6469 2.99995H2.25C1.00781 2.99995 0 4.00776 0 5.24995V21.7499C0 22.9921 1.00781 23.9999 2.25 23.9999H18.75C19.9922 23.9999 21 22.9921 21 21.7499V14.3531C21 13.8515 20.3953 13.6031 20.0391 13.9546L18.1641 15.8296C18.0609 15.9374 18 16.0781 18 16.2281Z"
                      fill="#787878"
                    />
                  </svg>
                </th>
              </tr>
            </thead>

            <tbody>
              {
                list?.map((employers, index) => {
                  return (
                    <>
                      <tr key={index}>

                        <td><b>{employers?.last_name}</b></td>
                        <td><b>{employers?.first_name}</b></td>
                        <td>{employers?.production_name}</td>
                        <td>{employers?.studio_name}</td>
                        <td ><a
                          onClick={() => {
                            setEmployeeId(employers?.user_id)
                            setRestore(true)
                          }}
                          href="#" className='link'>{t(
                            "Employer.Employee_archive.Restore"
                          )}</a> </td>
                      </tr>
                    </>
                  )
                })
              }

            </tbody>
          </>}
        </Table>

        <div className="request-invite-archive mt-2">
          <div className='d-flex'>

            <Button className='btn-sm  ps-3 pe-3 ' variant="outline-secondary" onClick={() => navigate('/employer/employees')}>
              {t(
                "Employer.Employee_archive.Employee_List"
              )}
            </Button>
          </div>
          <div>
            {
              list?.length >= 1 ? <Pagination initialpage={initialpage} onpage={pageChange} pageCount={pageCount} /> : null
            }

          </div>

        </div>
      </div>
    </>
  );
}

export default EmployeeArchive;
