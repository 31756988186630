import { axiosInstance } from "../interceptor";

export const employeelist = (production_id, search, perpage, page) => {
  return axiosInstance
    .get(
      `/production/get-employees?production_id=${production_id}&search=${search}&perpage=${perpage}&page=${page}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
export const deleteEmployeeFromProduction = (ids) => {
  const data = {
    id: ids,
  };
  return axiosInstance
    .delete("production/delete-employee", { data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
export const archiveEmployeeForProduction = (
  search,
  sort,
  perpage,
  page,
  id
) => {
  return axiosInstance
    .get(
      `production/archived-employees?sortby=${sort}&search=${search}&perpage=${perpage}&page=${page}&id=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const restoreEmployeeForProduction = async (id) => {
  return axiosInstance
    .put("production/employee-restore/" + id)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
export const getemployees = (id) => {
  return axiosInstance
    .get(`production/get-employees?production_id=${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const addFundsEmployer = async (data,invite_id) => {
  return await axiosInstance.post(`add/fund-stripeurl?link_id=${invite_id}`,data)
    
};

export const getcurrentprod = () => {
  return axiosInstance.get(`production/last-production-data`);
};

export const switchprod = (data) => {
  return axiosInstance
    .post(`last-production`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getproddetails = (id) => {
  return axiosInstance
    .get(`production-detail-dashbaord?production_id=${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getprodlist = () => {
  return axiosInstance
    .get(`production/active-production?status=1`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getunion = (id,search,page) => {
  return axiosInstance
    .get(`production/get-union?production_id=${id}&search=${search}&perpage=10&page=${page}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const employeedetail = (id) => {
  return axiosInstance
    .get(`production/get-employee-details/${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const updateemployee = (id, data) => {
  return axiosInstance.post(
    `production/update-Production-employee-details/${id}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const generateInvite = (id) => {
  return axiosInstance
    .post(`production/employee-create-invite?production_id=${id}&role_id=3`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const createUnionInvite = (data, id) => {
  return axiosInstance
    .post(`production/create-union-invite?production_id=${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const sendInvite = (data, production_id) => {
  return axiosInstance.post(
    `production/invite-send-to-employee?production_id=${production_id}&role_id=3`,
    data
  );
};

export const sendInviteUnion = (data, productionId) => {
  return axiosInstance
    .post(
      `production/invite-sent-to-union?production_id=${productionId}&role_id=4`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const UnionConfirmProduction = (data, union_name) => {
  let url = "union/confirm-union-production";
  if (union_name !== "null") {
    url = `union/confirm-union-production?union_name=${union_name}`;
  } else {
    url = "union/confirm-union-production";
  }
  return axiosInstance
    .post(url, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const UnionAddFunds = (data, amount,unionname) => {
  return axiosInstance
    .post(`union/fund-to-production-stripeurl?amount=${amount}&union_name=${unionname}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const employeelinkExpire = (productionGuid) => {
  return axiosInstance.get(
    `validate-employee-invite?production_gid=${productionGuid}`
  );
};

export const linkemployee = (data) => {
  return axiosInstance.post(`confirm-employee-production`, data);
};

export const employeerequestlist = (search, page, id) => {
  return axiosInstance.get(
    `production/employee-request?search=${search}&perpage=10&page=${page}&production_id=${id}`
  );
};

export const cancelemployeerequest = (id) => {
  return axiosInstance.delete(`production/cancel-request-link/${id}`);
};

export const getEmployerInfo = () => {
  return axiosInstance.get(`production/get-profile-personal-info`);
};
export const updateEmployerPersonalInfo = (data) => {
  return axiosInstance.post(`production/update-profile-personal-info`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export const getEmployerCompanyInfo = (id) => {
  return axiosInstance.get(
    `production/get-profile-company-info?production_id=${id}`
  );
};
export const updateEmployerCompanyInfo = (id, data) => {
  return axiosInstance.put(
    `production/update-profile-company-info?production_id=${id}`,
    data
  );
};

export const UnionList = () => {
  return axiosInstance
    .get(`production/union`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const EmployerAddFundsInside = (data) => {
  return axiosInstance
    .post(`production/add-fund-stripe`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};


export const productiondata = (guid) => {
  return axiosInstance.get(
    `production-data-confirm-page?production_pid=${guid}`
  );
};


export const UnionProductionList = (id) => {
  return axiosInstance
    .get(`production/union-production-list?production_id=${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteUnion = (records) => {
  const data = {
    id: records,
  };
  return axiosInstance.delete('production/union-delete',{data: data,})
}

export const archivedUnionlist = (id,search,page) => {
  return axiosInstance.get(`production/archive-union?production_id=${id}&search=${search}&perpage=10&page=${page}`)
}

export const unionPendingRequest = (id,search,page,sortby) => {
  return axiosInstance.get(`production/union-request?production_id=${id}&search=${search}&perpage=10&page=${page}&sortby=${sortby}`)
}

export const markcomplete = (id) => {
  return axiosInstance.put(`production/mark-complete?production_id=${id}`)
}

export const fundhistory = (id) => {
  return axiosInstance.get(`fund-history?production_id=${id}`)
}

export const employerProdFunding=(prod_id)=>{
  return axiosInstance.get(`prodution-fund?production_id=${prod_id}`)
}

export const removeunionlink=(data)=>{
  return axiosInstance.put(`link-expire`,data)
}

export const productionUnionList=(id)=>{
  return axiosInstance.get(`production/get-union?production_id=${id}`)
}

export const unionContribution=(prodId,unionId)=>{
  return axiosInstance.get(`union-contribution-production?production_id=${prodId}&union_id=${unionId}`)
}
export const productionContribution=(id)=>{
   return axiosInstance.get(`production-contribution?production_id=${id}`)
}
export const getunionProfile=(id)=>{
  return axiosInstance.get(`production/union-profile?union_id=${id}`)
}

export const scheduleEvent=(id,data)=>{
  return axiosInstance.post(`production/event?production_id=${id}`,data)
}

export const geteventsbyDate=(date,id)=>{
  return axiosInstance.get(`production/getEventDetailByDate?date=${date}&production_id=${id}`)
}

export const listingofEvents=(id,search)=>{
  return axiosInstance.get(`production/event?production_id=${id}&search=${search}`)
}

export const deleteEvent=(id)=>{
  return axiosInstance.delete(`production/event/${id}`,{data:id})
}

export const geteventsbyId=(id)=>{
  return axiosInstance.get(`/production/event/${id}`)
}

export const updateEvent=(id,data)=>{
  return axiosInstance.put(`production/event/${id}`,data)
}

export const generateCompleteReport=(id)=>{
  return axiosInstance.post(`production/send-report-complete-production?production_id=${id}`)
}

export const requestProductionReport=(id)=>{
  return axiosInstance.post(`production/send-report-request-production?production_id=${id}`)
}
