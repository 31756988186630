import React, { useRef ,useEffect,useState} from "react";

import  {Nav,Navbar,NavDropdown ,Button} from "react-bootstrap"

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";

import Logo from "../assets/Images/reelkids_logo_White.svg";

import {
  countNotifications,
  unreadNotifications,
  readNotifications,
  clearNotifications,
} from "../Services/https/comman/common";
import {
  getcurrentprod,
  switchprod,
  getprodlist,
} from "../Services/https/Employer/Employer";
import { logout } from "../Services/https/Auth/Auth";
import { clearLocalStorage } from "../Services/storage";
import { DeleteCookie, GetCookie } from "../Services/Cookiees";

import * as route from "../Routes/index";
import Loader from "../shared/Loader";
import arrow from "../assets/Images/Polygon 1.png";
import Modal from "../shared/Modal";
import { svg } from "../assets/svg/svg";

import { loginState } from "../Reducers/Login/loginSlice";
import { inviteEmail } from "../Reducers/EmailInvite/inviteSlice";
import { EmployeeState } from "../Reducers/EmployeeReducer/EmployeeSlice";
import { UnionState } from "../Reducers/UnionReducer/unionSlice";

function Header(props) {
  const [clearAll, setClearAll] = useState(false);
  const { setActive } = props;
  const [isActive] = useState(false);
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const { t, i18n } = useTranslation();
  const [english, setEnglish] = useState(true);
  const userId = useSelector((state) => state.login.role_data);
  const loginstate = useSelector((state) => state.login.loggedIn);
  const [user, setUser] = useState();
  const [count, setCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const id = useSelector((state) => state.invite?.production_id);
  const { role_data } = useSelector((state) => state.login);
  const [switchProduction, setSwitchProduction] = useState(false);
  const [productionlist, setProdList] = useState([]);
  const [currentprod, setCurrentProd] = useState(null);
  const [prodId, setProdId] = useState(null);
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (lang) {
      if (lang === "en") {
        setCurrentLanguage("English");
      }
      if (lang === "fr") {
        setCurrentLanguage("Français");
      }
      i18n.changeLanguage(`${lang}`);
    }

    if (loginstate) {
      countNotifications().then((response) => {
        setCount(response?.data?.data?.unread_notification_count);
      });

      unreadNotifications().then((response) => {
        // setLoader(true)
        setNotifications(response?.data?.data);
        setLoader(false);
      });
      if (userId === "1") {
        setUser("admin");
      }
      if (userId === "2") {
        setUser("employer");
      }
      if (userId === "3") {
        setUser("employee");
      }
      if (userId === "4") {
        setUser("union");
      }
      if (userId === "5") {
        setUser("caregiver");
      }
    }
  }, [clearAll, currentLanguage]);

  useEffect(() => {
    if (userId === "2" || userId === "3") {
      getcurrentprod()
        .then((response) => {

          setCurrentProd(response?.data?.data);
        })
        .catch((err) => {
          return err;
        });

      getprodlist()
        .then((response) => {
          setProdList(response?.data?.data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [switchProduction, id]);

  const handleClick = () => {
    setActive((prev) => !prev);
  };
  const changelang = (language) => {
    if (language === "en") {
      setCurrentLanguage("English");
    }
    if (language === "fr") {
      setCurrentLanguage("Français");
    }
    i18n.changeLanguage(`${language}`);
  };
  const handelHomePageNavigation = () => {
    navigate(role_data === "1" ? '/admin/dashboard' : role_data === "2" ? '/employer/dashboard' : role_data === "3" ? "/employee/dashboard" : role_data === "4" ? "/union/dashboard" : "/caregiver/dashboard")
  };
  const ClearNotifications = () => {
    clearNotifications().then((response) => {
      setClearAll((prev) => !prev);
    });
  };
  const handleShow = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const ViewDetails = (id, url) => {
    // setLoader(true);
    readNotifications(id, 1).then((response) => {
      navigate(url);
    });
  };
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: "50px",

      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
    }),
    indicatorSeparator: () => { },
  };

  const handelLogout = () => {
    const cookieData = GetCookie('re_used')
    setLoader(true)
    logout('/logout').then((response) => {
      if (response.status === 200) {
        if (cookieData) {
          DeleteCookie('re_used')
        }
        clearLocalStorage();
        dispatch(loginState({ loggedIn: false, role: null, id: null, role_data: null, remember: false }))
        dispatch(inviteEmail({email: null,
          role:null,
          production_name:null,
          studio_name:null,
          id:null,
          prod_id:null,
          production_id:null,
          redirectURL:null}));
          dispatch(EmployeeState({productionId:null}));
          dispatch(UnionState({production_id:null}));
        setLoader(false)
        navigate('/')
      }
    })
  }

  useEffect(() => {
    const handleAnchorClick = (event) => {
      // Check if the clicked element is an anchor tag
      if (event.target.tagName.toLowerCase() === 'a') {
        navigate(event.target.getAttribute('href'))
        // Prevent the default behavior of the anchor tag
        event.preventDefault();
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleAnchorClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, [navigate]);
  
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Navbar className="top_navbar" expand="lg">
          <Navbar.Brand onClick={() => {
            handelHomePageNavigation();
          }}>
            <div

              className="logo"
            >
              <img alt="Reelkids_logo" src={Logo} />
            </div>
          </Navbar.Brand>

          <Nav className="ms-auto">
            {route.PublicRoutes.includes(window.location.pathname) ? (
              <Nav.Link className="pe-4 " onClick={() => navigate("/login")}>
                {t("Landing.Login")}
              </Nav.Link>
            ) : (
              <>
                {switchProduction && (
                  <Modal
                    align="left"
                    show={switchProduction}
                    onHide={() => setSwitchProduction(false)}
                    setShow={setSwitchProduction}
                    modalTitle={t(
                      "Employer.Dashboard.Switch_production_modal.Switch_production"
                    )}
                    description={t("Employer.Dashboard.Production")}
                    field={
                      <>
                        <Select
                          options={productionlist?.map((list, index) => ({
                            value: list?.production_name,
                            label: list?.production_name,
                            id: list?.id,
                          }))}
                          styles={CustomStyle}
                          onChange={(option) => {
                            setProdId(option?.id);
                          }}
                          placeholder={
                            <div style={{ textAlign: "left" }}>
                              Select Production
                            </div>
                          }
                        />
                      </>
                    }
                    footer={
                      <div className="group_buttons mt-5">
                        <Button
                          variant="outline-info"
                          className="btn-lg"
                          onClick={() => setSwitchProduction((prev) => !prev)}
                        >
                          {t("Employer.Add_employee.Delete_modal.Cancel")}
                        </Button>
                        <Button
                          variant="info"
                          className="btn-lg"
                          onClick={() => {
                            setSwitchProduction((prev) => !prev);
                            setLoader(true);
                            const data = {
                              production_id: prodId,
                            };
                            switchprod(data)
                              .then((response) => {
                                getcurrentprod()
                                  .then((response) => {
                                    const production_name = response?.data?.data?.production_name;
                                    const production_id = response?.data?.data?.id;
                                    const object = {
                                      production_name,
                                      production_id,
                                    };

                                    dispatch(inviteEmail(object));
                                    setCurrentProd(response?.data?.data);
                                    setLoader(false);
                                  })
                                  .catch((err) => {
                                    return err;
                                  });
                              })
                              .catch((err) => {
                                return err;
                              });
                          }}
                        >
                          {t("Employer.Add_employee.Delete_modal.Confirm")}
                        </Button>
                      </div>
                    }
                  />
                )}
                {userId === "2" || userId === "3" ? (
                  <div
                    className="pro_dropdown"
                    style={{ color: "#fff" }}
                    onClick={() => setSwitchProduction(!switchProduction)}
                  >
                    {currentprod?.production_name}
                    <span className="p-1">
                      {" "}
                      <img src={arrow} />
                    </span>
                  </div>
                ) : null}

                <Nav.Link className="notification" ref={ref}>
                  <span></span>

                  <div onClick={handleShow}>
                    <span
                      className=""
                      style={{ cursor: "pointer" }}
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {svg.Notification}
                      <div>{count}</div>
                    </span>
                    <ul
                      className="dropdown-menu notification_menu"
                      aria-labelledby="dropdownMenuButton1"
                      style={{ display: open ? "block" : "none" }}
                    >
                      <li style={{ borderBottom: " 1px solid #B3B3B3" }}>
                        <span className="dropdown-item header">
                          {t("Notifications.Title")}
                        </span>
                      </li>
                      <li className="notifications_list">
                        <div>
                          {notifications && notifications.length > 0 ? (
                            notifications?.map((item,index) => {
                              return (
                                <div
                                  style={{
                                    borderBottom: " 1px solid #B3B3B3",
                                    padding: "14px",
                                  }}
                                  key={index}
                                >
                                  <div className="notification_date">
                                    {item?.date}
                                  </div>
                                  {item?.notificationdata?.map((content,index) => {
                                    return (
                                      <div className="notification_title" key={index}>
                                        {role_data!=="3" &&  content?.notification_content}
                                   {role_data==="3" &&  <div dangerouslySetInnerHTML={{ __html: content?.notification_content }} >
                                    </div>}    
                                        <span
                                            style={{
                                              textDecoration: "underline",
                                              paddingLeft: "5px",
                                              cursor:"pointer"
                                            }}
                                            onClick={() =>
                                              ViewDetails(
                                                item?.id,
                                                item?.click_url
                                              )
                                            }
                                          >
                                            {t("Notifications.ViewDetails")}
                                         
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                borderBottom: " 1px solid #B3B3B3",
                                padding: "14px",
                              }}
                            >
                              <div className="notification_date">
                                {t("Notifications.NoNotifications")}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                      <div className="notificationsButtons">
                        <button
                          className="viewALL"
                          onClick={ClearNotifications}
                        >
                          {t("Notifications.ClearAll")}
                        </button>
                        <button
                          className="viewALL"
                          onClick={() =>
                            navigate(`/${user}/dashboard/notifications`)
                          }
                        >
                          {t("Notifications.ViewAll")}
                        </button>
                      </div>
                    </ul>
                  </div>
                </Nav.Link>
              </>
            )}

            <NavDropdown
              title={english ? `${currentLanguage}` : `${currentLanguage}`}
              align="end"
              id="basic-nav-dropdown"
              className="d-inline-flex align-items-center language_dropdown"
            >
              <NavDropdown.Item
                className="language_options"
                onClick={() => {
                  changelang("en");
                  setEnglish(true);
                }}
              >
                English
              </NavDropdown.Item>
              <NavDropdown.Item
                className="language_options"
                onClick={() => {
                  changelang("fr");
                  setEnglish(false);
                }}
              >
                Français
              </NavDropdown.Item>
            </NavDropdown>
            {!route.PublicRoutes.includes(window.location.pathname) && (
              <>
              <Nav.Link onClick={() => handelLogout()}>
              {svg['Logout']}
              {t("Log Out")}
            </Nav.Link>
              <Nav.Link
                className={
                  isActive ? "bg-salmon toggle_button" : "toggle_button"
                }
                onClick={handleClick}
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </Nav.Link>
              
            </>
            )}
             
          </Nav>
          {/* </Navbar.Collapse>  */}
        </Navbar>
      )}
    </>
  );
}

export default Header;
