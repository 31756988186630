import React, { useState, useEffect } from "react";

import { Button, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select';
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Loader from "../../shared/Loader";

import {
  CaregiverRatesDetails,
  CaregiverUpdateRatesDetails,
  OptionsData,
} from "../../Services/https/Caregiver/Caregivers";
import { caregiverRatedetails } from "../../Services/https/Employee/Employee";
import { AdminCaregiverQualificationUpdate, AdminCaregivers } from "../../Services/https/Admin/Admin";

const Rates = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  
  const [rateDetails,setRateDetails]=useState({
    criminal:"",
    experience:"",
    hourlyrate:"",
    is_driving:"",
    description:""
  })
  const { role_data } = useSelector(state => state?.login)
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [update, setUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [experienceLevel, setExperienceLevel] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [criminalRecord, setCriminalRecord] = useState([]);
  const [drive, setDrive] = useState([]);
  const [coursetaken, setCouseTaken] = useState([]);
  const [firstaid, setFirstAid] = useState([]);
  const [stylecare, setStyleCare] = useState([]);
  const [ids, setIds] = useState([]);
  const [firstaidids, setFirstAidIds] = useState([]);
  const [carestyleids, setCareStyleIds] = useState([]);
  const [courseids, setCourseIds] = useState([]);
  const [course, setCourse] = useState([]);
  const [careStyle, setCareStyle] = useState([]);
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    OptionsData(
      "experience_level",
      "caregiver_qualification",
      "has_criminal_record",
      "can_drive",
      "course_taken",
      "care_style"
    )
      .then((response) => {
        setLoader(false);
         setExperienceLevel(response?.data?.data?.experience_level);
         setQualification(response?.data?.data?.caregiver_qualification);
         setCriminalRecord(response?.data?.data?.has_criminal_record);
         setDrive(response?.data?.data?.can_drive);
         setCareStyle(response?.data?.data?.care_style);
         setCourse(response?.data?.data?.course_taken);
      })
      .catch((error) => {
        return error
      });
  }, [lang]);

  // Get caregiver rates details

  useEffect(() => {
    
    if(qualification){
      setLoader(true);
      if (role_data === "5") {
        CaregiverRatesDetails()
          .then((response) => {
              setRateDetails(
                {
                criminal:criminalRecord?.find((item)=>item?.id==response?.data?.data?.data?.is_criminal_check),
                experience:experienceLevel?.find((item)=>item?.id==response?.data?.data?.data?.experience_level_id),
                hourlyrate:response?.data?.data?.data?.hourly_rate,
                is_driving:drive?.find((item)=>item?.id==response?.data?.data?.data?.is_driving),
                description:response?.data?.data?.data?.caregiver_experience_desc,
              })
            setFirstAidIds(response?.data?.data?.data?.qualification)
            setCareStyleIds(response?.data?.data?.data?.care_style)
            setIds(response?.data?.data?.data?.courses_taken)
            setLoader(false);
          })
          .catch((error) => {
            return error;
          });
      }
      else if (role_data==="3") {
        caregiverRatedetails(id).then((response) => {
          // setRatesDetails(response?.data?.data?.data);
          setRateDetails(
            {
            criminal:criminalRecord?.find((item)=>item?.id==response?.data?.data?.data?.is_criminal_check),
            experience:experienceLevel?.find((item)=>item?.id==response?.data?.data?.data?.experience_level_id),
            hourlyrate:response?.data?.data?.data?.hourly_rate,
            is_driving:drive?.find((item)=>item?.id==response?.data?.data?.data?.is_driving),
            description:response?.data?.data?.data?.caregiver_experience_desc,
          })
          setFirstAidIds(response?.data?.data?.data?.qualification)
            setCareStyleIds(response?.data?.data?.data?.care_style)
          setIds(response?.data?.data?.data?.courses_taken)
          setLoader(false);
        })
          .catch((error) => {
            return error;
          });
      }
      else{
        AdminCaregivers(id)
        .then((response) => {
          setRateDetails(
            {
            criminal:criminalRecord?.find((item)=>item?.id==response?.data?.data[0]?.user_details?.Is_criminal_check),
            experience:experienceLevel?.find((item)=>item?.id==response?.data?.data[0]?.user_details?.experience_level_id),
            hourlyrate:response?.data?.data[0]?.user_details?.hourly_rate,
            is_driving:drive?.find((item)=>item?.id==response?.data?.data[0]?.user_details?.Is_driving),
            description:response?.data?.data[0]?.user_details?.caregiver_experience_desc,
          })
          setFirstAidIds(response?.data?.data[0]?.user_details?.qualification)
            setCareStyleIds(response?.data?.data[0]?.user_details?.care_style)
          setIds(response?.data?.data[0]?.user_details?.courses_taken)
          setLoader(false);
        })
        .catch((error) => {
          return error
        });
      }
    }
    


  }, [update,course]);


  // Prevent scrolling on number input

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // Toaster Messages
  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  //Options API consumption
  
  const ratesSchema = Yup.object().shape({
     qualification: Yup.array()
     .min(1, `${t("Validation.InviteModal.Required")}`)
     .of(
       Yup.object().shape({
         firstaidids: Yup.number().required()
       }).nullable()
     ).nullable(),
    courses_taken: Yup.array()
      .min(1, `${t("Validation.InviteModal.Required")}`)
      .of(
        Yup.object().shape({
          courseids: Yup.number().required()
        }).nullable()
      ).nullable(),
      hourly_rate:Yup.number().required(`${t("Validation.InviteModal.Required")}`).nullable(),
    // Is_criminal_check: Yup.string().required("Required"),
     care_style: Yup.array()
     .min(1, `${t("Validation.InviteModal.Required")}`)
     .of(
       Yup.object().shape({
         carestyleids: Yup.number().required()
       }).nullable()
     ).nullable(),
    // experience_level_id: Yup.string().required("Required").nullable(),
    // Is_driving: Yup.string().required("Required").nullable(),
    // caregiver_experience_desc: Yup.string().required(`Required`).nullable(),
  });

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
      color: "black",
      fontSize: "15px",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        backgroundColor: "transparent ",
        // border: "none ",
        minHeight: '12px !important',
      },
      backgroundColor: "transparent !important",
      minHeight: state?.isMulti && '12px !important'
    }),
    indicatorSeparator: () => { },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      height: '40px',
      color: "#000",
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap'
    }),

  };

  useEffect(() => {
    if (ids?.length >= 1) {
      const givenCourse = course.filter(item => ids.includes(`${item?.id}`))
      setCouseTaken(givenCourse?.map(list => {
        return {
          value: list?.look_up_value,
          label: list?.look_up_value,
          id: list?.id
        }
      }))
      const selectedFirstAid=qualification.filter(item=>firstaidids.includes(`${item?.id}`));
      setFirstAid(selectedFirstAid?.map(list => {
        return {
          value: list?.look_up_value,
          label: list?.look_up_value,
          id: list?.id
        }
      }))
      const selectedStyleOfCare= careStyle.filter(item=>carestyleids?.includes(`${item?.id}`))
      setStyleCare(selectedStyleOfCare?.map(list => {
        return {
          value: list?.look_up_value,
          label: list?.look_up_value,
          id: list?.id
        }
      }))
    }
  }, [ids, course]);

  useEffect(() => {
    setCourseIds(coursetaken?.map(list => { return list?.id }))
  }, [coursetaken])

  useEffect(() => {
    setFirstAidIds(firstaid?.map(list => { return list?.id }))
  }, [firstaid])

  useEffect(() => {
    setCareStyleIds(stylecare?.map(list => { return list?.id }))
  }, [stylecare])

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="caregiver_profile_font required">
          {
            rateDetails && <>
             <Formik
            initialValues={{
              qualification: firstaidids,
              Is_criminal_check: rateDetails?.criminal?.id ?rateDetails?.criminal?.id:rateDetails?.criminal==undefined ? 25 :"" ,
              care_style: carestyleids,
              courses_taken: courseids,
              experience_level_id: rateDetails?.experience?.id ?rateDetails?.experience?.id:rateDetails?.experience==undefined ? 10 :"",
              Is_driving: rateDetails?.is_driving?.id ?rateDetails?.is_driving?.id:rateDetails?.is_driving==undefined ? 27 :"",
              hourly_rate: rateDetails?.hourlyrate,
              caregiver_experience_desc:rateDetails?.description? rateDetails?.description:"",
            }}
            enableReinitialize={true}
            validationSchema={ratesSchema}
            onSubmit={(values) => {
                setLoader(true);
              const data = {
                qualification:  values?.qualification,
                Is_criminal_check: values?.Is_criminal_check,
                care_style: values?.care_style,
                courses_taken: values?.courses_taken,
                experience_level_id: values?.experience_level_id,
                Is_driving: values?.Is_driving,
                hourly_rate: values?.hourly_rate,
                caregiver_experience_desc: values?.caregiver_experience_desc,
              };

         //     Update rates details
         role_data==="5" ? 
              CaregiverUpdateRatesDetails(data)
                .then((response) => {
                  setUpdate(!update);
                  setShow(false)
                  setLoader(false);
                  if (response?.status === 200) {
                    toast.success(response?.data?.message);
                  }
                  if (response?.response?.status === 422) {
                    setErrorMessage(response?.response?.data?.errors);
                  }
                  if (response?.response?.status === 400) {
                    setErrorMessage(response?.response?.data?.message);
                  }
                })
                .catch((err) => {
                  if (err?.response?.status === 422) {
                    setErrorMessage(err?.response?.data?.errors);
                  }
                  if (err?.response?.status === 400) {
                    setErrorMessage(err?.response?.data?.message);
                  }
                }):AdminCaregiverQualificationUpdate(id, data)
                .then((response) => {
                  setUpdate(true);
                  setUpdate(!update);
                  setShow(false)
                  if (response?.status === 200) {
                    setLoader(false);
                    toast.success(response?.data?.message);
                  }
                  if (response?.response?.status === 422) {
                    setLoader(false);
                    setErrorMessage(response?.response?.data?.errors);
                  }
                  if (response?.response?.status === 400) {
                    setLoader(false);
                    setErrorMessage(response?.response?.data?.message);
                  }
                })
                .catch((err) => {
                  setLoader(false);
                  if (err?.response?.status === 422) {
                    setErrorMessage(err?.response?.data?.errors);
                  }
                  if (err?.response?.status === 400) {
                    setErrorMessage(err?.response?.data?.message);
                  }
                });;
             }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              return (
             
              <Form className="mt-3" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4">
                    <Form.Label className="control-label control_label_text mt-3 ">
                      {t("Caregiver.Profile.Rates.FirstAid")}
                    </Form.Label>
                  </div>
                  <div className="col-lg-4">
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.Police")}
                    </Form.Label>
                  </div>
                </div>
                <div className="row mb-3">
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                  >
                    <Select
                      isMulti
                      name="qualification"
                      value={firstaid}
                      isDisabled={!show}
                      options={qualification?.map(list => {
                        return {
                          label: list?.look_up_value,
                          value: list?.look_up_value,
                          id: list?.id
                        }
                      })}
                      styles={CustomStyle}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setFirstAid}
                    />
                    {errors.qualification && (
                      <div className="error">
                        {touched.qualification && <>{errors.qualification}</>}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <select
                      as="select"
                      name="Is_criminal_check"
                      className="form-select"
                      disabled={!show}
                      value={values?.Is_criminal_check}
                      defaultValue={values.Is_criminal_check}
                      onChange={handleChange}
                    >
                      {criminalRecord.map((item, index) => {
                        return (
                          <option value={item?.id} >
                            {item?.look_up_value}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Group>
                </div>

                <Row className="mb-3">
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom03"
                    disabled={!show}
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.StyleHeading")}
                    </Form.Label>
                    <Select
                      isMulti
                      name="care_style"
                      value={stylecare}
                      isDisabled={!show}
                      options={careStyle?.map(list => {
                        return {
                          label: list?.look_up_value,
                          value: list?.look_up_value,
                          id: list?.id
                        }
                      })}
                      styles={CustomStyle}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setStyleCare}
                    />
                    {errors.care_style && (
                      <div className="error">
                        {touched.care_style && <>{errors.care_style}</>}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom04"
                    disabled={!show}
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.Course")}
                    </Form.Label>
                    <Select
                      isMulti
                      name="courses_taken"
                      value={coursetaken}
                      isDisabled={!show}
                      options={course?.map(list => {
                        return {
                          label: list?.look_up_value,
                          value: list?.look_up_value,
                          id: list?.id
                        }
                      })}
                      styles={CustomStyle}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setCouseTaken}
                    />
                    {errors.courses_taken && (
                      <div className="error">
                        {touched.courses_taken && <>{errors.courses_taken}</>}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom03"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.Days")}
                    </Form.Label>
                    <select
                      as="select"
                      name="experience_level_id"
                      className="form-select"
                      disabled={!show}
                      value={values?.experience_level_id}
                      defaultValue={values?.experience_level_id}
                      onChange={handleChange}
                    >
                      {experienceLevel.map((item, index) => {
                        return (
                          <option value={item?.id} >
                            {item?.look_up_value}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Group>
                  <Form.Group
                    className="required"
                    as={Col}
                    md="4"
                    controlId="validationCustom04"
                  >
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.DOD")}
                    </Form.Label>
                    <select
                      as="select"
                      name="Is_driving"
                      className="form-select"
                      disabled={!show}
                      value={values?.Is_driving}
                      defaultValue={values?.Is_driving}
                      onChange={handleChange}
                    >
                      {drive?.map((item, index) => {
                        return (
                          <option value={item?.id} >
                            {item?.look_up_value}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label className="control-label control_label_text">
                      {t("Caregiver.Profile.Rates.Rate")}
                    </Form.Label>
                    <Field
                      type="number"
                      name="hourly_rate"
                      className="form-control"
                      onWheel={numberInputOnWheelPreventChange}
                      value={values?.hourly_rate}
                      disabled={!show}
                    ></Field>
                    {errors.hourly_rate && (
                      <div className="error">
                        {touched.hourly_rate && <>{errors.hourly_rate}</>}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="required"
                    as={Col}
                    md="12"
                    controlId="validationCustom05"
                  >
                    <Form.Label className=" control_label_text">
                      {t("Caregiver.Profile.Rates.Caregiver")}
                    </Form.Label>

                    <Field
                      as="textarea"
                      name="caregiver_experience_desc"
                      className="form-control"
                      value={values?.caregiver_experience_desc}
                      disabled={!show}
                    ></Field>
                    {errors.caregiver_experience_desc && (
                      <div className="error">
                        {touched.caregiver_experience_desc && (
                          <>{errors.caregiver_experience_desc}</>
                        )}
                      </div>
                    )}
                  </Form.Group>
                </Row>
                {
                  role_data === "5" &&
                  <Row className="mb-3">
                    <Form.Group
                      className="required"
                      as={Col}
                      md="12"
                      controlId="validationCustom05"
                    >
                      <Form.Label className="control-label control_label_text">
                        {t("Caregiver.Profile.Rates.Additional")}
                      </Form.Label>


                      <div className="caregiver_additional">
                        <ul>
                          <li>{t("Caregiver.Profile.Rates.MinPay")}</li>
                          <li> {t("Caregiver.Profile.Rates.ExtraTwo")}</li>
                          <li>
                            {t("Caregiver.Profile.Rates.ExtraThree")} &#40;{" "}
                            {t("Caregiver.Profile.Rates.Friends")} &#41;
                          </li>
                          <li> {t("Caregiver.Profile.Rates.ExtraMidnight")}</li>
                          <li> {t("Caregiver.Profile.Rates.ExtraHolidays")}</li>
                          <li>{t("Caregiver.Profile.Rates.EightExtra")}</li>
                          <li>{t("Caregiver.Profile.Rates.TenthExtra")}</li>
                        </ul>

                        <br />
                        <span className="mt-0 ms-2">
                          {" "}
                          {t("Caregiver.Profile.Rates.SpecialDays")}
                        </span>
                        <ul>
                          <li> {t("Caregiver.Profile.Rates.ChristmasExtra")}</li>
                          <li>{t("Caregiver.Profile.Rates.NewYearExtra")}</li>
                          <li>{t("Caregiver.Profile.Rates.SiblingsExtra")}</li>
                        </ul>
                      </div>



                    </Form.Group>
                  </Row>
                }
                {
                  ((role_data === "5"|| role_data==="1") && show) && <Button
                    variant="info"
                    type="submit"
                    className="btn-lg caregiver_profile_button "
                  >
                    {t("Caregiver.Profile.ButtonConfirm")}
                  </Button>
                }
              </Form>
            
            )}}
          </Formik>
            </>
          }
         
          {
            ((role_data === "5"|| role_data==="1") && !show) && <Button
              variant="info"
              type="button"
              // type="submit"
              className="btn-lg caregiver_profile_button "
              onClick={() => {
                setShow(true);
              }}
            >
              {t("Caregiver.Profile.Button")}
            </Button>
          }
        </div>
      )}
    </>
  );
};

export default Rates;
