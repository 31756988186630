import React from 'react';

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Logo from '../assets/Images/reelkids_logo_Dark-svg.svg';

const Expired = () => {
 const navigate = useNavigate()
  const {t}=useTranslation()
  return (
    <section className='auth_layout login_screen'>
    <div className='left_box'>
    </div>
    <div className='right_box'>
    <div className="right_box_cont">
      <a href="/" className='logo'><img src={Logo} /></a>
      <h3 className='title_h3'>{t("Forgot_password.Forgot_password_title")}</h3>
      <div className='auth_form'>
        <h3 style={{fontSize:"20px"}}>{t('Expired.Message1')} <a className="link2" onClick={()=>navigate('/forgot_password')}>{t('Expired.Message2')}</a> {t('Expired.Message3')}</h3>
      </div>

    </div>
    </div>
  </section>
  )
}

export default Expired