import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { linkexpire } from "../../../Services/https/Admin/Admin";
import { productiondata } from "../../../Services/https/Employer/Employer";

const UnionConfirmation = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [productionData, setProductionData] = useState(null);

    const id = new URLSearchParams(location.search).get("inviteid");
    const prod_guid = new URLSearchParams(location.search).get("proid");
    const linkid = new URLSearchParams(location.search).get("link_guid");
    const union_name = new URLSearchParams(location.search).get("union_name");

    useEffect(() => {
        GetData();
    }
        , [])

    const GetData = () => {
        linkexpire(id)
            .then((response) => {
                setProductionData(response?.data?.data);
            })
            .catch((error) => {
                return error
            });

        productiondata(prod_guid)
            .then((response) => {
                setProductionData(response?.data?.data);
            })
            .catch((err) => {
                return err;
            });
    }

    return (
        <>
            <div className="confirm-production confirm-production-kids">
                <div className="container">
                    <div>
                        <div>
                            <h3 className="confirm-production-header">
                                {" "}
                                {t("Confirmation.Title")}
                            </h3>

                        </div>
                        <h5 className="confirm-production-text mt-3">
                            {t('Confirmation.UnionTitle')}
                        </h5>
                        <p className="production-name mt-5">
                            {productionData?.studio_name}:
                        </p>
                        <p className="production-name">{productionData?.production_name}</p>
                    </div>

                    <div className="mt-3 prod-buttons">
                        <Button
                            onClick={() =>
                                navigate(
                                    `/wrong-production?inviteid=${id}&prodid=${prod_guid}&linkguid=${linkid}`
                                )
                            }
                            className="wrong-production"
                        >
                            {t("Confirmation.Wrong")}
                        </Button>
                        <Button className="next ms-1" onClick={() => navigate(`/union/confirm-production/step-2?inviteid=${id}&proid=${prod_guid}&link_guid=${linkid}&union_name=${union_name}`, { state: location?.state })}>
                            {t("Confirmation.Next")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnionConfirmation