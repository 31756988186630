import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const SignupConfimation = () => {
    const { t } = useTranslation();
    const location =useLocation();
    const id = new URLSearchParams(location.search).get("inviteid");
  const proid = new URLSearchParams(location.search).get("proid");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const link_guid = new URLSearchParams(location.search).get("link_guid");
  const unionname = new URLSearchParams(location.search).get("union_name");
  const invite_type_id = new URLSearchParams(location.search).get("invite_type_id")

    return (
        <>
        <div className="confirm-production confirm-production-kids">
        <div className="container">
          <div>
            <h3 className="confirm-production-header">
              {" "}
              {t("Confirmation.Title")}
            </h3>
            
            </div>
            <h5 className="confirm-production-text mt-3">
            {t('SignUp.Confirmation.Success')} {id|| invite_type_id|| unionname||link_guid || prod_guid||proid ? <Link to={ `/login?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}&union_name=${unionname}`}> {t('SignUp.Confirmation.Click')}</Link> : <Link to={"/login"}>{t('SignUp.Confirmation.Click')}</Link>}  {t('SignUp.Confirmation.Sign-in')} {t('SignUp.Confirmation.Message')}
              </h5>
            </div>
            </div>
        </>
    )
}

export default SignupConfimation