import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Landing from "./Home/Landing";
import Login from "./Auth/Login";
import Index from "./Admin-Employers/Productions/index";
import Signup from "./Auth/Signup";
import { useIdleTimer } from 'react-idle-timer'

import Manageemployer from "./Admin-Employers/Manageemployer";
import { useEffect, useState } from "react";
import AddEmployer from "./Admin-Employers/AddEmployer";
import AddProduction from "./Admin-Employers/AddProduction";
import AddProductionFocused from "./Admin-Employers/AdminProductionFocused";
import ConfirmProduction from "./shared/ConfirmProduction";
import EmployerDashboard from "./Employer/EmpoyerDashboard";
import SecondConfirmation from "./Employee/confirmation/SecondConfirm";
import EmployeeDashboard from "./Employee/dashboard/main/Dashboard";
import FamilyAccount from './Admin-Employers/FamilyAccount'
import SuccessConfirmation from "./Admin-Employers/SuccessConfirmation";
import EmployerFundingTabs from "./Admin-Employers/EmployerFundingTabs";
import CalenderListing from "./Employer/CalenderListing";
import SelectProductions from "./Employee/dashboard/productions/selectProductions";
import FinishedProd from "./Employee/dashboard/productions/finishedProductions";
import Focusedprod from "./Employee/dashboard/productions/focused";
import CrewSchedule from './Employer/CrewSchedule'
import EmployeerRequests from "./Admin-Employers/EmployeerRequests";
import UpdateEmployeer from "./Admin-Employers/UpdateEmployer/UpdateEmployeer";
import ManageEmployee from "./Employer/ManageEmployee";
import ManageUnion from "./Employer/ManageUnion";
import EmployerProfile from "./Employer/EmployerProfile";
import UpdateUnion from "./Employer/UpdateUnion";
import UnionRequestApproval from "./Employer/UnionRequestApproval";
import EmployeeRequestApproval from "./Employer/EmployeeRequestApproval";
import UpdateCaregiver from "./Admin-Employers/UpdateCaregiver/updateCaregiver"
import ChildCare from "./Employee/dashboard/requests/requestChildcare";
import Success from "./Employee/dashboard/requests/success";
import Bookings from "./Employee/dashboard/booking/bookings";
import Settings from "./Employee/dashboard/settings/settings";
import Profile from "./Employee/dashboard/profile";
import UnionDashboard from "./Union/UnionDashboard";
import Balances from "./Union/balances/overview";
import BookingRequests from "./Caregiver/bookingRequests/bookingRequests";
import DashBoard from "./Caregiver/DashBoard";
import AvailabilityCalender from "./Caregiver/AvailabilityCalender";
import CareGiverProfile from "./Caregiver/profile/Profile";
import ProductionDetails from "./Union/ProductionDetails";
import ManageCaregiver from "./Admin-Employers/ManageCaregiver";
import UnionArchive from "./Employer/UnionArchive";
import EmployeeArchive from "./Employer/EmployeeArchive";
import Notifications from "./shared/Notifications";
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from "./Auth/ResetPassword";
import Expired from './Auth/Expired';
import { Toaster } from "react-hot-toast";
import Temp from "./Auth/Temp";
import * as route from './Routes'
import AppLayout from "./Layout/AppLayout";
import { useDispatch, useSelector } from "react-redux";
import PublicComponents from "./Layout/PublicComponent";
import PublicRoutes from "./Services/Routes/PublicRoutes";
import ArchivedEmployers from "./Admin-Employers/ArchivedEmployers";
import InviteLinkExpired from "./Auth/InviteLinkExpired";
import Details from "./Union/details";
import ArchiveCaregivers from '../src/Admin-Employers/ArchiveCaregivers'
import CaregiverProfileEmployee from "./Employee/caregiverInfo/caregiverProfile";
import BlockedCaregivers from "./Admin-Employers/BlockedCaregivers";
import Report from "./Admin-Employers/GenerateReport";
import PayrollGenerate from "./Admin-Employers/PayrollGenerate";
import PayrollApprove from "./Admin-Employers/PayrollApprove";
import NewPayroll from "./Admin-Employers/NewPayroll";
import CaregiverInfo from "./Employee/caregiverInfo";
import Journalslist from "./Admin-Employers/refunds";
import AddJournals from "./Admin-Employers/refunds/addjorurnals";
import SignupConfimation from "./Auth/SignupConfirmation";
import UnionConfirmation from "./shared/unionConfirmation/step1";
import UnionFinalConfirmation from "./shared/unionConfirmation/step2";
import AddFunds from "./Admin-Employers/AddFunds";
import PaymentError from "./shared/payments/paymentError";
import Redirect from "./Auth/Redirect";
import PaymentSuccess from "./shared/payments/success";

function App() {
  useEffect(() => {
    if (route.PublicRoutes.includes(window.location.pathname)) {
      document.body.classList.add("auth");
    } else {
      document.body.classList.remove("auth");
    }
  }, []);
  const checkLoginStates = useSelector(role => role.login)
  const isloggedIn = checkLoginStates.loggedIn
  const role = checkLoginStates.role_data

  const navigate = useNavigate()
  const timeout = 1.2e+6  //1min 
  const [remaining, setRemaining] = useState(timeout)
  const [isIdle, setIsIdle] = useState(false)
  const handleOnActive = () => setIsIdle(false)
  const dispatch = useDispatch()
  const handleOnIdle = () => setIsIdle(true)
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  //   useEffect(() => {
  //     setRemaining(getRemainingTime())
  //     setInterval(() => {
  //       setRemaining(getRemainingTime(), 1000)
  //     })
  //   }, [])

  // useEffect(()=>{
  //   if(isloggedIn && remaining === 0) {
  //     console.log('you are logout');
  //     clearLocalStorage()
  //     dispatch(loginState({
  //       loggedIn: false, access_token: null,
  //       role: null, role_data: null, remember: false
  //     }))
  //     navigate('/login')
  //   }
  // },[remaining])
  // useEffect(() => {


  //   if (!isloggedIn) {
  //     navigate('/')
  //   }
  // }, [remaining])

  const location = useLocation()
  // useEffect(() => {
  //   if (location.search === '' && !isloggedIn) {
  //     navigate('/')
  //   }
  // }, [isloggedIn])


  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={true}
      />
      <Routes>

        <Route element={<PublicRoutes><PublicComponents /></PublicRoutes>}>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/redirect" element={<Redirect />} />
          <Route exact path="/signup/confirmation" element={<SignupConfimation />} />
          <Route exact path="/forgot_password" element={<ForgotPassword />} />
          <Route exact path="/employer/confirm-production" element={<ConfirmProduction />} />
          <Route exact path="/employee/confirm-production" element={<ConfirmProduction />} />
          <Route exact path="/union/confirm-production/step-1" element={<UnionConfirmation />} />
          <Route exact path="/union/confirm-production/step-2" element={<UnionFinalConfirmation />} />
          <Route exact path="/wrong-production" element={<SecondConfirmation />} />
          <Route exact path="/reset_password" element={<ResetPassword />} />
          <Route exact path="/reset_link_expired" element={<Expired />} />
          <Route exact path="/redirect-forgot-password" element={<Temp />} />
          <Route exact path="/processinvite" element={<Temp />} />
          <Route exact path='/process-employee-invite' element={<Temp />} />
          <Route exact path='/process-union-invite' element={<Temp />} />
          <Route exact path='/process-union-email-invite' element={<Temp />} />
          <Route exact path="/invite_link_expired" element={<InviteLinkExpired />} />
          <Route exact path="/payment-error" element={<PaymentError />} />
          <Route exact path="/payment-success" element={<PaymentSuccess />} />
        </Route>
        <Route element={<AppLayout />}>
          {role === '1' && <>
            <Route exact path="/admin/dashboard" element={<Index />} />
            <Route exact path="/admin/caregivers" element={<ManageCaregiver />} />
            <Route exact path="/admin/productions/updateEmployer/:id" element={<UpdateEmployeer />} />
            <Route exact path="/admin/productions" element={<Manageemployer />} />
            <Route exact path="/admin/productions/archive-employers" element={<ArchivedEmployers />} />
            <Route exact path="/admin/productions/finished-productions" element={<FinishedProd />} />
            <Route exact path="/admin/productions/employerRequests" element={<EmployeerRequests />} />
            <Route exact path="/admin/caregivers/updateCaregiver/:id" element={<UpdateCaregiver />} />
            <Route exact path="/admin/productions/productionFocused/:id" element={<AddProductionFocused />} />
            <Route exact path="/admin/unions" element={<ManageUnion />} />
            <Route exact path='/admin/dashboard/notifications' element={<Notifications />} />
            <Route exact path='/admin/caregivers/archive-caregivers' element={<ArchiveCaregivers />} />
            <Route exact path='/admin/caregivers/cancelled-bookings' element={<BlockedCaregivers />} />
            <Route exact path='/admin/generatereport' element={<Report />} />
            <Route exact path='/admin/payrollgenerate' element={<PayrollGenerate />} />
            <Route exact path='/admin/payrollapproval' element={<PayrollApprove />} />
            <Route exact path='/admin/payrollgenerate/new' element={<NewPayroll />} />
            <Route exact path='/admin/payrollgenerate/view' element={<NewPayroll />} />
            <Route exact path='/admin/journals' element={<Journalslist />} />
            <Route exact path='/admin/journals/new' element={<AddJournals />} />
          </>}
          {role === '2' &&
            <>
              <Route exact path="/employer/dashboard" element={<EmployerDashboard />} />
              <Route exact path="/employer/employees" element={<ManageEmployee />} />
              <Route exact path="/employer/addfunds" element={<AddFunds />} />
              <Route exact path="/employer/unions" element={<ManageUnion />} />
              {/* <Route exact path="/employer/settings" element={<Settings />} /> */}
              <Route exact path="/employer/calendar" element={<CalenderListing />} />
              <Route exact path="/employer/calendar/crew_schedule" element={<CrewSchedule />} />
              <Route exact path="/employer/calendar/crew_schedule/edit" element={<CrewSchedule />} />
              <Route exact path="/employer/unions/union-request-approval" element={<UnionRequestApproval />} />
              <Route exact path="/employer/employees/employee-request-approval" element={<EmployeeRequestApproval />} />
              <Route exact path="/employer/employees/employee-archive" element={<EmployeeArchive />} />
              <Route exact path="/employer/unions/unions-archive" element={<UnionArchive />} />
              <Route exact path="/employer/employees/addemploye" element={<AddEmployer />} />
              <Route exact path="/employer/employees/union-request-approval" element={<UnionRequestApproval />} />
              <Route exact path="/employer/unions/updateUnion" element={<Details />} />
              <Route exact path="/employer/profile" element={<EmployerProfile />} />
              <Route exact path="/employer/addfunds/success_confirmation" element={<SuccessConfirmation />} />
              <Route exact path='/employer/dashboard/notifications' element={<Notifications />} />
              <Route exact path='/employer/employees/familymembers' element={<FamilyAccount />} />
            </>}
          {role === '3' &&
            <>
              <Route exact path="/employee/dashboard" element={<EmployeeDashboard />} />
              <Route exact path="/employee/calendar" element={<CalenderListing />} />
              <Route exact path="/employee/productions" element={<SelectProductions />} />
              <Route exact path="/employee/productions/finished" element={<FinishedProd />} />
              <Route exact path="/employee/productions/focused-productions/:id" element={<Focusedprod />} />
              <Route exact path="/employee/requests" element={<ChildCare />} />
              <Route exact path="/employee/requests/success/:id" element={<Success />} />
              <Route exact path="/employee/booking" element={<Bookings />} />
              <Route exact path="/employee/booking/caregiverProfile/:id" element={<CaregiverInfo />} />
              <Route exact path="/employee/profile" element={<Profile />} />
              <Route exact path="/employee/familymembers" element={<FamilyAccount />} />
              <Route exact path='/employee/dashboard/notifications' element={<Notifications />} />
            </>}
          {role === '4' &&
            <>
              <Route exact path="/union/dashboard" element={<UnionDashboard />} />
              <Route exact path="/union/productions" element={<SelectProductions />} />
              <Route exact path="/union/productions/finished" element={<FinishedProd />} />
              <Route exact path="/union/balances" element={<EmployerFundingTabs />} />
              <Route exact path="/union/profile" element={<Settings />} />
              <Route exact path="/union/productions/detail/:id" element={<ProductionDetails />} />
              <Route exact path="/union/success_confirmation" element={<SuccessConfirmation />} />
              <Route exact path='/union/dashboard/notifications' element={<Notifications />} />
            </>}
          {role === '5' &&
            <>
              <Route exact path="/caregiver/requests" element={<BookingRequests />} />
              <Route exact path="/caregiver/calendar" element={<AvailabilityCalender />} />
              <Route exact path="/caregiver/dashboard" element={<DashBoard />} />
              <Route exact path="/caregiver/profile" element={<CareGiverProfile />} />
              <Route exact path='/caregiver/dashboard/notifications' element={<Notifications />} />
              <Route exact path="/caregiver/requests/view-profile" element={<Settings />} />
            </>}
          {role === "1" || role === "2" || role === "3" || role === "4" || role === "5" ||
            <>
              <Route exact path='/notifications' element={<Notifications />} />
            </>
          }

          <Route exact path="/employers/addproduction" element={<AddProduction />} />
          <Route exact path="/successConfirmation" element={<SuccessConfirmation />} />
          <Route exact path="*" element={<Navigate to="/" replace />} />

        </Route>
      </Routes>

    </>
  );
}

export default App;
