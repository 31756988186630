import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore ,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,} from 'redux-persist'; 
import loginReducer from './Reducers/Login/loginSlice'; 
import inviteReducer from './Reducers/EmailInvite/inviteSlice'
import employeeReducer from './Reducers/EmployeeReducer/EmployeeSlice';
import unionReducer from './Reducers/UnionReducer/unionSlice';
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'

import {
  
} from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
}



const rootReducer = combineReducers({ 
  login: loginReducer, 
  invite :  inviteReducer,
  employee : employeeReducer,
  union :unionReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:  persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})


export const persistor = persistStore(store)