import React, { useState, useRef ,useEffect} from "react";

import Pie from "react-apexcharts";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { employerProdFunding } from "../Services/https/Employer/Employer";

import Loader from "../shared/Loader";

import info from "../assets/Images/info.png";

const FundsChart = () => {
  const [options, setOptions] = useState({});
  const [fund, setFund] = useState(null);
  const [loader, setLoader] = useState(true);
  let post = useRef();
  const {t}=useTranslation();
  const location = useLocation();
  const {role_data}=useSelector(state=>state?.login)
  const productionId = useSelector((state) => state?.invite?.production_id);
  const unionid =useSelector(state=>state?.union?.production_id);
  const employeeProdID= useSelector(state=>state?.employee?.productionId);
  post = Math.trunc((fund?.spent_amount / fund?.total_amount) * 100);

  useEffect(() => {
    const id= role_data=="4"? unionid :role_data==="3" ? employeeProdID: productionId;
    employerProdFunding(id)
      .then((response) => {
        setFund(response?.data?.data);
        setOptions({
          series: [`${post}`],
          options: {
            chart: {
              height: 350,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "70%",
                },
                dataLabels: {
                  name: {
                    offsetY: -10,
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: 800,
                    formatter: () => {
                      return [
                        `$${response?.data?.data?.spent_amount}`,
                        `$${response?.data?.data?.total_amount}`,
                      ];
                    },
                  },
                  value: {
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: 800,

                    show: false,
                  },
                },
              },
            },

            fill: {
              colors: ["#44B5AE"],
            },
            labels: [`${fund?.spent_amount}`, `${fund?.total_amount}`],
          },
        });

        setLoader(false);
      })
      .catch((error) => {
        return error;
      });
  }, [post,employeeProdID,unionid]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
        {
          role_data==="3" && <div className="mb-2" data-tooltip-content="hello world">
          <div className={role_data==="3"&& "tooltips"}>
            <img src={info} alt="no preview available"></img>
            {
              role_data==="3" && <div>You can spend upto ${fund?.remaining_employee_amount} more</div>
             }
          </div>
        </div>
        }
        <div className="">
          <Pie
            options={options.options}
            series={options.series}
            type="radialBar"
            labels={options.labels}
            height={role_data ==="3" ||(role_data==="4" && location?.pathname === "/union/dashboard") ?350:250}
          />
        </div>
        {
          (role_data==="3" || (role_data==="4" && location?.pathname === "/union/dashboard") ) &&  <div className="d-flex flex-column p-5">
          <button type="button" className="btn btn-info h52 w100">{t('Union.Dashboard.Spent')}: {`$${fund?.spent_amount}`}</button>
  
          <button type="button" className="btn btn-outline-info h52 w100 mt-3">{t('Union.Dashboard.Remaining')}: {`$${fund?.remaining_amount}`}</button>
          </div>
        }
        </>
      )}
    </>
  );
};

export default FundsChart;
