import React,{ useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

import { getemployees } from '../Services/https/Employer/Employer'

import Loader from '../shared/Loader'

function  EmployeCard({ data, change }) {
    const {t}=useTranslation();
    const [employees, setEmployees] = useState([])
    const [url, setUrl] = useState(null)
    const [loader, setLoader] = useState(true);
    const { role_data } = useSelector((state) => state.login);

    useEffect(() => {
        getemployees(data).then(response => {
            setEmployees(response?.data?.data?.paginate?.data)
            setUrl(response?.data?.base_url)
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            return err;
        })
    }, [data, change])

    return (


        <div className='row '>
            {loader && <Loader />}
            {
                employees?.slice(0, 8)?.map((emp, index) => {

                    return (
                        <div className= {role_data==="4"?"employers_box col-sm-3" : "employers_box col-sm-3" } key={index}>
                            <ul className="productions_list">
                                <li key={index}>
                                    <img src={emp?.profile_image_url} alt="" />
                                    <div className="d-inline-flex w100p">

                                        <h5>{emp?.first_name}</h5>
                                        <h6>{emp?.production_employee_role == null || emp?.production_employee_role == "null" ? t('Union.Dashboard.Department') : emp?.production_employee_role.charAt(0).toUpperCase() + emp?.production_employee_role.slice(1).toLowerCase()}</h6>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    )
                })
            }





        </div>
    )
}

export default EmployeCard