
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {Formik} from "formik";
import InputMask from "react-input-mask";

import Modal from "../shared/Modal";
import Button from "react-bootstrap/Button";

import FamilyLogo from '../assets/Images/icon-park-solid_family.png'

import { createFamilyAccount, deleteFamilyMember, familyCount, getFamilyMemberInfo, updateFamilyMember } from "../Services/https/Employee/Employee";
import Loader from "../shared/Loader";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { loginState } from ".././Reducers/Login/loginSlice";

const AddFamilyAccount = ({ countmembers }) => {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const location = useLocation();
  const [preview, setPreview] = useState(null);
  const [show, setShow] = useState(false);
  const [showform, setShowForm] = useState(false);
  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false)
  const [productionlist, setProdList] = useState([]);
  const [errorMessage, setErrorMessage] = useState({})
  const [avatar, setAvatar] = useState(null);
  const [memberid, setMemberId] = useState(null);
  const [member, setMember] = useState(null);
  const [disable,setDisable]=useState(true);
  const logindata = useSelector(state => state?.login);

  useEffect(() => {
    setLoader(true)
    FamilyCount();
  }, [])

  useEffect(() => {
    if (count?.length >= 1 && memberid) {
      memberInfo(memberid);
    }
  }, [memberid])

  const FamilyCount = () => {
     const id=logindata?.role_data==="3" ? "":location?.state?.id
    familyCount(id).then(response => {
      setCount(response?.data?.data);
      setMemberId(response?.data?.data[0]?.id)
      setLoader(false)
      countmembers(response?.data?.data)
    }).catch(err => {
      return err
    })
  }

  const memberInfo = (id) => {
    setLoader(true)
    getFamilyMemberInfo(id).then(response => {
      setMember(response?.data?.data);
      setLoader(false)
    }).catch(err => {
      return err
    })
  }

 useEffect(()=>{
  if(count?.length===0){
     dispatch(loginState({...logindata,...{hasfamily:false}}))
     setShowForm(false)
  }
  else{
    setShowForm(true)
    dispatch(loginState({...logindata,...{hasfamily:true}}))
  }

 },[count])

  const changeAvatar = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl)
    setAvatar(e.target.files[0])
  }


  const Schema = Yup.object().shape({
    first_name: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    last_name: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    email: Yup.string()
      .email(`${t("Admin.UpdateEmployeer.Details.Validations.Email")}`)
      .required(`${t("UpdateSchema.Required")}`),
    phone: Yup.string().required(`${t("Validation.InviteModal.Required")}`).nullable(),
    // production: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    // studio: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
  });


  const addTab = () => {
      const records = [...count]
      records.push({ first_name: "", last_name: "", email: "" ,phone:""});
      setCount(records);
      countmembers(records);
      setMember(null)
      setMemberId(null)
 }

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control field_view"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        disabled={disable}
        // defaultValue={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }

  useEffect(() => {
    Object.keys(errorMessage).map(key => {
      toast.error(errorMessage[key])
    })
  }, [errorMessage])
  return (
    <>
      {
        loader ? <Loader /> : (!showform) ?
         <>
          <div className="heading_box">
            <h3 className="title_h3"> {(logindata?.role_data ==="3" ? logindata?.name : location?.state?.name ) + " " + t('Employer.Add_employee.Family_list')}</h3>
            <div>
             
            </div>

            <div className="row mt-4 mb-2 ">
              <div className="col">

                <h6 className="title_h6"><img src={FamilyLogo} alt='Family icon' className="me-3"></img>{t('Employer.Add_employee.Family_account')}</h6>
              </div>

            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="info" className="btn-family mt-0 w_100" onClick={() => { setShowForm(true);setMember(null);setDisable(false) }}>
              {t('Employer.Add_employee.Create_individual_account')}
            </Button>

          </div> </> : 
          <div>
          <div className="fundingOverview_main">



            <div className="">
              <div className="heading_box">
                <h3 className="title_h3">{(logindata?.role_data ==="3" ? logindata?.name : location?.state?.name) + " " + t('Employer.Add_employee.Family_list')}</h3>
                <div>
                  
                </div>

                <div className="row mt-4 mb-2 ">
                  <div className="col">

                    <h6 className="title_h6"><img src={FamilyLogo} alt='Family icon' className="me-3"></img>{t('Employer.Add_employee.Family_account')}</h6>
                  </div>

                </div>
              </div>
              <div className="employee-booking-cards">
                <div className="row scrooltabs">
                  {
                    count?.length >= 1 && count?.map((list, index) => {
                    
                      return (
                        <div className="col-xl-3 ">
                          <Button onClick={()=>{memberInfo(list?.id);setMemberId(list?.id);setPreview(null);setDisable(true)}} className={memberid===list?.id ? 'employee-btn-active':""}>{t('Employer.Add_employee.Parent')} #{`${index + 1}`}</Button>
                        </div>
                      )
                    }

                    )
                  }
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div className=" mt-3 profile-pic">
                  <img src={preview ? preview : member?.profile_photo_url}alt="" style={{ height: "65px", width: "65px", borderRadius: "50%" }} />
                </div>

                <div className="profile-pic-text ms-3">
                  <label for="files" style={{ cursor: "pointer", textDecoration: "underline" }}>{t('Employee.Profile.Avatarlabel')}</label>
                  <input id="files" style={{ visibility: "hidden" }} type="file" onChange={(e) => changeAvatar(e)} disabled={disable}/>

                </div>
              </div>
            </div>


            <Formik
                initialValues={{first_name: member ? member?.first_name : "",
                last_name: member ? member?.last_name : "",
                email: member ? member?.email : "",
                phone: member ? member?.phone_number : "",
                production: "",
                studio: "",
                role: ""}}
                validationSchema={Schema}
                enableReinitialize={ true}
                onSubmit={(values) => { 
                  setLoader(true)
                  const formdata = new FormData();
                  formdata.append('first_name', values.first_name);
                  formdata.append('last_name', values?.last_name);
                  formdata.append('email', values?.email);
                  formdata.append('phone_number', values?.phone?.toString().replace(/\D+/g, ""));
                  avatar && formdata.append('profile_photo_url', avatar);
                  logindata?.role_data ==="2" && formdata.append('employee_id',location?.state?.id)
                  if(member){
                    updateFamilyMember(memberid,formdata).then(response => {
                      setLoader(false);
                      toast.success(response?.data?.message);
                      memberInfo(memberid);
                      setDisable(true)
                    }).catch(err => {
                      setLoader(false);
                      setErrorMessage(err?.response?.data?.errors)
                      return err
                    })
                  }
                  else{
                    createFamilyAccount(formdata).then(response => {
                      setLoader(false);
                      toast.success(response?.data?.message);
                      FamilyCount();  
                      setDisable(true)
                    }).catch(err => {
                      setLoader(false);
                      setErrorMessage(err?.response?.data?.errors)
                      return err
                    })
                  }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm
                    /* and other goodies */
                }) => (
                <form className="form_control" onSubmit={handleSubmit}>
              <div className="row mt-5">
                <div className="col-sm-4">
                  <Form.Group className="form-group" controlId="formBasicFirstName">
                    <Form.Label>{t('Employer.Add_employee.First_name')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="John"
                      name="first_name"
                      disabled={disable}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                  </Form.Group>
                  {errors.first_name && (
                    <div className="error">
                      {touched.first_name && <>{errors.first_name}</>}
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  <Form.Group className="form-group" controlId="formBasicLastName">
                    <Form.Label>{t('Employer.Add_employee.Last_name')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Doe"
                      name="last_name"
                      disabled={disable}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name} />
                  </Form.Group>
                  {errors.last_name && (
                    <div className="error">
                      {touched.last_name && <>{errors.last_name}</>}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>{t('Employer.Add_employee.Email')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Johndoe@mail.com" name="email"
                      onChange={handleChange}
                      disabled={disable}
                      onBlur={handleBlur}
                      value={values.email} />
                  </Form.Group>
                  {errors.email && (
                    <div className="error">
                      {touched.email && <>{errors.email}</>}
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  <Form.Group className="form-group" >
                    <Form.Label>{t('Employer.Add_employee.Phone')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <PhoneInput
                          name="phone"
                          // id="phone"
                          maskChar={null}
                          // defaultValue={detail?.phone}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="778-XXX-XXXX"
                        />
                    {/* <Form.Control type="text" placeholder="45677665" name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone} /> */}

                  </Form.Group>
                  {errors.phone && (
                    <div className="error">
                      {touched.phone && <>{errors.phone}</>}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>{t('Employer.Add_employee.Current_production')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="Diary of a Wimpy Kid"
                    >
                      {
                        productionlist?.map((list, index) => {
                          return (
                            <option selected>
                              {list?.production_name}
                            </option>
                          )
                        })
                      }

                    </Form.Select>
                  </Form.Group>
                  {errors.production && (
                    <div className="error">
                      {touched.production && <>{errors.production}</>}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Group className="form-group" controlId="formBasicFirstName">
                    <Form.Label>{t('Employer.Add_employee.Studio')}<span style={{ color: "red" }}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vancouver Film Studios"
                      name="studio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.studio}
                    />
                  </Form.Group>
                  {errors.studio && (
                    <div className="error">
                      {touched.studio && <>{errors.studio}</>}
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  <Form.Group className="form-group" controlId="formBasicLastName">
                    <Form.Label>{t('Employer.Add_employee.Role')}</Form.Label>
                    <Form.Control type="text" placeholder="Director"
                      name="role"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role} />
                  </Form.Group>
                </div>
              </div>

              <div className="family_btn_group mt-4">
                <div className="mb-4">
                  <Button variant="info" className="btn-family mt-0 w_100" disabled={!member} onClick={() => {addTab();setDisable(false)}}>
                    {t('Employer.Add_employee.Create_individual_account')}
                  </Button>
                </div>
                <div className="buttons_col">
                  {
                    disable ? <button className="btn btn-individual btn-add w_100" type="button" onClick={()=>setDisable(false)}>{t('Employer.Calender_listing.Edit')}</button> : 
                    <>
                    <button className="btn btn-individual btn-delete w_100 me-2 mb-4" type='button' disabled={!member} onClick={() => {setShow(true);}}>{t('Employer.Add_employee.Delete')}</button>
                    <button className="btn btn-individual btn-add w_100" type='submit'>{member ? t('Employer.Add_employee.Add'):t('Employer.Add_employee.Create')}</button>
                    </>
                  }
                 
                </div>
              </div>

              <Modal show={show} modalTitle={t('Employer.Add_employee.Delete_modal.Confirmation')} description={`${t('Employer.Add_employee.Delete_modal.Delete_user') + " " + `${member?.first_name}` + " " + `${member?.last_name}` } ${t('Employer.Add_employee.Delete_modal.Delete_list')}.`} footer={
                <div className="group_buttons mt-2">
                  <Button
                    variant="outline-info"
                    className="btn-lg"
                    onClick={() => setShow(false)}
                  >
                    {t('Employer.Add_employee.Delete_modal.Cancel')}
                  </Button>
                  <Button
                    variant="info"
                    className="btn-lg"
                    onClick={() => {
                      setLoader(true)
                      deleteFamilyMember(memberid).then(response=>{
                        toast.success(response?.data?.message)
                        FamilyCount();
                        setShow(false);
                        setDisable(true);
                      }).catch(err=>{
                        return err
                      });
                     
                    }}
                  >
                    {t('Employer.Add_employee.Delete_modal.Confirm')}
                  </Button>
                </div>
              } />
            </form>

                )}</Formik>
            
          </div>
        </div>
      }



    </>
  );
};

export default AddFamilyAccount;