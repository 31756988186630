import { axiosInstance } from "../interceptor";

export const CaregiverPersonalDetails = () => {
    return axiosInstance
      .get(
        `production/get-profile-personal-info`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const CaregiverRatesDetails = () => {
    return axiosInstance
      .get(
        `caregiver/get-rate-details`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const CaregiverUpdateRatesDetails = (data) => {
    return axiosInstance
      .put(
        `caregiver/update-rate-details`,data
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };


  export const OptionsData = (type,type1,type2,type3,type4,type5) => {
    return axiosInstance
      .get(
        `lookup?type%5B%5D=${type}&type%5B%5D=${type1}&type%5B%5D=${type2}&type%5B%5D=${type3}&type%5B%5D=${type4}&type%5B%5D=${type5}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const CaregiverUpdatePersonalDetails = () => {
    return axiosInstance
      .post(
        `production/update-profile-personal-info`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const CreateAvailability = (data) => {
    return axiosInstance
      .post(
        `caregiver/availability`,data
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const GetAvailabilities = () => {
    return axiosInstance
      .get(
        `caregiver/availability`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  export const getServiceArea=()=>{
    return axiosInstance.get(`city`)
  }

  export const GetSingleAvailability = (id) => {
    return axiosInstance
      .get(
        `caregiver/single-availability?id=${id}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  export const GetMonthData = (date,numberOfMonths) => {
    return axiosInstance
      .get(
        `caregiver/availabilityOfMonth?date=${date}&numberOfMonths=${numberOfMonths}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  export const bookingRequests = (status) =>{
    return axiosInstance.get(`caregiver/booking?status=${status}`)
  }

  export const updateRequests = (status,id,data) =>{
    return axiosInstance.put(`caregiver/action-booking?status=${status}&booking_request_id=${id}`,data)
  }

  export const CancelAvailability = (id) => {
    return axiosInstance.delete(`caregiver/cancel-availability?id=${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

 export const CheckInCaregiver=(bookingId,otp)=>{
  return axiosInstance.post(`/caregiver/booking-check-in?booking_request_id=${bookingId}`,otp)
 }

 export const CheckOutCaregiver=(bookingId,otp)=>{
  return axiosInstance.post(`/caregiver/booking-check-out?booking_request_id=${bookingId}`,otp)
 } 

 export const upcomingShifts=()=>{
  return axiosInstance.get(`caregiver/upcomming-shift`)
 } 

 export const caregiverEarnings=()=>{
  return axiosInstance.get(`caregiver/earning`)
 } 

 export const cancelBefore=(status,id,data)=>{
  return axiosInstance.put(`caregiver/cancel-booking?status=${status}&booking_request_id=${id}`,data)
 } 