import React,{useState,useEffect} from "react";

import {useTranslation} from 'react-i18next'
import {Table,Button} from "react-bootstrap";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { getchildBreakdown } from "../../../Services/https/comman/common";
import { reportRequest } from "../../../Services/https/Union/Union";

import NoRecords from "../../../shared/NoRecords";
import Loader from "../../../shared/Loader";
import { dateFormat } from "../../../shared/moment";

const ChildcareBreakdown = () => {
  const [records,setRecords]=useState([]);
  const [total,setTotal]=useState("");
  const [remaining,setRemaining]=useState("")
  const [loader,setLoader]=useState(true)
  const {t} = useTranslation();
  const {role_data,timezonevalue}=useSelector(state=>state?.login)
  const productionId = useSelector((state) => state?.invite?.production_id);
  const unionid =useSelector(state=>state?.union?.production_id);
  const lang = localStorage.getItem("i18nextLng");
 

  useEffect(()=>{
    const id = role_data==="4"?unionid:productionId
    getchildBreakdown(id).then(response=>{
      setRecords(response?.data?.data);
      setTotal(response?.data?.total);
      setRemaining(response?.data?.remaining_total);
      setLoader(false)
    }).catch(err=>{
      return err
    })
  },[timezonevalue])

  const Report = () => {
        reportRequest(unionid).then(response => {
            toast.success(response?.data?.data?.message)
        }).catch(err => {
            return err
        })
    
}

  return (
    <>
    {
     loader ? <Loader/> : records?.length === 0 ? <NoRecords/> :
      <div>
      <Table responsive className=" childcareBreakdown_main custom_table">
        <thead>
          <tr>
            <th>{t('Admin.ManageEmployeer.Dropdown.Employee')}</th>
            <th>{t('Union.Balances.Funds')}</th>
            <th>{t('Employee.Bookings.Caretaker')}</th>
            <th>{t('Caregiver.Profile.Rates.Style')}</th>
            <th width="380px">{t('Employer.Funding.Childcare_breakdown.Date_&_location')}</th>
          </tr>
        </thead>
        <tbody>
          {  records?.map((item, index) => {
            return (
              <tr key={index}>
                <td><p>{item.employee}</p> </td>
                <td>
                  <div>
                 {`$${item?.amount}`} </div>
                </td>
                <td>{item?.credited_name}</td>
                <td>{item?.style_care}</td>
                <td>
                  <div>
                    <p>{
                    dateFormat(item?.booking_start_date_time,"YYYY-MM-DD HH:mm:ss",lang)
                    } - {
                    dateFormat(item?.booking_end_date_time,"YYYY-MM-DD HH:mm:ss",lang)
                    } ({timezonevalue})</p> </div>
                  <div>
                  <div>{item.address}</div>
                  </div>
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      
      <div className="row text-center childcareBreakdown_main">
        <div className="col-lg-5 addFunds_footer childcareBreakdown_main d-flex align-items-center">
          <b style={{fontSize:"20px"}}> {t('Employer.Funding.Childcare_breakdown.Total')}: </b> <span className="ms-3">{records?.length===0 ?"$00.00":`$${total}`}</span> 
        </div>
        <div className="col-lg-5 addFunds_footer childcareBreakdown_main d-flex align-items-center">
          <b  style={{fontSize:"20px"}}>{t('Employer.Funding.Childcare_breakdown.Remaining')}: </b> <span  className="ms-3">{`$${remaining}`}</span> 
        </div>
      </div>

      <div className="mt-3"> <Button className=' btn-info' style={{ cursor: "pointer" }} onClick={() => Report()}><>{t('Employer.Dashboard.Request_report')}</></Button></div>

    </div>
    }
    
    </>
  );
};

export default ChildcareBreakdown;
