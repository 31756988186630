import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { prodlist } from "../../../Services/https/Union/Union";
import Loader from "../../../shared/Loader";
import Pagination from "../../../shared/Pagination";
import { prodList } from "../../../Services/https/Employee/Employee";
import { useSelector } from "react-redux";

const SelectProductions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { role_data } = useSelector((state) => state.login);
  const [loader, setLoader] = useState(false);
  const [searchkeyword, setSearchKeyword] = useState("");
  const [sort, setSort] = useState(null);
  const [handlesearch, sethandleSearch] = useState(false);
  const [initialpage, setInitialPage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const [productions, setProductions] = useState([]);
  const pageCount = Math.ceil(totalcount / 10);

  useEffect(() => {
    if (role_data === "4") {
      setLoader(true)
      prodlist(searchkeyword, initialpage, sort)
        .then((response) => {
          setProductions(response?.data?.data?.data);
          setTotalcount(response?.data?.data?.meta?.pagination?.total);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    }
  }, [role_data, handlesearch, initialpage,sort]);

  useEffect(() => {
    if (role_data === "3") {
      setLoader(true)
      prodList(searchkeyword, initialpage, sort)
        .then((response) => {
          setProductions(response?.data?.data?.data);
          setTotalcount(response?.data?.data?.total);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return err;
        });
    }
  }, [role_data, handlesearch, initialpage, sort]);

  const handelOnClick = (id,status,prodName) => {
    if (location?.pathname === "/union/productions") {
     status ===0 ? navigate(`/employee/productions/finished`,{state:{name:prodName}}) :  navigate(`/union/productions/detail/${id}`);
    } else {
      status===0?navigate(`/employee/productions/finished`,{state:{name:prodName}}):navigate(`/employee/productions/focused-productions/${id}`);
      
    }
  };

  const pageChange = (pageNum) => {
    setInitialPage(pageNum);
  };

  return (
    <>
      {loader && <Loader />}
      <h1 className="text-center request_title">
        {t("Employee.Productions.Select_production")}
      </h1>

      <div>
        <div className="search">
          <i className="fa fa-search" style={{cursor:"pointer"}} onClick={()=>{
            sethandleSearch((prev) => !prev);
            setLoader(true);
          }}></i>
          <input
            type="text"
            className="form-control"
            placeholder={t("Employee.Productions.Search_production")}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchKeyword(e.target.value);
                sethandleSearch((prev) => !prev);
                setLoader(true);
              }
            }}
          />
        </div>
      </div>

      <div className="filter_prod">
        <div className="common_select">
          <span className="arrange">{t("Admin.Dashboard.Dropdown.Sort")}</span>
          <select
            className="admin-dashboard-dropdown"
            name="language"
            id="language"
            selected="Studio"
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="studio_name">
              {t("Admin.Dashboard.Dropdown.Studio")}
            </option>
            <option value="production_name">
              {t("Admin.Dashboard.Dropdown.Production")}
            </option>
            <option value="city">
              {t("Employee.Productions.City")}
            </option>
          </select>
        </div>
      </div>

      <div className="dashboard_screen align_pagination">
        <div className="row ">
          {productions?.map((list, index) => {
            return (
              <React.Fragment key={index}>
                <div className="col-xl-4 mb-3">
                  <div className="white_box">
                    <h4 className="prod_title">{list?.production_name}</h4>
                    <div className="d-flex justify-content-between">
                      <span className="prod_loc">{list?.studio_name}</span>
                      <div style={{ cursor: "pointer" }}>
                        <svg
                          onClick={() => {
                            handelOnClick(list?.production_id,list?.status,list?.production_name);
                          }}
                          width="24"
                          height="22"
                          viewBox="0 0 24 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.1667 19.166L21.3334 10.9993L13.1667 2.83268M21.3334 10.9993L2.66675 10.9993L21.3334 10.9993Z"
                            stroke="#44B5AE"
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="prod-buttons" style={{ display: "flex" }}>
        {productions?.length >= 1 ? (
          <Pagination
            initialpage={initialpage}
            onpage={pageChange}
            pageCount={pageCount}
            style="true"
          />
        ) : null}
      </div>
    </>
  );
};
export default SelectProductions;
