import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { addFundsEmployer } from "../Services/https/Employer/Employer";
import { loginState } from "../Reducers/Login/loginSlice";
import Loader from "../shared/Loader";
import Modal from "../shared/Modal";
import toast from "react-hot-toast";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  linkemployee,
  switchprod,
  UnionConfirmProduction,
  UnionAddFunds,
  productiondata,
} from "../Services/https/Employer/Employer";
import { linkexpire } from "../Services/https/Admin/Admin";

const ConfirmProduction = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [employee, setEmployee] = useState(true);
  const [loader, setLoader] = useState(false);
  const [union, setUnion] = useState(false);
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState({
    amount: "",
    opt_out: 1
  });
  const [fundsConfirmation, setFundsConfirmation] = useState(false);
  const [productionData, setProductionData] = useState("");
  const state = useSelector((state) => state.invite);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = new URLSearchParams(location.search).get("inviteid");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const link_guid = new URLSearchParams(location.search).get("linkguid");
  const union_name = new URLSearchParams(location.search).get("union_name");
  const linkid = new URLSearchParams(location.search).get("link_guid");
  const amount = data.amount;

  console.log("state", state)

  useEffect(() => {
    if (location?.pathname === "/employee/confirm-production") {
      setEmployee(false);
    }
    if (location?.pathname === "/union/confirm-production") {
      setUnion(true);
      setEmployee(false);
    }
    if (employee) {
      linkexpire(id)
        .then((response) => {
          setProductionData(response?.data?.data);
        })
        .catch((error) => {
          return error
        });
    }
    if (union) {
      productiondata(prod_guid)
        .then((response) => {
          setProductionData(response?.data?.data);
        })
        .catch((err) => {
          return err;
        });
    }
    //  else {
    //   productiondata(prod_guid)
    //     .then((response) => {
    //       setProductionData(response?.data?.data);
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    // }
  }, []);

  // Employer Confirm Production

  const prodConfirmation = () => {
    if (!union) {
      const data = {
        production_id: state?.prod_id,
      };
      switchprod(data)
        .then((response) => {
          dispatch(loginState(location.state));
          navigate("/employer/dashboard");
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    }
    //Union Confirm Production
    else if (union) {
      const uniondata = {
        production_puid: prod_guid,
        link_guid: id,
        opt_out: data?.opt_out
      };
      console.log("here")
      // setLoader(true);
      // UnionConfirmProduction(uniondata, union_name)
      //   .then((response) => {
      //     setLoader(false);
      //     if (response?.status === 200) {
      //       const data = {
      //         production_id: prod_guid,
      //       };
      //       switchprod(data)
      //         .then((response) => {
      //           dispatch(loginState(location.state));
      //           navigate("/union/dashboard");
      //           setLoader(false);
      //         })
      //         .catch((err) => {
      //           return err;
      //         });
      //     }
      //   })
      //   .catch((error) => {
      //     return error
      //   });
    }
  };

  // Employee Confirm Production

  const employeeAcceptance = () => {
    if (!union) {
      const data = {
        production_puid: prod_guid,
        link_guid: id,
      };
      setLoader(true);
      linkemployee(data)
        .then((response) => {
          if (response?.status === 200) {
            // setLoader(true)
            const data = {
              production_id: prod_guid,
            };
            switchprod(data).then((response) => {
              dispatch(loginState(location.state));
              navigate("/employee/dashboard");
              setLoader(false);
            });
            // setLoader(false)
          }
        })
        .catch((err) => {
          return err;
        });
    } else if (union) {
      // Union Confirm Production
      const uniondata = {
        production_puid: prod_guid,
        link_guid: id,
        opt_out: data?.opt_out
      };
      setLoader(true);
      UnionConfirmProduction(uniondata, union_name)
        .then((response) => {
          setLoader(false);
          if (response?.status === 200) {
            const data = {
              production_id: prod_guid,
            };
            switchprod(data)
              .then((response) => {
                dispatch(loginState(location.state));
                navigate("/union/dashboard");
                setLoader(false);
              })
              .catch((err) => {
                return err;
              });
          }
        })
        .catch((error) => {
          return error
        });
    }
  };

  const employerAddFunds = async (funds) => {
    // debugger
    setLoader(true);
    if (!union) {
      // Employer Add Funds
      await addFundsEmployer(data, id)
        .then((res) => {
          if (res?.status === 200) {
            // setFundsConfirmation(true);
            window.open(res?.data?.data?.payment_url, "_self")
          } else if (res?.response?.status === 500) {
            setLoader(false);
            toast.error("Amount added cannot be empty");
          } else {
            setLoader(false);
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else if (union) {
      const data = {
        production_puid: prod_guid,
      };

      //  Union Add Funds

      await UnionAddFunds(data, amount, union_name)
        .then((res) => {
          if (res?.status === 200) {
            setLoader(false);
            setFundsConfirmation(true);
          } else if (res?.response?.status === 500) {
            setLoader(false);
            toast.error("Amount added cannot be empty");
          } else {
            setLoader(false);
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };
  const employerAddFunds2 = async () => {
    setLoader(true);
    await addFundsEmployer(data, id)
      .then((res) => {
        if (res?.status === 200) {
          console.log(res)
          window.open(res?.data?.data?.payment_url, "_self")
          // prodConfirmation();

          // setLoader(false);
        } else if (res?.response?.status === 500) {
          setLoader(false);
          toast.error("Amount added cannot be empty");
        } else {
          setLoader(false);
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <>
      <div className="confirm-production confirm-production-kids">
        {loader ? <Loader /> : null}
        <Modal
          show={fundsConfirmation}
          description={t("Confirmation.Funds_added")}
          footer={
            <div className="group_buttons mt-2">
              <Button
                variant="info"
                className="btn-lg"
                onClick={prodConfirmation}
              >
                {t("Confirmation.OK")}
              </Button>
            </div>
          }
        />
        <div className="container">
          <div>
            <h3 className="confirm-production-header">
              {" "}
              {t("Confirmation.Title")}
            </h3>
            {employee ? (
              <h5 className="confirm-production-text mt-3">
                {t('Confirmation.ContributionText')}
              </h5>
            ) : (
              <h5 className="confirm-production-text mt-3">
                {union ? t("Confirmation.UnionTitle") : t("Confirmation.Subtitle")}
              </h5>
            )}
            <p className="production-name mt-5">
              {productionData?.studio_name}
            </p>
            <p className="production-name">{productionData?.production_name}</p>
            {employee || union ? (
              <>
                <p className="production-name mt-5">{t('Confirmation.ContributionAmount')}</p>
                <div className="contribution ">
                  <div className="conti_input">
                    <input
                      className="contribution-amount"
                      name="contribution-amount"
                      placeholder=" 12,000.00"
                      onChange={(e) => setData({ amount: e.target.value, opt_out: 0 })}
                      // alias= 'currency'
                      // prefix= '$'
                      disabled={checked}
                      value={data?.amount}
                      type="number"
                      step="any"
                    />
                  </div>
                  <button
                    className="confirm-amount ms-3  mt-2"
                    onClick={employerAddFunds}
                    disabled={!data?.amount}
                  >
                    <u>{t('Admin.ManageEmployeer.Modal.Confirm')}</u>
                  </button>
                </div>
                <div className="mt-3 d-flex align-items-center">
                  <input
                    className="addFunds_check form-check-input custom_input_check"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    // disabled={data?.amount}
                    onChange={(e) => { setData({ amount: "", opt_out: 1 }); setChecked(!checked) }}
                  />
                  <label
                    className=" ms-4  addFunds_check_label"
                    for="flexCheckDefault"
                  >
                    {t(
                      "Employer.Funding.Add_funds.Optout"
                    )}
                  </label>

                </div>


              </>
            ) : null}
            <div className="mt-3 prod-buttons">
              {employee ? (
                <Button onClick={employerAddFunds2} className="next ms-1">
                  {t("Confirmation.Next")}
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() =>
                      navigate(
                        `/wrong-production?inviteid=${id}&prodid=${prod_guid}&linkguid=${linkid}`
                      )
                    }
                    className="wrong-production"
                  >
                    {t("Confirmation.Wrong")}
                  </Button>
                  <Button onClick={employeeAcceptance} className="next ms-1">
                    {t("Confirmation.Next")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmProduction;
