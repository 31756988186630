import { axiosInstance } from "../interceptor";

export const prodlist = (search, page,sort) => {
    return axiosInstance.get(
      `union/production?search=${search}&perpage=10&sortby=${sort}&page=${page}`
    );
  };

  export const UnionAddFundsInside = (data,id) => {
    return axiosInstance
      .post(`union/add-fund-stripe?union_id=${id}`, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  
  export const UnionDetails = () => {
    return axiosInstance
      .get(`/union/union-detail`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  export const unionProfile = () => {
    return axiosInstance.get(`/union/union-profile`)
      
  };

  export const updateUnionProfile = (data) => {
    return axiosInstance.put(`/union/update-union-profile`,data) ; 
  };

  export const reportRequest = (id) => {
    return axiosInstance.post(`union/send-report-request-union?production_id=${id}`) ; 
  };