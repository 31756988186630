import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getNotificationsList,
  delNotification,
  readNotifications,
} from "../Services/https/comman/common";
import Modal from "../shared/Modal";
import Loader from "../shared/Loader";

const Notifications = () => {
  const [notify, setNotify] = useState([]);
  const [deleterecord, setDeleteRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [read, setRead] = useState(false);
  const [del, setDel] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getNotificationsList().then((response) => {
      setNotify(response?.data?.data);
      setDeleteRecord([]);
      setRead(false);
      setLoader(false);
    });
  }, [del, read]);

  const recordstoDelete = (e, id) => {
    const records = [...deleterecord];
    if (e.target.checked) {
      records.push(id);
      setDeleteRecord(records);
    } else {
      const recs = records.filter((rec) => rec !== id);
      setDeleteRecord(recs);
    }
  };

  const MarkAsRead = (id, status) => {
    setLoader(true);
    readNotifications(id, status).then((response) => {
      setRead(true);
      setLoader(false);
    });
  };
  const ViewDetails = (id, url) => {
    readNotifications(id, 1).then((response) => {
      if (url) {
        navigate(url);
      }
      setRead(true);
    });
  };

  return (
    <>
      {show && (
        <Modal
          show={show}
          modalTitle={t("Admin.ManageEmployeer.Modal.Title")}
          description={
            `
            ${t("Admin.ManageEmployeer.Modal.Heading.start")} "` +
            `
            ${deleterecord.length} ` +
            `" 
            ${t("Notifications.End")}
            `
          }
          setShow={setShow}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("Admin.ManageEmployeer.Modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setShow((prev) => !prev);

                  delNotification(deleterecord)
                    .then((response) => {
                      setDel((prev) => !prev);
                    })
                    .catch((err) => {
                      return err;
                    });
                }}
              >
                {t("Admin.ManageEmployeer.Modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}
      {del ? (
        <Modal
          modalTitle={t("Notifications.delete")}
          show={del}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}

      {loader ? (
        <Loader />
      ) : (
        <div className="white_box manage_screen">
          <div className="noti_header search_box ">
            <h3 className="title_h3">{t("Notifications.Title")}</h3>
            <button
              type="button"
              className="btn btn-outline-secondary"
              style={{ boxShadow: "none" }}
              variant="outline-secondary"
              onClick={() => setShow(deleterecord.length < 1 ? false : true)}
            >
              {deleterecord.length} {t("Notifications.Selected")}
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 7.75V11.5M2.5 4H14.5L13.315 14.665C13.2744 15.032 13.0998 15.3712 12.8247 15.6175C12.5496 15.8638 12.1933 16 11.824 16H5.176C4.80673 16 4.45042 15.8638 4.17531 15.6175C3.9002 15.3712 3.72562 15.032 3.685 14.665L2.5 4ZM5.00875 1.86025C5.13006 1.60298 5.32203 1.3855 5.56225 1.23318C5.80248 1.08087 6.08106 0.999997 6.3655 1H10.6345C10.9191 0.999854 11.1978 1.08066 11.4382 1.23298C11.6785 1.38531 11.8706 1.60286 11.992 1.86025L13 4H4L5.00875 1.86025V1.86025ZM1 4H16H1ZM7 7.75V11.5V7.75Z"
                  stroke="#204288"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>

          <div className="row notification-container">
            <div className="col-12">
              <div className="card-body">
                {notify.length !== 0 ? (
                  <>
                    <table>
                      <tbody>
                      {notify &&
                        notify?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr >
                                <td>
                                  <Form.Check
                                    key={`${item?.id}`}
                                    className="custom_checkbox"
                                    aria-label={`option-${item?.id}`}
                                    name={`${item?.id}`}
                                    onClick={(e) => {
                                      recordstoDelete(e, item?.id);
                                    }}
                                  />
                                </td>
                                {item?.notificationdata.map((content) => {
                                  return (
                                    <React.Fragment key={index}>
                                    <td>
                                      <div
                                        className={
                                          notify && item?.is_read === 0
                                            ? "card-title"
                                            : "readContent"
                                        }
                                      >
                                        {content?.notification_content}
                                      </div>
                                    </td>
                                    </React.Fragment>
                                  );
                                })}

                                <td>
                                  <div>
                                    {notify && item?.is_read === 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => MarkAsRead(item?.id, 1)}
                                      >
                                        {`${t("Notifications.Mark")}`}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => MarkAsRead(item?.id, 0)}
                                      >
                                        {`${t("Notifications.Unread")}`}
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      onClick={() =>
                                        ViewDetails(item?.id, item?.click_url)
                                      }
                                    >
                                      {t("Notifications.Details")}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                        </tbody>
                    </table>
                  </>
                ) : (
                  <>No Notifications Yet...</>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
