import React, { useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {  useSelector } from "react-redux";
import InputMask from "react-input-mask"
import { useLocation } from "react-router-dom";

import Loader from "../../../shared/Loader";
import Details from "../../../Union/details";
import ModalDialog from "../../../shared/Modal";

import { getUserRole } from "../../../Services/https/Auth/Auth";

import DeleteAccount from "../../../shared/deleteAccount";

const Settings = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { role_data } = useSelector(state => state.login)
    const [settings, setSettings] = useState(true);
    const [loader, setLoader] = useState(true);
    const [details, setDetails] = useState(null);
    const [deletePopup, setDeletePopup] = useState(false);

    useEffect(() => {
        if (role_data === "4" && settings) {
            getUserRole().then(response => {
                setLoader(false);
                setDetails(response?.data?.data)
            }).catch(err => {
                return err
            })
        }
    }, [role_data, settings])

    useEffect(() => {
        if (role_data === "2" || role_data === "3" || role_data === "5") { setLoader(false) }

    }, [role_data])

    function PhoneInput(props) {
        return (
            <InputMask
                {...props}
                mask="999-999-9999"
                maskChar={null}
                name="phone"
                disabled
                value={props.value}
                onChange={props.onChange}
                defaultValue={details?.phone}
            />
        )
    }

    const closePopup = ()=>{
        setDeletePopup(false)
        }

    return (
        <>
            {loader && <Loader />}

            <div className="dashboard_screen">
                <div className="white_box">
                    <div className="d-flex align-items-center justify-content-between ">
                        <p className="title_h3 ">{role_data === "5" ? "Profile" : settings ? t('Settings') : t('Union.Details.Title')}</p>
                        {
                            role_data === "4" && <Button
                            type="button"
                            variant="danger"
                            onClick={() => setDeletePopup(true)}
                          >
                            {t('DeleteAccount')}
                          </Button>
                        }

                    </div>

                    {
                        role_data === "4"
                        && <div className="employee-booking-cards cards_mb">
                            <div className="row ">
                                <div className="col-xl-3">
                                    <Button
                                        onClick={() => {
                                            setSettings(true)
                                            setLoader(true)
                                        }}
                                        className={settings ? "employee-btn-active" : null}
                                    >
                                        {t("Settings")}
                                    </Button>
                                </div>
                                <div className="col-xl-3">
                                    <Button
                                        className={!settings ? "employee-btn-active" : null}
                                        onClick={() => {
                                            setSettings(false)
                                        }}
                                    >
                                        {t("Admin.UpdateEmployeer.Details.Title")}
                                    </Button>
                                </div>
                            </div>
                        </div>}
                    {
                        !settings ? <Details /> :
                            <Formik
                                initialValues={{ email: role_data === "5" ? state?.user[0]?.email : details?.email, phone: role_data === "5" ? state?.user[0]?.phone : details?.phone, name: role_data === "5" ? state?.user[0]?.client_name : details?.name }}
                                enableReinitialize={true}
                                onSubmit={(values) => { }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>


                                        <div className="row mb-3">
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="mb-2">{t('Employee.Setting.Pprofile')}</label>

                                                    <Form.Control
                                                        type="text"
                                                        placeholder=" Name"
                                                        name="name"
                                                        id="name"
                                                        disabled
                                                        className="field_view"
                                                        defaultValue={details?.name}
                                                        value={values?.name}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="mb-2">{t('Employee.Setting.Email')}</label>

                                                    <Form.Control
                                                        type="text"
                                                        placeholder="johndoe@example.com"
                                                        disabled
                                                        name="email"
                                                        id="email"
                                                        className="field_view"
                                                        defaultValue={details?.email}
                                                        value={values?.email}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="mb-2">{t('Employee.Setting.Number')}</label>
                                                    <PhoneInput
                                                        defaultValue={details?.phone}
                                                        value={values?.phone}
                                                        onChange={handleSubmit}
                                                        placeholder="773-XXX-XXXX"
                                                        name="phone"

                                                        className="form-control update_employeer_input"

                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </form>)}
                            </Formik>}
                </div>
            </div>

            <ModalDialog
          align='left'
          show={deletePopup}
          onHide={() => setDeletePopup(false)}
          component={<DeleteAccount close={closePopup}/>}
        />

        </>
    )
}

export default Settings