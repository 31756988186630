import React, { useState, useEffect } from "react";

import { Button, Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";

import Loader from "../shared/Loader";
import ModalDialog from "../shared/Modal";

import { SignUp } from "../Services/https/Auth/Auth";
import Logo from "../assets/Images/reelkids_logo_Dark-svg.svg";

import TermsAndConditions from "./Terms&cond";

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [pass, setPass] = useState(false);
  const [cpass, setCpass] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tandcondmodal, setTandcondModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [signUpTitle, setSignUpTitle] = useState("");
  const [role, setRole] = useState(null);
  const state = useSelector((state) => state.invite);
  const id = new URLSearchParams(location.search).get("inviteid");
  const prod_guid = new URLSearchParams(location.search).get("proid");
  const link_guid = new URLSearchParams(location.search).get("link_guid");
  const unionname = new URLSearchParams(location.search).get("union_name");
  const invite_type_id = new URLSearchParams(location.search).get("invite_type_id");

  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        className="form-control"
        mask="999-999-9999"
        name="phone"
        value={props.value}
        onChange={props.onChange}
      ></InputMask>
    );
  }

  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);

  useEffect(() => {
    if (id) {
      setRole(state?.role.toString());
    } else if (invite_type_id) {
      setRole(invite_type_id);
    } else {
      setRole("5");
    }
  }, []);

  useEffect(() => {
    switch (role) {
      case "1":
        setSignUpTitle(`${t("SignUp.AdminSignUp")}`);
        break;
      case "2":
        setSignUpTitle(`${t("SignUp.EmployerSignUp")}`);
        break;
      case "3":
        setSignUpTitle(`${t("SignUp.EmployeeSignUp")}`);
        break;
      case "4":
        setSignUpTitle(`${t("SignUp.UnionSignUp")}`);
        break;
      case "5":
        setSignUpTitle(`${t("SignUp.CaregiverSignUp")}`);
        break;
    }
  }, [role]);

  const signupSchema = Yup.object().shape({
    fullName: Yup.string().required(t("ResetSchema.Required")),
    password: Yup.string()
      .required(t("ResetSchema.Required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t("ResetSchema.Weak")
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("ResetSchema.Confirm")
    ),
    phone: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, `${t("UpdateSchema.Phone")}`).required(`${t("UpdateSchema.Required")}`),

    email: Yup.string()
      .email(t("SignUp.Validations.Email"))
      .required(t("ResetSchema.Required")),
    termsAndConditions: Yup
      .bool()
      .oneOf([true], t("SignUp.Validations.Terms")),
  });

  const navigation = (address) => {
    navigate(`${address}`);
  };

  return (
    <section className="auth_layout login_screen signup_screen">
      <div className="left_box"></div>

      <ModalDialog show={tandcondmodal} align="left" terms={true} modalTitle={t('SignUp.Terms')} onHide={() => setTandcondModal(false)} component={<TermsAndConditions show={tandcondmodal} setShow={setTandcondModal} />} />

      {/* Loader condition  */}
      {loader ? <Loader /> : null}

      <div className="right_box">
        <a href="/" className="logo">
          <img src={Logo} />
        </a>
        <h3 className="title_h3">{signUpTitle}</h3>

        <div className="auth_form">
          <Formik
            initialValues={{
              fullName: "",
              email: id ? state.email : "",
              phone: "",
              password: "",
              confirmPassword: "",
              termsAndConditions: false,
            }}
            enableReinitialize={true}
            validationSchema={signupSchema}
            onSubmit={async (values) => {
              setLoader(true);
              const data = {
                name: values.fullName,
                email: id == !"null" ? state.email : values.email,
                phone: values.phone.replace(/\D+/g, ""),
                password: values.password,
                confirm_password: values.confirmPassword,
              };

              // Signup API consumption

              SignUp(data, id, role, unionname)
                .then((response) => {
                  setLoader(false);
                  if (response?.status === 200) {
                    role === "5" ? navigate('/signup/confirmation') : navigate(`/signup/confirmation?inviteid=${id}&proid=${prod_guid}&link_guid=${link_guid}&union_name=${unionname}`);
                  } else if (response?.response?.status === 422) {
                    toast.error(response?.response?.data?.message);
                  }
                })
                .catch((error) => {
                  setErrorMessage(error?.response?.data?.errors);
                  setLoader(false);
                });
            }}
          >
            {({
              values,
              initialValues,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group
                    className="form-group-1"
                    as={Col}
                    md="12"
                    controlId="validationCustom03"
                  >
                    <Form.Label>{t("SignUp.FullName")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder="John Doe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                    />
                    {errors.fullName && (
                      <div className="error">
                        {touched.fullName && <>{errors.fullName}</>}
                      </div>
                    )}
                  </Form.Group>

                  {id !== "null" && id !== "" && id !== null ? (
                    <Form.Group
                      className="form-group-1"
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                    >
                      <Form.Label>{t("SignUp.Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="johndoe@example.com"
                        value={state.email}
                        defaultValue={state.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={true}
                      />
                      {
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      }
                    </Form.Group>
                  ) : (
                    <Form.Group
                      className="form-group-1"
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                    >
                      <Form.Label>{t("SignUp.Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        // defaultValue={state.email}
                        placeholder="johndoe@example.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                      />

                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      {errors.email && (
                        <div className="error">
                          {touched.email && <>{errors.email}</>}
                        </div>
                      )}
                    </Form.Group>
                  )}

                  <Form.Group
                    className="form-group-1"
                    as={Col}
                    md="12"
                    controlId="validationCustom03"
                  >
                    <Form.Label>{t("SignUp.Phone")}</Form.Label>
                    <PhoneInput
                      name="phone"
                      maskChar={null}
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="778-XXX-XXXX"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("SignUp.Validations.Phone")}
                    </Form.Control.Feedback>
                    {errors.phone && (
                      <div className="error">
                        {touched.phone && <>{errors.phone}</>}
                      </div>
                    )}
                  </Form.Group>

                  {/* <Row className="mb-3"> */}
                  <Form.Group
                    className="form-group-1 "
                    as={Col}
                    md="6"
                    controlId="validationCustom03"
                  >
                    <Form.Label>{t("SignUp.Password")}</Form.Label>
                    <div className="input_icon">
                      <Form.Control
                        placeholder="********"
                        name="password"
                        type={pass ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      {!pass ? (
                        <i
                          className=" view_password fa-solid fa-eye-slash"
                          onClick={() => setPass((prev) => !prev)}
                        ></i>
                      ) : (
                        <i
                          onClick={() => setPass((prev) => !prev)}
                          className="view_password fa-solid fa-eye"
                        ></i>
                      )}
                    </div>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    {errors.password && (
                      <div className="error">
                        {
                          touched.password && errors.password

                          // "Must Contain 8 Characters, One Uppercase, One Special Case Character"
                        }
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="form-group-1 "
                    as={Col}
                    md="6"
                    controlId="validationCustom03"
                  >
                    <Form.Label>{t("SignUp.Confirm")}</Form.Label>
                    <div className="input_icon">
                      <Form.Control
                        placeholder="********"
                        name="confirmPassword"
                        type={cpass ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      {!cpass ? (
                        <i
                          className=" view_password fa-solid fa-eye-slash"
                          onClick={() => setCpass((prev) => !prev)}
                        ></i>
                      ) : (
                        <i
                          onClick={() => setCpass((prev) => !prev)}
                          className="view_password fa-solid fa-eye"
                        ></i>
                      )}
                    </div>
                    {errors.confirmPassword && (
                      <div className="error">
                        {touched.password && errors.confirmPassword}
                      </div>
                    )}
                  </Form.Group>
                  <span className="permanant">
                    {t("SignUp.Validations.Password")}
                  </span>
                </Row>
                {/* </Row> */}
                <div className="terms_block">
                  <input
                    className="form-check-input signup-checked"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    name="termsAndConditions"
                    onChange={handleChange}
                  />
                  <label
                    className=" ms-3 permanant"
                    for="flexCheckDefault"
                  >
                    {t("SignUp.Agree")} <a className="signUpLogin">
                      <u onClick={() => setTandcondModal(true)}>{t("SignUp.Terms")} </u>
                    </a>
                  </label>


                </div>
                <div>
                  {errors.termsAndConditions && (
                    <div className="error">
                      {touched.termsAndConditions && errors.termsAndConditions}
                    </div>
                  )}
                </div>

                <Button
                  type="submit"
                  variant="info"
                  className="btn-lg w100p justify-content-center d-inline-block"
                >
                  {t("SignUp.Title")}
                </Button>
              </Form>
            )}
          </Formik>

          <div className="sign-up-here">
            <a>{t("SignUp.Already")}</a>
            <a onClick={() => navigation("/login")} className="signUpLogin">
              <u>{t("SignUp.SignIn")}</u>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
