import React, { useState, useEffect } from "react";

import { Button,Form } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from 'react-datepicker'
import { useFormik } from "formik";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import fr from 'date-fns/locale/fr-CA';
import en from 'date-fns/locale/en-US';
import * as Yup from "yup";
import Select from 'react-select'
import Autocomplete from "react-google-autocomplete";

import "react-datepicker/dist/react-datepicker.css";

import { getchildrens, lookupdata, requestChildcare } from "../../../Services/https/Employee/Employee";

import Loader from "../../../shared/Loader";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ModalDialog from "../../../shared/Modal";
import UserTimezone from "../../../shared/UserTimezone";

import { getStyleOfCare } from "../../../Services/https/comman/common";
registerLocale('fr', fr);
registerLocale('eng', en);

const Childcare = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [style, setStyle] = useState([]);
  const [confirmation,setConfirmation]=useState(false)
  const [workingmode, setWorkingMode] = useState([]);
  const [childrens, setChildrens] = useState([]);
  const [loader, setLoader] = useState(true);
  const [timeerrormessage, setTimeErrorMessage] = useState(false);
  const [city, setCity] = useState(null);
  const [childrenids, setChildrenIds] = useState([]);
  const [isDisable,setdisable]=useState(false);
  const [manual,setManual]=useState("0");
  const [alertmodal, setAlertModal] = useState(false);
  const [employeealertmodal, setEmployeeAlertModal] = useState(false);
  const CheckboxValue = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Show"]
  const localization = localStorage.getItem('i18nextLng');
  const { productionId } = useSelector(state => state.employee);
  const { timezonevalue } = useSelector(state => state.login);
  const options = [0,1,2,3,4,5,6,7,8,9,10]

  useEffect(() => {
    lookupdata('care_style', "where_working").then(response => {
      setLoader(false)
      // setStyle(response?.data?.data?.care_style);
      setWorkingMode(response?.data?.data?.where_working)
    }).catch(err => {
      return err
    })

    getStyleOfCare(productionId).then(response=>{
      setStyle(response?.data?.data?.care_style?.split(','))
    }).catch(err=>{
      return err
    })
  }, [localization])

  useEffect(() => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const enddatetime= moment(startDate).format("MM/DD/YYYY") +" " + end.format("HH:mm")
    if(end.isBefore(start)){
      setTimeErrorMessage(true)
    }
    else{
      setTimeErrorMessage(false)
    }
  }, [startTime, endTime,startDate]);

  useEffect(()=>{
    getchildrens().then(response => {
      setChildrens(response?.data?.data?.data);
    }).catch(err => {
      return err
    })
  },[])

  const getIds = (e) =>{
     const ids = e?.map(ids=>ids?.value);
     setChildrenIds(ids)
  }


  const Schema = Yup.object().shape({
    address: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    workingMode: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    care_needs: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    end_time: Yup.string().required(`${t("Validation.InviteModal.Required")}`).nullable(),
    start_time: Yup.string().required(`${t("Validation.InviteModal.Required")}`).nullable(),
    // start_date: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    // numberofChildren: Yup.string().required(`${t("UpdateSchema.Required")}`).matches('^[+]?0*[1-9]{1,9}?$', `${t('Employee.Requests.Value')}`)
  });

  const formik = useFormik({
    initialValues: {
      address: "",
      workingMode: "",
      styleofCare: "",
      repeat_request: "",
      numberofChildren: "",
      start_time: startTime,
      end_time: endTime,
      care_needs:"",
      childrenoutside:""
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const data = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        start_time:moment(startDate).format("YYYY-MM-DD") + " "+ moment(startTime).format("HH:mm:ss"),
        end_time:moment(startDate).format("YYYY-MM-DD") +" "+ moment(endTime).format("HH:mm:ss"),
        style_care: values.styleofCare,
        working: values.workingMode,
        address: values.address,
        repeat_request: values?.repeat_request && values?.repeat_request?.join(","),
        number_of_kids:childrenids?.length,
        number_of_kids_ids:childrenids,
        is_manual_pay:manual,
        care_needs:values.care_needs,
        friends_kids:values.childrenoutside
      };
      if(timeerrormessage){
        setConfirmation(true);
      }
      else{
        childCare(data)
      }
    }

  });


  const childCare = (data) =>{
    setLoader(true)
    requestChildcare(productionId, data, city).then(response => {
          toast.success(response?.data?.message);
           setLoader(false);
           setConfirmation(false);
           setAlertModal(false);
           setEmployeeAlertModal(false);
           navigation(`/employee/requests/success/${response?.data?.data?.id}`);
           setManual("0");
         }).catch(err => {
          if(err?.response?.status===409){
            setAlertModal(true);
            setManual("1");
            setLoader(false);
            setConfirmation(false)
          }
          else if(err?.response?.status===406){
            setEmployeeAlertModal(true);
            setManual("1");
            setLoader(false);
            setConfirmation(false)
          }
          else{
        setLoader(false)
        setConfirmation(false)
           toast.error(err?.response?.data?.message)
           return err
          }
        
         })
  }

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left"
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '8px',
      backgroundColor: '#f2f2f2',
      padding: '10px'

    }),
    indicatorSeparator: () => { },
    dropdownIndicator: base => ({
      ...base,
      color: "#000" // Custom colour
    })
  }

  const CustomOption = ({ innerProps, isDisabled ,children}) =>{
    return (
      <div {...innerProps} className="d-flex justify-content-between flex-row-reverse p-2"><input className="form-check-input custom_input_check" type={"checkbox"}/>{children}</div>

    )
  }
  
;

  return (
    <>
      {loader && <Loader />}
      {confirmation && <ModalDialog  onHide={() => setConfirmation(false)} show={confirmation} modalTitle={`${t("Employee.Requests.Modal.Title")} ${moment(startDate).locale(localization==="fr"?"fr":"en").format('MMMM DD , YYYY')} ${moment(startTime).format("hh:mm A")} ${timezonevalue} ${t("Caregiver.Availability_calender.to")} ${moment(addDays(startDate,1)).locale(localization==="fr"?"fr":"en").format('MMMM DD , YYYY')} ${moment(endTime).format("hh:mm A")} ${timezonevalue}. ${t("Employee.Requests.Modal.Proceed")}`} footer={
        <div className="group_buttons mt-5">
        <Button
          variant="outline-info"
          className="btn-lg"
          onClick={()=>setConfirmation(false)}
        >
          {t("Employee.Requests.No")}
        </Button>
        <Button
          variant="info"
          className="btn-lg"
         onClick={()=>{
          const data = {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            start_time:moment(startDate).format("YYYY-MM-DD") +" "+ moment(startTime).format("HH:mm:ss"),
            end_time:moment(addDays(startDate,1)).format('YYYY-MM-DD') +" "+ moment(endTime).format("HH:mm:ss"),
            style_care: formik.values.styleofCare,
            working: formik.values.workingMode,
            address:formik.values.address,
            repeat_request: formik.values?.repeat_request && formik.values?.repeat_request?.join(","),
            number_of_kids:childrenids?.length,
            number_of_kids_ids:childrenids,
            is_manual_pay:manual,
            care_needs:formik.values.care_needs,
            friends_kids:formik.values.childrenoutside
          };
          childCare(data)
         }}
        >
          {t("Employee.Requests.Yes")}
        </Button>
      </div>
      }/>}
      {alertmodal && <ModalDialog  onHide={() => setAlertModal(false)} show={alertmodal} modalTitle={t('Employee.Requests.Fundsalert2')} footer={
        <div className="group_buttons mt-5">
        <Button
          variant="outline-info"
          className="btn-lg"
          onClick={()=>{setAlertModal(false);setManual("0")}}
        >
          {t("Employee.Requests.No")}
        </Button>
        <Button
          variant="info"
          className="btn-lg"
         onClick={()=>{
          const data = {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            start_time:moment(startDate).format("YYYY-MM-DD") +" "+ moment(startTime).format("HH:mm:ss"),
            end_time:timeerrormessage ? moment(addDays(startDate,1)).format('YYYY-MM-DD') +" "+ moment(endTime).format("HH:mm:ss") : moment(startDate).format("YYYY-MM-DD") +" "+ moment(endTime).format("HH:mm:ss"),
            style_care: formik.values.styleofCare,
            working: formik.values.workingMode,
            address:formik.values.address,
            repeat_request: formik.values?.repeat_request && formik.values?.repeat_request?.join(","),
            number_of_kids:childrenids?.length,
            number_of_kids_ids:childrenids,
            is_manual_pay:manual,
            care_needs:formik.values.care_needs,
            friends_kids:formik.values.childrenoutside
          };
          childCare(data)
         }}
        >
          {t("Employee.Requests.Yes")}
        </Button>
      </div>
      }/>}
      {employeealertmodal && <ModalDialog  onHide={() => setEmployeeAlertModal(false)} show={employeealertmodal} modalTitle={t('Employee.Requests.Fundsalert1')} footer={
        <div className="group_buttons mt-5">
        <Button
          variant="outline-info"
          className="btn-lg"
          onClick={()=>{setEmployeeAlertModal(false);setManual("0")}}
        >
          {t("Employee.Requests.No")}
        </Button>
        <Button
          variant="info"
          className="btn-lg"
         onClick={()=>{
          const data = {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            start_time:moment(startDate).format("YYYY-MM-DD") +" "+ moment(startTime).format("HH:mm:ss"),
            end_time:timeerrormessage ? moment(addDays(startDate,1)).format('YYYY-MM-DD') +" "+ moment(endTime).format("HH:mm:ss") : moment(startDate).format("YYYY-MM-DD") +" "+ moment(endTime).format("HH:mm:ss"),
            style_care: formik.values.styleofCare,
            working: formik.values.workingMode,
            address:formik.values.address,
            repeat_request: formik.values?.repeat_request && formik.values?.repeat_request?.join(","),
            number_of_kids:childrenids?.length,
            number_of_kids_ids:childrenids,
            is_manual_pay:manual,
            care_needs:formik.values.care_needs,
            friends_kids:formik.values.childrenoutside
          };
          childCare(data)
         }}
        >
          {t("Employee.Requests.Yes")}
        </Button>
      </div>
      }/>}
      <div>
        <div className="dashboard_screen">
          <div className="white_box">
            <div>
              <h1 className="text-center request_title">{t('Employee.Requests.Request_childcare')}</h1>
              <div className="">
                <p className="text-center addFunds_body">
                  {t('Employee.Requests.Info')}
                </p>
                <div className="addFunds_body">
                  <UserTimezone/>
                  </div>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <div className="row mb-4">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <DatePicker className="field_view form-control" name="start_date" id="start_date" minDate={new Date()}
                          locale={localization === "fr"? "fr" : "en"} selected={startDate} onChange={(date) => { setStartDate(date); formik.setFieldValue("start_date", date) }} />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-xl-6 childcare_timings">
                        <DatePicker
                          className="field_view form-control "
                          locale={localization === "en" ? "en" : "fr"}
                          placeholderText={t('Caregiver.Calendar.CalendarPopUp.StartTime')}
                          selected={startTime}
                          onChange={(date) => {  setStartTime(date); formik.setFieldValue('start_time', date)}}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="hh:mm aa"
                        />
                        {
                          formik.errors.start_time && (
                            <div className="error">
                              {formik.touched.start_time && <>{formik.errors.start_time}</>}
                            </div>
                          )
                        }
                      </div>
                      <div className="col-xl-6">
                        <DatePicker
                          className="crew_schedule_fields form-control time_width"
                          locale={localization === "en" ? "en" : "fr"}
                          selected={endTime}
                          placeholderText={t('Caregiver.Calendar.CalendarPopUp.EndTime')}
                          onChange={(date) => { setEndTime(date); formik.setFieldValue("end_time", date) }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="hh:mm aa"
                        />
                        {
                          formik.errors.end_time && (
                            <div className="error">
                              {formik.touched.end_time && <>{formik.errors.end_time}</>}
                            </div>
                          )
                        }
                        {/* {timeerrormessage && <div className="error">{t('Employer.Crew_schedule.Time')}</div>} */}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xl-12">
                        <Autocomplete
                          apiKey={process.env.REACT_APP_API_KEY}
                          className="field_view form-control"
                          id="address"
                          options={{
                            types: ["premise"],
                            // fields: ["address_components", "formatted_address"],
                            componentRestrictions: { country: "ca" },
                          }}
                          placeholder={t('Admin.UpdateCaregiver.Detail.Address')}
                          onPlaceSelected={(place) => {
                            const cityName = place?.address_components?.find((list) => list.types.includes("locality" || "political"))
                            setCity(cityName?.long_name)
                            formik.setFieldValue("address", place.formatted_address)
                          }}
                        />
                        {formik.errors.address && (
                          <div className="error">
                            {formik.touched.address && <>{formik.errors.address}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <span className="request_detail ">{t('Employee.Requests.Details')}</span>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xl-6 childcare_timings">
                        <select
                          name="workingMode"
                          id="workingMode"
                          className="form-control field_view"
                          value={formik.values.workingMode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{ display: "block" }}
                        >
                          <option value="" label={t('Employee.Requests.Working')}>
                            {t('Employee.Requests.Working')}
                          </option>
                          {
                            workingmode?.map((list, index) => {
                              return (
                                <option value={list?.look_up_value} label={list?.look_up_value} key={list?.id}>
                                  {list?.look_up_value}
                                </option>
                              )
                            })
                          }
                        </select>
                        {formik.errors.workingMode && (
                          <div className="error">
                            {formik.touched.workingMode && <>{formik.errors.workingMode}</>}
                          </div>
                        )}
                      </div>
                      <div className="col-xl-6">
                        <select
                          name="styleofCare"
                          id="styleofCare"
                          className="form-control field_view"
                          value={formik.values.styleofCare}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{ display: "block" }}
                        >
                          <option value="" label={t('Employee.Requests.Care')}>
                            {t('Employee.Requests.Care')}
                          </option>
                          {
                            style?.map((list, index) => {
                              return (
                                <option value={list} label={list} key={list?.id}>
                                  {list?.look_up_value}
                                </option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xl-12">
                        {/* <input
                          type="number"
                          name="numberofChildren"
                          placeholder={t('Employee.Requests.Children')}
                          className="form-control field_view"
                          onKeyDown={(e) => keyPressAlphaNumeric(e)}
                          onWheelCapture={e => { e.target.blur() }}
                          value={formik.values.numberofChildren}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{ display: "block" }}
                        /> */}
                        <Select
                        isMulti
                        name="numberofChildren"
                        styles={CustomStyle}
                        // defaultValue={{ label: logindata?.timezone, name: logindata?.timezone }}
                        options={childrens?.map((list, index) => {
                          return {
                            label: list?.first_name + " "+ list?.last_name +  " "+ "-" + `Age ${list?.age}`,
                            // name: list?.timezone_name,
                            value: list?.id ,
                            // code: list?.code_name
                          }
                        })}
                        components={{Option:CustomOption}}
                         onChange={getIds}
                        placeholder={<div style={{ textAlign: "left" }}>{t('Employee.Requests.FamilyChildren')}</div>} 
                        />
                        {formik.errors.numberofChildren && (
                          <div className="error">
                            {formik.touched.numberofChildren && <>{formik.errors.numberofChildren}</>}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-xl-12">

                      <Select
                        name="childrenoutside"
                        styles={CustomStyle}
                        // defaultValue={{ label: logindata?.timezone, name: logindata?.timezone }}
                        options={options?.map((list, index) => {
                          return {
                            label: list,
                            value: list,
                          }
                        })}
                        onChange={(option) => {formik.setFieldValue("childrenoutside",option.value)}}
                        placeholder={<div style={{ textAlign: "left" }}>{t('Employee.Requests.OutsideChildrens')}</div>} 
                        />
                      </div>
                      </div>

                    <div className="row mb-4">
                      <div className="col-xl-12">
                      <Form.Control
                          as="textarea"
                          rows={5}
                          name="care_needs"
                          id="care_needs"
                          placeholder={t("Employee.Requests.CareNeeds")}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                          className="field_view "
                          value={formik.values.care_needs}
                        />
                        {formik.errors.care_needs && (
                          <div className="error">
                            {formik.touched.care_needs && <>{formik.errors.care_needs}</>}
                          </div>
                        )}

                      </div>
                      </div>

                      

                    <div className="d-flex flex-row mb-2 align-items-end">
                      <span className="me-2 request_schedule">{t('Employee.Requests.Repetition')}</span>
                      <span className="request_validate">{t('Employee.Requests.Valid')}</span>
                    </div>
                    <div className="row mb-2 ms-4 week_checkboxes">
                      {
                        CheckboxValue?.map((values,index) => {
                          return (

                            <div className="col-xl-3" key={index}>
                              <div className="form-check form-check-inline ">
                                <input
                                  className="form-check-input custom_input_check"
                                  type="checkbox"
                                  disabled={isDisable}
                                  name="repeat_request"
                                  id="repeat_request"
                                  value={`${values}`}
                                  onChange={formik.handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox1"
                                >
                                  {t(`Caregiver.Calendar.CalendarPopUp.${values}`)}
                                </label>
                              </div>

                            </div>

                          )
                        })
                      }
                    </div>
                  
                    <div className="text-center mb-3">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input custom_input_check "
                          type="checkbox"
                          id="inlineCheckbox9"
                          value="all_schedule"
                          // onChange={(e)=> {
                          //   formik.setFieldValue('repeat_request',e.target.value);
                          //  e.target.checked ?   setdisable(true):setdisable(false)
                          // }}
                        />
                        <label
                          className="form-check-label "
                          for="inlineCheckbox9"
                        >
                          {t('Employee.Requests.Schedule_all')}
                        </label>
                      </div>
                    </div>

                    <div className="text-center btn-section">
                      <Button className="next" type="submit">{t('Employee.Requests.Submit')}</Button>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>)

};

export default Childcare;
