import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from 'react-datepicker'
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { useFormik } from 'formik';
import moment from "moment";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Loader from '../../src/shared/Loader'
import fr from 'date-fns/locale/fr-CA';
import en from 'date-fns/locale/en-US';

import "react-datepicker/dist/react-datepicker.css";

import { employeelist, geteventsbyId, scheduleEvent, updateEvent } from "../Services/https/Employer/Employer";
import UserTimezone from "../shared/UserTimezone";

registerLocale('fr', fr);
registerLocale('en', en);
const CrewSchedule = () => {

  const navigation = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(new Date());
  const [employeesList, setEmployeeList] = useState([]);
  const [selectedParticipents, setSelectedParticipents] = useState([]);
  const [empdetail, setEmpDetail] = useState([]);
  const [ids, setIds] = useState([])
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [url, setUrl] = useState(null);
  const [eventdetail, setEvendetail] = useState(null)
  const [timeerrormessage, setTimeErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({})
  const [loader, setLoader] = useState(true)
  const currentprodId = useSelector(state => state?.invite?.production_id)
  const localization = localStorage.getItem('i18nextLng')
  const { timezone } = useSelector(state => state.login);
  const [ename,setEname]=useState("")

  const Schema = Yup.object().shape({
    eventName: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    event_members:Yup.array()
    .min(1, `${t("Validation.InviteModal.Required")}`)
    .of(
      Yup.object().shape({
        userid:Yup.string().required()
      }).nullable()
    ).nullable()
  });

  useEffect(() => {
    if (state?.id) {
      getEmployees();
      geteventsbyId(state?.id).then(response => {
        setEvendetail(response?.data?.data);
        setEname(response?.data?.data?.event_title)
        setStartDate(moment(response?.data?.data?.event_date).toDate())
        setStartTime(moment(response?.data?.data?.event_start_date_time, "YYYY-MM-DD HH:mm:ss").toDate());
        setEndTime(moment(response?.data?.data?.event_end_date_time, "YYYY-MM-DD HH:mm:ss").toDate());
        setSelectedParticipents(response?.data?.data?.event_participants?.map(list => {
          return {
            value: list?.user?.name,
            label: list?.user?.name,
            userid: list?.user?.id
          }
        }))
      }).catch(err => {
        setLoader(false)
        return err
      })
    }
    else {
      getEmployees();
    }
  }, [state?.id])

  const formik = useFormik({
    initialValues: {
      eventName: ename ,
      event_members:ids
    },
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: values => {
      const userIds = selectedParticipents.map(item => item.userid)
      const data = {
        event_title: values.eventName,
        event_date: moment(startDate).format("YYYY-MM-DD"),
        event_start_date_time:moment(startDate).format("YYYY-MM-DD") + " " + moment(startTime).format("HH:mm:ss"),
        event_end_date_time:moment(startDate).format("YYYY-MM-DD") + " " +   moment(endTime).format("HH:mm:ss"),
        event_members: userIds
      }
      if (!timeerrormessage && !state?.id) {
        ScheduleEvent(data);
      }
      if(!timeerrormessage && state?.id){
        UpdateEvent(data)
      }
      }
  });

  const ScheduleEvent = (data) => {
    setLoader(true)
    scheduleEvent(currentprodId, data).then(response => {
      toast.success(response?.data?.message);
      setLoader(false);
      navigation('/employer/calendar')
    }).catch(err => {
      setErrorMessage(err?.response?.data?.errors);
      setLoader(false)
      return err
    })
  }

  const UpdateEvent = (data) => {
    setLoader(true)
    updateEvent(state?.id, data).then(response => {
      toast.success(response?.data?.message);
      setLoader(false);
      navigation('/employer/calendar')
    }).catch(err => {
      setErrorMessage(err?.response?.data?.errors);
      setLoader(false)
      return err
    })
  }


  const getEmployees = () => {
    employeelist(currentprodId, "", 100, 1).then(response => {
      setEmployeeList(response?.data?.data?.paginate?.data);
      setUrl(response?.data?.base_url);
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      return err
    })
  }

  useEffect(() => {
    if (selectedParticipents?.length >= 0) {
      const userIds = selectedParticipents.map(item => item.userid);
      setIds(userIds);
      const records = employeesList?.filter((item) => userIds.includes(item?.user_id))
      setEmpDetail(records)
    }
  }, [selectedParticipents]);


  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left"
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        backgroundColor: "transparent",
      },
      backgroundColor: "transparent",
      borderColor: "transparent",
      borderRadius: "0px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxSizing: "border-box",
      boxShadow: "none"
    }),
    indicatorSeparator: () => { },
  }

  useEffect(() => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    if (start.isAfter(end)) {
      setTimeErrorMessage(true)
    }
    else {
      setTimeErrorMessage(false)
    }
  }, [startTime, endTime]);
  

  useEffect(() => {
    Object?.keys(errorMessage).map(key => {
      toast.error(errorMessage[key])
    })
  }, [errorMessage])

  return (
    <div>

      <div className="white_box manage_screen">
        <h3 className="event_heading">{t('Employer.Crew_schedule.Create')}</h3>
        <p className="crew_schedule_details">{t('Employer.Crew_schedule.Details')}</p>

        <div>
          <>
            {loader && <Loader />}
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div>
                  <div><UserTimezone/></div>
                  <div className="row">
                    <div className="col-lg-8">
                      <div>
                        <label className="mb-3  mt-5 labels_style">{t('Employer.Crew_schedule.Event_title')}</label>
                      </div>
                      <input
                        type="text"
                        placeholder={t('Employer.Crew_schedule.Event_title')}
                        className="crew_schedule_fields form-control mb-3 placeholder_style"
                        name="eventName"
                        onChange={(e)=>{formik.setFieldValue("eventName",e.target.value);setEname(e.target.value)}}
                        onBlur={formik.handleBlur}
                        value={formik.values.eventName}
                      />
                    </div>
                    {formik.errors.eventName && (
                      <div className="error">
                        {formik.touched.eventName && <>{formik.errors.eventName}</>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div>
                        <label className="mb-3  labels_style">{t('Employer.Crew_schedule.Event_date')}</label>
                      </div>
                      <DatePicker className="crew_schedule_fields form-control" locale={localization == "fr" ? "fr" : "en"} selected={startDate} minDate={startDate} onChange={(date) => {setStartDate(date)}} />
                    </div>
                    <div className="col-lg-4">
                      <div className="row">

                        <div style={{ display: "flex" }}>
                          <div className="col-lg-6 me-2">
                            <div>
                              <label className="mb-3 labels_style">{t('Employer.Crew_schedule.Start_time')}</label>
                            </div>
                            <DatePicker
                              className="crew_schedule_fields form-control time_width"
                              name="start_time"
                              id="start_time"
                              selected={startTime}
                              placeholderText="Start Time"
                              onChange={(date) => setStartTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                            />
                            {formik.errors.start_time && (
                              <div className="error">
                                {formik.touched.start_time && <>{formik.errors.start_time}</>}
                              </div>
                            )}
                          </div>

                          <div className="col-lg-6">
                            <div>
                              <label className="mb-3 labels_style">{t('Employer.Crew_schedule.End_time')}</label>
                            </div>
                            <DatePicker
                              className="crew_schedule_fields form-control time_width"
                              selected={endTime}
                              placeholderText="End Time"
                              onChange={(date) => setEndTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                            />
                          </div>
                        </div>

                      </div>
                      {timeerrormessage && <div className="error">{t('Employer.Crew_schedule.Time')}</div>}
                    </div>

                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-7">
                    <label className="mt-3 mb-3 labels_style">{t('Employer.Crew_schedule.Participants')}</label>
                    <Select
                      isMulti
                      name="event_members"
                      id="event_members"
                      value={selectedParticipents}
                      components={{ DropdownIndicator: () => null }}
                      options={employeesList?.map((list, index) => ({
                        label: `${list?.first_name} ${list?.last_name}`,
                        value: `${list?.first_name} ${list?.last_name}`,
                        userid: list?.user_id
                      }))}
                      placeholder={t('Employer.Crew_schedule.Participants')}
                      // onInputChange={formik.handleChange}
                      onChange={setSelectedParticipents}
                      styles={CustomStyle}
                      className=" form-control crew_schedule_fields mb-3"

                    />
                    {formik.errors.event_members &&(
                      <div className="error">
                        {formik.touched.event_members && <>{formik.errors.event_members}</>}
                      </div>
                    )}
                  </div>
                </div>

              </div>
              {
                empdetail?.length >= 1 && empdetail?.map((list, index) => {
                  return (
                    <>
                      <img src={list?.profile_image_url} height="65px" width="65px" alt="profile pic" className="mt-3" style={{borderRadius:"50%"}}/>
                    </>
                  )
                })
              }

              <div>
                <button type="submit" className="btn btn-info mt-3 event_button" >
                  {state?.id ? t('Employer.Crew_schedule.Update') : t('Employer.Crew_schedule.Add_event')}
                </button>
              </div>
            </form>
          </>
        </div>
      </div>
    </div>
  );
};

export default CrewSchedule;
