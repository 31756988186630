import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Avatar from "../../assets/Images/avatar.png";

import { DashboardContactList } from "../../Services/https/Admin/Admin";

const Productions_contacts = () => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    DashboardContactList()
      .then((response) => {
        setContacts(
          response?.data?.data
        );
      })
      .catch((error) => {
        return error;
      });
  }, []);
  
  return (
    <>
      <div className="contacts employers_box">
        <h4 className="title_h4_line ml-3">
          <span>{t("Admin.Dashboard.ProductionContacts")}</span>
        </h4>

        <ul className="productions_list ave employers_box">
          {contacts?.slice(0,5)?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <li >
                    <img src={item?.image ? item?.image : Avatar} alt="" />
                    <div className="d-inline-flex w100p">
                      <h5>{item?.name}</h5>
                      <h6>{item?.role_text==="employer" ? "Production Manager" : item?.employer?.role_text?.charAt(0).toUpperCase() + item?.employer?.role_text?.slice(1)}</h6>
                    </div>
                  </li>
                </React.Fragment>
              );
            
          })}
        </ul>
      </div>
    </>
  );
};

export default Productions_contacts;
