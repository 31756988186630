import React,{useEffect,useState} from "react";

import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { svg } from '../assets/svg/svg'
import { dateFormat } from "../shared/moment";
import Loader from "../shared/Loader";

import { generatenewPayroll, payrollgenerateList, viewgeneratedPayroll } from "../Services/https/Admin/Admin";


const NewPayroll = () => {
    const { t } = useTranslation();
    const location=useLocation();
    const [loader,setLoader]=useState(false);
    const [caregivers,setCaregivers]=useState([]);
    const [payrolldate,setPayrollDate]=useState(null);
    const language= localStorage.getItem('i18nextLng');
    const Id=new URLSearchParams(location?.search).get("payrollId");
    

    useEffect(()=>{
        setLoader(true)
        if(location?.pathname==="/admin/payrollgenerate/new"){
            getList();
        }
        else{
            viewgeneratedPayroll(Id).then(response=>{
                setLoader(false);
                setCaregivers(response?.data?.data)
                setPayrollDate(response?.data?.payroll_generate_date)
            }).catch(err=>{
                return err
            })
        }    
      },[location,Id])
  
      const getList=()=>{
        payrollgenerateList().then(response=>{
          setLoader(false)
          setCaregivers(response?.data?.data)
        }).catch(err=>{
        setLoader(false)
          return err
        })
      }

      const generate = () =>{
        setLoader(true);
        generatenewPayroll().then(response=>{
            toast.success(response?.data?.message);
            setLoader(false);
            getList();
        }).catch(err=>{
            setLoader(false);
            return err
        })
      }

      const generateExcel = () => {
        setLoader(true);
        if(location?.pathname==="/admin/payrollgenerate/new"){
            payrollgenerateList(true).then(response=>
                response.data
              ).then(blob=>{
                setLoader(false);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = "genearteReport.xlsx";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);  
              }).catch(err=>{
                return err
              })
        }
        else{
            viewgeneratedPayroll(Id,true).then(response=>
                response.data
              ).then(blob=>{
                setLoader(false);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = "genearteviewReport.xlsx";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);  
              }).catch(err=>{
                return err
              })
        }
      }

    return (
        <>
            <div className="white_box manage_screen">
                <div className="title_box d-inline-flex w100p">
                    <h3 className="title_h3">{t("Admin.Payroll.Generate")}</h3>
                    <div className="ms-auto d-flex align-items-center">
                        <div><p className="funding_heading">{t("Admin.Payroll.Date")} : </p></div>
                        <div><p> {location?.pathname ==="/admin/payrollgenerate/new" ?  " " + moment().locale(language).format("MMMM DD ,YYYY HH:mm A") : dateFormat(payrolldate,"YYYY-MM-DD HH:mm:ss",language)}</p>   </div>

                    </div>
                </div>
                <div className="mb-3">
                    <div className="d-flex justify-content-end">
                        {
                            location?.pathname==="/admin/payrollgenerate/new" && <div className="me-5">
                            <Button
                                variant="info"
                                onClick={generate}
                            >
                                {" " + t('Admin.ManageEmployeer.ModalGenerateInvite.Generate').toUpperCase()}
                            </Button>
                        </div>
                        }
                        

                        <div className="ms-2 excel-section" onClick={generateExcel}>
                            {svg["Excel"]}
                            <h6>{t('Admin.Payroll.Export')}</h6>
                        </div>
                    </div>

                </div>

                {
                    loader ? <Loader/> : 
                    caregivers?.map((list,index)=>{
                        return(
                            <React.Fragment key={index}>
<Table className="table" responsive>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th>{t("Employer.Employer_profile.Company_info.Production")}</th>
                            <th >{t("Admin.Payroll.Employee")}</th>
                            <th>{t("Admin.Payroll.CheckIn")}</th>
                            <th>{t("Admin.Payroll.CheckOut")}</th>
                            <th>{t("Admin.Payroll.Amount")}</th>
                            
                        </tr>
                        <tr className="table-light">
                            <th scope="col">{list?.caregiver}</th>
                            <th>{list?.caregiver_email}</th>
                            <th ></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                
                        {
                            list?.payroll?.map((list, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td ></td>
                                            <td>{list?.production_name}</td>
                                            <td>{list?.employee_name}</td>
                                            <td>{dateFormat(list?.check_in_date,"YYYY-MM-DD HH:mm:ss",language)}</td>
                                            <td>{dateFormat(list?.check_out_date,"YYYY-MM-DD HH:mm:ss",language)}</td>
                                            <td>{`$${list?.amount}`}</td>
                                        </tr>
                                        
                                        
                                        
                                    </React.Fragment>
                                )
                               
                            })
                        
                        }
                         <tr className="table-light">
                            <th scope="col"></th>
                            <th></th>
                            <th ></th>
                            <th></th>
                            <th>Total</th>
                            <th>{`$${list?.total}`}</th>
                        </tr>
                        
                    </tbody>
                </Table>
                </React.Fragment>
                        )
                    })
                }

                
            </div>
        </>
    )
}

export default NewPayroll;