import React, { Component } from "react";

const Studio = ({ data }) => {
  return (
    <>
      {data?.map((item) => {
        return <p className="prod_description">{item?.studio_name}</p>;
      })}
    </>
  );
};

export default Studio;
