import React, { useEffect, useState } from "react";

import { Button, Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "../shared/Loader";
import { payrolldateFormat } from "../shared/moment";

import { generatedpayrollList } from "../Services/https/Admin/Admin";
import Pagination from "../shared/Pagination";

const PayrollGenerate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [payrolllist, setPayrollList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const pageCount = Math.ceil(totalcount / 10);
  const language = localStorage.getItem('i18nextLng')

  useEffect(() => {
    getList();
  }, [initialpage])

  const getList = () => {
    setLoader(true);
    generatedpayrollList(initialpage,10).then(response => {
      setPayrollList(response?.data?.data?.data)
      setTotalcount(response?.data?.data?.total)
      setLoader(false);
    }).catch(err => {
      setLoader(false);
      return err
    })
  }

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };

  return (
    <>
      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">{t("Admin.Payroll.Generate")}</h3>
          <div className="ms-auto">
            <Button
              variant="info"
              className=""
              onClick={() => navigate('/admin/payrollgenerate/new')}
            >
              {t("Admin.Payroll.New")}
            </Button>
          </div>
        </div>
        {
          loader ? <Loader /> :
          <>
          <Table className="custom_table" responsive>
            <>
              <thead>
                <tr>
                <th>{t("Admin.Payroll.Id")}</th>
                <th>{t("Admin.Payroll.Date")}</th>
                <th >{t("Admin.Payroll.Records")}</th>
                <th>{t("Admin.Payroll.View")}</th>
                </tr>
              
              </thead>
              <tbody>
                {
                  payrolllist?.map((list, index) => {
                    return (
                      <React.Fragment key={index}>
                      <tr>
                        <td>#{list?.id}</td>
                        <td>{payrolldateFormat(list?.payroll_generate_date, "YYYY-MM-DD HH:mm:ss", language)}</td>
                        <td>{list?.payrolllist_count}</td>
                        <td><Button
                          variant="info"
                          className="w-50"
                          onClick={() => navigate(`/admin/payrollgenerate/view?payrollId=${list?.id}`)}
                        >
                          {t("Admin.Payroll.View")}
                        </Button></td>
                      </tr>
                      </React.Fragment>
                    )
                  })
                }

              </tbody>
            </>
          </Table>
          <div style={{display:"flex", justifyContent:"end"}}>
            {payrolllist?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
          </>

          
        }
      </div>
    </>
  )
}

export default PayrollGenerate;