import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import Creatable from "react-select/creatable";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  createinvite,
  delproductionlist,
  downloadReport,
  inviteproductions,
  productionlist,
  sendInvite,
  studiolist,
} from "../Services/https/Admin/Admin";
import { requestPendingCount } from "../Services/https/Auth/Auth";

import Loader from "../shared/Loader";
import Pagination from "../shared/Pagination";
import NoRecords from "../shared/NoRecords";
import { svg } from "../assets/svg/svg";
import Modal from "../shared/Modal";


function Manageemployer() {
  const role = useSelector((state) => state.login.role_data * 3 - 1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [popUp, setPopUp] = React.useState(false);
  const [del, setDel] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [prodMenu, setShowProdMenu] = React.useState(false);
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [handlesearch, sethandleSearch] = useState(false);
  const [searchkeyword, setSearchkeyword] = useState("");
  const [totalcount, setTotalcount] = useState(null);
  const [deleterecord, setDeleteRecord] = useState([]);
  const [productions, setProductions] = useState([]);
  const [studios, setStudios] = useState([]);
  const [inviteprodList, setInviteProdList] = useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [link, setlink] = useState("");
  const [generate, setGenerate] = useState(false);
  const [employerCount, setEmployerCount] = useState('')
  const [showNoRecord, setShowNoRecord] = useState(false);
  const pageCount = Math.ceil(totalcount / 10);
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: "60px",
      width: "100%",

      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #ced4da",
    }),

    indicatorSeparator: () => { },
  };
  useEffect(() => {
    setLoading(true)
    productionlist(sort, searchkeyword, 10, initialpage)
      .then((response) => {
        setProductions(response?.data?.data?.data.map((item) => {
          item.showProductions = false;
          return item;
        }));
        setTotalcount(response?.data?.data?.total);
        setLoading(false);
        setDeleteRecord([]);
        setShowNoRecord(true);
      })
      .catch((error) => {
        return error;
      });
  }, [sort, initialpage, del, handlesearch]);

  useEffect(() => {
    if (popUp) {
      studiolist()
        .then((response) => {
          setStudios(response?.data?.data);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [popUp]);

  useEffect(() => {
    requestPendingCount(role)
      .then((response) => {
        setEmployerCount(response.data.data);
      })
      .catch((err) => {
        return err;
      });

  }, [popUp, !generate]);

  const recordstoDelete = (e, id) => {
    const records = [...deleterecord];
    if (e.target.checked) {
      records.push(id);
      setDeleteRecord(records);
    } else {
      const recs = records.filter((rec) => rec !== id);
      setDeleteRecord(recs);
    }
  };

  const GenerateReport = (id) => {
    downloadReport(id).then(response => {
      const blob = new Blob([response?.data], { type: "octect/stream" });
      // creates a url that points to the file in the browsers memory
      const url = URL.createObjectURL(blob);
      // using an 'a' tag to trigger a download
      const anchor = document.createElement("a");
      anchor.setAttribute("href", url);
      anchor.setAttribute("download", 'productionreport.xlsx');
      anchor.click();
      // revoke url after using it
      URL.revokeObjectURL(url);
    })
      .catch(err => {
        return err
      })
  }

  const inviteSchema = Yup.object().shape({
    studio: Yup.object().required(`${t("Validation.InviteModal.Required")}`),
    email: Yup.string()
      .email(`${t("Validation.InviteModal.Invalid_email")}`)
      .required(`${t("Validation.InviteModal.Required")}`),
    production: Yup.object().required(
      `${t("Validation.InviteModal.Required")}`
    ),
  });
  const formik = useFormik({
    initialValues: { email: "", studio: "", production: "" },
    validationSchema: inviteSchema,
    onSubmit: (values) => {
      let id = link?.split('id=')[1].slice(0, 36)
      const data = {
        id: id,
      };
      sendInvite(data)
        .then((response) => {
          setPopUp((prev) => !prev);
          formik.resetForm(formik?.values);
          setGenerate(false);
          setlink(null);
          setLoading(false);
          toast.success(response?.data?.message);
        })
        .catch((error) => {
          return error;
        });
    },
  }

  );
  const inviteGenreate = () => {
    const data = {
      email: formik?.values.email,
      production_name: formik?.values?.production.value,
      studio_name: formik?.values?.studio.value,
      role_id: 2,
    };
    createinvite(data)
      .then((response) => {
        setGenerate(true);
        setlink(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        return error;
      });
  };
  const [check, setCheck] = useState(true);
  useEffect(() => {
    if (
      formik.values.email !== "" &&
      !formik?.errors.email &&
      formik.values.studio !== "" &&
      formik.values.production !== ""
    ) {
      setCheck(false);
      setGenerate(true);
    } else {
      setCheck(true);
    }
  }, [formik?.values]);
  const handleInputChange = (typedOption) => {
    setShowMenu(typedOption.length >= 3 ? true : false);
  };

  const handleProdInputChange = (typedOption) => {
    setShowProdMenu(typedOption.length >= 3 ? true : false);
  };

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };

  return (
    <>
      {show && (
        <Modal
          show={show}
          modalTitle={t("Admin.ManageEmployeer.Modal.Title")}
          description={`
            ${t("Admin.ManageEmployeer.Modal.Heading.start")} "` + `
            ${deleterecord.length} ` + `" 
            ${t("Admin.ManageEmployeer.Modal.Heading.end")}
            `
          }
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("Admin.ManageEmployeer.Modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setShow((prev) => !prev);

                  delproductionlist(deleterecord)
                    .then((response) => {
                      setDel((prev) => !prev);
                    })
                    .catch((err) => {
                      return err;
                    });
                }}
              >
                {t("Admin.ManageEmployeer.Modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}
      {del ? (
        <Modal
          modalTitle={t("Admin.ManageEmployeer.ModalDelAfter.Title")}
          show={del}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {popUp ? (
        <Modal
          show={popUp}
          modalTitle={t("Admin.ManageEmployeer.ModalGenerateInvite.Title")}
          field={
            <>
              <div className="text-center w100p font">
                <p style={{ textAlign: "center" }}>
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Heading")}
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 invitelink">
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input2heading"
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          value={formik.values.email}
                          class="form-control"
                          placeholder={t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input2"
                          )}
                          name="email"
                          id="email"
                          aria-describedby="basic-addon1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                      </div>
                      {formik.errors.email && (
                        <div className="error">
                          {formik.touched.email && <>{formik.errors.email}</>}
                        </div>
                      )}
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input3heading"
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <Creatable
                          options={studios?.map((list, index) => ({
                            value: list?.studio_name,
                            label: list?.studio_name,
                            id: list?.id,
                          }))}
                          styles={CustomStyle}
                          name="studio"
                          id="studio"
                          isClearable={true}
                          value={formik.values.studio}
                          components={{ DropdownIndicator: () => null }}
                          loadingMessage={() => "Loading..."}
                          menuIsOpen={showMenu}
                          onInputChange={handleInputChange}
                          onChange={(option) => {
                            formik.setFieldValue("studio", option);
                            inviteproductions(option?.id)
                              .then((response) => {
                                setInviteProdList(response?.data?.data);
                              })
                              .catch((error) => {
                                return error;
                              });
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("studio", true);
                            setShowMenu(false);
                          }}
                          placeholder={
                            <div
                              style={{ textAlign: "left", color: "#9D9D9D" }}
                            >
                              {t(
                                "Admin.ManageEmployeer.ModalGenerateInvite.Input3"
                              )}
                            </div>
                          }
                        />
                      </div>
                      {formik.errors.studio && (
                        <div className="error">
                          {formik.touched.studio && (
                            <>
                              {formik?.values?.studio == null
                                ? "Required"
                                : `${formik.errors.studio}`}
                            </>
                          )}
                        </div>
                      )}
                      <div>
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input4heading"
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <Creatable
                          options={inviteprodList?.map((list, index) => ({
                            value: list?.production_name,
                            label: list?.production_name,
                          }))}
                          styles={CustomStyle}
                          name="production"
                          id="production"
                          isClearable={true}
                          components={{ DropdownIndicator: () => null }}
                          value={formik.values.production}
                          menuIsOpen={prodMenu}
                          onInputChange={handleProdInputChange}
                          onChange={(option) => {
                            formik.setFieldValue("production", option);
                          }}
                          onBlur={() =>
                            formik.setFieldTouched("production", true)
                          }
                          placeholder={
                            <div
                              style={{ textAlign: "left", color: "#9D9D9D" }}
                            >
                              {t(
                                "Admin.ManageEmployeer.ModalGenerateInvite.Input4"
                              )}
                            </div>
                          }
                        />
                      </div>
                      {formik.errors.production && (
                        <div className="error">
                          {formik.touched.production && (
                            <>
                              {formik?.values?.production == null
                                ? "Required"
                                : `${formik.errors.production}`}
                            </>
                          )}
                        </div>
                      )}
                      <div>
                        <div>
                          {t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input1Heading"
                          )}{" "}
                          <button
                            type="button"
                            className="generate"
                            disabled={check === true ? true : false}
                            onClick={() => {
                              inviteGenreate();
                            }}
                          >
                            {t(
                              "Admin.ManageEmployeer.ModalGenerateInvite.Generate"
                            )}
                          </button>
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "Admin.ManageEmployeer.ModalGenerateInvite.Input1"
                          )}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={link ? link : null}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="group_buttons mt-5">
                      <Button
                        type="button"
                        variant="outline-info"
                        className="btn-lg"
                        onClick={() => {
                          setPopUp((prev) => !prev);
                          formik.resetForm(formik?.values);
                          setlink("");
                          setGenerate(false);
                        }}
                      >
                        {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                      </Button>
                      <Button
                        variant="info"
                        className="btn-lg"
                        type="submit"
                        disabled={!link}
                      >
                        {t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.SendInvite"
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          }
        />
      ) : null}
      {invite ? (
        <Modal
          modalTitle={t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Title")}
          show={invite}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setInvite((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}

      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">{t("Admin.ManageEmployeer.Title")}</h3>
          <div className="search_box d-flex ms-auto">
            <div className="search_box_in">
              <span
                onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }}
              >{svg['Search']}</span>
              <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>{" "}
            {/* // </form> */}
            &nbsp; &nbsp;
            <div className="manage_employeer_dropdown">
              <div className="common_select">
                <span className="arrange">{t("Admin.Dashboard.Dropdown.Sort")}</span>
                <select
                  className="admin-dashboard-dropdown"
                  name="language"
                  id="language"
                  selected="Studio"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="studio_name">
                    {t("Admin.Dashboard.Dropdown.Studio")}
                  </option>
                  <option value="first_name">
                    {t("Admin.Dashboard.Dropdown.First_name")}
                  </option>
                  <option value="last_name">
                    {t("Admin.Dashboard.Dropdown.Last_name")}
                  </option>
                  <option value="production_name">
                    {t("Admin.Dashboard.Dropdown.Production")}
                  </option>
                </select>
              </div>
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    setShow(deleterecord.length < 1 ? false : true)
                  }
                >
                  {deleterecord?.length} {t("Admin.ManageEmployeer.Selected")}
                  {svg["Delete"]}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table className="custom_table" responsive>
          {loading ? (
            <Loader />
          ) : (
            <>
              {productions?.length === 0 && showNoRecord ? (
                <NoRecords />
              ) : (
                <>
                  <thead>
                    <tr>
                      <th width="50px"></th>
                      <th>{t("Admin.ManageEmployeer.Table.LastName")}</th>
                      <th>{t("Admin.ManageEmployeer.Table.FirstName")}</th>
                      <th width="350">{t("Admin.ManageEmployeer.Table.Production")}</th>
                      <th>{t("Admin.ManageEmployeer.Table.Studio")}</th>
                      <th width="200px">{svg["Edit"]} </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productions?.map((list, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr >
                            <td>
                              <Form.Check
                                key={`${list?.production_id}`}
                                className="custom_checkbox"
                                aria-label={`option-${list?.production_id}`}
                                name={`${list.id}`}
                                onClick={(e) => {
                                  recordstoDelete(e, list?.user_id);
                                }}
                              />
                            </td>
                            <td>
                              <b>{list?.last_name}</b>
                            </td>
                            <td>
                              <b>{list?.first_name}</b>
                            </td>
                            <td>{list?.production_name}</td>
                            <td>{list?.studio_name}</td>
                            <td>
                              <a
                                className="link"
                                onClick={() =>
                                  navigate(
                                    `/admin/productions/updateEmployer/${list?.production_id}`
                                  )
                                }
                              >
                                {t("Admin.UpdateEmployeer.Title")}
                              </a>
                              {
                                list?.status === 0 &&
                                <div onClick={() => GenerateReport(list?.production_id)}  ><span style={{ cursor: "pointer" }}>{svg["Download"]}</span> </div>

                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </>
              )}
            </>
          )}
        </Table>

        <div className="d-flex justify-content-between table_buttons">
          <div>
            <Button
              className="btn-sm ms-0 ps-3 pe-3 me-4"
              onClick={() => navigate("/admin/productions/employerRequests")}
              variant="outline-secondary"
            >
              <strong>
                {employerCount} {t("Admin.ManageEmployeer.RequestsPending")}
              </strong>
            </Button>

            <Button
              onClick={() => setPopUp((prev) => !prev)}
              className="btn-sm ms-0 ps-3 pe-3"
              variant="outline-secondary"
            >
              <strong>{t("Admin.ManageEmployeer.GenerateInvite")}</strong>
            </Button>

            <Button
              onClick={() => navigate("/admin/productions/archive-employers")}
              className="btn-sm  ps-3 pe-3"
              style={{ float: "right" }}
              variant="outline-secondary"
            >
              <strong>{t("Admin.ManageCaregiver.Button")}</strong>
            </Button>
          </div>

          <div></div>
          <div>
            {productions?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Manageemployer;
