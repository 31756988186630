import React,{useState,useEffect} from "react";

import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";

import NoRecords from "../../../shared/NoRecords";
import { dateFormat } from "../../../shared/moment";
import { svg } from "../../../assets/svg/svg";
import BookingDetails from "../../../shared/bookingDetails";

const Cancelled = (props) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  const getdata = (id) => {
    const getRecord = props?.data?.find((records) => records?.id === id);
    setDetails(getRecord);
  }

  useEffect(() => {
    if (Object.keys(details)?.length >= 1) {
      setShowDetails(true)
    }
  }, [details])

  return (
    <>
      {props?.data.length === 0 ? (
        <div style={{ marginTop: "50px" }}>
          {" "}
          <NoRecords />
        </div>
      ) : (
        <>
        <div style={{ paddingBottom: "0px", position: "relative" }}>
              <Table className="custom_table" responsive > 
          <thead>
            <tr>
              <th>{t("Employee.Bookings.Status")}</th>
              <th width="600px">{t("Employee.Bookings.Date")}</th>
              <th>{t("Employee.Bookings.Caretaker")}</th>
              <th>{t(`Employee.Bookings.Detail`)}</th>
            </tr>
          </thead>
          <tbody>
          {props?.data?.map((item, index) => {
            const phone =
              item?.phone.toString().slice(0, 3) +
              "-" +
              item?.phone.toString().slice(3, 6) +
              "-" +
              item?.phone.toString().slice(6, 12);
            return (
              <>
                
                  <tr>
                    <td>
                      <b className="cancelled">
                        {t("Employee.Bookings.Cancelled_req")}
                      </b>
                    </td>

                    <td>
                      <p>
                        
                          {dateFormat(item?.dates,"YYYY-MM-DD HH:mm:ss",lang)
                          }{" "}
                          - {dateFormat(item?.end_date,"YYYY-MM-DD HH:mm:ss",lang)
                          }
                         ({item?.time_zone_code})
                      </p>
                      <div className="d-flex flex-column mt-2">
                        <span>{item?.location}</span>
                        {/* <span>Vancouver, BC V6M 2K3</span> */}
                      </div>
                    </td>

                    <td>
                      <p>{item?.caretaker}</p>
                      <div className="d-flex flex-column mt-2">
                        <span>{phone}</span>
                        <span>{item?.email}</span>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-column mt-2">
                        <span>$ {item?.price ? item?.price : "0.00"} </span>
                        <div>{item?.fund}</div>
                        <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(item?.id)}>{svg["File"]}</span> </div>
                      </div>
                    </td>
                  </tr>
              </>
            );
          })}
                </tbody>
          </Table>
          {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails} />}
          </div>
        </>
      )}
    </>
  );
};

export default Cancelled;
