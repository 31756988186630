import React from "react";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TermsAndConditions = ({ setShow, show }) => {
    const { t } = useTranslation();

    return (
        <>

            <div>
                <div className="termscontent">
                    <h6>
                        {t('SignUp.TermsContent.PTitle')}
                    </h6>
                    <h6>
                        {t('SignUp.TermsContent.Heading1')}
                    </h6>
                    <h6>
                        {t('SignUp.TermsContent.Heading2')}

                    </h6>
                    <h6>{t('SignUp.TermsContent.TermsConditionHeading')}  </h6>
                    <ul>
                        <li>{t('SignUp.TermsContent.Point1')}</li>
                        <li>
                            {t('SignUp.TermsContent.Point2')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.Point3')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.Point4')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.Point5')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.Point6')}
                        </li>

                    </ul>
                    <h6>{t('SignUp.TermsContent.TermsConditionHeading2.Title')}</h6>

                    <ul>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading2.Point1')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading2.Point2')}

                        </li>

                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading2.Point3')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading2.Point4')}

                        </li>

                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading2.Point5')}

                        </li>
                    </ul>

                    <h6>
                        {t('SignUp.TermsContent.TermsConditionHeading3.Title')}

                    </h6>

                    <ul>

                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading3.Point1')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading3.Point2')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading3.Point3')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading3.Point4')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading3.Point5')}

                        </li>

                    </ul>

                    <h6>
                        {t('SignUp.TermsContent.TermsConditionHeading4.Title')}

                    </h6>

                    <ul>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point1')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point2')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point3')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point4')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point5')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading4.Point6')}

                        </li>
                    </ul>

                    <h6>
                        {t('SignUp.TermsContent.TermsConditionHeading5.Title')}

                    </h6>

                    <ul>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point1')}
                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point2')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point3')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point4')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point5')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point6')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point7')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point8')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point9')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point10')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point11')}

                        </li>
                        <li>
                            {t('SignUp.TermsContent.TermsConditionHeading5.Point12')}

                        </li>
                    </ul>
                </div>
                <div className="btn_footer">
                    <Button
                        type="submit"
                        variant="info"
                        className="btn-lg w100p justify-content-center "
                        onClick={() => setShow(!show)}
                    >
                        {t('Admin.ManageEmployeer.Modal.Confirm')}
                    </Button>

                </div>
            </div>

        </>
    )
}

export default TermsAndConditions;