import React, { useState, useEffect } from 'react'

import { getunion } from '../Services/https/Employer/Employer';

function UnionCard({ data }) {
    const [unions, setUnions] = useState([])
    useEffect(() => {
        getunion(data, "", 1).then(response => {
            setUnions(response?.data?.data?.paginate?.data)
        }).catch(err => {
            return err
        })


    }, [data])
    return (

        <>
            <div className="employers_box">

                <div className='row'>
                    {
                        unions?.slice(0, 4)?.map((list, index) => {

                            return (
                                <div className="employers_box col-sm-3" key={index}>
                                    <ul className="productions_list">
                                        <>
                                            <li key={index}>
                                                <img src={list?.profile_image_url} alt="" />
                                                <div className="d-inline-flex w100p">

                                                    <h5>{list?.first_name} {list?.last_name}</h5>
                                                </div>
                                            </li>
                                        </>
                                    </ul>

                                </div>

                            )
                        })
                    }
                </div>


            </div>
        </>
    )
}

export default UnionCard