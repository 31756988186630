import React, { useState, useEffect} from "react";

import { useLocation } from "react-router-dom";

const UnionProduction = ({data}) => {
  return (
    <>
      <p>{data[0]?.studio_name}</p>
    </>
  )
}

export default UnionProduction
