import React,{useState,useEffect} from 'react'

import { useTranslation } from 'react-i18next';
import {Button,Form,Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import Invite from './Invite';
import Modal from '../shared/Modal'
import Loader from '../shared/Loader';
import NoRecords from '../shared/NoRecords';
import Pagination from '../shared/Pagination';

import { cancelemployeerequest, employeerequestlist } from '../Services/https/Employer/Employer';
import { resendrequest } from '../Services/https/Admin/Admin';

import { svg } from '../assets/svg/svg';

function EmployeeRequestApproval() {

  const { t } = useTranslation()
  const [loader, setLoader] = React.useState(true);
  const [show, setShow] = useState(false)
  const [showResend,setShowResend] = useState(false)
  const [showCancel,setShowCancel]= useState(false)
  const [search,setSearch]=useState('');
  const [handlesearch, sethandleSearch] = useState(false)
  const [list,setList]=useState([]);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);
  const [initialpage,setInitialpage]=useState(1)
  const [totalcount, setTotalcount] = useState(null);
  const pageCount = Math.ceil(totalcount / 10);
  const {production_id} = useSelector((state) => state?.invite);

  useEffect(()=>{
    employeerequestlist(search,initialpage,production_id).then(response=>{
      setList(response?.data?.data?.data)
      setTotalcount(response?.data?.data?.total)
      setLoader(false)
    }).catch(err=>{
      return err
    })

  },[handlesearch,initialpage,showCancel])

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  }; 
  const InviteEmployeeBody = () => {
    return (
      <div>
        <div className="row  generate_invite_input">
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control "
              placeholder="invite.link.newemployee.com/employee"
            ></input>
          </div>

          <div></div>
        </div>
        <div className="row generate_invite_input">
          <div className="col-lg-6">
            <label className="mb-2 mt-4 generate_invite_modal_label">
              {t("Employer.Manage_union.Generate_link_modal.Send_invite")}:
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="email@emailaddress.com"
            ></input>
          </div>

        </div>
      </div>
    );
  };
  const InviteDescription = {
    heading: `${t(
      "Employer.Manage_union.Generate_link_modal.Invite_employee"
    )}`,
    headin_two: `${t("Employer.Manage_union.Generate_link_modal.Otp")}`,
    body: <InviteEmployeeBody />,
  };

  const cancelRequest = (id) => {
    cancelemployeerequest(id).then(response => {
      toast.success(response?.data?.message)
      setLoader(false);
      setShowCancel((prev) => !prev);
    }).catch(err => {
      return err
    })
  }

  const resendRequest = (email) => {
    
    if (email) {
      resendrequest(email).then(response => {
        toast.success(response?.data?.message);
        setShowResend(false)
      }).catch(err => {
        return err
      })
    }
  }
  return (
    <>
      <Invite show={show} InviteDescription={InviteDescription}
        onHide={() => setShow(prev => !prev)} setShow={setShow} />
         <Modal  show={showCancel} modalTitle={t("Employer.Employee_requests_approval.Cancel_confirmation")}  footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowCancel((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              
              cancelRequest(id);
            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      }/>
          <Modal onHide={()=>setShowCancel(false)} show={showResend} modalTitle={t("Employer.Employee_requests_approval.Resend_confirmation")} setShowResend={setShowCancel} footer={
        <div className="group_buttons mt-2">
          <Button
            variant="outline-info"
            className="btn-lg"
            onClick={() => setShowResend((prev) => !prev)}
          >
            {t('Employer.Add_employee.Delete_modal.Cancel')}
          </Button>
          <Button
            variant="info"
            className="btn-lg"
            onClick={() => {
              resendRequest(email)
              // setShowResend((prev) => !prev);
            }}
          >
            {t('Employer.Add_employee.Delete_modal.Confirm')}
          </Button>
        </div>
      }/>
      <div className='white_box manage_screen'>
        <div className='title_box d-inline-flex w100p'>
          <h3 className='title_h3'> {t("Employer.Employee_requests_approval.Employee_request")}</h3>
          <div className='search_box d-flex ms-auto'>
            <div className='search_box_in'>
              <span onClick={() => { sethandleSearch((prev) => !prev); setLoader(true) }}>{svg["Search"]}</span>
              <Form.Control type="email" placeholder={t("Employer.Employee_requests_approval.Search")} onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoader(true);
                  }
                }}/>
            </div>
          </div>
        </div>
        {
          loader ? <Loader/> : list?.length===0 ? <NoRecords/> : 
          <div>
          <Table className='custom_table' responsive>

          <thead>
            <tr>

            <th>{t('Employee.Productions.Email')}</th>
              <th>{t("Employer.Employee_requests_approval.Production")}</th>
              <th>{t("Employer.Employee_requests_approval.Studio")}</th>
              <th width="200px"> {svg["Edit"]} </th>
            </tr>
          </thead>
          <tbody>
            {
              list?.map((requestList,index)=>{
                return(
                  <tr key={index}>
              <td><b>{requestList?.email}</b></td>
              <td>{requestList?.production_name}</td>
              <td>{requestList?.studio_name}</td>
              <td>
                <div className="action_button">
                  <a  onClick={()=>{setShowCancel(prev=>!prev);setId(requestList?.linkguid)}} className="link" style={{ color: "#DC6962" }}>
                    {t('Employer.Union_requests_approval.Cancel')}
                  </a>
                  <a onClick={()=>{setShowResend(prev=>!prev); setEmail(requestList?.linkguid) }} className="link" style={{ color: "#038900" }}>
                    {t('Employer.Union_requests_approval.Resend')}
                  </a>
                </div>
              </td>
            </tr>
                )
              })
            }
            

            
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "end" }}>
            {list?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
</div>
        

        }
        
      </div>
    </>


  )
}

export default EmployeeRequestApproval