import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from 'react-select'
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployerInfo,
  updateEmployerPersonalInfo,
} from "../Services/https/Employer/Employer";
import { getTimezone, uploadProfilePicture } from "../Services/https/comman/common";
import { lookupdata } from "../Services/https/Employee/Employee";
import { loginState } from "../Reducers/Login/loginSlice";

import ModalDialog from "../shared/Modal";
import Loader from "../shared/Loader";
import ChangePassword from "../shared/changePassword";


const PersonalInfo = () => {
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [disable, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [loader, setLoader] = useState(true);
  const [timezone,setTimeZone]=useState([]);
  const [timezonevalue,setTimezoneValue]=useState(null);
  const { t } = useTranslation();
  const dispatch=useDispatch()
  const logindata= useSelector((state) => state.login);
  const [style, setStyle] = useState([]);
  const [selectedstyle,setSelectedStyle]=useState([]);
  const [updatedstyle,setUpdatedStyle]=useState();
  
  const localization = localStorage.getItem('i18nextLng');

  const signupSchema = Yup.object().shape({
    first_name: Yup.string().required(
      `${t("Validation.InviteModal.Required")}`
    ),
    last_name: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    email: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .email(`${t("Validation.InviteModal.Invalid_email")}`)
      .nullable(),
    phone: Yup.string().required(`${t("Validation.InviteModal.Required")}`),
    // address: Yup.string()
    //   .required(`${t("Validation.InviteModal.Required")}`)
    //   .nullable(),
    postal_code: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    province: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
    country: Yup.string()
      .required(`${t("Validation.InviteModal.Required")}`)
      .nullable(),
  });
 
  
  function PhoneInput(props) {
    return (
      <InputMask
        {...props}
        mask="999-999-9999"
        maskChar={null}
        name="phone"
        value={props.value}
        onChange={props.onChange}
      />
    );
  }

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () =>{
    getEmployerInfo()
    .then((response) => {
      setDetail(response.data.data);
      setLoader(false);
      const getstyles =   response?.data?.data?.style_care?.split(',')?.map(element => {
        return {
          label: element,
          value: element
        }
        
      })
      setSelectedStyle(getstyles)
    })
    .catch((error) => {
      return error;
    });

    getTimezone().then(response=>{
      setTimeZone(response?.data?.data)
    }).catch(err=>{
      return err
    })
  }

  useEffect(()=>{
    lookupdata('care_style',"where_working").then(response=>{
      setStyle(response?.data?.data?.care_style);
    }).catch(err=>{
      return err
    })
    },[localization])

    useEffect(()=>{
      if (selectedstyle?.length >= 0) {
        const userIds = selectedstyle.map(item => item?.value);
        setUpdatedStyle(userIds.join(','))
      }
    },[selectedstyle])


  useEffect(() => {
    Object.keys(errorMessage).map((key) => {
      toast.error(errorMessage[key]);
    });
  }, [errorMessage]);


  const updateAvatar = (file) => {
    setLoader(true)
    const formdata = new FormData();
    formdata.append("profile_image_url",file)
    uploadProfilePicture(formdata).then(response => {
      setLoader(false);
      toast.success(response?.data?.message)
      getProfile();
    }).catch(err => {
      if (err?.response?.status === 422) {
        setErrorMessage(err?.response?.data?.errors)
        setAvatar(null)
      }
      if (err?.response?.status === 400) {
        setAvatar(null)
        setErrorMessage(err?.response?.data?.message)
      }
      setLoader(false)
      return err
    })
  }

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left"
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '8px',
      backgroundColor: '#f2f2f2',
      padding: '10px'

    }),
    indicatorSeparator: () => { },
  }

  const closePopup = (value)=>{
    setModal(value)
    }


  return (
    <>
      {loader && <Loader />}
      {modal && <ModalDialog modalTitle={t('Employee.Profile.PasswordChange')} show={modal} onHide={()=>setModal(false)} component={<ChangePassword close={closePopup} showPopup={modal}/>} />}
      
      <Formik
        initialValues={{
          first_name: detail?.first_name,
          last_name: detail?.last_name,
          email: detail?.email,
          phone: detail?.phone,
          address: detail?.address,
          postal_code: detail?.postal_code,
          province: detail?.province,
          country: detail?.country,
          time_zone:detail?.time_zone,
          care_style:updatedstyle
        }}
        enableReinitialize={true}
        validationSchema={signupSchema}
        onSubmit={(values) => {
          setLoader(true);
          const formdata = new FormData();
          formdata.append("first_name", values.first_name);
          formdata.append("last_name", values?.last_name);
          formdata.append("email", values?.email);
          formdata.append(
            "phone",
            values?.phone?.toString().replace(/\D+/g, "")
          );
          formdata.append("address", values?.address);
          formdata.append("postal_code", values?.postal_code);
          formdata.append("province", values?.province);
          formdata.append("country", values?.country);
          avatar && formdata.append("profile_image_url", avatar);
          formdata.append("time_zone",values?.time_zone);
          formdata.append('care_style',updatedstyle)
          updateEmployerPersonalInfo(formdata)
            .then((response) => {
              toast.success(response?.data?.message);
              setLoader(false);
              setDisabled(!disable)
              getProfile();
              dispatch(loginState({...logindata,...{timezone:values?.time_zone,name:values?.first_name  + " " +  values?.last_name,timezonevalue: timezonevalue?timezonevalue:logindata?.timezonevalue}})) 
            })
            .catch((err) => {
              setLoader(false);

              if (err?.response?.status === 422) {
                setErrorMessage(err?.response?.data?.errors)
              }
              if (err?.response?.status === 400) {
                setErrorMessage(err?.response?.data?.message)
              }
              return err;
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="profile_main">
              <div className="row">
                <div className="col-lg-8 profile_form">
                  <div className="row mt-3">
                    <div className="col-lg-6 mb-3">
                      <div>
                        <label className="mt-2 setting_label ">
                          {t(
                            "Employer.Employer_profile.Personal_info.First_name"
                          )}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="John"
                          name="first_name"
                          disabled={disable}
                          className="crew_schedule_fields form-control placeholder_style"
                          aria-describedby="emailHelp"
                          onChange={handleChange}
                          value={values.first_name}
                        />
                        {errors.first_name && (
                          <div className="error">
                            {touched.first_name && <>{errors.first_name}</>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <label className="mt-2 setting_label">
                          {t(
                            "Employer.Employer_profile.Personal_info.Last_name"
                          )}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="Doe"
                        name="last_name"
                        disabled={disable}
                        className="crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.last_name}
                      />
                      {errors.last_name && (
                        <div className="error">
                          {touched.last_name && <>{errors.last_name}</>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <div>
                        <label className="mt-2 setting_label">
                          {t("Employer.Employer_profile.Personal_info.Email")}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <input
                        type="email"
                        placeholder="Johdoe@mail.com"
                        name="email"
                        disabled={disable}
                        className=" crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.email}
                      />
                      {errors.email && (
                        <div className="error">
                          {touched.email && <>{errors.email}</>}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <label className="mt-2 setting_label">
                          {t("Employer.Employer_profile.Personal_info.Phone")}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <PhoneInput
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="phone"
                        disabled={disable}
                        className="form-control update_employeer_input"
                        placeholder="999-999-9999"
                      />
                      {errors.phone && (
                        <div className="error">
                          {touched.phone && <>{errors.phone}</>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-5">
                      <div>
                        <label className="mt-2 setting_label">
                          {t("Employer.Employer_profile.Personal_info.Address")}
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="123 Breddock Ave Unit 231"
                        name="address"
                        disabled={disable}
                        className="crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.address == "null" || values.address == null ? "" : values.address}
                      />
                      {/* {errors.address && (
                        <div className="error">
                          {touched.address && <>{errors.address}</>}
                        </div>
                      )} */}
                    </div>
                    <div className="col-lg-3">
                      <div>
                        <label className="mt-2 setting_label">
                          {t(
                            "Employer.Employer_profile.Personal_info.Postal_code"
                          )}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="V3K 2K7"
                        name="postal_code"
                        disabled={disable}
                        className="crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.postal_code}
                      />
                      {errors.postal_code && (
                        <div className="error">
                          {touched.postal_code && <>{errors.postal_code}</>}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="mt-2 setting_label">
                          {t(
                            "Employer.Employer_profile.Personal_info.Province"
                          )}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="BC"
                        name="province"
                        disabled={disable}
                        className="crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.province}
                      />
                      {errors.province && (
                        <div className="error">
                          {touched.province && <>{errors.province}</>}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="mt-2 setting_label">
                          {t("Employer.Employer_profile.Personal_info.Country")}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <input
                        type="text"
                        placeholder="CANADA"
                        name="country"
                        disabled={disable}
                        className="crew_schedule_fields form-control placeholder_style"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={values?.country}
                      />
                      {errors.country && (
                        <div className="error">
                          {touched.country && <>{errors.country}</>}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-12">
                      <div>
                        <label className=" mt-2 setting_label">{t('Timezone')}</label>
                      </div>
                      <Select
                      defaultValue={{label:logindata?.timezone,name:logindata?.timezone}}
                      options={timezone?.map((list,index)=>{
                        return {
                          label:list?.timezone_name,
                          name:list?.timezone_name,
                          value:list?.timezone_name_value,
                          code:list?.code_name
                        }
                      })}
                      onChange={(option)=>{setFieldValue("time_zone",option?.name);setTimezoneValue(option?.code)}}
                        styles={CustomStyle}
                        isDisabled={disable}
                        placeholder={<div style={{ textAlign: "left" }}>{t('SelectTimezone')}</div>} />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-12">
                      <div>
                        <label className=" mt-2 setting_label">{t('Employee.Requests.Care')}</label>
                      </div>
                      <Select
                      isMulti
                      name="event_members"
                      id="event_members"
                      value={selectedstyle}
                      options={style?.map((list, index) => {
                        return {
                          label: `${list?.look_up_value}`,
                          value: `${list?.look_up_value}`
                      }})}
                      placeholder={t('Employee.Requests.Care')}
                      // onInputChange={formik.handleChange}
                      onChange={setSelectedStyle}
                      styles={CustomStyle}
                      isDisabled={disable}
                      // className=" form-control crew_schedule_fields mb-3"

                    />
                    </div>
                  </div>


                  <div className="row mt-3">
                    <div className="col-lg-12">
                      <div>
                        <label className="mt-2 setting_label">
                          {t(
                            "Employer.Employer_profile.Personal_info.Password"
                          )}
                          <span className="mandatory">*</span>
                        </label>
                      </div>
                      <div className="form-group input_icon">
                      <input
                          placeholder="********"
                          // type={!showPassword ? "password" : "text"}
                          name="password"
                          className="crew_schedule_fields form-control mb-3 placeholder_style"
                          disabled
                          // value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mt-2  setting_label avatar_section">
                  <div className="ms-4">{t('Caregiver.Profile.Personal.ProfilePicture')}</div>
                  <div className="ms-4 mt-3">
                    <img
                      src={preview ? preview : detail?.profile_image_url}
                      alt="profile picture"
                      style={{ height: "208px", width: "208px" }}
                    />
                  </div>
                  <div className="change_profile mt-3 ms-4">
                    <label
                      for="files"
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {t("Employee.Profile.Avatarlabel")}
                    </label>
                    <input
                      id="files"
                      style={{ visibility: "hidden" }}
                      type="file"
                     accept="image/*"
                      onChange={(e) => updateAvatar(e.target.files[0])}
                    />
                  </div>
                  <div className="row change_passrow">
                    <div
                      className="change_profile mt-5 ms-4"
                      onClick={() => setModal(true)}
                    >
                      {t(
                        "Employer.Employer_profile.Personal_info.Password_change"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "end" }}>
                {
                  !disable &&
                  <>
                    <Button
                      variant="outline-info"
                      className="cancel_childreq"
                      onClick={() => setDisabled(true)}
                    >
                      {t("Employer.Add_employee.Delete_modal.Cancel")}
                    </Button>
                    <Button type="submit" variant="info" className="ms-3 update_child btn w_100" style={{ float: "right" }}
                      onClick={() => setDisabled(false)}
                    >
                      {t('Employee.Profile.Save')}
                    </Button>
                  </>
                }
                {
                  disable && <Button type="button" variant="info" className="ms-3 update_child btn w_100" style={{ float: "right" }}
                    onClick={() => setDisabled(false)}
                  >
                    {t('Employer.Calender_listing.Edit')}
                  </Button>
                }
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PersonalInfo;
