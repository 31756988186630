import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2"; 
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Header/Sidebar"; 
const AppLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [active, setActive] = useState(false); 
    const checkLoginStates = useSelector(role => role.login)
    const isloggedIn = checkLoginStates.loggedIn; 
    const userid=new URLSearchParams(location?.search).get("user_id");

    useEffect(() => {
        if (!isloggedIn && location?.search==='') {
          navigate('/')
        }
      }, [isloggedIn])


    return (<>
        <div className={active ? "wrapper active" : "wrapper"}>
            <Header setActive={setActive} />
            <>
             {userid===null? <Sidebar setActive={setActive}/> : null}   
            </>
            <div className="layout-right">
                <Scrollbars className='scroll_bars'>
                    <Outlet />
                </Scrollbars>
            </div>
        </div>
    </>)
}

export default AppLayout