import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";

import { selfDelete } from "../Services/https/Auth/Auth";
import { clearLocalStorage } from "../Services/storage";

import { loginState } from "../Reducers/Login/loginSlice";
import { inviteEmail } from "../Reducers/EmailInvite/inviteSlice";
import { EmployeeState } from "../Reducers/EmployeeReducer/EmployeeSlice";
import { UnionState } from "../Reducers/UnionReducer/unionSlice";

import Loader from "./Loader";

const DeleteAccount = ({ close }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const passwordSchema = Yup.object().shape({
        password: Yup.string().required(t('ResetSchema.Required'))
    });

    const formik = useFormik({
        initialValues: { password: "" },
        validationSchema: passwordSchema,
        onSubmit: (values) => {
            setLoader(true)
            const data = {
                password: values.password
            }
            selfDelete(data).then(response => {
                setLoader(false);
                clearLocalStorage();
                close();
                dispatch(loginState({
                    loggedIn: false,
                    role: null,
                    name: null,
                    role_data: null,
                    id: null,
                    userid: null,
                    timezone: null,
                    timezonevalue: null,
                    hasfamily: false
                }))
                dispatch(inviteEmail({
                    email: null,
                    role: null,
                    production_name: null,
                    studio_name: null,
                    id: null,
                    prod_id: null,
                    production_id: null,
                    redirectURL: null
                }));
                dispatch(UnionState({ production_id: null }));
                dispatch(EmployeeState({ productionId: null }));
            }).catch(err => {
                if (err) {
                    setLoader(false)
                    toast.error(err?.response?.data?.message)
                }
            })
        },
    })
    return (
        <>
            {loader && <Loader />}
            <h5> {t('DeleteAccount')}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <div>{t('DeleteUser')}</div>
                    <div className="mt-4">
                        <div>{t('Password')}</div>
                        <div className="input_icon generate_invite_input mb-3">
                            <input
                                type={!showPassword ? "password" : "text"}
                                value={formik.values.password}
                                className="form-control"
                                placeholder="*********"
                                name="password"
                                id="password"
                                aria-describedby="basic-addon1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                            />
                            <i
                                onClick={() => setShowPassword(!showPassword)}
                                className={
                                    !showPassword ?
                                        "view_password fa-solid fa-eye-slash " : " view_password fa-solid fa-eye"

                                }
                            ></i>
                        </div>
                        {formik.errors.password && (
                            <div className="error">
                                {formik.touched.password && <>{formik.errors.password}</>}
                            </div>
                        )}

                    </div>

                    <div>
                        <div className="mt-4 d-flex justify-content-around group_buttons">
                            <Button
                                type="button"
                                variant="outline-info"
                                className="btn-lg"
                                onClick={() => close()}
                            >
                                {t('No')}
                            </Button>
                            <Button
                                variant="danger"
                                className="btn-lg"
                                type="submit"
                            >
                                {t('Yes')}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default DeleteAccount;