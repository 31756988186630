import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Dashboard from "../shared/Dashboard";
import CurrentProduction from "./DashboardCurrentProduction";
import Funding from "./DashboardFunding";
import EmployeCard from "./EmployeeCard";
import UnionCard from "./UnionCard";
import EmployeeHide from "./EmployeeHide";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getcurrentprod } from "../Services/https/Employer/Employer";
import { useDispatch, useSelector } from "react-redux";
import { inviteEmail } from "../Reducers/EmailInvite/inviteSlice";
import NoProduction from "./Noproduction";
import Loader from "../shared/Loader";
const EmployerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.invite);
  const { name } = useSelector(state => state.login)
  const [showEmployeeCard, setShowEmployeeCard] = useState(true);
  const [showUnionCard, setShowUnionCard] = useState(false);
  const [show, setShow] = useState(false);
  const [currentprod, setCurrentProd] = useState({});
  const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(false)
  const [id, setId] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    getcurrentprod()
      .then((response) => {
        setCurrentProd(response?.data?.data);
        setId(response?.data?.data?.id);
        dispatch(
          inviteEmail({
            ...state,
            ...{ production_id: response?.data?.data?.id },
          })
        );
        setLoader(false);
      })
      .catch((err) => {
        return err;
      });
  }, [change]);

  const handelHideAndShow = () => {
    setShow(!show);
  };

  const detectChange = (value) => {
    setChange(value)
  }

  return (
    <>
      <div className="dashboard_screen">
        <h3 className=" title_h3">
          {t('Employee.Dashboard.Welcome')}, <span>{name}</span>
        </h3>
      </div>
      {loader ? (
        <Loader />
      ) : Object.keys(currentprod)?.length === 0 ? (
        <NoProduction />
      ) : (
        <div className="employer-dashboard">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div className="row">
                <div className="current-production">
                  <Dashboard
                    text_decorator="true"
                    current={true}
                    title={t("Employer.Dashboard.Current_production")}
                    component={<CurrentProduction observechange={detectChange} change={change} />}
                  />
                </div>
              </div>

            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="row">
                <div className="employer-dashboard-funding">
                  <Dashboard
                    component={<Funding />}
                  />
                </div>
              </div>
              <div className="dashboard_screen  ">
                <div className="white_box">
                  <div className="row">
                    <div className="col-sm-6">
                      <p>
                        <b> {t("Employer.Dashboard.Childcare_coordinator")}</b>
                        <br />
                        {currentprod?.coordinator ? currentprod?.coordinator : "Marie Johnson"} <br />
                        <b>M:</b>{currentprod?.coordinator_phone ? currentprod?.coordinator_phone : "7781234567"}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        {" "}
                        <b> {t("Employer.Dashboard.Main_office")}</b>
                        <br />
                        {currentprod?.office_address ? currentprod?.office_address : "12768 21A Avenue"}
                        <br />
                        <b>M:</b>{currentprod?.office_phone ? currentprod?.office_phone : "7781234567"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="dashboard_screen">
                <div className="white_box exployer_whiteBox">
                  <div className="employee-booking-cards">
                    <div className="row ">
                      <div className="col-xl-4 ">
                        <Button
                          className={showEmployeeCard && "employee-btn-active"}
                          onClick={() => {
                            setShowEmployeeCard(true);
                            setShowUnionCard(false);
                          }}
                        >
                          {t("Employer.Dashboard.Employees")}
                        </Button>
                      </div>
                      <div className="col-xl-4">
                        <Button
                          onClick={() => {
                            setShowUnionCard(true);
                            setShowEmployeeCard(false);
                          }}
                          className={showUnionCard ? "ms-2 employee-btn-active" : ""}
                        >
                          {t("Employer.Dashboard.Union")}
                        </Button>
                      </div>
                    </div>
                    {showEmployeeCard && (
                      <div className="employee-card">
                        {show ? (
                          <EmployeCard data={id} change={change} />
                        ) : (
                          <EmployeeHide data={id} />
                        )}
                      </div>
                    )}
                    {showUnionCard && (
                      <div className="union-card ">
                        <UnionCard data={id} />
                      </div>
                    )}
                  </div>
                  <div className="col mt-5 float-end show_hide">
                    {showEmployeeCard && (
                      <button
                        onClick={() => {
                          handelHideAndShow();
                        }}
                        className="show-manage ms-auto"
                      >
                        {show
                          ? `${t("Employer.Dashboard.Hide")}`
                          : `${t("Employer.Dashboard.Show")}`}
                      </button>
                    )}
                    <button
                      className="show-manage ms-1 "
                      onClick={() => {
                        <>
                          {showEmployeeCard && navigate("/employer/employees")}
                          {showUnionCard && navigate("/employer/unions")}
                        </>;
                      }}
                    >
                      {t("Employer.Dashboard.Manage")}
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      )}
    </>
  );
};
export default EmployerDashboard;
