import React,{useEffect,useState} from "react";

import Dashboard from "../../../../shared/Dashboard";

import Studio from "./studio";
import Overview from "./overview";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { productionOverview } from "../../../../Services/https/Employee/Employee";
import {useParams} from "react-router-dom";
import Loader from '../../../../shared/Loader'
import { getStyleOfCare } from "../../../../Services/https/comman/common";

const Focusedprod = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [data,setData]=useState([])
    const prodId=useParams();
    const [styles,setStyles]=useState(null)
    const [loader,setLoader]=useState(true)

    useEffect(() => {
        productionOverview(prodId.id)
          .then((response) => {
            setLoader(true)
            setData(response?.data?.data);
            setLoader(false)
          })
          .catch((error) => {
            return error;
          });

          getStyleOfCare(prodId.id).then(response=>{
            setStyles(response?.data?.data?.care_style?.split(','))
          }).catch(err=>{
            return err
          })

      },[]);
    return (
        <>
        {loader ? (<Loader/>):
        
        (
            <>
            <div className="d-flex align-items-baseline" >
                <svg style={{cursor:"pointer"}}  onClick={() => navigate(-1)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-left ms-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg >
                <p className="title_h3 ms-4">
                    {data[0]?.production_name}
                </p>
            </div>
    
            <div className="row mb-2">
            <div className="col-xl-12 col-md-12 ">
                    <Dashboard text_decorator="true" title={t('Employee.Focused-productions.Studio')} component={<Studio data={data} />} />
                </div>
            </div>

            <div className="row pb-2">
                <div className="col-xl-7  setHeight">
                    <Dashboard text_decorator="true" title={t('Employee.Focused-productions.Overview')} component={<Overview data={data}  />} />
                </div>
                <div className="col-xl-3">
                    <div className="dashboard_screen">
                        <div className="white_box card_lng">
                            <div className="">
                                <h4 className="title_h4_line"><span>{t('Employee.Focused-productions.Style')}</span> </h4>
                                <ul className="productions_list">
                                    {
                                        styles?.map((style,index)=>{
                                            return(
                                            <h6 key={index}>{style}</h6>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            )}
        </>
    )
}

export default Focusedprod