import React, { useState } from "react";

import Personal from "./Personal";
import Rates from "./Rates"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap";

import ModalDialog from "../../shared/Modal";

import DeleteAccount from "../../shared/deleteAccount";

const CareGiverProfile = () => {
  const [show, setShow] = useState(true);
  const [deletePopup, setDeletePopup] = useState(false);
  const { t } = useTranslation();

  const closePopup = ()=>{
    setDeletePopup(false)
    }

  return (
    <div>
      <div className="white_box manage_screen caregiver_profile_screen">
        <div className="d-flex align-items-center justify-content-between ">
          <h3 className="title_balances">
            {t('Caregiver.Profile.Title')}
          </h3>
          <Button
            type="button"
            variant="danger"
            onClick={() => setDeletePopup(true)}
          >
            {t('DeleteAccount')}
          </Button>
        </div>

        <div className="employee-booking-cards employee-booking-cards2">
          <div className="row mb-3">
            <div className="col-xl-3">
              <Button onClick={() => setShow(true)} className={show ? `employee-btn-active` : null}> {t('Caregiver.Profile.Personal.Title')}</Button>
            </div>
            <div className="col-xl-3">
              <Button onClick={() => setShow(false)} className={!show ? `employee-btn-active` : null}>{t('Caregiver.Profile.Rates.Title')}</Button>
            </div>
          </div>
        </div>
        {show ? <Personal show={show} /> : <Rates />}
        <ModalDialog
          align='left'
          show={deletePopup}
          onHide={() => setDeletePopup(false)}
          component={<DeleteAccount close={closePopup}/>}
        />
      </div>
    </div>
  );
};

export default CareGiverProfile;
