import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { requestProductionReport } from '../Services/https/Employer/Employer';
import { reportRequest } from '../Services/https/Union/Union';
import toast from 'react-hot-toast';

function Funding() {
    const { t } = useTranslation();
    const { role_data } = useSelector(state => state?.login);
    const { production_id } = useSelector(state => state?.invite);
    const id = useSelector(state => state?.union?.production_id)


    const Report = () => {
        if (role_data === "2") {
            requestProductionReport(production_id).then(response => {
                toast.success(response?.data?.message)
            }).catch(err => {
                return err
            })
        }
        else {
            reportRequest(id).then(response => {
                toast.success(response?.data?.data?.message)
            }).catch(err => {
                return err
            })
        }
    }
    return (
        <>
            <div>
                <div className='funding-report-button'> <Button className=' btn-info' style={{ cursor: "pointer" }} onClick={() => Report()}><>{t('Employer.Dashboard.Request_report')}</></Button></div>
            </div>
        </>
    )
}

export default Funding