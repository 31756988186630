import React, { useEffect, useState } from "react";

import {Button,Form,Table,Dropdown} from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ManageCaregiverListing,
  DeleteCaregivers,
  unblockCaregiver,
} from "../Services/https/Admin/Admin";

import Pagination from "../shared/Pagination";
import Loader from "../shared/Loader";
import NoRecords from "../shared/NoRecords";
import Modal from "../shared/Modal";
import { svg } from "../assets/svg/svg";



const ManageCaregiver = () => {
  const [show, setShow] = React.useState(false);
  const [popUp, setPopUp] = React.useState(false);
  const [del, setDel] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [arechieve, setArchieve] = useState(false);
  const [archievemodal, setArchiveModal] = useState(false);
  const [caregivers, setCaregivers] = useState([]);
  const [sort, setSort] = useState("");
  const [searchkeyword, setSearchkeyword] = useState("");
  const [handlesearch, sethandleSearch] = useState(false);
  const [totalcount, setTotalcount] = useState(null);
  const [initialpage, setInitialpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showNoRecord, setShowNoRecord] = useState(false);
  const [deleterecord, setDeleteRecord] = useState([]);
  const pageCount = Math.ceil(totalcount / 10);
 
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Caregiver Listing
  useEffect(() => {
    setLoading(true);
    Listing();
  }, [sort, initialpage,del, handlesearch]);

  const Listing = () => {
    ManageCaregiverListing(sort, searchkeyword, 10, initialpage)
    .then((response) => {
      if (response?.status === 200) {
        setLoading(false);
        setCaregivers(response?.data?.data?.data);
        setDeleteRecord([]);
        setShowNoRecord(true);
        setTotalcount(response?.data?.data?.total)
      }
    })
    .catch((error) => {
      return error;
    });
  }

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };
  // Select caregivers to delete
  const recordstoDelete = (e, id) => {
    const records = [...deleterecord];
    if (e.target.checked) {
      records.push(id);
      setDeleteRecord(records);
    } else {
      const recs = records.filter((rec) => rec !== id);
      setDeleteRecord(recs);
    }
  };

  const UnBlockCaregiver = (id) => {
    setLoading(true);
    unblockCaregiver(id).then(response=>{
      toast.success(response?.data?.message);
      setLoading(false);
      Listing();
    }).catch(err=>{
      return err
    })

  }
 
  return (
    <>
      {show && (
        <Modal
          show={show}
          modalTitle={t("Admin.ManageEmployeer.Modal.Title")}
          description={
            `
          ${t("Admin.ManageEmployeer.Modal.Heading.start")} "` +
            `
          ${deleterecord.length} ` +
            `" 
          ${t("Admin.ManageEmployeer.Modal.Heading.endcaregiver")}
          `
          }
          setShow={setShow}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("Admin.ManageEmployeer.Modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setLoading(true);
                  setShow((prev) => !prev);
                  DeleteCaregivers(deleterecord)
                    .then((response) => {
                      setLoading(false);
                      setDel(true);
                    })
                    .catch((err) => {
                      return err;
                    });
                }}
              >
                {t("Admin.ManageEmployeer.Modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}
      {del ? (
        <Modal
        modalTitle={t("Admin.ManageEmployeer.ModalDelAfter.DeleteCaregiver")}
          show={del}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setDel((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalDelAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}
      {popUp ? (
        <Modal
          show={popUp}
          title={t("Admin.ManageEmployeer.ModalGenerateInvite.Title")}
          field={
            <>
              <div className="text-center w100p font">
                <p style={{ textAlign: "center" }}>
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Heading")}
                </p>
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-8 invitelink">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input1"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input2heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input2"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input3heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input3"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div>
                      {t(
                        "Admin.ManageEmployeer.ModalGenerateInvite.Input4heading"
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(
                          "Admin.ManageEmployeer.ModalGenerateInvite.Input4"
                        )}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          footer={
            <>
              {" "}
              <div className="group_buttons mt-5">
                <Button
                  variant="outline-info"
                  className="btn-lg"
                  onClick={() => setPopUp((prev) => !prev)}
                >
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                </Button>
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setPopUp((prev) => !prev);
                    setInvite((prev) => !prev);
                  }}
                >
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.SendInvite")}
                </Button>
              </div>
            </>
          }
        />
      ) : null}
      {invite ? (
        <Modal
          title={t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Title")}
          show={invite}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setInvite((prev) => !prev);
                }}
              >
                {t("Admin.ManageEmployeer.ModalGenerateInviteAfter.Done")}
              </Button>{" "}
            </div>
          }
        />
      ) : null}

      {archievemodal && (
        <Modal
          title="Are you sure you want to restore?"
          show={archievemodal}
          footer={
            <>
              {" "}
              <div className="group_buttons mt-5">
                <Button
                  variant="outline-info"
                  className="btn-lg"
                  onClick={() => setArchiveModal(false)}
                >
                  {t("Admin.ManageEmployeer.ModalGenerateInvite.Cancel")}
                </Button>
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => setArchiveModal(false)}
                >
                  Confirm
                </Button>
              </div>
            </>
          }
        />
      )}

      <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">
            {arechieve
              ? t("Admin.ManageCaregiver.Archived_caregivers")
              : t("Admin.ManageCaregiver.Manage_caregivers")}
          </h3>
          <div className="search_box d-flex ms-auto">
            <div className="search_box_in">
            <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }}>{svg["Search"]}</span>  
              <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>{" "}
            &nbsp; &nbsp;
            <div className="manage_employeer_dropdown">
              <div className="common_select">
                <span className="arrange">
                  {t("Admin.Dashboard.Dropdown.Sort")}
                </span>
                <select
                  className="admin-dashboard-dropdown"
                  name="language"
                  id="language"
                  selected="Studio"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="first_name">
                    {t("Admin.Dashboard.Dropdown.First_name")}
                  </option>
                  <option value="last_name">
                    {t("Admin.Dashboard.Dropdown.Last_name")}
                  </option>
                  <option value="experience_level_value">{t("Admin.Dashboard.Dropdown.Experience")}</option>
                  <option value="city">
                    {t("Admin.Dashboard.Dropdown.City")}
                  </option>
                </select>
              </div>
              {arechieve ? null : (
                <div>
                  <Button
                    variant="outline-secondary"
                    onClick={() =>
                      setShow(deleterecord.length < 1 ? false : true)
                    }
                  >
                    {deleterecord?.length} {t("Admin.ManageCaregiver.Selected")}
                    {svg["Delete"]}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Table className="custom_table" responsive>
            {caregivers?.length === 0 && showNoRecord ? (
              <NoRecords />
            ) : (
              <>
                <thead>
                  <tr>
                    {arechieve ? null : <th width="50px"></th>}

                    <th>{t("Admin.ManageCaregiver.Table.LastName")}</th>
                    <th>{t("Admin.ManageCaregiver.Table.FirstName")}</th>
                    <th>{t("Admin.ManageCaregiver.Table.Experience")}</th>
                    <th>{t("Admin.ManageCaregiver.Table.Rate")}</th>
                    <th width="300px">{t("Admin.ManageCaregiver.Table.Service")}</th>
                    <th width="200px">{svg["Edit"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {caregivers?.map((list, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr >
                          <td>
                            <Form.Check
                              key={`${list?.user_id}`}
                              className="custom_checkbox"
                              aria-label={`option-${list?.user_id}`}
                              name={`${list?.id}`}
                              onClick={(e) => {
                                recordstoDelete(e, list?.user_id);
                              }}
                            />
                          </td>
                          <td>
                            <b ><span className={list?.is_delete!==null ? "timezone_text":""}>{list?.last_name}</span> </b>
                          </td>
                          <td>
                            <b><span className={list?.is_delete!==null ? "timezone_text":""}>{list?.first_name}</span></b>
                          </td>
                          <td>{list?.experience_level_value}</td>
                          <td>{list?.hourly_rate}</td>
                          <td>
                          {
                            list?.userservice_areas?.map((areas,index)=>{
                              return(
                                   areas?.city_name
                              )
                            }).toString()
                            }
                            </td>
                          {arechieve ? (
                            <td>
                              <a
                                className="link"
                                onClick={() =>
                                  navigate(`/admin/caregivers/updateCaregiver/${list.user_id}`)
                                }
                              >
                                Restore
                              </a>
                            </td>
                          ) : (
                            <td>
                              {/* <a
                                className="link"
                                onClick={() =>
                                  navigate(`/admin/caregivers/updateCaregiver/${list.user_id}`)
                                }
                              >
                                {t("Admin.ManageCaregiver.Table.Update")}
                              </a> */}
                               <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <u> {t("Admin.ManageCaregiver.Table.Update")}</u>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          list?.is_delete===null && 
                          <Dropdown.Item
                          onClick={() =>
                            navigate(`/admin/caregivers/updateCaregiver/${list.user_id}`)
                          }
                        >
                          {t("Admin.ManageCaregiver.Table.Edit")}
                        </Dropdown.Item>
                        }                     
                        {
                          list?.is_delete!==null && <Dropdown.Item
                          onClick={() => {UnBlockCaregiver(list?.user_id)}}
                        >
                          {t("Admin.ManageCaregiver.Table.Unblock")}
                        </Dropdown.Item>
                        }
                        
                      </Dropdown.Menu>
                    </Dropdown>
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </>
            )}
          </Table>
        )}
        <div className="a_productions">
        <div>
          {arechieve ? (
          <Button
            className="btn-sm ms-0 ps-3 pe-3"
            variant="outline-secondary"
          >
            <strong>{t("Admin.ManageCaregiver.cargiver_list")}</strong>
          </Button>
        ) : (
          <>
          <Button
            className="btn-sm ms-0 ps-3 pe-3"
            variant="outline-secondary"
            onClick={() => navigate("/admin/caregivers/archive-caregivers")}
          >
            <strong>{t("Admin.ManageCaregiver.Archive")}</strong>
          </Button>
          <Button
            className="btn-sm ms-3 ps-3 pe-3"
            variant="outline-secondary"
            onClick={() => navigate("/admin/caregivers/cancelled-bookings")}
          >
            <strong>{t("Employee.Bookings.Cancelled")}</strong>
          </Button>
          </>
        )}
          </div>
          <div>
          {caregivers?.length >= 1 && (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            )  
            }
          </div>
          
        
        </div>
       
           
           
      </div>
    </>
  );
};

export default ManageCaregiver;
