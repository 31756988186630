import React,{ useState,useEffect } from "react";

import{Form,Button,Table} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import ModalDialog from "../shared/Modal";
import {svg} from '../assets/svg/svg'
import PayrollDetail from "./PayrollDetail";
import Loader from "../shared/Loader";
import NoRecords from "../shared/NoRecords";
import { dateFormat } from "../shared/moment";
import Pagination from "../shared/Pagination";

import { approvePayroll, exportPayroll, payrollList, viewPayroll } from "../Services/https/Admin/Admin";

const PayrollApprove = () => {
    const {t}=useTranslation();
    const [detail,setDetail]=useState(false);
    const [data,setData]=useState([]);
    const [payrolldetail,setPayrolldetail]=useState(null)
    const [handlesearch, sethandleSearch] = useState(false);
    const [searchkeyword, setSearchkeyword] = useState("");
    const [approverecord, setApproveRecord] = useState([]);
    const [sort, setSort] = useState("0");
    const [loading, setLoading] = useState(false);
    const [initialpage, setInitialpage] = useState(1);
    const [totalcount, setTotalcount] = useState(null);
    const pageCount = Math.ceil(totalcount / 10);
    const language= localStorage.getItem('i18nextLng')

    
    useEffect(()=>{
      list();
    },[handlesearch,sort,initialpage])

    const list = () => {
      setLoading(true);
      payrollList(searchkeyword,sort,10,initialpage).then(response=>{
        setLoading(false);
        setData(response?.data?.data?.data);
        setTotalcount(response?.data?.data?.meta?.pagination?.total)
        setApproveRecord([]);
      }).catch(err=>{
        setLoading(false)
        return err
      })
    }

    const viewpayroll = (id) => {
      setDetail(true);
      setLoading(true)
     viewPayroll(id).then(response=>{
      setLoading(false);
      setPayrolldetail(response?.data?.data)
     }).catch(err=>{
      setLoading(false);
      return err
     })
    }

    const recordstoApprove = (e, id) => {
      const records = [...approverecord];
      if (e.target.checked) {
        records.push(id);
        setApproveRecord(records);
      } else {
        const recs = records.filter((rec) => rec !== id);
        setApproveRecord(recs);
      }
    };

    const updateRecords = (e,id,value) => {
      const data = {
        booking_id:value===true ? approverecord?.join(',') : id
      }
      setLoading(true)
      approvePayroll(data).then(response=>{
        toast.success(response?.data?.message);
        list();
        setLoading(false);
        setApproveRecord([]);
      }).catch(err=>{
        setLoading(false);
        return err
      })
    }

    const downloadReport = () => {
      exportPayroll(searchkeyword,sort).then(response=>
        response.data
      ).then(blob=>{
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "approvalreport.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);  
      }).catch(err=>{
        return err
      })
    }

    const pageChange = (pageNum) => {
      setInitialpage(pageNum);
    };

    return (
        <>
       <div className="white_box manage_screen">
        <div className="title_box d-inline-flex w100p">
          <h3 className="title_h3">{t("Admin.Payroll.Approval")}</h3>
          <div className="search_box  ms-auto">
            <div className="payroll_header">
            <div className="search_box_in">
              <span onClick={() => {
                  sethandleSearch((prev) => !prev);
                  setLoading(true);
                }}>{svg["Search"]}</span>
              <Form.Control
                type="text"
                placeholder={t("Admin.ManageEmployeer.Search")}
                onChange={(e) => setSearchkeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchkeyword(e.target.value);
                    sethandleSearch((prev) => !prev);
                    setLoading(true);
                  }
                }}
              />
            </div>{" "}
            &nbsp; &nbsp;
            <div className="manage_employeer_dropdown">
              <div className="common_select">
                <span className="arrange">{t("Admin.Payroll.Status")}</span>
                <select
                  className="admin-dashboard-dropdown"
                  name="Approve"
                  id="Approve"
                  selected="Approve"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="0">
                  {t("Admin.Payroll.UnApprove")}
                  </option>
                  <option value="1">
                    {t("Admin.Payroll.Approve")}
                  </option>
                </select>
              </div>
              <div >
                <Button
                  variant="outline-secondary"
                  // disabled={approverecord?.length>=1?false:true}
                  onClick={()=>approverecord?.length>=1 && updateRecords("","",true)}
                >
                  {approverecord?.length} 
                  {" " + t('Admin.Payroll.Button').toUpperCase()}
                </Button>
              </div>

              </div>
              <div className="ms-2 excel-section" onClick={()=>downloadReport()}>
             {svg["Excel"]}
             <h6>{t('Admin.Payroll.Export')}</h6>
              </div>
            </div>
          </div>
        </div>

        {
            loading ? <Loader/> : data?.length ===0 ? <div className="mt-5"> <NoRecords/> </div>: <Table className="custom_table" responsive>
            <>
            {detail && <ModalDialog show={detail} onHide={() => setDetail(false)} component={<PayrollDetail data={payrolldetail} loader={loading}/>}/>
            }
              <thead>
                <tr>
                  <th width="50px"></th>
                  <th>{t("Admin.Payroll.Caregiver")}</th>
                  <th>{t("Employer.Employer_profile.Company_info.Production")}</th>
                  <th >{t("Admin.Payroll.Employee")}</th>
                  <th>{t("Admin.Payroll.CheckIn")}</th>
                  <th>{t("Admin.Payroll.CheckOut")}</th>
                  <th>{t("Admin.Payroll.Amount")}</th>
                  <th>{t("Admin.Payroll.Detail")}</th>
                  <th>{t("Admin.Payroll.Button")}</th>
                </tr>
              </thead>
              <tbody>
                {
                    data?.map((list,index)=>{
                        return(
                            <React.Fragment key={index}>
                            <tr >
                            <td>
                          <Form.Check
                            key={`${list?.booking_id}`}
                            className="custom_checkbox"
                            aria-label={`option-${list?.booking_id}`}
                            name={`${list.booking_id}`}
                            onClick={(e) => {
                              recordstoApprove(e, list?.booking_id);
                            }}
                          />
                        </td>
                        <td>{list?.caregiver}</td>
                        <td>{list?.production_name}</td>
                        <td>{list?.employee_name}</td>
                        <td>{dateFormat(list?.check_in_date,"YYYY-MM-DD HH:mm:ss",language) }</td>
                        <td>{dateFormat(list?.check_out_date,"YYYY-MM-DD HH:mm:ss",language) }</td>
                        <td>{`$${list?.amount}`}</td>
                        <td>
                        <Button
              variant="info"
              className=""
              style={{whiteSpace:"nowrap"}}
              onClick={()=>viewpayroll(list?.booking_id)}
            >
              {t("Admin.Payroll.Detail")}
            </Button>
                        </td>
                        <td>
                        <Button
              variant="info"
              className=""
              onClick={()=>list?.status===0 && updateRecords("",list?.booking_id,false)}
            >
              {list?.status===0 ?  t("Admin.Payroll.Button"):t("Admin.Payroll.Approve")}
            </Button>
                        </td>
                        </tr>
                            </React.Fragment>
                        )
                    })
                }
              </tbody>
            </>
    </Table>
        }
          <div className="event_button">
            {data?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
        </div>
        </>
    )
}

export default PayrollApprove;