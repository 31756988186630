import { axiosInstance } from "../interceptor";

export const authForgetPassword = async (data) => {
  return await axiosInstance.post('forget-password', data)
};

export const login = async (url, data) => {
  return await axiosInstance.post(url, data).then((res) => {
    return res
  }).catch((err) => {
    return err;
  })
};

export const getUserRole = async () => {
  return await axiosInstance.get('/user')

}

export const checkLinkExpiration = async (token,language) => {
  return await axiosInstance.get(`validate-forgot-token?token=${token}&language=${language}`)
};

export const authResetPassword = async (data, link_id) => {
  return await axiosInstance.post(`reset-password?link_id=${link_id}`, data)  
};

export const getEmployerDetail = async (id) => {
  return await axiosInstance.get(`get-Production-details/${id}`)    
};

export const getUpdateProductionList = async (id) => {
  return await axiosInstance.get(`production-list?studio_id=${id}`)
};

export const logout = async () => {
  return await axiosInstance.post('/logout')
}

// export const tokenReplace = async (url, data) => {
//   return await axiosInstance.post(url, data).then((res)=>{
//     return res
//   }).catch((err)=>{
//     return err
//   })
// }
export const getStudioList = async () => {
  return await axiosInstance.get(`studio-list`)
};

export const SignUp = async (data,invite_id,role,union_name) => {
  return await axiosInstance.post(`sign-up?role=${role}&invite_id=${invite_id}&union_name=${union_name}`,data)
};

export const requestPendingCount= async (data)=>{
  return await axiosInstance.get(`request-count?role_id=${data}&status=7`)
}

export const detailUpdate =  (id,data)=>{
  return  axiosInstance.put(`update-production-details/${id}`,data)
}

export const additionalUpdate=async (id,data)=>{
  return await axiosInstance.put(`update-additional-Production-details/${id}`,data)
}

export const selfDelete = async (data) => {
  return await axiosInstance.post('self-delete',data);
};

