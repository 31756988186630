

import { useTranslation } from 'react-i18next'

function UnionCards({ list }) {
const {t}=useTranslation();
    return (
        <div className='row '>

            {
                list?.map((employee, index) => {
                    return (
                        <div className="employers_box col-sm-4">
                            <ul className="productions_list">
                                <li>
                                    <img src={employee?.profile_image_url} alt="" />
                                    <div className="d-inline-flex w100p">

                                        <h5>{employee?.first_name} {employee?.last_name}</h5>
                                        <h6>{(employee?.production_employee_role === null || employee?.production_employee_role === "null") ? t('Union.Dashboard.Department') : employee?.production_employee_role}</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )
                })
            }




        </div>
    )
}

export default UnionCards