import React, { useState } from "react";


import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ChildInfo from "./children/childinfo";
import PersonalInfo from "./personal/personal";
import ModalDialog from "../../../shared/Modal";

import DeleteAccount from "../../../shared/deleteAccount";

const Profile = () => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(true)
    const [deletePopup, setDeletePopup] = useState(false);

    const closePopup = () => {
        setDeletePopup(false)
    }

    return (
        <>
            <div className="">
                <div className="white_box manage_screen">
                    <div className="d-flex align-items-center justify-content-between ">
                        <p className="title_balances ">{t(('Profile'))}</p>
                        <Button
                            type="button"
                            variant="danger"
                            onClick={() => setDeletePopup(true)}
                        >
                            {t('DeleteAccount')}
                        </Button>
                    </div>
                    <div className="employee-booking-cards employee-booking-cards2">
                        <div className="row mb-3">
                            <div className="col-xl-3">
                                <Button onClick={() => setToggle(true)} className={toggle ? `employee-btn-active` : null}> {t('Employee.Profile.Personal')}</Button>
                            </div>
                            <div className="col-xl-3">
                                <Button onClick={() => setToggle(false)} className={!toggle ? `employee-btn-active` : null}>{t('Employee.Profile.Children')}</Button>
                            </div>
                        </div>
                    </div>

                    {toggle ? <PersonalInfo /> : <ChildInfo />}
                    <ModalDialog
                        align='left'
                        show={deletePopup}
                        onHide={() => setDeletePopup(false)}
                        component={<DeleteAccount close={closePopup} />}
                    />
                </div>
            </div>
        </>
    )
}

export default Profile