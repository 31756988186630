import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getcurrentprod, getprodlist, switchprod, markcomplete, generateCompleteReport } from "../Services/https/Employer/Employer";

import Loader from "../shared/Loader";
import Modal from "../shared/Modal";
import toast from "react-hot-toast";


const CurrentProduction = ({ observechange, change}) => {
  const { t } = useTranslation();
  const navigate=useNavigate();
  const id = useSelector(state => state.invite?.production_id);
  const {role_data}=useSelector(state=>state.login)
  const [switchProduction, setSwitchProduction] = useState(false);
  const [markAsCompleteModal, setMarkAsCompleteModal] = useState(false);
  const [complete, setComplete] = useState(false);
  const [productionlist, setProdList] = useState([]);
  const [currentprod, setCurrentProd] = useState(null);
  const [prodId, setProdId] = useState(null);
  const [loader, setLoader] = useState(false)
 

  useEffect(() => {
    CurrentProduction();
    getprodlist().then(response => {
      setProdList(response?.data?.data)
    }).catch(err => {
      return err
    })
  }, [switchProduction,id])

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "left"
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '50px',

      borderRadius: '8px',
      backgroundColor: '#FFFFFF',

    }),
    indicatorSeparator: () => { },
  }

  const CurrentProduction = () => {
    getcurrentprod().then(response => {
      setCurrentProd(response?.data?.data);
      observechange(() => !change);
      setLoader(false)
    }).catch(err => {
      return err
    })
  }

  const generateReport = () => {
    generateCompleteReport(id).then((response)=>{
      setComplete(false);
      toast.success(response?.data?.message);
      CurrentProduction();
    }).catch(err=>{
      return err
    })
  }

  return (

    <>
      {
        loader && <Loader />
      }
      <div className={role_data==="3" ? "current-production-header":""}>
        <h5><span onClick={()=>{role_data==="3" && navigate(`/employee/productions/focused-productions/${currentprod?.id}`)}}>{currentprod?.studio_name}</span></h5> 
        <h6>{currentprod?.production_name}</h6>
      </div>
      <div style={{textAlign:"right",marginTop:"auto"}}>
      <div className=" mt-2">
        {
          role_data ==="2" && <button
          className="show-manage ms-auto"
          onClick={() => setMarkAsCompleteModal(!markAsCompleteModal)}
        >
          {t("Employer.Dashboard.Mark_complete")}
        </button>
        }
        
        {
          productionlist?.length > 1 && <button
            onClick={() => setSwitchProduction(!switchProduction)}
            className="show-manage ms-1"
          >
            {t("Employer.Dashboard.Switch")}
          </button>
        }

      </div>
      </div>
      
      {switchProduction && (
        <Modal
          align='left'
          show={switchProduction}
          onHide={() => setSwitchProduction(false)}
          setShow={setSwitchProduction}
          modalTitle={t("Employer.Dashboard.Switch_production_modal.Switch_production")}
          description={t("Employer.Dashboard.Production")}
          field={<>

            <Select options={productionlist?.map((list, index) => ({
              value: list?.production_name,
              label: list?.production_name,
              id: list?.id
            }))}
              styles={CustomStyle}
              onChange={(option) => {
                setProdId(option?.id)
              }}
              placeholder={<div style={{ textAlign: "left" }}>Select Production</div>} />
          </>}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setSwitchProduction((prev) => !prev)}
              >
                {t("Employer.Add_employee.Delete_modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  setSwitchProduction((prev) => !prev);
                  setLoader(true)
                  const data = {
                    production_id: prodId
                  }
                  switchprod(data).then(response => {
                    CurrentProduction();
                  }).catch(err => {
                    return err
                  })
                }}
              >
                {t("Employer.Add_employee.Delete_modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}
      {markAsCompleteModal && (
        <Modal
          show={markAsCompleteModal}
          onHide={() => setMarkAsCompleteModal(false)}
          setShow={setMarkAsCompleteModal}
          modalTitle={t("Employer.Dashboard.Mark_production_complete_modal.Mark_complete")}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => setMarkAsCompleteModal(false)}
              >
                {t("Employer.Add_employee.Delete_modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  markcomplete(id).then(response => {
                    setMarkAsCompleteModal(false);
                    setComplete(true);
                  }).catch(err => {
                    return err
                  })


                }}
              >
                {t("Employer.Add_employee.Delete_modal.Confirm")}
              </Button>
            </div>
          }
        />
      )}

      {complete && (
        <Modal
          show={complete}
          onHide={() => setComplete(false)}
          modalTitle={t("Employer.Dashboard.Complete_production_modal.Complete_production")}
          footer={
            <div className="group_buttons mt-5">
              <Button
                variant="outline-info"
                className="btn-lg"
                onClick={() => {setComplete(false);CurrentProduction()}}
              >
                {t("Employer.Add_employee.Delete_modal.Cancel")}
              </Button>
              <Button
                variant="info"
                className="btn-lg"
                onClick={() => {
                  generateReport();
                  
                }}
              >
                {t("Employer.Dashboard.Complete_production_modal.Send_report")}
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default CurrentProduction;
