import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Button, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-hot-toast";

import Modal from "../../../shared/Modal";
import { svg } from "../../../assets/svg/svg";
import Loader from "../../../shared/Loader";
import NoRecords from "../../../shared/NoRecords";
import { dateFormat } from "../../../shared/moment";
import BookingDetails from "../../../shared/bookingDetails";

import {
  employeeBookings,
  cancelBooking,
} from "../../../Services/https/Employee/Employee";


const Accepted = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([...props?.data]);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({});
  const [showdetails, setShowDetails] = useState(false);
  const [cancelbooking, setCancelBooking] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("")
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (modal) {
      setLoader(true);
      CancelBookingRequest();
    }
  }, [modal]);

  const CancelBookingRequest = () => {
    cancelBooking(id).then((response) => {
      toast.success("User has deleted the request");
      employeeBookings(1, searchKeyword).then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        setModal(false);
        setCancelBooking(false)
      });
    }).catch((err) => {
      toast.error(err.response.data.message);
      setLoader(false);
      setModal(false);
      setCancelBooking(false)
    });
  }

  const getdata = (id) => {
    const getRecord = data?.find((records) => records?.id === id);
    setDetails(getRecord);
  }

  useEffect(() => {
    if (Object.keys(details)?.length >= 1) {
      setShowDetails(true)
    }
  }, [details])

  return (
    <>
      {open && (
        <Modal
          modalTitle={t(`Employee.Bookings.Message`)}
          show={open}
          footer={
            <>
              <div className="group_buttons mt-5">
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  {t("Employee.Bookings.Cancel")}
                </Button>{" "}
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setModal(true);
                  }}
                >
                  {t("Employee.Bookings.Okay")}
                </Button>{" "}
              </div>
            </>
          }
        />
      )}
      {cancelbooking && (
        <Modal
          modalTitle={t(`Employer.Employee_requests_approval.Cancel_confirmation`) + "?"}
          show={cancelbooking}
          description={t(`Employee.Bookings.CancelDescription`)}
          footer={
            <>
              <div className="group_buttons mt-5">
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setCancelBooking(false)
                    // setOpen((prev) => !prev);
                  }}
                >
                  {t("Employee.Bookings.Cancel")}
                </Button>{" "}
                <Button
                  variant="info"
                  className="btn-lg"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  {t("Admin.ManageEmployeer.Modal.Confirm")}
                </Button>{" "}
              </div>
            </>
          }
        />
      )}
      {loader ? (
        <Loader />
      ) : data.length === 0 ? (
        <div style={{ marginTop: "50px" }}>
          {" "}
          <NoRecords />
        </div>
      ) : (

        <>
          <div style={{ paddingBottom: "0px", position: "relative" }}>
            <Table className="custom_table" responsive>
              <thead>
                <tr>
                  <th>{t("Employee.Bookings.Status")}</th>
                  <th width="400px" >{t("Employee.Bookings.Date")}</th>
                  <th>{t("Employee.Bookings.Caretaker")}</th>
                  <th>{t(`Employee.Bookings.Detail`)}</th>

                  <th>{t("Employee.Bookings.Action")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.map((item, index) => {
                    const phone =
                      item?.phone.toString().slice(0, 3) +
                      "-" +
                      item?.phone.toString().slice(3, 6) +
                      "-" +
                      item?.phone.toString().slice(6, 12);

                    return (
                      <React.Fragment key={index} >
                        <tr>
                          <td>
                            <b className="accepted ">
                              {t("Employee.Bookings.Accepted")}
                            </b>
                          </td>

                          <td>
                            <p>
                              {dateFormat(item?.dates, "YYYY-MM-DD HH:mm:ss", lang)
                              }{" "}
                              -
                              <div>
                                {dateFormat(item?.end_date, "YYYY-MM-DD HH:mm:ss", lang)
                                }
                                ({item?.time_zone_code})
                              </div>

                            </p>
                            <div className="d-flex flex-column mt-2">
                              <span>{item?.location}</span>
                            </div>
                          </td>

                          <td>
                            <p><span style={{ cursor: "pointer" }} onClick={() =>
                              navigate(
                                `/employee/booking/caregiverProfile/${item?.caregiver_id}`
                              )
                            }>{item?.caretaker}</span></p>
                            <div className="d-flex flex-column mt-2">
                              <span>{phone}</span>
                              <span>{item?.email}</span>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex flex-column mt-2">
                              <span className="check-code"><>$ {item?.price ? item.price : "0.00"}</>  </span>
                              <span className="check-code"> <>{t("Employee.Bookings.CheckIn")} :</> </span>{item?.check_in_otp}
                              <span className="check-code"><>{t("Employee.Bookings.Checkout")} :</>  </span>{item?.check_out_otp}
                              <div>{item?.fund}</div>
                              <div ><span style={{ cursor: "pointer" }} onClick={() => getdata(item?.id)}>{svg["File"]}</span> </div>
                            </div>
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <u> {t("Employee.Bookings.Manage")}</u>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate(
                                      `/employee/booking/caregiverProfile/${item?.caregiver_id}`
                                    )
                                  }
                                >
                                  {t("Employee.Bookings.Dropdown.CaregiverProfile")}
                                </Dropdown.Item>
                                {
                                  item?.checkout === 0 && <Dropdown.Item
                                    onClick={() => {
                                      setId(item?.id);
                                      let caregiverDate = moment(item?.dates, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
                                      let cureentDate = moment().format("YYYY-MM-DD");
                                      const datedifference = moment(caregiverDate).diff(moment(cureentDate), 'days')
                                      datedifference === 1 ? setOpen(true) : setCancelBooking(true);
                                    }}
                                  >
                                    {t("Employee.Bookings.Dropdown.Cancel")}
                                  </Dropdown.Item>
                                }
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>

                      </React.Fragment>
                    );
                  })

                }
              </tbody>
            </Table>
            {showdetails && <BookingDetails details={details} show={showdetails} setShow={setShowDetails} setDetails={setDetails} />}

          </div>
        </>
      )}

    </>
  );
};

export default Accepted;
