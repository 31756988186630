import React,{ useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Dashboardproductionlist } from "../../Services/https/Admin/Admin";

import Pagination from "../../shared/Pagination";
import Loader from "../../shared/Loader";

import Avatar from '../../assets/Images/avatar.png'


const Productions = () => {
  const navigate = useNavigate();

  const navigation = (address) => {
    navigate(`${address}`);
  };
  const [data, setData] = React.useState("");
  const [productions, setProductions] = useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [sort, setSort] = useState("");
  const [totalcount, setTotalcount] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageCount = Math.ceil(totalcount / 5);
  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };
  const { t } = useTranslation();

  // Productions list
  useEffect(() => {
    setLoading(true);
    Dashboardproductionlist(sort, 5, initialpage)
      .then((response) => {
        setProductions(
          response?.data?.data?.data.map((item) => {
            item.showProductions = false;
            return item;
          })
        );
        setLoading(false);
        setTotalcount(response?.data?.data?.total);
      })
      .catch((error) => {
        return error;
      });
  }, [sort, initialpage]);

  const onShowProductions = (index) => {
    const allProductions = [...productions];
    allProductions[index].showProductions =
      !allProductions[index].showProductions;
    setProductions(allProductions);
  };



  return (
    <>
      <div className="contacts">
        <div className="dashborad_header">
          <h4 className="title_h4_line">
            <span>{t("Admin.Dashboard.Productions")}</span>
          </h4>

          <div className="common_select">
            <span className="arrange">
              {t("Admin.Dashboard.Dropdown.Sort")}
            </span>
            <select
              className="admin-dashboard-dropdown"
              name="language"
              id="language"
              selected="Studio"
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="studio_name">
                {t("Admin.Dashboard.Dropdown.Studio")}
              </option>
              <option value="production_name">
                {t("Admin.Dashboard.Dropdown.Production")}
              </option>
            </select>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ul className="productions_list ave">
            {productions?.map((item, index) => {
              return (
                <>
                  <div className="a_productions" key={index}>
                    <div style={{ width: "60%" }} key={index}>
                      <li>
                        <img
                          src={
                            item?.profile_image_url
                              ? item?.profile_image_url
                              : Avatar
                          }
                          alt=""
                        />
                        <div className="d-inline-flex pointer w100p">
                          <h5>
                            <span onClick={()=>{item?.status===1 ? navigate(`/admin/productions/productionFocused/${item?.production_id}`):
                            navigate(`/admin/productions/finished-productions`,{state:{name:item?.production_name}})}}>
                            {item?.production_name}

                            </span>
                          </h5>
                        </div>
                      </li>
                    </div>

                    <div>
                      <h6>{item?.studio_name}</h6>
                    </div>
                  </div>
                </>
              );
            })}
          </ul>
        )}

        <div className="manage_prod">
          <div style={{ marginTop: "12px" }}>
            {productions?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
                custom="true"
              />
            ) : null}
          </div>
          <div>
            <a
              onClick={() => navigation("/admin/productions")}
              className="link_a "
              // style={{ float: "right" }}
            >
              {t("Admin.Dashboard.ManageProductions")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productions;
